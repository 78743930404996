// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as DOMPurify from "dompurify";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Comment } from "@/assets/icons/shared/comment.svg";
import { ReactComponent as Clock } from "@/assets/icons/shared/duration.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Document } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Show } from "@/assets/icons/shared/show.svg";
import { ReactComponent as Attendees } from "@/assets/icons/training/attendees.svg";
import { ReactComponent as Star } from "@/assets/icons/training/catalog/ects.svg";
import { ReactComponent as Ddpp } from "@/assets/icons/training/catalog/organization.svg";
import { ReactComponent as Type } from "@/assets/icons/training/catalog/type.svg";
import { ReactComponent as Ovvt } from "@/assets/icons/training/ovvt.svg";
import { ReactComponent as Former } from "@/assets/icons/training/training.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { ISession, SessionOutletContext } from "@/components/HealthAccreditationTraining/interface";
import StyledChip from "@/components/styled/StyledChip";
import { HttpStatus } from "@/interfaces/global";
import { SessionMode, TrainerType } from "@/interfaces/training";
import { getEnumKeyByValue, openPreview } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";

interface ViewSessionDialogProps {
    session: ISession;
    onClose: () => void;
}

export default function ViewSessionDialog({ session, onClose }: ViewSessionDialogProps) {
    const { ovvtOrganization, ddpp, healthAccreditationTraining } = session;
    const { regions } = useOutletContext<SessionOutletContext>();

    const onFilePreview = async (fileUuid: string) => {
        const file: File = await getTrainingFile(fileUuid);
        if (!file) return;
        openPreview(file);
    };

    const getTrainingFile = async (fileUuid: string): Promise<File> => {
        const res = await TrainingService.getHealthAccreditationTrainingFile(
            healthAccreditationTraining.uuid,
            fileUuid
        );
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            const fileName = res.headers.get("content-disposition").split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers.get("content-type"),
            });
            return newFile;
        }

        return null;
    };

    return (
        <GenericDialog
            title={`Session de formation n°${session.internalId} : ${healthAccreditationTraining.title}`}
            onClose={onClose}
            maxWidth="xl"
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} closeLabel="Fermer" />}
            renderContent={() => (
                <Stack spacing={1}>
                    <GenericAccordion title="Informations sur la session" defaultExpanded>
                        <Stack
                            spacing={1}
                            sx={{
                                mx: 2,
                            }}
                        >
                            <GenericIconText
                                icon={<Ovvt />}
                                iconTooltip="Organisme organisateur"
                                text={`${ovvtOrganization.name} - ${regions.find((r) => r.inseeCode === ovvtOrganization.regionInseeCode)?.name}`}
                            />
                            <GenericIconText icon={<Ddpp />} iconTooltip="DDPP" text={ddpp?.name} />
                            <GenericIconText
                                icon={<Calendar />}
                                iconTooltip="Date"
                                text={DateTime.fromISO(session.startDate).toLocaleString(DateTime.DATETIME_SHORT)}
                            />
                            <GenericIconText
                                icon={<Location />}
                                iconTooltip="Lieu de formation"
                                text={
                                    session.sessionMode === getEnumKeyByValue(SessionMode, SessionMode.DISTANCE)
                                        ? `${SessionMode[session.sessionMode]}`
                                        : `${SessionMode[session.sessionMode]} : ${
                                              session.address
                                          } ${session.complementaryAddress ?? ""} ${session.postalCode} ${session.city}`
                                }
                            />
                            <GenericIconText
                                icon={<Clock />}
                                iconTooltip="Durée"
                                text={`${healthAccreditationTraining.duration}h`}
                            />
                            <GenericIconText
                                icon={<Attendees />}
                                iconTooltip="Participants"
                                text={`${session.inscriptionNumberMin} à ${session.inscriptionNumberMax} participants`}
                            />
                            <GenericIconText
                                icon={<Former />}
                                iconTooltip="Formateurs"
                                text={session.trainers
                                    .map(
                                        (trainer) =>
                                            `${trainer.firstname} ${trainer.lastname} (${TrainerType[trainer.trainerType]})`
                                    )
                                    .join(", ")}
                            />
                            {!!session.comment && (
                                <GenericIconText icon={<Comment />} iconTooltip="Commentaire" text={session.comment} />
                            )}
                        </Stack>
                    </GenericAccordion>
                    <GenericAccordion title="Détails de la formation" defaultExpanded>
                        <Stack
                            spacing={2}
                            sx={{
                                mx: 2,
                            }}
                        >
                            <Stack spacing={1}>
                                <Typography variant="bold">Points clés</Typography>
                                <GenericIconText
                                    icon={<Type />}
                                    iconTooltip="Formation"
                                    text={`${healthAccreditationTraining.internalId} - ${healthAccreditationTraining.title}`}
                                />
                                <GenericIconText
                                    icon={<Star />}
                                    iconTooltip="Points ECTS"
                                    text={`${healthAccreditationTraining.ectsPoints} ECTS`}
                                />
                                <GenericIconText
                                    icon={<Pet />}
                                    iconTooltip="Public cible"
                                    text={healthAccreditationTraining.targetPublic}
                                />
                                <GenericIconText
                                    icon={<Type />}
                                    iconTooltip="Type"
                                    text={healthAccreditationTraining.type.label}
                                />
                            </Stack>
                            <Stack spacing={1}>
                                <Typography variant="bold">Objectifs de la formation</Typography>
                                <div
                                    className="ql-editor"
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(healthAccreditationTraining.content),
                                    }}
                                />
                            </Stack>
                            <Grid container spacing={1}>
                                {healthAccreditationTraining.attachedFiles.map((attachment) => (
                                    <Grid key={attachment.uuid}>
                                        <StyledChip
                                            key={attachment.uuid}
                                            label={attachment.filename}
                                            onDelete={() => onFilePreview(attachment.uuid)}
                                            deleteIcon={<Show />}
                                            icon={<Document style={{ color: "inherit" }} />}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </GenericAccordion>
                </Stack>
            )}
        />
    );
}
