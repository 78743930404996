// === NPM
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Divider, ListItemIcon } from "@mui/material";
// === LOCAL
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { IDrawerSection } from "../interface";
import DrawerIcon from "./DrawerIcon";
import { DrawerListItemButton } from "./DrawerListItemButton";
import DrawerTooltip from "./DrawerTooltip";

interface DrawerCloseProps {
    config: IDrawerSection[];
}

const DrawerClose = React.memo(({ config }: Readonly<DrawerCloseProps>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | Record<string, boolean>>(null);

    const handleOpenMenu = (index: string) => {
        setAnchorEl({ [index]: true });
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (<>
        {config.map((section, key) => (
            <PermissionsCheck key={key} requiredPermissions={section.permissions} preferences={section.preferences}>
                <Box
                    sx={{
                        px: 2,
                        py: 1
                    }}>
                    {section.children.map((subSection, index) => (
                        <PermissionsCheck
                            key={index}
                            requiredPermissions={subSection.permissions}
                            preferences={subSection.preferences}
                        >
                            {subSection.children ? (
                                <>
                                    <DrawerTooltip
                                        open={Boolean(anchorEl && anchorEl[`${key}-${index}`])}
                                        title={subSection.label}
                                        subSection={subSection}
                                        onClose={handleCloseMenu}
                                        onOpen={() => handleOpenMenu(`${key}-${index}`)}
                                    >
                                        <DrawerListItemButton
                                            id={subSection.id}
                                            selected={location.pathname.startsWith(subSection.selectedPath)}
                                            onClick={() => handleOpenMenu(`${key}-${index}`)}
                                        >
                                            <ListItemIcon sx={{ justifyContent: "center" }}>
                                                <DrawerIcon
                                                    icon={subSection.icon}
                                                    selected={location.pathname.startsWith(subSection.selectedPath)}
                                                />
                                            </ListItemIcon>
                                        </DrawerListItemButton>
                                    </DrawerTooltip>
                                </>
                            ) : (
                                <DrawerTooltip
                                    title={subSection.label}
                                    open={Boolean(anchorEl && anchorEl[`${key}-${index}`])}
                                    onClose={handleCloseMenu}
                                    onOpen={() => handleOpenMenu(`${key}-${index}`)}
                                >
                                    <DrawerListItemButton
                                        id={subSection.id}
                                        onClick={() => navigate(subSection.path)}
                                        selected={location.pathname.startsWith(subSection.path)}
                                    >
                                        <ListItemIcon sx={{ justifyContent: "center" }}>
                                            <DrawerIcon
                                                icon={subSection.icon}
                                                selected={location.pathname.startsWith(subSection.path)}
                                            />
                                        </ListItemIcon>
                                    </DrawerListItemButton>
                                </DrawerTooltip>
                            )}
                        </PermissionsCheck>
                    ))}
                    {key !== config.length - 1 && <Divider sx={{ mt: 2 }} />}
                </Box>
            </PermissionsCheck>
        ))}
    </>);
});

DrawerClose.displayName = "DrawerClose";

export default DrawerClose;
