// === NPM
import React from "react";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { IMonitoringInterventionDetails } from "@/interfaces/vaccination";
import Controls from "../../containers/Controls";
import InitializationAccordion from "../../containers/InitializationAccordion";
import InterventionDataAccordion from "../../containers/InterventionDataAccordion";

interface ViewDialogProps {
    onClose: () => void;
    intervention: IMonitoringInterventionDetails;
}

export default function ViewDialog({ onClose, intervention }: Readonly<ViewDialogProps>) {
    const displayActionButton = () => (
        <SecondaryButton onClick={onClose} variant="outlined">
            Fermer
        </SecondaryButton>
    );
    const renderContent = () => (
        <Stack spacing={2} sx={{
            width: "100%"
        }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                {`Intervention de surveillance ${intervention.generalInformation?.id ?? ""}`}
            </Typography>
            <InitializationAccordion
                generalInformation={intervention.generalInformation}
                farmName={intervention.generalInformation.farmName}
                workshopsLength={intervention.workshops.length}
                dpeName={intervention.generalInformation.dpeName}
                veterinaryFirstname={intervention.generalInformation.veterinaryFirstname}
                veterinaryLastname={intervention.generalInformation.veterinaryLastname}
            />
            {intervention.interventionData && (
                <InterventionDataAccordion interventionData={intervention.interventionData} />
            )}
            {intervention.workshops.some((ws) => ws.controls) && <Controls workshops={intervention.workshops} />}
        </Stack>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title="Récapitulatif de l'intervention de surveillance"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={displayActionButton}
        />
    );
}
