// === NPM
import React, { useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { Card, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { IFarmMonitoring } from "@/interfaces/farm";
import { UserWithoutProperties } from "@/interfaces/user";
import { IMonitoringInterventionForm, MonitoringInterventionStatus } from "@/interfaces/vaccination";
import { statusColor } from "@/resources/CssConstant";
import { getEnumKeyByValue } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import Controls from "../../containers/Controls";
import InitializationAccordion from "../../containers/InitializationAccordion";
import InterventionDataAccordion from "../../containers/InterventionDataAccordion";
import { MonitoringInterventionOutletContext } from "../../interface";
import { useFormMonitoringIntervention } from "./useFormMonitoringIntervention";

interface RecapDialogProps {
    onClose: () => void;
    intervention: IMonitoringInterventionForm;
    veterinaries: UserWithoutProperties[];
    onValid: (finalized: boolean) => void;
    farms: IFarmMonitoring[];
    loading: boolean;
}

export default function RecapDialog({
    onClose,
    intervention,
    veterinaries,
    farms,
    onValid,
    loading,
}: Readonly<RecapDialogProps>) {
    const { form } = useFormMonitoringIntervention();
    const { correctIntervention } = useOutletContext<MonitoringInterventionOutletContext>();
    const { userInfo } = useAuth();
    const correctionReason = useLocation().state?.correctionReason;
    const correctionType = useLocation().state?.correctionType;
    const [finalized, setFinalized] = useState<boolean>(false);

    const farm = farms.find((f) => f.id === form.generalInformation.farmId);
    const isRealized =
        intervention.generalInformation.status ===
        getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.REALIZED);

    const selectedVeterinaries = veterinaries.find((v) => v.id === form.generalInformation.veterinaryId);

    const renderContent = () => (
        <Stack spacing={2} sx={{
            width: "100%"
        }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                {`Intervention de surveillance ${intervention.generalInformation?.id ?? ""}`}
            </Typography>
            <InitializationAccordion
                generalInformation={intervention.generalInformation}
                farmName={farm.name}
                workshopsLength={intervention.workshops.length}
                dpeName={userInfo.properties.dpes.find((dpe) => dpe.id === form.generalInformation.dpeId).name}
                veterinaryFirstname={selectedVeterinaries.firstname}
                veterinaryLastname={selectedVeterinaries.lastname}
            />
            {intervention.interventionData && (
                <InterventionDataAccordion interventionData={intervention.interventionData} />
            )}
            {intervention.workshops.some((ws) => ws.controls) && <Controls workshops={intervention.workshops} />}
            {!correctionReason && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Attestation d'intervention de surveillance
                    </Typography>
                    {renderCertificat()}
                </>
            )}
        </Stack>
    );

    const renderCertificat = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Stack spacing={2}>
                    <IconBannerText
                        message={
                            isRealized
                                ? "Attention\nCocher la case suivante vous permet de générer une attestation de surveillance. Une fois l’attestation générée, vous ne pourrez plus modifier les données de cette intervention."
                                : "Attention\nUne fois finalisée, vous ne pourrez plus modifier les données de cette intervention."
                        }
                        color={statusColor.warningText}
                        backgroundColor={statusColor.warningBackground}
                        icon={<Warning />}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={finalized} onChange={(e) => setFinalized(e.target.checked)} />}
                        label={
                            isRealized
                                ? "Je souhaite générer une attestation de surveillance"
                                : "Je souhaite finaliser cette intervention de surveillance"
                        }
                    />
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const displayActionButton = () => (
        <>
            <SecondaryButton onClick={onClose} variant="outlined" disabled={loading}>
                Retour au formulaire
            </SecondaryButton>

            {correctionReason ? (
                <GenericButton
                    onClick={() => correctIntervention(correctionType, correctionReason, form)}
                    color="primary"
                    label="Enregistrer la correction de l'intervention de surveillance"
                    loading={loading}
                />
            ) : (
                <>
                    <GenericButton
                        onClick={() => onValid(false)}
                        color="primary"
                        label="Enregistrer l'intervention de surveillance"
                        loading={loading}
                    />
                    <GenericButton
                        onClick={() => onValid(true)}
                        color="primary"
                        label={isRealized ? "Valider et générer le certificat" : "Enregistrer et finaliser"}
                        disabled={!finalized}
                        loading={loading}
                    />
                </>
            )}
        </>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title="Récapitulatif de l'intervention de surveillance"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={displayActionButton}
        />
    );
}
