// === Import: NPM
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { saveAs } from "file-saver";
// === Import: LOCAL
import { ReactComponent as FileUpload } from "@/assets/icons/file_upload.svg";
import { ReactComponent as Upload } from "@/assets/icons/upload.svg";
import { colors } from "@/resources/CssConstant";
import { routerLinks } from "@/routers/RouterConstant";
import LocalStorageService from "@/services/LocalStorageService";
import DeleteAction from "./actions/DeleteAction";
import DownloadAction from "./actions/DownloadAction";
import ViewAction from "./actions/ViewAction";
import "./FileUploader.scss";

interface FileUploaderWithPreviewProps {
    files: File[];
    setFiles: Dispatch<SetStateAction<File[]>>;
    maxFiles?: number;
    label?: string;
    validator?: any;
    acceptedFiles: string[];
    required?: boolean;
    info?: string;
}

export default function FileUploaderWithPreview({
    files,
    setFiles,
    acceptedFiles,
    validator,
    maxFiles = 1,
    label = "Déposer le fichier",
    required = false,
    info,
}: Readonly<FileUploaderWithPreviewProps>) {
    const [errors, setErrors] = useState(null);
    const [generalError, setGeneralError] = useState<string>(null);
    const hiddenFileInput = React.useRef(null);

    const { getRootProps } = useDropzone();

    const validateFiles = (fileList: File[]) => {
        const newErrors = {};
        let isValid = true;

        if (fileList.length > maxFiles) {
            isValid = false;
            setGeneralError(`Maximum ${maxFiles} fichier(s) autorisé(s)`);
        }

        const fileNames = fileList.map((file) => file.name);
        const duplicateElements = findDuplicateFiles(fileNames);

        if (duplicateElements.length > 0) {
            isValid = false;
            setGeneralError(`Vous avez déjà ajouté ${duplicateElements.join(", ")}`);
        }

        Array.prototype.forEach.call(fileList, (file) => {
            const error = validator ? validator(file) : null;
            if (error) {
                isValid = false;
                newErrors[file.name] = error.message;
            }
        });

        if (!isValid) {
            setErrors(newErrors);
            return;
        }
        setErrors(null);
        setGeneralError(null);
        setFiles(renameFiles(fileList));
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let newFiles: File[] = Array.from(event.target.files);
        if (maxFiles > 1) {
            newFiles = newFiles.concat(files);
        }
        validateFiles(newFiles);
        event.target.value = "";
    };

    const findDuplicateFiles = (fileNameList: string[]) =>
        fileNameList.filter((item, index) => fileNameList.indexOf(item) !== index);

    const renameFiles = (files: File[]): File[] => {
        const renamedFiles = [];
        files.forEach((file) => {
            const fileName = file.name.replace(/['=;]/g, " ");
            renamedFiles.push(new File([file], fileName, { type: file.type }));
        });
        return renamedFiles;
    };

    const removeFile = (index: number) => {
        const updatedFiles = files.filter((file: File, fileIndex: number) => index !== fileIndex);
        setErrors(null);
        setGeneralError(null);
        setFiles(renameFiles(updatedFiles));
    };

    const onDrop = (event) => {
        event.preventDefault();
        let newFiles: File[] = Array.from(event.dataTransfer.files);
        if (maxFiles > 1) {
            newFiles = newFiles.concat(files);
        }
        validateFiles(newFiles);
    };

    const displayRequired = () => {
        return required ? (
            <Typography component="span" variant="h5" color="error">
                *
            </Typography>
        ) : (
            ""
        );
    };

    const renderText = () => {
        return (
            <Typography
                variant="h5"
                sx={{
                    color: "secondary",
                }}
            >
                {label}
                {displayRequired()}
            </Typography>
        );
    };

    const onFilePreview = (event, file: File) => {
        event.stopPropagation();
        const title = file.name;
        const url = URL.createObjectURL(file);
        LocalStorageService.setLocaleStorageItem(title, url);
        LocalStorageService.setLocaleStorageItem(`${title}Type`, file.type);
        window.open(routerLinks.filePreview.dynamicPath(title), title);
    };

    const onFileDownload = (event, file: File) => {
        event.stopPropagation();
        saveAs(file, file.name);
    };

    return (
        <section className="file-upload">
            <Box className="dropzone" {...getRootProps} onDrop={onDrop} onClick={handleClick}>
                <Upload />
                <input
                    type="file"
                    accept={acceptedFiles.toString()}
                    ref={hiddenFileInput}
                    onInput={handleChange}
                    style={{ display: "none" }}
                    multiple
                />
                {files.length === 0 ? (
                    renderText()
                ) : (
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            justifyContent: "center",
                            my: 1,
                        }}
                    >
                        {files.map((file: File, index) => (
                            <Grid key={file.name}>
                                <Card
                                    sx={{
                                        display: "flex",
                                        borderRadius: "10px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        p: 2,
                                    }}
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    <FileUpload />
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <CardContent sx={{ flex: "1 0 auto", p: 0 }}>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: colors.secondaryColor,
                                                }}
                                            >
                                                {file.name}
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <DownloadAction
                                                onClick={(event) => onFileDownload(event, file)}
                                                title="Télécharger le fichier"
                                            />
                                            <ViewAction
                                                onClick={(event) => onFilePreview(event, file)}
                                                title="Voir le fichier"
                                            />
                                            <DeleteAction
                                                onClick={() => removeFile(index)}
                                                title="Supprimer le fichier"
                                            />
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
                {errors &&
                    Object.entries(errors).map(([key, value]) => (
                        <Typography
                            key={key}
                            sx={{
                                color: colors.error,
                            }}
                        >
                            {`${key} - ${value}`}
                        </Typography>
                    ))}
                {generalError && (
                    <Typography
                        sx={{
                            color: colors.error,
                        }}
                    >
                        {generalError}
                    </Typography>
                )}
                {!errors && info && (
                    <Typography
                        sx={{
                            color: "secondary",
                        }}
                    >
                        {info}
                    </Typography>
                )}
            </Box>
        </section>
    );
}
