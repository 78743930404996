import React, { PropsWithChildren } from "react";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

interface GenericAccordionProps {
    title: string;
    defaultExpanded?: boolean;
}

export default function GenericAccordion({
    title,
    children,
    defaultExpanded,
}: PropsWithChildren<GenericAccordionProps>) {
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            sx={{
                "&.Mui-expanded:first-of-type": {
                    marginTop: "16px",
                },
                "&:before": {
                    display: "none",
                },
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
}
