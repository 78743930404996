// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// === LOCAL
import Spinner from "@/components/generics/layout/Spinner";
import { HttpStatus } from "@/interfaces/global";
import HealthReportingService from "@/services/HealthReportingService";
import { IHealthReportingForm } from "../../interface";
import Form from "./containers/Form/Form";
import FormSelection from "./containers/FormSelection";
import { useFormHealthReporting } from "./containers/useFormHealthReporting";

export default function HealthReportingFormManager() {
    const { form, setForm, isFilling, setIsFilling } = useFormHealthReporting();
    const [draft, setDraft] = useState<IHealthReportingForm>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!isFilling) getDraft();
    }, [isFilling]);

    const getDraft = async () => {
        setLoading(true);
        const response = await HealthReportingService.getDraft(true);
        if (response.status === HttpStatus.OK) {
            setDraft(await response.json());
        } else {
            setDraft(null);
        }
        setLoading(false);
    };

    const handleResetForm = async () => {
        const response = await HealthReportingService.deleteDraft();
        if (response.status === HttpStatus.NO_CONTENT) setIsFilling(true);
    };

    const saveDraft = async (withToaster: boolean, files: File[]) => {
        const response = await HealthReportingService.putDraft(form, files);
        if ((response.status === HttpStatus.CREATED || response.status === HttpStatus.NO_CONTENT) && withToaster) {
            toast.success("Votre brouillon a bien été enregistré. Il sera supprimé automatiquement au bout de 48h.");
            getDraft();
        }
    };

    const handleDeclareForm = () => {
        if (draft) {
            setForm(draft);
        }
        setIsFilling(true);
    };

    const renderContent = () =>
        !isFilling ? (
            <FormSelection draft={draft} handleResetForm={handleResetForm} handleDeclareForm={handleDeclareForm} />
        ) : (
            <Form saveDraft={saveDraft} />
        );

    return loading ? <Spinner /> : renderContent();
}
