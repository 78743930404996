// === NPM
import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Info } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericSwitch from "@/components/generics/buttons/GenericSwitch";
import InfoIcon from "@/components/generics/InfoIcon";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericErrorMessageText from "@/components/generics/text/GenericErrorMessageText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import {
    IChargeableCreateUpdate,
    IChargeableRequiredFields,
    IChargeableVersion,
} from "@/components/VaccinationIahp/Billing/interface";
import { IReferential } from "@/interfaces/referential";
import { colors } from "@/resources/CssConstant";
import { FORM_TEXT } from "@/resources/FormUtils";
import { AmvReferentialsContext, VatReferentialsContext } from "../../../../Management";
import { computeMinimumStartDate } from "../../referentialFormUtils";

interface ChargeableVersionFormProps {
    version: IChargeableVersion;
    previousValues?: IChargeableVersion;
    nextValues?: IChargeableVersion;
    requiredFields: IChargeableRequiredFields;
    onCancel: () => void;
    onSubmit: (data: IChargeableCreateUpdate, uuid?: string) => void;
}

export default function ChargeableVersionForm({
    version,
    previousValues,
    nextValues,
    requiredFields,
    onCancel,
    onSubmit,
}: Readonly<ChargeableVersionFormProps>) {
    const vatReferentials: IReferential[] = useContext(VatReferentialsContext);
    const amvReferentials: IReferential[] = useContext(AmvReferentialsContext);
    const isFirstVersion = version.version === 1;
    const minDate = computeMinimumStartDate(version.startDate, previousValues?.startDate);

    const areTwoVersionsEqual = (v1: IChargeableCreateUpdate, v2: IChargeableCreateUpdate) => {
        if (!v1 || !v2) return false;
        if (v1.vatKey !== v2.vatKey) return false;
        if (requiredFields.amv && v1.amvMultiplierFactor !== v2.amvMultiplierFactor) return false;
        if (requiredFields.amv && v1.amvKey !== v2.amvKey) return false;
        if (requiredFields.externalMultiplier && v1.externalMultiplierFactor !== v2.externalMultiplierFactor)
            return false;
        return true;
    };

    const formSchema = z
        .object({
            startDate: z
                .string()
                .nullable()
                .refine(
                    (value) => {
                        if (isFirstVersion) return true;
                        return DateTime.fromISO(value) >= minDate;
                    },
                    {
                        message: "Vous ne pouvez pas reculer la date de début.",
                    }
                )
                .refine(
                    (value) => {
                        if (isFirstVersion) return true;
                        return nextValues?.startDate
                            ? DateTime.fromISO(value) < DateTime.fromISO(nextValues.startDate)
                            : true;
                    },
                    {
                        message: "La date de début ne peut pas excéder celle de la version suivante.",
                    }
                ),
            active: z.boolean(),
            vatKey: z.string().nullable(),
            amvKey: z.string().nullable(),
            amvMultiplierFactor: z.coerce
                .number({
                    required_error: FORM_TEXT.required,
                    invalid_type_error: "Le multiplicateur AMV doit être un nombre positif",
                })
                .positive("Le multiplicateur AMV doit être un nombre positif")
                .multipleOf(0.0001, {
                    message: "Le multiplicateur AMV ne doit pas excéder 4 chiffres après la virgule",
                })
                .nullable(),
            externalMultiplierFactor: z.coerce
                .number({
                    required_error: FORM_TEXT.required,
                    invalid_type_error: "Le multiplicateur externe doit être un nombre positif",
                })
                .positive("Le multiplicateur externe doit être un nombre positif")
                .multipleOf(0.0001, {
                    message: "Le multiplicateur externe ne doit pas excéder 4 chiffres après la virgule",
                })
                .nullable(),
        })
        .superRefine((object, context) => {
            if (!isFirstVersion && object.startDate === null) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["startDate"],
                });
            }
            if (object.active) {
                if (!object.vatKey) {
                    context.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["vatKey"],
                    });
                }
                if (!object.amvKey && requiredFields.amv) {
                    context.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["amvKey"],
                    });
                }
                if (!object.amvMultiplierFactor && requiredFields.amv) {
                    context.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["amvMultiplierFactor"],
                    });
                }
                if (!object.externalMultiplierFactor && requiredFields.externalMultiplier) {
                    context.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["externalMultiplierFactor"],
                    });
                }
                const actualVersion: IChargeableCreateUpdate = {
                    vatKey: object.vatKey,
                    amvKey: object.amvKey,
                    amvMultiplierFactor: object.amvMultiplierFactor,
                    externalMultiplierFactor: object.externalMultiplierFactor,
                };

                if (
                    areTwoVersionsEqual(actualVersion, previousValues) ||
                    areTwoVersionsEqual(actualVersion, nextValues)
                ) {
                    context.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: "Deux versions consécutives ne peuvent pas être identiques",
                        path: ["active"],
                    });
                }
            } else if ((!previousValues && previousValues?.active) || (nextValues && !nextValues?.active)) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: "Vous ne pouvez pas désactiver deux versions consécutives",
                    path: ["active"],
                });
            }
        });

    type ChargeableSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        watch,
        control,
    } = useForm<ChargeableSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            startDate:
                version.startDate ??
                DateTime.max(DateTime.fromISO(previousValues?.startDate).plus({ days: 1 }), DateTime.now()).toISODate(),
            active: version.active ?? true,
            vatKey: version.vatKey ?? null,
            amvKey: version.amvKey ?? null,
            amvMultiplierFactor: version.amvMultiplierFactor ?? null,
            externalMultiplierFactor: version.externalMultiplierFactor ?? null,
        },
    });

    const active = watch("active");

    const setInactiveValues = () => {
        setValue("vatKey", null);
        setValue("amvKey", null);
        setValue("amvMultiplierFactor", null);
        setValue("externalMultiplierFactor", null);
    };

    const onValid = (formValues: ChargeableSchema) => {
        onSubmit(
            {
                startDate: isFirstVersion ? null : formValues.startDate,
                active: formValues.active,
                vatKey: formValues.vatKey,
                amvKey: formValues.amvKey,
                amvMultiplierFactor: formValues.amvMultiplierFactor,
                externalMultiplierFactor: formValues.externalMultiplierFactor,
            },
            version.uuid
        );
    };

    const renderFields = () => (
        <Stack spacing={2}>
            <Controller
                name="vatKey"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                    <GenericSelect
                        value={value || ""}
                        label="Référence TVA"
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message}
                        required
                        options={vatReferentials}
                        optionsValue="key"
                    />
                )}
            />
            {requiredFields.amv && (
                <Stack spacing={2}>
                    <FormControl fullWidth error={!!errors.amvKey} required>
                        <Controller
                            name="amvKey"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value || ""}
                                    label="Référence AMV"
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error?.message}
                                    required
                                    options={amvReferentials}
                                    optionsValue="key"
                                />
                            )}
                        />
                    </FormControl>
                    <TextField
                        label="Facteur multiplicateur AMV"
                        {...register("amvMultiplierFactor")}
                        required
                        fullWidth
                        error={!!errors.amvMultiplierFactor}
                        helperText={errors?.amvMultiplierFactor?.message.toString()}
                    />
                </Stack>
            )}
            {requiredFields.externalMultiplier && (
                <TextField
                    label="Facteur multiplicateur externe"
                    {...register("externalMultiplierFactor")}
                    required
                    fullWidth
                    error={!!errors.externalMultiplierFactor}
                    helperText={errors?.externalMultiplierFactor?.message.toString()}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Tooltip title={version.externalMultiplierDescription}>
                                        <Info />
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            )}
        </Stack>
    );

    return (
        <Card variant="outlined" sx={{ paddingY: 1, paddingX: 2, width: "100%" }}>
            <CardHeader
                titleTypographyProps={{ fontSize: 18, fontWeight: 500, color: colors.primaryColor }}
                title={`Version ${version.version}`}
            />
            <CardContent>
                <Stack spacing={2}>
                    <GenericErrorMessageText fieldError={errors?.active} />
                    <Stack
                        spacing={1}
                        direction="row"
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        <InfoIcon tooltip="Désactiver une prestation supprimera les valeurs saisies" />
                        <FormControl error={!!errors?.active}>
                            <Controller
                                name="active"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <GenericSwitch
                                        value={value}
                                        onChange={(event) => {
                                            onChange(event);
                                            setInactiveValues();
                                        }}
                                        leftText="Prestation inactive"
                                        rightText="Prestation active"
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <Tooltip title="La date de début de la première version n'est pas modifiable">
                                <span>
                                    <GenericDatePicker
                                        value={isFirstVersion ? "2023-01-01" : value}
                                        name="startDate"
                                        label="Date de début"
                                        onChange={onChange}
                                        error={!!error}
                                        required
                                        helperText={error?.message}
                                        minDate={minDate}
                                        maxDate={DateTime.fromISO(nextValues?.startDate).minus({ days: 1 })}
                                        disabled={isFirstVersion}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    />
                    {active && renderFields()}
                </Stack>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
                <SecondaryButton onClick={() => onCancel()} variant="outlined">
                    Annuler
                </SecondaryButton>
                <Button onClick={handleSubmit(onValid)} variant="contained" color="primary">
                    Enregistrer
                </Button>
            </CardActions>
        </Card>
    );
}
