// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Card, CardActions, CardContent, CssBaseline, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as PSC } from "@/assets/icons/PSC.svg";
import TriggerSsoLogin from "./containers/TriggerSsoLogin";
import "./Login.scss";

interface LoginProps {
    setConfigurationName: Dispatch<SetStateAction<string>>;
    configurationName: string;
}

export default function Login({ setConfigurationName, configurationName }: Readonly<LoginProps>) {
    return (
        (<Stack
            className="login-root"
            sx={{
                height: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <CssBaseline />
            <Card
                elevation={0}
                sx={{
                    backgroundColor: "rgba(255,255,255,0.9)",
                    p: { xs: 2, sm: 3, md: 0 },
                    px: { xs: 0, sm: 0, md: 10 },
                    py: { xs: 0, sm: 0, md: 5 },
                    margin: 4,
                }}
            >
                <CardContent>
                    <Box
                        sx={{
                            pb: 5,
                            display: "flex",
                            justifyContent: "center"
                        }}>
                        <img src="/Logo-CALYPSO.webp" width={500} height={160} alt="Logo Calypso" />
                    </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: "center" }}>
                    <Stack spacing={4} sx={{
                        alignItems: "center"
                    }}>
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            sx={{
                                width: { xs: 200, sm: 210, md: 250 },
                                height: { xs: 80, sm: 100, md: 80 },
                                fontSize: { xs: 14, sm: 15, md: 18 },
                            }}
                            onClick={() => setConfigurationName("CNOV")}
                        >
                            Se connecter
                        </Button>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                            <Typography variant="h5">Je suis pharmacien, je m'identifie avec</Typography>
                            <Button onClick={() => setConfigurationName("PSC")}>
                                <PSC />
                            </Button>
                        </Box>
                    </Stack>
                </CardActions>
            </Card>
            <Box
                sx={{
                    width: "100%",
                    py: 0.5,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    position: "absolute",
                    bottom: 0,
                    textAlign: "center"
                }}>
                <Typography
                    variant="body2"
                    sx={{ color: "white" }}
                >{`Version : ${window._env_.REACT_APP_VERSION}`}</Typography>
            </Box>
            {configurationName && <TriggerSsoLogin configurationName={configurationName} />}
        </Stack>)
    );
}
