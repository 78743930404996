// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Cancel } from "@/assets/icons/actions/cancel.svg";
import { ReactComponent as Info } from "@/assets/icons/actions/info.svg";
import { ReactComponent as Add } from "@/assets/icons/actions/plus.svg";
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import { ReactComponent as Valid } from "@/assets/icons/shared/valid.svg";
import { ReactComponent as Attendees } from "@/assets/icons/training/catalog/attendees.svg";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import IconBannerText from "@/components/generics/IconBannerText";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import {
    GenericPresetFilters,
    PresetFilterConfigurations,
} from "@/components/generics/presetFilters/GenericPresetFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import {
    ISession,
    ISessionShortAdminDdppOvvt,
    SessionStatus,
} from "@/components/HealthAccreditationTraining/interface";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { SessionMode } from "@/interfaces/training";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { createPayload, getEnumKeyByValue } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import TrainingService from "@/services/TrainingService";
import ViewSessionDialog from "../../ViewSessionDialog";

interface SessionsTableProps<T, U> {
    inputFilters: T;
    filterConfigurations: FilterConfigurations<T>;
    initialValues: T;
    setInputFilters: Dispatch<SetStateAction<T>>;
    presetFilterConfigurations?: PresetFilterConfigurations<U, T>;
    renderActionToBeDone?: (params: GridRenderCellParams) => string;
}

export default function SessionsTable<T, U>({
    inputFilters,
    filterConfigurations,
    initialValues,
    setInputFilters,
    presetFilterConfigurations,
    renderActionToBeDone,
}: SessionsTableProps<T, U>) {
    const navigate = useNavigate();

    const { departments } = useDepartments();

    const [sessions, setSessions] = useState<ISessionShortAdminDdppOvvt[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "internalId", sort: "desc" }]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [selectedSession, setSelectedSession] = useState<ISession>(null);
    const [selectedSessionShort, setSelectedSessionShort] = useState<ISessionShortAdminDdppOvvt>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getSessions();
    }, [pagination, sortModel]);

    const getSessions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getHealthAccreditationTrainingSessions(payload);
        if (res.status === HttpStatus.OK) {
            setSessions((await res.json()) as ISessionShortAdminDdppOvvt[]);
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getSession = async (uuid: string) => {
        return await TrainingService.getHealthAccreditationTrainingSession(uuid);
    };

    const cancelSession = async (confirm: boolean) => {
        if (!confirm) {
            setSelectedSessionShort(null);
            return;
        }

        const res = await TrainingService.cancelHealthAccreditationTrainingSession(selectedSessionShort.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Session annulée avec succès");
            setSelectedSessionShort(null);
        }
    };

    const handleOpenViewDialog = async (uuid: string) => {
        const res = await getSession(uuid);
        if (res.status === HttpStatus.OK) {
            setSelectedSession(await res.json());
        }
    };

    const handleOpenEditDialog = async (session: ISessionShortAdminDdppOvvt) => {
        const res = await getSession(session.uuid);
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            navigate(routerLinks.healthAccreditationTraining.sessions.form(), {
                state: { session: data, registrationNumber: session.registrationNumber },
            });
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const switchStatus = (params: GridRenderCellParams) => {
        switch (SessionStatus[params.row.status]) {
            case SessionStatus.ACTIVE:
                return (
                    <IconBannerText
                        message="Active"
                        icon={<Info />}
                        color="black"
                        backgroundColor={statusColor.infoBackground}
                        alignItems
                    />
                );
            case SessionStatus.ACTIVE_CLOSED:
                return (
                    <IconBannerText
                        message="Clôturée"
                        icon={<Warning />}
                        color={statusColor.warningText}
                        backgroundColor={statusColor.warningBackground}
                        alignItems
                    />
                );
            case SessionStatus.REALIZED:
                return (
                    <IconBannerText
                        message="Réalisée"
                        icon={<Info />}
                        color="black"
                        backgroundColor={statusColor.infoBackground}
                        alignItems
                    />
                );
            case SessionStatus.CANCELED:
                return (
                    <IconBannerText
                        message="Annulée"
                        icon={<Cancel />}
                        color="black"
                        backgroundColor={statusColor.cancelBackground}
                        alignItems
                    />
                );
            case SessionStatus.FINISHED:
                return (
                    <IconBannerText
                        message="Terminée"
                        icon={<Valid />}
                        color={statusColor.successText}
                        backgroundColor={statusColor.successBackground}
                        alignItems
                    />
                );
        }
    };

    const columns: GridColDef[] = [
        {
            field: "internalId",
            headerName: "Identifiant",
            flex: 1,
        },
        {
            field: "trainingTitle",
            headerName: "Nom de la formation",
            flex: 2,
        },
        {
            field: "startDate",
            headerName: "Date de formation",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => DateTime.fromISO(params.row.startDate).toLocaleString(),
        },
        {
            field: "registrationLimitDate",
            headerName: "Date limite",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                DateTime.fromISO(params.row.registrationLimitDate).toLocaleString(),
        },
        {
            field: "department",
            headerName: "Département",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                departments.find((d) => d.inseeCode === params.row.ddpp.department)?.name,
        },
        {
            field: "sessionMode",
            headerName: "Mode",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => SessionMode[params.row.sessionMode],
        },
        {
            field: "registrationNumber",
            headerName: "Nombre d'inscrits",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.registrationNumber}/${params.row.inscriptionNumberMax}`,
            sortable: false,
        },
        ...(renderActionToBeDone
            ? [
                  {
                      field: "actionToBeDone",
                      headerName: "Action à effectuer",
                      flex: 2,
                      sortable: false,
                      valueGetter: (params: GridRenderCellParams) => renderActionToBeDone(params),
                  },
              ]
            : []),
        {
            field: "status",
            headerName: "Statut",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <Box
                    sx={{
                        width: 100,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {switchStatus(params)}
                </Box>
            ),
        },
        {
            ...ActionsColumnProps,
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction title="Voir le détail" onClick={() => handleOpenViewDialog(params.row.uuid)} />
                    <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_OVVT, UserSituation.USER_OVVT]}>
                        {(params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE) ||
                            params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED)) && (
                            <>
                                <EditAction
                                    title="Modifier la session"
                                    onClick={() => handleOpenEditDialog(params.row)}
                                />
                                <IconActionButton
                                    title="Annuler la session"
                                    onClick={() => setSelectedSessionShort(params.row)}
                                    icon={<Cancel />}
                                />
                            </>
                        )}
                    </PermissionsCheck>
                    <PermissionsCheck
                        requiredPermissions={[
                            UserSituation.ADMIN_OVVT,
                            UserSituation.USER_OVVT,
                            UserSituation.ADMIN_DDPP,
                            UserSituation.USER_DDPP,
                        ]}
                    >
                        {params.row.status !== getEnumKeyByValue(SessionStatus, SessionStatus.CANCELED) && (
                            <IconActionButton
                                icon={<Attendees />}
                                onClick={() =>
                                    navigate(
                                        `${routerLinks.healthAccreditationTraining.sessions.base()}/${params.row.uuid}/inscriptions`,
                                        {
                                            state: {
                                                session: {
                                                    internalId: params.row.internalId,
                                                    title: params.row.trainingTitle,
                                                    startDate: params.row.startDate,
                                                },
                                            },
                                        }
                                    )
                                }
                                title="Gérer les inscriptions"
                            />
                        )}
                    </PermissionsCheck>
                </Box>
            ),
        },
    ];

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography variant="h4">
                        Sessions de formation en vue du maintien de l'habilitation sanitaire
                    </Typography>
                    <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_OVVT, UserSituation.USER_OVVT]}>
                        <GenericButton
                            startIcon={<Add />}
                            label="Nouvelle session"
                            onClick={() => navigate(routerLinks.healthAccreditationTraining.sessions.form())}
                        />
                    </PermissionsCheck>
                </Box>
                {presetFilterConfigurations && (
                    <GenericPresetFilters
                        superFilterConfiguration={presetFilterConfigurations}
                        inputFilters={inputFilters}
                        setInputFilters={setInputFilters}
                        clearFilters={() => setInputFilters(initialValues)}
                    />
                )}
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={initialValues}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            rows={sessions}
                            columns={columns}
                            getRowId={(row) => row.uuid}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                        />
                    </CardContent>
                </Card>
            </Stack>
            {selectedSession && (
                <ViewSessionDialog session={selectedSession} onClose={() => setSelectedSession(null)} />
            )}
            {selectedSessionShort && (
                <GenericConfirmDialog
                    title={`Annulation de la session ${selectedSessionShort.internalId}`}
                    onClose={cancelSession}
                    message={`Êtes-vous sûr de vouloir annuler la session ${selectedSessionShort.internalId} concernant la formation "${selectedSessionShort.trainingTitle}" ?\nLes inscriptions en cours seront annulées et aucune nouvelle inscription ne sera possible.`}
                />
            )}
        </>
    );
}
