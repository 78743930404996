// === NPM
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { IHealthReportingForm } from "@/components/HealthReporting/AnimalAbuse/interface";
import { HttpStatus } from "@/interfaces/global";
import HealthReportingService from "@/services/HealthReportingService";
import { useFormHealthReporting } from "../useFormHealthReporting";
import CommentsAndFiles from "./containers/CommentsAndFiles/CommentsAndFiles";
import RecapDialog from "./containers/RecapDialog";
import ReportedAnomalies from "./containers/ReportedAnomalies/ReportedAnomalies";
import ReportInformations from "./containers/ReportInformations/ReportInformations";
import WorkshopInformations from "./containers/WorkshopInformations/WorkshopInformations";
import FormStepper from "./FormStepper";

interface FormProps {
    saveDraft: (withToaster: boolean, files: File[]) => void;
}

export default function Form({ saveDraft }: Readonly<FormProps>) {
    const { form, setForm, resetData, activeStep, setActiveStep } = useFormHealthReporting();
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [files, setFiles] = useState<File[]>([]);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [validateForm, setValidateForm] = useState<boolean>(false);
    const [saveAndQuit, setSaveAndQuit] = useState<boolean>(false);

    const formRef = useRef<HTMLButtonElement>(null);
    const getValuesRef = useRef<HTMLButtonElement>(null);

    const steps = [
        "Informations relatives au signalement",
        "Informations relatives à la détention",
        "Les anomalies remarquées",
        "Commentaires et fichiers",
    ];

    useEffect(() => {
        getHealthReportingFiles();
    }, []);

    useEffect(() => {
        if (validateForm) {
            setOpenRecapDialog(true);
        }
    }, [validateForm]);

    useEffect(() => {
        if (saveAndQuit) {
            saveDraft(true, files);
            resetData();
        }
    }, [saveAndQuit]);

    const getHealthReportingFile = async (fileList: File[], fileUuid: string) => {
        const res = await HealthReportingService.getDraftFile(fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            const fileName = res.headers.get("content-disposition").split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers.get("content-type"),
            });
            fileList.push(newFile);
        }
    };

    const getHealthReportingFiles = async () => {
        const fileList: File[] = [];
        if (form?.attachedFiles) {
            Promise.all(form?.attachedFiles?.map((attachment) => getHealthReportingFile(fileList, attachment.uuid)));
        }
        setFiles(fileList);
    };

    const handleConfirmCancel = (confirm: boolean) => {
        setOpenConfirmCancelDialog(false);
        if (confirm) resetData();
    };

    const handleSaveAndQuit = () => {
        getValuesRef.current.click();
    };

    const handleNextStep = (partialFormValues: Partial<IHealthReportingForm>, finalStep?: boolean) => {
        setForm({
            ...form,
            ...partialFormValues,
        });
        if (finalStep) {
            setValidateForm(true);
        } else {
            setActiveStep(activeStep + 1);
        }
    };

    const handlePreviousStep = () => {
        setActiveStep(activeStep - 1);
    };

    const handleSubmit = async (confirm: boolean) => {
        setOpenRecapDialog(false);
        setValidateForm(false);
        if (!confirm) return;
        const res = await HealthReportingService.postHealthReporting(form, files);
        if (res.status === HttpStatus.CREATED) {
            toast.success("Déclaration enregistrée avec succès");
            resetData();
        }
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <ReportInformations
                        formRef={formRef}
                        getValuesRef={getValuesRef}
                        handleNextStep={handleNextStep}
                        setSaveAndQuit={setSaveAndQuit}
                    />
                );
            case 1:
                return (
                    <WorkshopInformations
                        formRef={formRef}
                        getValuesRef={getValuesRef}
                        handleNextStep={handleNextStep}
                        setSaveAndQuit={setSaveAndQuit}
                    />
                );
            case 2:
                return (
                    <ReportedAnomalies
                        formRef={formRef}
                        getValuesRef={getValuesRef}
                        handleNextStep={handleNextStep}
                        setSaveAndQuit={setSaveAndQuit}
                    />
                );
            case 3:
                return (
                    <CommentsAndFiles
                        formRef={formRef}
                        getValuesRef={getValuesRef}
                        handleNextStep={handleNextStep}
                        files={files}
                        setFiles={setFiles}
                        setSaveAndQuit={setSaveAndQuit}
                    />
                );
        }
    };

    return (
        (<Stack
            spacing={3}
            sx={{
                height: "100%",
                width: "100%",
                px: 2
            }}>
            <Typography variant="h4">Nouveau signalement de maltraitance</Typography>
            <FormStepper
                activeStep={activeStep}
                steps={steps}
                handleNextStep={() => {
                    formRef.current.click();
                }}
                handlePreviousStep={handlePreviousStep}
                setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
                handleSaveAndQuit={handleSaveAndQuit}
            >
                <Box sx={{ flexGrow: 1, my: 4 }}>{renderSteps()}</Box>
            </FormStepper>
            {openConfirmCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleConfirmCancel}
                />
            )}
            {openRecapDialog && <RecapDialog onClose={handleSubmit} files={files} />}
        </Stack>)
    );
}
