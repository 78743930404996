// === NPM
import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import {
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Lock } from "@/assets/icons/billing/lock.svg";
import { ReactComponent as Unlock } from "@/assets/icons/billing/unlock.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import EditAction from "@/components/generics/actions/EditAction";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { IAmvVersion, IChargeableVersion, IVatVersion } from "@/components/VaccinationIahp/Billing/interface";
import { colors } from "@/resources/CssConstant";
import { isDateInRange, toLocaleDateFormat } from "@/resources/utils";

interface VersionTileLayoutProps extends PropsWithChildren {
    version: IVatVersion | IAmvVersion | IChargeableVersion;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    deleteVersion: (uuid: string) => void;
}
export default function VersionTileLayout({
    version,
    setIsEditing,
    deleteVersion,
    children,
}: Readonly<VersionTileLayoutProps>) {
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    return (
        <Badge
            color="primary"
            badgeContent={
                <Tooltip title="Cette version est actuellement en vigueur">
                    <span>!</span>
                </Tooltip>
            }
            sx={{
                "width": "100%",
                "& .MuiBadge-badge": {
                    right: 16,
                    top: 16,
                },
            }}
            invisible={
                !isDateInRange(DateTime.now().toLocaleString(), [version.startDate ?? "2023-01-01", version.endDate])
            }
        >
            <Card variant="outlined" sx={{ paddingY: 1, paddingX: 2, width: "100%" }}>
                <CardHeader
                    titleTypographyProps={{ fontSize: 18, fontWeight: 500, color: colors.primaryColor }}
                    title={`Version ${version.version}`}
                    action={
                        !version.used && (
                            <>
                                <EditAction title="Modifier" onClick={() => setIsEditing(true)} />
                                {!version.endDate && version.version > 1 && (
                                    <DeleteAction title="Supprimer" onClick={() => setConfirmDelete(true)} />
                                )}
                            </>
                        )
                    }
                />
                <CardContent>
                    <Stack spacing={2}>
                        <Chip
                            icon={
                                version.used ? (
                                    <Lock style={{ color: colors.primaryColor }} />
                                ) : (
                                    <Unlock style={{ color: colors.primaryColor }} />
                                )
                            }
                            label={version.used ? "Utilisée dans un mémoire" : "Inutilisée"}
                            sx={{
                                p: 1,
                                border: `3px solid ${colors.primaryColor}`,
                                color: colors.primaryColor,
                                fontSize: 16,
                                fontWeight: 600,
                                backgroundColor: colors.white,
                                width: "fit-content",
                            }}
                        />
                        <GenericIconText
                            icon={<Calendar />}
                            iconTooltip="Dates de validité"
                            text={
                                version.endDate
                                    ? `Du ${toLocaleDateFormat(version.startDate) ?? "01/01/2023"} au ${toLocaleDateFormat(version.endDate)}`
                                    : `Débute le ${toLocaleDateFormat(version.startDate) ?? "01/01/2023"}`
                            }
                        />
                        {children}
                        {confirmDelete && (
                            <Typography variant="h5" color="error">
                                Êtes-vous sûr de vouloir supprimer cette version ?
                            </Typography>
                        )}
                    </Stack>
                </CardContent>
                {confirmDelete && (
                    <CardActions sx={{ justifyContent: "center" }}>
                        <SecondaryButton onClick={() => setConfirmDelete(false)} variant="outlined">
                            Non
                        </SecondaryButton>
                        <Button
                            onClick={() => {
                                deleteVersion(version.uuid);
                                setConfirmDelete(false);
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Oui
                        </Button>
                    </CardActions>
                )}
            </Card>
        </Badge>
    );
}
