// === NPM
import React from "react";
import { SellOutlined } from "@mui/icons-material";
import { Card, CardHeader, Link, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === LOCAL
import { ReactComponent as Check } from "@/assets/icons/farm/check.svg";
import { ReactComponent as Close } from "@/assets/icons/farm/close-square.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Comment } from "@/assets/icons/shared/comment.svg";
import { ReactComponent as Home } from "@/assets/icons/shared/home.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Paper } from "@/assets/icons/shared/paper.svg";
import { ReactComponent as User } from "@/assets/icons/shared/user.svg";
import { ReactComponent as Workshop } from "@/assets/icons/shared/workshop.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { IWorkshopRelation } from "@/interfaces/farm";
import { IReferential } from "@/interfaces/referential";
import { toLocaleDateFormat } from "@/resources/utils";

interface ViewRelationDialogProps {
    onClose: () => void;
    relation: IWorkshopRelation;
    getFile: (workshopId: string, fileUuid: string) => void;
    closureReasons: IReferential[];
}
export default function ViewRelationDialog({
    onClose,
    relation,
    getFile,
    closureReasons,
}: Readonly<ViewRelationDialogProps>) {
    const renderCloseRelationReason = () => (
        <Card sx={{ p: 2 }}>
            <CardHeader title="Informations de clôture" />
            <StyledCardContent>
                <Stack spacing={1}>
                    <GenericIconText
                        icon={<User />}
                        text={closureReasons.find((reason) => relation.closingReason === reason.key)?.label}
                        iconTooltip="Motif de clôture"
                    />
                    <GenericIconText
                        icon={<Calendar />}
                        text={toLocaleDateFormat(relation.closingDate)}
                        iconTooltip="Date de clôture"
                    />
                    {relation.closingComment && (
                        <GenericIconText icon={<Comment />} text={relation.closingComment} iconTooltip="Commentaire" />
                    )}
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderRelationInformations = () => (
        <Card sx={{ p: 2 }}>
            <CardHeader title="Informations de la relation" />
            <StyledCardContent>
                <Grid container rowSpacing={4}>
                    <Grid size={6}>
                        <Stack spacing={1}>
                            <Typography variant="bold">Établissement concerné</Typography>
                            <GenericIconText
                                icon={<SellOutlined />}
                                text={relation.farmName}
                                iconTooltip="Nom de l'établissement"
                            />
                            <GenericIconText
                                icon={<Home />}
                                text={`${relation.dpeId} - ${relation.dpeName}`}
                                iconTooltip="Informations du DPE"
                            />
                        </Stack>
                    </Grid>
                    <Grid size={6}>
                        <Stack spacing={1}>
                            <Typography variant="bold">Atelier</Typography>
                            <GenericIconText
                                icon={<Workshop />}
                                text={`Identifiant : ${relation.workshopId}`}
                                iconTooltip="Identifiant de l'atelier"
                            />

                            <GenericIconText
                                icon={<Location />}
                                text={`${relation.workshopDepartmentCode} ${relation.workshopDepartmentName} - ${relation.workshopCity}`}
                                iconTooltip="Département - Commune"
                            />
                            {relation.workshopClosingDate && (
                                <GenericIconText
                                    icon={<Calendar />}
                                    text={relation.workshopClosingDate}
                                    iconTooltip="Date de clôture de l'atelier"
                                />
                            )}
                        </Stack>
                    </Grid>
                    <Grid>
                        <Stack spacing={1}>
                            <Typography variant="bold">Statut de la relation</Typography>
                            <GenericIconText
                                icon={relation.closingDate ? <Close /> : <Check />}
                                text={`${relation.closingDate ? "Clôturée" : "Active"}`}
                                iconTooltip="Statut de la relation"
                            />
                            <GenericIconText
                                icon={<Calendar />}
                                text={relation.openingDate}
                                iconTooltip="Date d'ouverture de la relation"
                            />
                            <Stack direction="row" alignItems="center">
                                <GenericIconText
                                    icon={<Paper />}
                                    text={
                                        relation.cerfaFileUuid
                                            ? ""
                                            : "Je ne dispose pas du CERFA permettant de confirmer la validité de cette déclaration"
                                    }
                                    iconTooltip="Document CERFA"
                                />
                                {relation.cerfaFileUuid && (
                                    <Link
                                        component="button"
                                        variant="body2"
                                        color="inherit"
                                        onClick={() => getFile(relation.uuid, relation.cerfaFileUuid)}
                                    >
                                        Document CERFA
                                    </Link>
                                )}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Stack spacing={2}>
            {renderRelationInformations()}
            {relation.closingDate && renderCloseRelationReason()}
        </Stack>
    );

    return (
        <GenericDialog
            title="Détails de la relation"
            renderContent={() => renderContent()}
            onClose={onClose}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} closeLabel="Fermer" />}
        />
    );
}
