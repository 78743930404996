// === NPM
import React, { Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, FormControl, Stack, TextField, Typography } from "@mui/material";
import { z, ZodIssueCode } from "zod";
// === LOCAL
import { ReactComponent as Danger } from "@/assets/icons/shared/danger-triangle.svg";
import GenericSwitch from "@/components/generics/buttons/GenericSwitch";
import IconBannerText from "@/components/generics/IconBannerText";
import CityPostalCodeAutocomplete from "@/components/generics/inputs/CityPostalCodeAutocomplete";
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import { ISessionCreate } from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { IRegion } from "@/interfaces/referential";
import { SessionMode } from "@/interfaces/training";
import { IAgriMinistryGeoUnitShort } from "@/interfaces/user";
import { statusColor } from "@/resources/CssConstant";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { getEnumKeyByValue, typedObjectKeys } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";

type SessionLocationForm = Pick<
    ISessionCreate,
    "ddppUuid" | "trainingSessionMode" | "address" | "complementaryAddress" | "postalCode" | "city"
>;

interface SessionLocationProps {
    form: ISessionCreate;
    formRef: RefObject<HTMLButtonElement>;
    backRef: RefObject<HTMLButtonElement>;
    onStepChange: (data: Partial<SessionLocationForm>, previous?: boolean) => void;
    selectedDdpp: IAgriMinistryGeoUnitShort;
    setSelectedDdpp: Dispatch<SetStateAction<IAgriMinistryGeoUnitShort>>;
    regions: IRegion[];
    ddppName: string;
}

const [firstMode, ...other] = typedObjectKeys(SessionMode);

const formSchema = z
    .object({
        ddppUuid: stringRequired(),
        trainingSessionMode: z.enum([firstMode, ...other]),
        address: z.string().optional().nullable(),
        complementaryAddress: z.string().optional().nullable(),
        postalCode: z.string().optional().nullable(),
        city: z.string().optional().nullable(),
    })
    .superRefine((values, context) => {
        if (SessionMode[values.trainingSessionMode] === SessionMode.FACE_TO_FACE) {
            if (!values.address) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["address"],
                });
            }
            if (!values.postalCode) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["postalCode"],
                });
            }
            if (!values.city) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["city"],
                });
            }
        }
    });

type ValidationSchema = z.infer<typeof formSchema>;

export default function SessionLocation({
    form,
    formRef,
    backRef,
    onStepChange,
    selectedDdpp,
    setSelectedDdpp,
    regions,
    ddppName,
}: Readonly<SessionLocationProps>) {
    const auth = useAuth();
    const [ddpps, setDdpps] = useState<IAgriMinistryGeoUnitShort[]>([]);
    const [ddppSearch, setDdppSearch] = useState<string>((ddppName || selectedDdpp?.name) ?? "");

    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        watch,
        setValue,
        getValues,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            ddppUuid: form?.ddppUuid,
            trainingSessionMode: form?.trainingSessionMode,
            address: form?.address,
            complementaryAddress: form?.complementaryAddress,
            postalCode: form?.postalCode,
            city: form?.city,
        },
    });

    const trainingSessionMode = watch("trainingSessionMode");
    const ddppUuid = watch("ddppUuid");

    useEffect(() => {
        if (ddppSearch) {
            getDdpps();
        }
    }, []);

    useTimeout(() => {
        if (ddppSearch) {
            getDdpps();
        }
    }, [ddppSearch]);

    const getDdpps = async () => {
        const payload = { page: 0, size: 20, type: "DDPP", name: ddppSearch };
        const res = await UserService.getAgriMinistryGeoUnits(payload);
        if (res.status === HttpStatus.OK) {
            setDdpps(await res.json());
        }
    };

    const renderWarningDdpp = () =>
        regions.find((r) => r.departments.find((d) => d.inseeCode === selectedDdpp.inseeCode))?.inseeCode !==
            auth.userInfo.properties?.regionInseeCode && (
            <IconBannerText
                icon={<Danger />}
                message="La DDPP responsable de la session que vous avez renseignée est hors de votre région."
                color={statusColor.warningText}
                backgroundColor={statusColor.warningBackground}
            />
        );

    const onChangeDdpp = (event) => {
        setSelectedDdpp(event?.target?.value);
        setValue("ddppUuid", event?.target?.value?.uuid);
    };

    const handleCityPostalCodeChange = (event) => {
        setValue("postalCode", event.target.value?.postalCode ?? "");
        setValue("city", event.target.value?.name ?? "");
    };

    return (
        <>
            <form onSubmit={handleSubmit((data) => onStepChange(data as SessionLocationForm))} noValidate>
                <Stack spacing={2}>
                    <Typography variant="h5">DDPP responsable de la formation</Typography>
                    <Card sx={{ p: 2 }}>
                        <StyledCardContent>
                            <Stack spacing={1}>
                                <GenericAutocomplete
                                    value={ddpps.find((ddpp) => ddpp.uuid === ddppUuid) ?? null}
                                    onChange={onChangeDdpp}
                                    onInputChange={(event, value) => setDdppSearch(value)}
                                    label="DDPP du lieu de formation"
                                    options={ddpps}
                                    required
                                    getOptionLabel={(option) => option?.name}
                                    optionValue="uuid"
                                    isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                    error={!!errors?.ddppUuid}
                                    helperText={errors?.ddppUuid?.message}
                                />
                                {selectedDdpp && renderWarningDdpp()}
                            </Stack>
                        </StyledCardContent>
                    </Card>
                    <Typography variant="h5">Mode de formation</Typography>
                    <Card sx={{ p: 2 }}>
                        <StyledCardContent>
                            <FormControl error={!!errors?.trainingSessionMode}>
                                <Controller
                                    name="trainingSessionMode"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <GenericSwitch
                                            value={value === getEnumKeyByValue(SessionMode, SessionMode.FACE_TO_FACE)}
                                            onChange={(event) =>
                                                onChange(
                                                    getEnumKeyByValue(
                                                        SessionMode,
                                                        event.target.checked
                                                            ? SessionMode.FACE_TO_FACE
                                                            : SessionMode.DISTANCE
                                                    )
                                                )
                                            }
                                            rightText="Présentiel"
                                            leftText="Distanciel"
                                        />
                                    )}
                                />
                            </FormControl>
                        </StyledCardContent>
                    </Card>
                    {trainingSessionMode === getEnumKeyByValue(SessionMode, SessionMode.FACE_TO_FACE) && (
                        <>
                            <Typography variant="h5">Adresse du lieu de formation</Typography>
                            <Card sx={{ p: 2 }}>
                                <StyledCardContent>
                                    <Stack spacing={2}>
                                        <Stack spacing={1} direction="row">
                                            <TextField
                                                {...register("address")}
                                                error={!!errors?.address}
                                                helperText={errors?.address?.message}
                                                label="Adresse"
                                                fullWidth
                                                required
                                            />
                                            <TextField
                                                {...register("complementaryAddress")}
                                                error={!!errors?.complementaryAddress}
                                                helperText={errors?.complementaryAddress?.message}
                                                label="Complément d'adresse"
                                                fullWidth
                                            />
                                        </Stack>
                                        <Stack spacing={1} direction="row">
                                            <CityPostalCodeAutocomplete
                                                handleChange={handleCityPostalCodeChange}
                                                defaultCitySearch={form?.city}
                                                defaultPostalCodeSearch={form?.postalCode}
                                                initialValues={
                                                    form?.city
                                                        ? {
                                                              name: form?.city,
                                                              postalCode: form?.postalCode,
                                                          }
                                                        : null
                                                }
                                                errors={{
                                                    name: errors?.city?.message,
                                                    postalCode: errors?.postalCode?.message,
                                                }}
                                                required
                                            />
                                        </Stack>
                                    </Stack>
                                </StyledCardContent>
                            </Card>
                        </>
                    )}
                </Stack>
                <button style={{ display: "none" }} type="submit" ref={formRef} />
            </form>
            <button style={{ display: "none" }} onClick={() => onStepChange(getValues(), true)} ref={backRef} />
        </>
    );
}
