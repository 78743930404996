// === Import: NPM
import React from "react";
// === Import: LOCAL
import { IconButton, Tooltip } from "@mui/material";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { IActionButton } from "@/interfaces/global";
import { colors } from "@/resources/CssConstant";

interface IconActionButtonProps extends IActionButton {
    icon: React.ReactElement;
    id?: string;
    color?: string;
    disabled?: boolean;
}

export default function IconActionButton({
    title,
    onClick,
    icon,
    id,
    color,
    disabled,
}: Readonly<IconActionButtonProps>) {
    const { loadingTable, loadingButton } = useProvideGlobal();
    return (
        <Tooltip title={title}>
            <span>
                <IconButton
                    onClick={onClick}
                    id={id}
                    sx={{ alignSelf: "center", color: color ?? colors.primaryColor }}
                    disabled={loadingTable || loadingButton || disabled}
                >
                    {icon}
                </IconButton>
            </span>
        </Tooltip>
    );
}
