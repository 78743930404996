// === LOCAL
import { ArrayOfTwo } from "@/interfaces/global";
import { IDepartment, IReferential, IRegion, ISpecies } from "@/interfaces/referential";
import { IAttachment, IHealthAccreditationTraining, ITrainer, ITrainingType, SessionMode } from "@/interfaces/training";

// CATALOG

// Dtos

export interface IHealthAccreditationTrainingCreate
    extends Omit<IHealthAccreditationTraining, "uuid" | "attachedFiles" | "type"> {
    type: string;
}

// Filters

export interface ICatalogHealthAccreditationTrainingFilters {
    title: string;
    typeUuid: string;
    targetPublic: string;
    duration: ArrayOfTwo;
    ectsPoints: string;
    inscriptionNumberMin: string;
    inscriptionNumberMax: string;
    archived: string;
}

// SESSIONS

// Dtos

export interface ISessionCreate {
    trainingUuid: string;
    startDate: string;
    inscriptionNumberMin: number;
    inscriptionNumberMax: number;
    comment: string;
    ddppUuid: string;
    trainingSessionMode: keyof typeof SessionMode;
    address: string;
    complementaryAddress: string;
    postalCode: string;
    city: string;
    trainers: ITrainer[];
}

interface ISessionShort {
    uuid: string;
    internalId: string;
    trainingTitle: string;
    startDate: string;
    registrationLimitDate: string;
    sessionMode: keyof typeof SessionMode;
    inscriptionNumberMin: string;
    inscriptionNumberMax: string;
    status: string;
    ovvtOrganization: {
        uuid: string;
        name: string;
        regionInseeCode: string;
    };
}

export interface ISessionShortAdminDdppOvvt extends ISessionShort {
    registrationNumber: string;
    needRegistrationCheck: string;
    needPresenceCheck: string;
    needAttestationSign: string;
    needAttendanceSheetSign: string;
    ddpp: {
        uuid: string;
        name: string;
        department: string;
    };
}

export interface ISessionShortVeterinary extends ISessionShort {
    healthAccreditationTraining: {
        uuid: string;
        title: string;
        type: {
            uuid: string;
            label: string;
            fixed: boolean;
        };
        targetPublic: string;
        duration: string;
        content: string;
        ectsPoints: string;
        inscriptionNumberMin: string;
        inscriptionNumberMax: string;
        organizationUuid: string;
        internalId: string;
        archived: boolean;
        attachedFiles: [
            {
                uuid: string;
                filename: string;
                contentType: string;
            },
        ];
    };
    registrationStatus: keyof typeof InscriptionStatus;
    registrationUuid: string;
}

export interface ISession extends Omit<ISessionShort, "ovvtOrganization"> {
    address: string;
    complementaryAddress: string;
    postalCode: string;
    city: string;
    trainers: ITrainer[];
    comment: string;
    ovvtOrganization: {
        uuid: string;
        name: string;
        regionInseeCode: string;
    };
    ddpp: {
        uuid: string;
        name: string;
        department: string;
    };
    healthAccreditationTraining: {
        uuid: string;
        internalId: string;
        title: string;
        ectsPoints: string;
        type: {
            label: string;
            uuid: string;
            fixed: string;
        };
        targetPublic: string;
        duration: string;
        content: string;
        attachedFiles: IAttachment[];
    };
}

export interface ISessionDdppProcess {
    uuid: string;
    internalId: string;
    trainingTitle: string;
    startDate: string;
    needAttestationSign: boolean;
    needAttendanceSheetSign: boolean;
    attendanceSheetName: string;
}

export interface INextSessionScheduled {
    sessionUuid: string;
    trainingTitle: string;
    address: string;
    startDate: string;
    duration: string;
    ectsPoints: string;
    speciesUuids: string[];
    attachedFiles: IAttachment[];
}

export interface ISessionInfo {
    lastTrainingDate: string;
    renewDate: string;
    trainingNumber: string;
    trainingNumberPast10Years: number;
}

// Filters

export interface IViewOvvtFilters {
    internalId: string;
    trainingTitle: string;
    startDate: ArrayOfTwo;
    registrationLimitDate: ArrayOfTwo;
    department: string[];
    sessionMode: string[];
    status: string[];
    needRegistrationCheck: string;
    needPresenceCheck: string;
}

export interface IViewDdppFilters {
    internalId: string;
    trainingTitle: string;
    startDate: ArrayOfTwo;
    registrationLimitDate: ArrayOfTwo;
    department: string[];
    sessionMode: string[];
    status: string[];
    needAttestationSign: string;
    needAttendanceSheetSign: string;
}

export interface IViewAdminFilters {
    internalId: string;
    trainingTitle: string;
    startDate: ArrayOfTwo;
    registrationLimitDate: ArrayOfTwo;
    department: string[];
    sessionMode: string[];
    status: string[];
}

export type IViewFilters = IViewOvvtFilters | IViewDdppFilters | IViewAdminFilters;

export interface ISessionsToProcessFilters {
    internalId: string;
    trainingTitle: string;
    startDate: ArrayOfTwo;
    veterinaryId: string;
    needAttendanceSheetImport: string;
    needAttendanceSheetSign: string;
    needAttestationSign: string;
}

// Counts

export interface ISessionCountsOvvt {
    preRegisteredVeterinaryParticipations: number;
    twoDaysSessions: number;
    activeClosedOrFutureSessions: number;
    unfilledPresenceSessions: number;
    finishedSessions: number;
}

export interface ISessionCountsDdpp {
    generateSign: number;
    editAttendanceSheet: number;
    finishedSessions: number;
}

export type ISessionCounts = ISessionCountsOvvt & ISessionCountsDdpp;

export interface ISessionCountsDdppProcess {
    importAttendanceSheet: number;
    signAttendanceSheet: number;
    generateSign: number;
}

// Enum

export enum SessionStatus {
    ACTIVE = "Active",
    ACTIVE_CLOSED = "Clôturée",
    REALIZED = "Réalisée",
    FINISHED = "Terminée",
    CANCELED = "Annulée",
}

// INSCRIPTIONS

// Dtos

export interface IParticipationOvvtDdpp {
    uuid: string;
    sessionUuid: string;
    veterinary: string;
    veterinaryFirstname?: string;
    veterinaryLastname?: string;
    lastTrainingDate: string;
    horsePower: string;
    distance: string;
    speciesUuids: string[];
    otherSpecies: string;
    registrationStatus: string;
    presenceStatus: string;
    attestationSigned: boolean;
    departmentInseeCode: string;
}

export interface IInscriptionVeterinary {
    uuid: string;
    sessionUuid: string;
    sessionInternalId: string;
    trainingTitle: string;
    address: string;
    startDate: string;
    duration: string;
    sessionMode: string;
    registrationStatus: string;
    presenceStatus: string;
    ectsPoints: string;
    trainingType: ITrainingType;
    attestationSigned: string;
}

export interface IRegistrationCreate {
    speciesUuids: string[];
    otherSpecies: string;
    distance: number;
    horsePower: string;
    sessionUuid: string;
}

export interface IRegistration {
    uuid: string;
    speciesUuids: string[];
    otherSpecies: string;
    distance: number;
    horsePower: string;
    sessionUuid: string;
}

export interface IParticipationDdpp {
    uuid: string;
    veterinaryId: string;
    veterinaryLastname: string;
    veterinaryFirstname: string;
    attestationSigned: boolean;
}

// Filters

export interface IInscriptionsOvvtDdppFilters {
    veterinary: string;
    lastTrainingDate: ArrayOfTwo;
    registrationStatus: string[];
    presenceStatus: string[];
}

export interface IInscriptionsOvvtDdppFiltersPastSession {
    veterinary: string;
    lastTrainingDate: ArrayOfTwo;
    presenceStatus: string[];
}

export interface IInscriptionsOvvtDdppFiltersFutureSession {
    veterinary: string;
    lastTrainingDate: ArrayOfTwo;
    registrationStatus: string[];
}

// Enums

export enum InscriptionStatus {
    PRE_REGISTERED = "Préinscrit",
    REGISTERED = "Inscrit",
    UNREGISTERED = "Non inscrit",
}

export enum PresenceStatus {
    UNDEFINED = "Inscrit",
    PRESENT = "Présent",
    ABSENT = "Absent",
}

//CONTEXT

export interface SessionOutletContext {
    regions: IRegion[];
    horsePowers: IReferential[];
    species: ISpecies[];
    trainingTypes: ITrainingType[];
    infos: ISessionInfo;
}
