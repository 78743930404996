import React from "react";
import { Box, Typography } from "@mui/material";

interface IconBannerTextProps {
    message: string;
    icon: React.ReactElement;
    color: string;
    backgroundColor: string;
    alignItems?: boolean;
    displayBorderRadius?: boolean;
}
export default function IconBannerText({
    message,
    icon,
    color,
    backgroundColor,
    alignItems,
    displayBorderRadius,
}: Readonly<IconBannerTextProps>) {
    return (
        (<Box
            sx={{
                display: "flex",
                alignItems: alignItems ? "center" : "flex-start",
                backgroundColor: backgroundColor,
                borderRadius: 1,
                gap: 2,
                px: 1,
                py: 0.5
            }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: color
                }}>
                {icon}
            </Box>
            <Typography
                variant="bold"
                sx={{
                    color: color,
                    whiteSpace: "pre-line"
                }}>
                {message}
            </Typography>
        </Box>)
    );
}
