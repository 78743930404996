// === Import: NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Card, CardContent, Stack, Typography } from "@mui/material";
// === Import: LOCAL
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { HttpStatus } from "@/interfaces/global";
import { IPharmacyShort } from "@/interfaces/user";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import PharmacistMail from "./containers/PharmacistMail";
import PharmacistProfileSkeleton from "./containers/PharmacistProfileSkeleton";

export default function PharmacistProfile() {
    const auth = useAuth();
    const [pharmacy, setPharmacy] = useState<IPharmacyShort>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getUserPharmacy();
    }, []);

    const getUserPharmacy = async () => {
        const res = await UserService.getUserPharmacy(auth.userInfo.id);
        if (res.status === HttpStatus.OK) {
            setPharmacy(await res.json());
        }
        setLoading(false);
    };

    const handleUpdateAgency = async (email: string) => {
        const res = await UserService.updatePharmacy(pharmacy.id, { email });
        if (res.status === HttpStatus.OK) {
            toast.success("Mail modifié avec succès");
            getUserPharmacy();
        }
    };
    return loading ? (
        <PharmacistProfileSkeleton />
    ) : (
        <Stack spacing={3} direction="row" sx={{
            height: "100%"
        }}>
            <Stack spacing={3}>
                <Typography variant="h5">Informations personnelles</Typography>
                <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                    <CardContent>
                        <Stack sx={{
                            p: 2
                        }}>
                            <GenericTitleValueText title="ID RPPS" value={auth.userInfo.id} />
                            <GenericTitleValueText title="Nom" value={auth.userInfo.lastname} />
                            <GenericTitleValueText title="Prénom" value={auth.userInfo.firstname} />
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            <Stack spacing={3}>
                <Typography variant="h5">Informations de mon officine</Typography>
                <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                    <CardContent>
                        <Stack sx={{
                            p: 2
                        }}>
                            <GenericTitleValueText title="ID FINESS" value={pharmacy.id} />
                            <GenericTitleValueText title="Nom" value={pharmacy.name} />
                            <PharmacistMail calypsoEmail={pharmacy.email} onConfirm={handleUpdateAgency} />
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Stack>
    );
}
