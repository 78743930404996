// === NPM
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { QuestionMark } from "@mui/icons-material";
import { Box, Fab, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Document } from "@/assets/icons/actions/document.svg";
import { ReactComponent as Download } from "@/assets/icons/actions/download.svg";
import Tour from "@/components/generics/Tour";
import { useTour } from "@/components/generics/useTour";
import { ImportStatus } from "@/interfaces/drug";
import { ITourStep } from "@/interfaces/global";
import { TourKey, UserType } from "@/interfaces/user";
import { DeliveryStatusStyle } from "@/resources/CssConstant";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";

interface FamDamDeliveryTour {
    nonDelivery: boolean;
    drugDeliveryDashboard: boolean;
}

export default function FamDamDeliveryTour() {
    const { tour } = useTour<FamDamDeliveryTour>();

    const location = useLocation();
    const auth = useAuth();
    const [config, setSteps] = useState([]);

    useMemo(() => {
        const steps: ITourStep[] = [
            {
                target: "#delivery-template-btn",
                disableBeacon: true,
                content:
                    "Cliquez sur ce bouton pour télécharger le classeur Excel de référence basé sur le modèle utilisé par l’ANMV. Les référentiels sont régulièrement mis à jour, pensez donc à re-télécharger le template. Les consignes d’utilisation sont présentées dans un des onglets du fichier.",
            },
            {
                target: "#import-delivery-btn",
                content: "Une fois votre document Excel renseigné, cliquez sur ce bouton pour l’importer dans Calypso.",
            },
            {
                target: "#tracking-table",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Dans ce tableau vous pouvez vérifier que le document a été correctement importé. Vérifiez le
                            statut de votre déclaration :
                        </Typography>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <Typography variant="body2" sx={DeliveryStatusStyle.OK}>
                                {ImportStatus.OK}
                            </Typography>
                            <Typography>Toutes les données sont conformes</Typography>
                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <Typography variant="body2" sx={DeliveryStatusStyle.OK_WITH_WARNING}>
                                {ImportStatus.OK_WITH_WARNING}
                            </Typography>
                            <Typography>
                                Des contrôles non bloquants sur des champs facultatifs seront bloquants dans une future
                                version
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <Typography variant="body2" sx={DeliveryStatusStyle.PARTIAL}>
                                {ImportStatus.PARTIAL}
                            </Typography>
                            <Typography>
                                Certaines données de cessions ne sont pas conformes et n’ont pas été importées
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: "flex"
                        }}>
                            <Typography variant="body2" sx={DeliveryStatusStyle.REJECTED}>
                                {ImportStatus.REJECTED}
                            </Typography>
                            <Typography>Les données ne sont pas conformes</Typography>
                        </Box>
                        <Typography>
                            Vous pouvez télécharger le rapport d’import en cliquant sur
                            <Document
                                style={{
                                    position: "relative",
                                    top: 5,
                                }}
                            />
                            . Il faudra si nécessaire, corrriger les données non conformes et ré-importer le document
                            après correction.
                        </Typography>
                        <Typography>
                            Le document importé reste disponible et téléchargeable en cliquant sur{" "}
                            <Download
                                style={{
                                    position: "relative",
                                    top: 5,
                                }}
                            />
                        </Typography>
                    </Stack>
                ),
                styles: {
                    tooltip: {
                        minWidth: 1000,
                    },
                },
            },
            {
                target: "#non-delivery-btn",
                content:
                    "Si vous n’avez fait aucune cession, vous devez quand même faire une déclaration de non cession. Cliquez sur le bouton pour continuer.",
                hideFooter: true,
                spotlightClicks: true,
                condition: () => tour.nonDelivery,
            },
            {
                target: "#non-delivery-dialog",
                content: `Dans cette fenêtre, Le·s établissement·s concerné·s doivent être renseigné·s. La déclaration de non cession s'effectue pour un trimestre d'une année.`,
                hideBackButton: true,
            },
            {
                target: "#cancel-dialog-btn",
                content: `Cliquez sur "Annuler" pour poursuivre la visite.`,
                condition: () => !tour.nonDelivery,
                hideFooter: true,
                spotlightClicks: true,
            },
            {
                target: "#drug-delivery-tab",
                content: (
                    <Stack spacing={1}>
                        <Typography>
                            Dans la seconde partie "Suivi des cessions" vous retrouvez toutes les cessions liées à votre
                            groupe d’établissements.
                        </Typography>
                    </Stack>
                ),
            },
            {
                target: "body",
                content: (
                    <Box>
                        <Typography>
                            À vous de jouer !
                            <br />
                            Vous pouvez relancer à tout moment ce guide d'aide à la navigation en cliquant sur le bouton
                            <Fab disableRipple disableFocusRipple color="primary" sx={{ mx: 1 }} size="small">
                                <QuestionMark sx={{ color: "#FFF" }} />
                            </Fab>{" "}
                            (en bas à droite de votre écran)
                        </Typography>
                    </Box>
                ),
                hideBackButton: true,
            },
        ];
        setSteps(steps);
    }, [tour]);

    return (
        <Tour
            config={config}
            display={
                location.pathname === routerLinks.delivery.dashboard.trackingLogs() &&
                auth.userInfo.type === UserType.FAM_DAM
            }
            tourKey={TourKey.FAM_DAM_DELIVERY}
        />
    );
}
