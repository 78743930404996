import ky, { KyInstance, KyResponse, Options } from "ky";

class HttpService {
    kyInstance: KyInstance;

    constructor() {
        // Initialize ky with default options
        this.kyInstance = ky.create({
            retry: 0,
            throwHttpErrors: false,
            hooks: {
                beforeRequest: [
                    (request) => {
                        request.headers.set("Authorization", `Bearer ${localStorage.getItem("CALYPSO_TOKEN")}`);
                    },
                ],
            },
        });
    }

    async fetch<T>(url: string, options?: Options): Promise<KyResponse<T>> {
        const response = await this.kyInstance(url, options);
        return response;
    }
}

export default new HttpService();
