// === NPM
import React from "react";
import { Box, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === LOCAL
import ChipTag from "@/components/generics/ChipTag";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { ITag } from "@/interfaces/faq";
import { colors } from "@/resources/CssConstant";
import { IFaqFilters } from "../interface";

interface FaqFiltersProps {
    tags: ITag[];
    filters: IFaqFilters;
    setFilters: React.Dispatch<React.SetStateAction<IFaqFilters>>;
}

export default function FaqFilters({ tags, filters, setFilters }: Readonly<FaqFiltersProps>) {
    const { loadingTable } = useProvideGlobal();
    const handleTagClick = (tagUuid: string) => {
        const tagIndex = filters.tags.indexOf(tagUuid);
        let tagLists = [];
        if (tagIndex === -1) {
            tagLists = [...filters.tags, tagUuid];
        } else {
            tagLists = filters.tags.filter((_, index) => index !== tagIndex);
        }
        setFilters((prev) => ({ ...prev, tags: tagLists }));
    };

    return (
        <Stack
            spacing={2}
            sx={{
                alignItems: "center",
            }}
        >
            <Typography variant="h4">Comment pouvons-nous vous aider ?</Typography>
            <TextField
                placeholder="Rechercher un mot clef"
                sx={{ borderRadius: 8, background: colors.white, width: "33%" }}
                onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value }))}
                slotProps={{
                    input: { sx: { borderRadius: 8 } },
                }}
            />
            <Typography>Rechercher par tag : </Typography>
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                {tags.map((tag) => (
                    <Grid key={tag.uuid}>
                        <ChipTag
                            tag={tag}
                            handleClick={() => handleTagClick(tag.uuid)}
                            selected={filters.tags.includes(tag.uuid)}
                        />
                    </Grid>
                ))}
            </Grid>
            {loadingTable && (
                <Box
                    sx={{
                        p: 2,
                        width: "100%",
                    }}
                >
                    <LinearProgress />
                </Box>
            )}
        </Stack>
    );
}
