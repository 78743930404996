// === NPM
import React, { useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { colors } from "@/resources/CssConstant";
import { toLocaleDateFormat } from "@/resources/utils";
import { IAmv, IVat } from "../../../interface";
import AmvVersionsDialog from "../Dialogs/AmvVersionsDialog/AmvVersionsDialog";
import VatVersionsDialog from "../Dialogs/VatVersionsDialog/VatVersionsDialog";
import TileTitle from "./containers/TileTitle";
import VersionChip from "./containers/VersionChip";

interface VatAndAmvProps {
    element: IVat | IAmv;
    isVat: boolean;
    title: string;
}

export default function VatAndAmvTile({ element, isVat, title }: Readonly<VatAndAmvProps>) {
    const [openManageDialog, setOpenManageDialog] = useState<boolean>(false);

    return (
        <>
            <Card variant="outlined" sx={{ paddingTop: 1, paddingX: 2, width: "100%" }}>
                <TileTitle title={title} setOpenManageDialog={setOpenManageDialog} />
                <CardContent>
                    <Stack spacing={2}>
                        <VersionChip versionNumber={element.version} />
                        <Stack
                            direction="row"
                            sx={{
                                justifyContent: "space-between",
                                alignItems: "bottom",
                            }}
                        >
                            <GenericIconText
                                icon={<Calendar />}
                                iconTooltip="Dates de validité"
                                text={
                                    element.endDate
                                        ? `Du ${toLocaleDateFormat(element.startDate) ?? "01/01/2023"} au ${toLocaleDateFormat(element.endDate)}`
                                        : `Débute le ${toLocaleDateFormat(element.startDate) ?? "01/01/2023"}`
                                }
                            />
                            <Typography
                                variant="bold"
                                sx={{
                                    fontSize: 32,
                                    color: colors.primaryColor,
                                    lineHeight: 1,
                                }}
                            >
                                {`${element.rate.toFixed(4)}${isVat ? "%" : "€"}`}
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            {openManageDialog &&
                (isVat ? (
                    <VatVersionsDialog title={title} vatKey={element.key} onClose={() => setOpenManageDialog(false)} />
                ) : (
                    <AmvVersionsDialog title={title} amvKey={element.key} onClose={() => setOpenManageDialog(false)} />
                ))}
        </>
    );
}
