// === NPM
import React, { ChangeEvent, Dispatch, RefObject, SetStateAction, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, FormControl, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { z, ZodIssueCode } from "zod";
// === LOCAL
import InfoIcon from "@/components/generics/InfoIcon";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { DisplayType } from "@/interfaces/notification";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { UserSituation } from "@/resources/PermissionConstant";
import { convertEnumToKeyLabelObject, getEnumKeyByValue } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import {
    AllowedTargetType,
    BroadcastType,
    IAllowedTarget,
    INotificationCreateUpdate,
    NotificationContext,
    SendMode,
} from "../../../../interface";
import { useSelectedNotification } from "../../../useSelectedNotification";

interface GeneralInformationsProps {
    onValid: () => void;
    form: INotificationCreateUpdate;
    setForm: Dispatch<SetStateAction<INotificationCreateUpdate>>;
    formRef: RefObject<HTMLButtonElement>;
    selectedReceiversType: string;
    setSelectedReceiversType: Dispatch<SetStateAction<string>>;
    setFiles: Dispatch<SetStateAction<File[]>>;
}

const formSchema = z
    .object({
        sendMode: stringRequired(),
        broadcastType: stringRequired(),
        displayType: z.string().trim().optional().nullable(),
        receiversType: stringRequired(),
    })
    .superRefine((values, context) => {
        if (
            values.sendMode === getEnumKeyByValue(SendMode, SendMode.CALYPSO_APPLICATION) &&
            values.displayType === ""
        ) {
            context.addIssue({
                code: ZodIssueCode.custom,
                message: FORM_TEXT.required,
                path: ["displayType"],
            });
        }
    });

type ValidationSchema = z.infer<typeof formSchema>;

export default function GeneralInformations({
    form,
    setForm,
    formRef,
    onValid,
    selectedReceiversType,
    setSelectedReceiversType,
    setFiles,
}: Readonly<GeneralInformationsProps>) {
    const { allowedTargets } = useOutletContext<NotificationContext>();
    const { selectedNotification } = useSelectedNotification();
    const auth = useAuth();
    const isAnmvNotif = auth.userInfo.situation === UserSituation.ANMV_NOTIF;
    const displayTypes = convertEnumToKeyLabelObject(DisplayType).filter((element) =>
        isAnmvNotif ? element.key !== getEnumKeyByValue(DisplayType, DisplayType.ALERT_BANNER) : true
    );
    const [targets, setTargets] = useState<IAllowedTarget[]>(allowedTargets);

    const initializeReceiversType = () => {
        const items = form.receiversSituationIds?.reduce((acc, curr) => {
            const type = targets.find((type) => type.situations.some((situation) => situation === curr))?.situations;
            if (type) {
                acc.push(type);
            }
            return acc;
        }, []);

        if ([...new Set(items)].length > 1) {
            return getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL);
        }

        return targets.find((type) =>
            type.situations.some((situation) => form.receiversSituationIds.includes(situation))
        )?.type;
    };

    const {
        formState: { errors },
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            sendMode: form.sendMode,
            broadcastType: form.broadcastType,
            displayType: form.displayType,
            receiversType:
                form.receiversSituationIds.length > 0 && BroadcastType[form.broadcastType] === BroadcastType.GENERIC
                    ? initializeReceiversType()
                    : selectedReceiversType,
        },
    });

    const watchReceiversType = watch("receiversType");
    const watchDisplayType = watch("displayType");
    const watchBroadcastType = watch("broadcastType");
    const watchSendMode = watch("sendMode");

    useEffect(() => {
        setSelectedReceiversType(watchReceiversType);
    }, [watchReceiversType]);

    useEffect(() => {
        if (DisplayType[watchDisplayType] === DisplayType.ALERT_BANNER) {
            setForm((prev) => ({
                ...prev,
                publicationEndDate:
                    selectedNotification?.publicationEndDate ?? DateTime.now().plus({ days: 7 }).toISO(),
            }));
        } else {
            setForm((prev) => ({
                ...prev,
                publicationEndDate:
                    selectedNotification?.publicationEndDate ?? DateTime.now().plus({ months: 2 }).toISO(),
            }));
        }
    }, [watchDisplayType]);

    useEffect(() => {
        handleReceiversType();
    }, [watchBroadcastType, watchSendMode]);

    const handleReceiversType = () => {
        const filteredTargets = allowedTargets.filter(
            (target) =>
                target.type !== getEnumKeyByValue(AllowedTargetType, AllowedTargetType.VIMS) &&
                target.type !== getEnumKeyByValue(AllowedTargetType, AllowedTargetType.TRAINING_ORGANIZATION)
        );
        if (
            watchSendMode === getEnumKeyByValue(SendMode, SendMode.CALYPSO_APPLICATION) &&
            watchBroadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.GENERIC)
        ) {
            setTargets(filteredTargets);
        } else if (watchBroadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.TARGETED)) {
            setTargets(
                filteredTargets.filter(
                    (target) => target.type !== getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL)
                )
            );
        } else {
            setTargets(allowedTargets);
        }
    };

    const handleBroadcastTypeChange = (e: ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
        setValue("broadcastType", e.target.value);
        resetField("receiversType");
        setForm((prev) => ({ ...prev, receiversSituationIds: [], receiversUserIds: [] }));
    };

    const handleReceiversTypeChange = (e: ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
        setValue("receiversType", e.target.value);
        if (watchBroadcastType === getEnumKeyByValue(BroadcastType, BroadcastType.GENERIC)) {
            setForm((prev) => ({
                ...prev,
                receiversSituationIds: allowedTargets.find((target) => target.type === e.target.value)?.situations,
            }));
        }
    };

    const handleValidateForm = (data: ValidationSchema) => {
        setForm((prev) => ({
            ...prev,
            ...data,
            receiversSituationIds: data.receiversType === "ALL" ? ["ALL"] : form.receiversSituationIds,
        }));
        onValid();
    };

    return (
        (<form onSubmit={handleSubmit(handleValidateForm)} noValidate>
            <Stack spacing={2}>
                <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                    Informations générales
                </Typography>
                <Card>
                    <StyledCardContent>
                        <Stack spacing={4} sx={{ p: 2 }}>
                            <Typography variant="h5">Mode d'envoi</Typography>
                            <FormControl fullWidth required error={!!errors.sendMode}>
                                <Controller
                                    name="sendMode"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value}
                                            label="Mode d'envoi"
                                            name="sendMode"
                                            onChange={(e) => {
                                                onChange(e);
                                                setFiles([]);
                                            }}
                                            optionsValue="key"
                                            error={!!error}
                                            helperText={error?.message}
                                            options={convertEnumToKeyLabelObject(SendMode)}
                                            required
                                        />
                                    )}
                                />
                            </FormControl>
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    alignItems: "center",
                                    mb: 3
                                }}>
                                <Typography variant="h5">Type de diffusion</Typography>
                                <InfoIcon tooltip="La diffusion ciblée vous permet de filtrer sur plusieurs critères et choisir vos destinataires tandis que la diffusion générique consiste à envoyer votre message à un groupe de destinataire" />
                            </Stack>
                            <FormControl fullWidth required error={!!errors.broadcastType}>
                                <Controller
                                    name="broadcastType"
                                    control={control}
                                    render={({ field: { value }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value}
                                            label="Type de diffusion"
                                            name="broadcastType"
                                            onChange={handleBroadcastTypeChange}
                                            optionsValue="key"
                                            error={!!error}
                                            helperText={error?.message}
                                            options={convertEnumToKeyLabelObject(BroadcastType)}
                                            required
                                        />
                                    )}
                                />
                            </FormControl>
                            {SendMode[watchSendMode] !== SendMode.MAIL && (
                                <>
                                    <Typography variant="h5">Catégorie</Typography>
                                    <FormControl fullWidth required error={!!errors.displayType}>
                                        <Controller
                                            name="displayType"
                                            control={control}
                                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                                <GenericSelect
                                                    value={value}
                                                    label="Catégorie"
                                                    name="displayType"
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        if (
                                                            e.target.value ===
                                                            getEnumKeyByValue(DisplayType, DisplayType.ALERT_BANNER)
                                                        ) {
                                                            setForm((prev) => ({ ...prev, content: "" }));
                                                        }
                                                    }}
                                                    optionsValue="key"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    options={displayTypes}
                                                    required
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </>
                            )}

                            <Typography variant="h5">Type d'utilisateur</Typography>
                            <FormControl fullWidth required error={!!errors.receiversType}>
                                <Controller
                                    name="receiversType"
                                    control={control}
                                    render={({ field: { value }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value}
                                            label="Type d'utilisateur"
                                            name="receiverSituation"
                                            onChange={handleReceiversTypeChange}
                                            error={!!error}
                                            helperText={error?.message}
                                            options={targets
                                                .reduce((acc: { value: string; label: string }[], curr) => {
                                                    const option = {
                                                        value: curr.type,
                                                        label: AllowedTargetType[curr.type],
                                                    };
                                                    if (!acc.some((target) => target.value === option.value)) {
                                                        acc.push(option);
                                                    }
                                                    return acc;
                                                }, [])
                                                .sort((a, b) => a.label.localeCompare(b.label))}
                                            required
                                        />
                                    )}
                                />
                            </FormControl>
                        </Stack>
                    </StyledCardContent>
                </Card>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>)
    );
}
