// === NPM
import React, { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { HttpStatus } from "@/interfaces/global";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { IModule } from "../interface";
import DropableModule from "./DropableModule";
import Module from "./Module";
import ModuleDrawer from "./ModuleDrawer";

interface EditHomeModulesProps {
    modules: IModule[];
    setUpdateModules: Dispatch<SetStateAction<boolean>>;
}

export default function EditHomeModules({ modules, setUpdateModules }: Readonly<EditHomeModulesProps>) {
    const auth = useAuth();
    const { homePreferences } = auth.userInfo;
    const theme = useTheme();
    const xl = useMediaQuery(theme.breakpoints.up("xl"));
    const [editPreferences, setEditPreferences] = useState<string[]>(homePreferences);

    const moduleListWidth = xl ? 400 : 300;

    const deleteModule = (module: IModule) => {
        const index = editPreferences.indexOf(module.key);
        if (index > -1) {
            const newPreferences = [...editPreferences];
            newPreferences.splice(index, 1, null);
            setEditPreferences(newPreferences);
        }
    };

    const dropOverModule = (droppedModule: IModule, module: IModule) => {
        const droppedModuleIndex = editPreferences.indexOf(droppedModule.key);
        const moduleIndex = editPreferences.indexOf(module.key);
        if (droppedModuleIndex > -1) {
            const newPreferences = [...editPreferences];
            newPreferences[droppedModuleIndex] = module.key;
            newPreferences[moduleIndex] = droppedModule.key;
            setEditPreferences(newPreferences);
        } else {
            const newPreferences = [...editPreferences];
            newPreferences[moduleIndex] = droppedModule.key;
            setEditPreferences(newPreferences);
        }
    };

    const dropModule = (module: IModule, index: number) => {
        const existingModuleIndex = editPreferences.indexOf(module.key);
        if (existingModuleIndex > -1) {
            const newPreferences = [...editPreferences];
            newPreferences[existingModuleIndex] = null;
            newPreferences[index] = module.key;
            setEditPreferences(newPreferences);
        } else {
            const newPreferences = [...editPreferences];
            newPreferences[index] = module.key;
            setEditPreferences(newPreferences);
        }
    };

    const renderCards = (key: string, index: number) => {
        const module = modules.find(
            (m: IModule) =>
                m.key === key &&
                (!m.preferences?.every((p) => auth.userInfo.preferences[p] === false))
        );
        return module ? (
            <Module module={module} onDelete={deleteModule} onDrawer={false} onDrop={dropOverModule} />
        ) : (
            <DropableModule index={index} dropModule={dropModule} />
        );
    };

    const savePreferences = async () => {
        const res = await UserService.putHomePreferences(editPreferences, auth.userInfo.id);
        if (res.status === HttpStatus.OK) {
            toast.success("Vos préférences ont bien été enregistrées");
            auth.updateHomePreferences(editPreferences);
            setUpdateModules(false);
        }
    };

    return (<>
        <Stack
            spacing={2}
            sx={{
                p: 2,
                width: `calc(100% - ${moduleListWidth}px)`
            }}>
            <Typography variant="h4">Modifier les modules</Typography>
            <Typography>Ordonnez vos modules en les glissant-déposant à l'endroit souhaité.</Typography>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(12, 1fr)",
                    gridAutoRows: "1fr",
                    gap: 4,
                    pt: 3,
                    pb: 3
                }}>
                {editPreferences.map((key: string, i: number) => {
                    return (
                        <Box
                            sx={{
                                gridColumn: { xs: "span 6", md: "span 3" },
                                position: "relative",
                            }}
                            key={i}
                        >
                            {renderCards(key, i)}
                        </Box>
                    );
                })}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 4,
                    mt: 2
                }}>
                <SecondaryButton onClick={() => setUpdateModules(false)} variant="outlined">
                    Annuler
                </SecondaryButton>
                <GenericButton onClick={savePreferences} label="Enregistrer" />
            </Box>
        </Stack>
        <ModuleDrawer moduleListWidth={moduleListWidth} modules={modules} editPreferences={editPreferences} />
    </>);
}
