// === NPM
import React, { ChangeEvent, RefObject } from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === LOCAL
import GenericSwitch from "@/components/generics/buttons/GenericSwitch";
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { Form } from "@/hooks/useForm";
import { IVaccinationSupervision } from "@/interfaces/vaccination";
import { defaultIntegerLimit } from "@/resources/AppConstant";

interface MonitoringVaccinationProps {
    form: Form<IVaccinationSupervision>;
    monitoringVaccinationRef: RefObject<HTMLButtonElement>;
}

export default function MonitoringVaccination({
    monitoringVaccinationRef,
    form,
}: Readonly<MonitoringVaccinationProps>) {
    const { data, errors, handleSubmit, handleChange, cleanValue, initValues } = form;

    const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>, field: keyof IVaccinationSupervision) => {
        const target = {
            target: { value: null, checked: e.target.checked },
        } as ChangeEvent<HTMLInputElement & HTMLSelectElement>;
        handleChange(field)(target);
    };

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Stack
                spacing={4}
                sx={{
                    width: "100%",
                }}
            >
                <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                    Contrôle sérologique de supervision de la vaccination
                </Typography>
                <Grid container rowSpacing={2}>
                    <Grid container spacing={2}>
                        <Grid
                            size={{
                                xs: 12,
                                md: 4,
                            }}
                        >
                            <GenericSwitch
                                title="Réalisation du contrôle"
                                leftText="Non réalisé"
                                rightText="Réalisé"
                                value={data.realized}
                                onChange={(e) => {
                                    handleSwitch(e, "realized");
                                    initValues(
                                        e.target.checked
                                            ? {
                                                  comment: "",
                                                  compliantAnalysis: false,
                                                  sampleNumber: 20,
                                              }
                                            : {
                                                  comment: "",
                                                  compliantAnalysis: null,
                                                  sampleNumber: null,
                                              }
                                    );
                                }}
                            />
                        </Grid>
                        {data.realized && (
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 4,
                                }}
                            >
                                <GenericTextField
                                    label="Nombre d'analyses"
                                    value={data.sampleNumber}
                                    onChange={handleChange("sampleNumber")}
                                    error={!!errors.sampleNumber}
                                    helperText={errors.sampleNumber}
                                    required
                                    maxLength={defaultIntegerLimit}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {data.realized && (
                        <Grid container spacing={2} size={12}>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 4,
                                }}
                            >
                                <GenericSwitch
                                    title="Résultat des analyses"
                                    leftText="Non conforme"
                                    rightText="Conforme"
                                    value={data.compliantAnalysis}
                                    onChange={(e) => {
                                        handleSwitch(e, "compliantAnalysis");
                                        cleanValue("comment");
                                    }}
                                />
                            </Grid>
                            {!data.compliantAnalysis && (
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 4,
                                    }}
                                >
                                    <GenericComment
                                        label="Motif de non conformité"
                                        value={data.comment}
                                        onChange={handleChange("comment")}
                                        required
                                        helperText={errors.comment}
                                        maxLength={100}
                                        rows={1}
                                        placeholder="Motif de non conformité"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={monitoringVaccinationRef} />
        </form>
    );
}
