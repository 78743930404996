// === NPM
import { KyResponse } from "ky";
// === LOCAL
import { IDownload } from "@/components/Downloads/interface";
import { HttpMethod, Loader } from "@/interfaces/global";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class DownloadService {
    async getDownloads(): Promise<KyResponse<IDownload[]>> {
        return HttpService.fetch(endpoints.downloadService.downloads(), {
            method: HttpMethod.GET,
        });
    }

    async getDownload(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.downloadService.download(uuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }
}

export default new DownloadService();
