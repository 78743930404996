// === NPM
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Card, Skeleton, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/home/calendar.svg";
import { ReactComponent as Certificate } from "@/assets/icons/training/certificate.svg";
import { ReactComponent as LastTraining } from "@/assets/icons/training/lastTraining.svg";
import {
    FilterConfigurations,
    GenericFilters,
    SortingConfiguration,
} from "@/components/generics/filters/GenericFilters";
import { GenericPagination } from "@/components/generics/layout/GenericPagination";
import { IInscriptionVeterinary, SessionOutletContext } from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useDepartments } from "@/context/useDepartmentContext";
import { useProvideGlobal } from "@/context/useGlobalContext";
import useTimeout from "@/hooks/useTimeout";
import { ArrayOfTwo, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { SessionMode } from "@/interfaces/training";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { colors, drawerColors } from "@/resources/CssConstant";
import { convertEnumToKeyLabelObject, createPayload } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import NextSessionCard from "../NextSessionCard";
import InscriptionCard from "./containers/InscriptionCard";

export interface IAttestationsVeterinaryFilters {
    trainingTitle: string;
    startDate: ArrayOfTwo;
    trainingTypeUuid: string[];
    department: string[];
    sessionMode: string[];
}

interface IAttestationsVeterinarySortConfig {
    trainingTitle: string;
    startDate: string;
}

export default function AttestationsVeterinary() {
    const { loadingTable } = useProvideGlobal();
    const { departments } = useDepartments();
    const { trainingTypes, infos } = useOutletContext<SessionOutletContext>();
    const [inscriptions, setInscriptions] = useState<IInscriptionVeterinary[]>([]);
    const [inputFilters, setInputFilters] = useState<IAttestationsVeterinaryFilters>({
        trainingTitle: "",
        startDate: [null, null],
        trainingTypeUuid: [],
        department: [],
        sessionMode: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sort, setSort] = useState({ field: "trainingTitle", direction: "asc" });

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getInscriptions();
    }, [pagination, sort]);

    // const getHealthAccreditationInfo = async () => {
    //     const res = await TrainingService.getHealthAccreditationInfo();
    //     if (res.status === HttpStatus.OK) {
    //         setInfos(await res.json());
    //     }
    // };

    const getInscriptions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: [`${sort.field},${sort.direction}`],
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getVeterinaryAttestations(payload);
        if (res.status === HttpStatus.OK) {
            setInscriptions(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const renderSkeleton = () => (
        <Stack spacing={-3}>
            <Skeleton height={200} width="100%" sx={{ borderRadius: "40px" }} />
            <Skeleton height={200} width="100%" sx={{ borderRadius: "40px" }} />
        </Stack>
    );

    const renderContent = () =>
        inscriptions?.length > 0 ? (
            inscriptions.map((i) => <InscriptionCard inscription={i} key={i.sessionUuid} />)
        ) : (
            <Typography>Vous n'avez suivi aucune formation</Typography>
        );

    const sortingConfiguration: SortingConfiguration<IAttestationsVeterinarySortConfig>[] = [
        {
            label: "Titre de la formation - Alphabétique",
            value: SortDirection.ASC,
            field: "trainingTitle",
        },
        {
            label: "Titre de la formation - Alphabétique inverse",
            value: SortDirection.DESC,
            field: "trainingTitle",
        },
        {
            label: "Date de formation - Du plus ancien au plus récent",
            value: SortDirection.ASC,
            field: "startDate",
        },
        {
            label: "Date de formation - Du plus récent au plus ancien",
            value: SortDirection.DESC,
            field: "startDate",
        },
    ];

    const filterConfigurations: FilterConfigurations<IAttestationsVeterinaryFilters> = {
        trainingTitle: { type: FilterType.INPUT, label: "Formation" },
        startDate: { type: FilterType.DATEPICKER, label: "Date de formation" },
        trainingTypeUuid: {
            type: FilterType.SELECT,
            label: "Type",
            values: trainingTypes?.map((t) => ({ key: t.uuid, label: t.label })),
        },
        department: {
            type: FilterType.SELECT_AUTOCOMPLETE,
            label: "Département",
            values: departments?.map((d) => ({ key: d.inseeCode, label: `${d.inseeCode} - ${d.name}` })),
        },
        sessionMode: { type: FilterType.SELECT, label: "Mode", values: convertEnumToKeyLabelObject(SessionMode) },
    };

    return (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            <Typography variant="h4">Suivi de mes formations en vue du maintien de l'habilitation sanitaire</Typography>
            <Card>
                <StyledCardContent>
                    {infos ? (
                        <Stack
                            direction="row"
                            sx={{
                                p: 2,
                                justifyContent: "space-between",
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                <LastTraining />
                                <Stack>
                                    <Typography
                                        variant="bold"
                                        sx={{
                                            color: drawerColors.text,
                                        }}
                                    >
                                        Ma dernière formation date du
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: colors.purple,
                                        }}
                                    >
                                        {infos.lastTrainingDate
                                            ? DateTime.fromISO(infos.lastTrainingDate).toLocaleString()
                                            : "Inexistante"}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                <Calendar />
                                <Stack>
                                    <Typography
                                        variant="bold"
                                        sx={{
                                            color: drawerColors.text,
                                        }}
                                    >
                                        Renouvellement de l'habilitation sanitaire
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: colors.green,
                                        }}
                                    >
                                        {infos.renewDate
                                            ? DateTime.fromISO(infos.renewDate).toLocaleString()
                                            : "Inexistante"}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                <Certificate />
                                <Stack>
                                    <Typography
                                        variant="bold"
                                        sx={{
                                            color: drawerColors.text,
                                        }}
                                    >
                                        Formations validées
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: colors.yellow,
                                        }}
                                    >
                                        {infos.trainingNumber}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    ) : (
                        <Typography>Vous n'avez suivi aucune formation</Typography>
                    )}
                </StyledCardContent>
            </Card>
            <NextSessionCard />
            <Typography variant="h4">Mes attestations de formation</Typography>
            <GenericFilters
                inputFilters={inputFilters}
                filterConfigurations={filterConfigurations}
                setInputFilters={setInputFilters}
                initialValues={{
                    trainingTitle: "",
                    startDate: [null, null],
                    trainingTypeUuid: [],
                    department: [],
                    sessionMode: [],
                }}
                sort={sort}
                setSort={setSort}
                sortingConfigurations={sortingConfiguration}
            />
            {loadingTable ? renderSkeleton() : renderContent()}
            <GenericPagination
                page={pagination.page}
                pageSize={pagination.pageSize}
                pageCount={Math.ceil(rowCount / pagination.pageSize)}
                setPage={handlePageChange}
                setPageSize={handlePageSizeChange}
            />
        </Stack>
    );
}
