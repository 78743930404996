// === NPM
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { ComplianceType, IAudit, IVaccinationSiteShort, IWorkshopAudit } from "@/interfaces/vaccination";
import { defaultTextLimit } from "@/resources/AppConstant";
import { FORM_TEXT, PATTERN, stringRequired } from "@/resources/FormUtils";
import { convertEnumToKeyLabelObject, getEnumKeyByValue } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import { VaccinationSiteOutletContext } from "../../../interface";

interface AuditDialogProps {
    onClose: () => void;
    audit?: IAudit;
    workshopIds: string[];
    vaccinationSite: IVaccinationSiteShort;
    onValid: (data: IAudit) => void;
}

export default function AuditDialog({
    onClose,
    audit,
    workshopIds,
    vaccinationSite,
    onValid,
}: Readonly<AuditDialogProps>) {
    const { userInfo } = useAuth();

    const workshopSchema = z
        .object({
            id: stringRequired(),
            auditPerformed: z.boolean(),
            complianceType: z
                .enum(["COMPLIANT", "NOT_COMPLIANT_MAJOR", "NOT_COMPLIANT_MINOR"], {
                    invalid_type_error: FORM_TEXT.required,
                })
                .nullable(),
            complianceComment: z
                .string()
                .max(2000, "Le motif de non-conformité ne peut pas excéder 2000 caractères")
                .optional()
                .nullable()
                .refine((value) => !(value && value.trim().length === 0), FORM_TEXT.emptyString),
        })
        .superRefine((object, ctx) => {
            if (object.auditPerformed) {
                if (!object.complianceType) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["complianceType"],
                    });
                }
                if (object.complianceType && object.complianceType !== "COMPLIANT" && !object.complianceComment) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["complianceComment"],
                    });
                }
            }
        });

    const auditSchema = z.object({
        date: stringRequired().refine(
            (value) =>
                DateTime.fromISO(value) >= DateTime.fromISO(vaccinationSite.date) &&
                DateTime.fromISO(value) <= DateTime.now(),
            {
                message: "La date de l'audit ne peut pas précéder la date du chantier, ni être dans le futur",
            }
        ),
        distance: z
            .number({
                required_error: FORM_TEXT.required,
                invalid_type_error: "Le nombre de kilomètres parcourus doit être un nombre entier positif",
            })
            .int("Le nombre de kilomètres parcourus doit être un nombre entier positif")
            .nonnegative("Le nombre de kilomètres parcourus doit être un nombre entier positif"),
        plateNumber: stringRequired().regex(new RegExp(PATTERN.plateNumber), FORM_TEXT.plateNumber),
        horsePower: stringRequired(),
        workshops: z.array(workshopSchema),
    });

    type AuditSchema = z.infer<typeof auditSchema>;

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        register,
        control,
    } = useForm<AuditSchema>({
        resolver: zodResolver(auditSchema),
        defaultValues: audit ?? {
            date: "",
            distance: null,
            plateNumber: userInfo.vehicle?.plateNumber ?? null,
            horsePower: userInfo.vehicle?.horsePower ?? "",
            workshops: workshopIds.map((workshop) => ({
                id: workshop,
                auditPerformed: false,
                complianceType: null,
                complianceComment: null,
            })),
        },
    });

    const { fields } = useFieldArray({
        control,
        name: "workshops",
        keyName: "uuid",
    });

    const workshops = watch("workshops");

    const { horsePower } = useOutletContext<VaccinationSiteOutletContext>();

    const renderGeneralInfo = () => (
        <GenericAccordion title="Informations générales" defaultExpanded>
            <Stack spacing={1} direction="row">
                <Controller
                    name="date"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <GenericDatePicker
                            value={value}
                            name="date"
                            label="Date de l'audit"
                            onChange={onChange}
                            error={!!error}
                            required
                            helperText={error?.message}
                            minDate={DateTime.fromISO(vaccinationSite.date)}
                            maxDate={DateTime.now()}
                        />
                    )}
                />
                <TextField
                    {...register("distance", {
                        valueAsNumber: true,
                    })}
                    label="Kilomètres parcourus (aller et retour)"
                    error={!!errors.distance}
                    helperText={errors.distance?.message}
                    required
                    fullWidth
                />
                <TextField
                    {...register("plateNumber")}
                    label="Immatriculation"
                    error={!!errors.plateNumber}
                    helperText={errors.plateNumber?.message}
                    required
                    fullWidth
                />
                <Controller
                    name="horsePower"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <GenericSelect
                            value={value}
                            label="Chevaux fiscaux"
                            onChange={onChange}
                            error={!!error}
                            required
                            helperText={error?.message}
                            options={horsePower}
                            optionsValue="key"
                        />
                    )}
                />
            </Stack>
        </GenericAccordion>
    );

    const renderAuditWorkshop = (workshop: IWorkshopAudit, index: number) => (
        <GenericAccordion title={`Atelier ${workshopIds[index]}`} key={workshop.id} defaultExpanded>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 4,
                    }}
                >
                    <Typography variant="h5">
                        Réalisation de l'audit{" "}
                        <Typography component="span" variant="h5" color="error">
                            *
                        </Typography>
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        <Typography variant={!workshop.auditPerformed ? "bold" : "body1"}>Audit non réalisé</Typography>
                        <Controller
                            name={`workshops.${index}.auditPerformed`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Switch
                                    checked={value}
                                    onChange={(e) => {
                                        onChange(e.target.checked);
                                        setValue(`workshops.${index}.complianceType`, null);
                                        setValue(`workshops.${index}.complianceComment`, null);
                                    }}
                                />
                            )}
                        />

                        <Typography variant={workshop.auditPerformed ? "bold" : "body1"}>Audit réalisé</Typography>
                    </Stack>
                </Grid>
                {workshops[index]?.auditPerformed && (
                    <>
                        <Grid
                            sx={{ pl: 3 }}
                            size={{
                                xs: 12,
                                md: 3,
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                <FormControl>
                                    <Typography variant="h5">
                                        Résultat de l'audit{" "}
                                        <Typography component="span" variant="h5" color="error">
                                            *
                                        </Typography>
                                    </Typography>

                                    <Controller
                                        name={`workshops.${index}.complianceType`}
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                                            <>
                                                <RadioGroup
                                                    value={value}
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        if (
                                                            e.target.value ===
                                                            getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT)
                                                        ) {
                                                            setValue(`workshops.${index}.complianceComment`, null);
                                                        }
                                                    }}
                                                >
                                                    {convertEnumToKeyLabelObject(ComplianceType).map((item) => (
                                                        <FormControlLabel
                                                            key={item.key}
                                                            value={item.key}
                                                            control={<Radio />}
                                                            label={item.label}
                                                            labelPlacement="end"
                                                        />
                                                    ))}
                                                </RadioGroup>
                                                {error && (
                                                    <FormHelperText error={!!errors}>{error?.message}</FormHelperText>
                                                )}
                                            </>
                                        )}
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>

                        {workshops[index]?.complianceType &&
                            workshops[index]?.complianceType !==
                                getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) && (
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 4,
                                    }}
                                >
                                    <TextField
                                        label="Motif de non conformité du compte-rendu"
                                        {...register(`workshops.${index}.complianceComment`)}
                                        rows={3}
                                        multiline
                                        placeholder="Commentaire relatif à la non conformité de l'audit"
                                        required
                                        fullWidth
                                        error={!!errors.workshops?.[index]?.complianceComment}
                                        helperText={
                                            errors.workshops?.[index]?.complianceComment?.message ??
                                            `${workshops?.[index]?.complianceComment?.length ?? 0}/${defaultTextLimit}`
                                        }
                                        slotProps={{
                                            htmlInput: {
                                                maxLength: { defaultTextLimit },
                                            },
                                        }}
                                    />
                                </Grid>
                            )}
                    </>
                )}
            </Grid>
        </GenericAccordion>
    );

    const renderContent = () => (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            {renderGeneralInfo()}
            {fields.map((workshop, index) => renderAuditWorkshop(workshop as IWorkshopAudit, index))}
        </Stack>
    );

    const displayActionButton = () => (
        <>
            <SecondaryButton onClick={onClose} variant="outlined">
                Fermer
            </SecondaryButton>
            <Button onClick={handleSubmit(onValid)} variant="contained" color="primary">
                Enregistrer
            </Button>
        </>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Informations d'audit du chantier n°${vaccinationSite.id}`}
            onClose={onClose}
            renderContent={renderContent}
            renderActions={displayActionButton}
        />
    );
}
