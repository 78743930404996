// === Import: NPM
import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === Import: LOCAL
import { IOVDpe, ITreatedSpecies } from "@/components/Profile/interface";
import TreatedSpeciesCard from "./TreatedSpeciesCard";

interface TreatedSpeciesProps {
    treatedSpecies: ITreatedSpecies[];
    dpes: IOVDpe[];
}
export default function TreatedSpecies({ treatedSpecies, dpes }: Readonly<TreatedSpeciesProps>) {
    return (
        <>
            <Typography sx={{ mb: 3 }} variant="h5">
                Espèces traitées
            </Typography>
            <Grid container spacing={2}>
                {treatedSpecies.map((ts, i) => (
                    <Grid
                        key={i}
                        size={{
                            xs: 12,
                            sm: 6,
                            md: 4,
                        }}
                    >
                        <TreatedSpeciesCard treatedSpecies={ts} dpes={dpes} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
