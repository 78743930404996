// === NPM
import React from "react";
import { ConnectableElement, useDrag, useDrop } from "react-dnd";
import { Cancel } from "@mui/icons-material";
import { Box, Card, IconButton, Tooltip, Typography } from "@mui/material";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
// === LOCAL
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { colors } from "@/resources/CssConstant";
import { IModule } from "../interface";

interface ModuleProps {
    module: IModule;
    onDelete?: (module: IModule) => void;
    onDrawer: boolean;
    onDrop?: (droppedModule: IModule, module: IModule) => void;
}

export default function Module({ module, onDelete, onDrawer, onDrop }: Readonly<ModuleProps>) {
    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: "MODULE",
            drop: (item: IModule) => onDrop(item, module),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.getItem()?.key !== module.key && monitor.canDrop(),
            }),
        }),
        [onDrop, module]
    );

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "MODULE",
            item: module,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [module]
    );

    return (
        (<PermissionsCheck requiredPermissions={module.permissions} preferences={module.preferences}>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: "20px",
                    width: "100%",
                    height: "100%",
                    cursor: "move",
                    opacity: isDragging ? 0.5 : 1,
                    transform: isOver && canDrop ? "scale(1.1) rotate(8deg)" : "",
                    overflow: "hidden",
                }}
                ref={onDrawer ? drag : (node) => drag(drop(node as ConnectableElement))}
                key={module.key}
            >
                <StyledCardContent
                    sx={{
                        height: "100%",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "20px",
                        flexDirection: "row",
                        justifyContent: "center",
                        backgroundColor: module.background ?? colors.white,
                        width: "100%",
                    }}
                >
                    {module.icon}
                    <Box
                        sx={{
                            width: "100%",
                            ml: 1
                        }}>
                        <Typography variant="bold">{module.name}</Typography>
                    </Box>
                    {onDelete && !isOver && (
                        <Tooltip title="Supprimer le module">
                            <IconButton
                                onClick={() => onDelete(module)}
                                sx={{ position: "absolute", top: -13, right: -13, p: 0 }}
                            >
                                <Cancel fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    )}
                </StyledCardContent>
            </Card>
        </PermissionsCheck>)
    );
}
