// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
// === LOCAL
import DeleteAction from "@/components/generics/actions/DeleteAction";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import EditAction from "@/components/generics/actions/EditAction";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import InfoText from "@/components/generics/text/InfoText";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import {
    IMonitoringInterventionDetails,
    InterventionCorrectionType,
    MonitoringInterventionStatus,
} from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { convertEnumToKeyLabelObject, createPayloadWithObject } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import CorrectionDialog from "../../containers/CorrectionDialog";
import WorkshopsCell from "../../containers/WorkshopsCells";
import { useFormMonitoringIntervention } from "../Form/containers/useFormMonitoringIntervention";
import {
    IMonitoringIntervention,
    IMonitoringInterventionFilters,
    MonitoringInterventionOutletContext,
} from "../interface";
import ViewDialog from "./containers/ViewDialog";

export function FollowUp() {
    const { departments } = useDepartments();
    const navigate = useNavigate();
    const { setForm } = useFormMonitoringIntervention();
    const { correctIntervention } = useOutletContext<MonitoringInterventionOutletContext>();
    const [monitoringInterventions, setMonitoringInterventions] = useState<IMonitoringIntervention[]>([]);
    const [inputFilters, setInputFilters] = useState<IMonitoringInterventionFilters>({
        id: "",
        interventionDate: [null, null],
        dpe: "",
        veterinary: "",
        farm: "",
        farmCity: "",
        farmDepartmentCode: [],
        workshopId: "",
        status: [],
        elisaSampled: "",
        postVaccinationSampled: "",
        isCertified: "",
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "interventionDate", sort: "desc" }]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedInterventionId, setSelectedInterventionId] = useState<string>("");
    const [selectedIntervention, setSelectedIntervention] = useState<IMonitoringInterventionDetails>(null);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [correctionType, setCorrectionType] = useState<InterventionCorrectionType>(null);
    const interventionToDelete = monitoringInterventions.find((i) => i.id === selectedInterventionId);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getInterventions();
    }, [pagination, sortModel]);

    const getInterventions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayloadWithObject(inputFilters, filterConfigurations),
        };
        const res = await VaccinationService.getMonitoringInterventions(payload);
        if (res.status === HttpStatus.OK) {
            setMonitoringInterventions(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const handleDeleteIntervention = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            setSelectedInterventionId("");
            return;
        }
        const res = await VaccinationService.deleteMonitoringIntervention(selectedInterventionId);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenDeleteDialog(false);
            toast.success("Intervention supprimée avec succès");
            setSelectedInterventionId("");
            getInterventions();
        }
    };

    const handleViewIntervention = async (id: string) => {
        const res = await VaccinationService.getMonitoringIntervention(id);
        if (res.status === HttpStatus.OK) {
            setSelectedIntervention(await res.json());
            setOpenRecapDialog(true);
        }
    };

    const handleOpenCorrectionModal = async (correctionType: InterventionCorrectionType, id: string) => {
        const res = await VaccinationService.getMonitoringIntervention(id);
        if (res.status === HttpStatus.OK) {
            setSelectedIntervention(await res.json());
            setCorrectionType(correctionType);
        }
    };

    const handleCorrectionType = async (reason: string) => {
        if (correctionType === InterventionCorrectionType.UPDATE) {
            setForm(selectedIntervention);
            navigate(routerLinks.iahp.monitoringIntervention.form(), {
                state: { correctionReason: reason, correctionType: correctionType },
            });
        }
        if (correctionType === InterventionCorrectionType.DELETE) {
            const res = await correctIntervention(correctionType, reason, selectedIntervention);
            if (res.status === HttpStatus.NO_CONTENT) {
                getInterventions();
                setCorrectionType(null);
                setSelectedIntervention(null);
            }
        }
    };

    const getFile = async (interventionId: string, fileUuid: string) => {
        const res = await VaccinationService.getMonitoringInterventionFile(interventionId, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            saveAs(file, res.headers.get("content-disposition").split("filename=")[1].slice(1, -1));
        }
    };

    const handleEditIntervention = async (id: string) => {
        const res = await VaccinationService.getMonitoringIntervention(id);
        if (res.status === HttpStatus.OK) {
            setForm(await res.json());
            navigate(routerLinks.iahp.monitoringIntervention.form());
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Numéro intervention",
            width: 150,
        },
        {
            field: "interventionDate",
            headerName: "Date d'intervention",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
        },
        {
            field: "dpeId",
            headerName: "N° DPE",
            width: 100,
        },
        {
            field: "dpeName",
            headerName: "Nom du DPE",
            width: 200,
        },
        {
            field: "veterinaryId",
            headerName: "N° ordinal vétérinaire",
            width: 150,
        },
        {
            field: "veterinaryLastname",
            headerName: "Vétérinaire",
            width: 200,
            valueGetter: (params: GridRenderCellParams) =>
                `${params.row.veterinaryFirstname} ${params.row.veterinaryLastname}`,
        },
        {
            field: "farmId",
            headerName: "Identifiant établissement",
            width: 150,
        },
        {
            field: "farmName",
            headerName: "Nom établissement",
            width: 150,
        },
        {
            field: "farmCity",
            headerName: "Commune établissement",
            width: 150,
        },
        {
            field: "farmDepartmentCode",
            headerName: "Département établissement",
            width: 150,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.farmDepartmentCode
                    ? `${params.row.farmDepartmentCode} - ${params.row.farmDepartmentName}`
                    : "",
        },
        {
            field: "workshopIds",
            headerName: "Identifiant atelier",
            width: 150,
            sortable: false,
            renderCell: (params: GridRenderCellParams) =>
                params.row.workshopIds.length > 0 && (
                    <>
                        <WorkshopsCell workshopIds={params.row.workshopIds} />
                        <Typography variant="body2">{params.row.workshopIds[0]}</Typography>
                        {params.row.workshopIds.length > 1 && <Typography variant="body2">...</Typography>}
                    </>
                ),
        },
        {
            field: "status",
            headerName: "Statut Intervention",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) => MonitoringInterventionStatus[params.value],
        },
        {
            field: "elisaSampled",
            headerName: "Indicateur sérologie ELISA-NP",
            width: 100,
            sortable: false,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? "Prélevé" : "Non prélevé"),
        },

        {
            field: "postVaccinationSampled",
            headerName: "Indicateur sérologie supervision de la vaccination",
            width: 100,
            sortable: false,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? "Prélevé" : "Non prélevé"),
        },
        {
            field: "certificateFileUuid",
            headerName: "Attestation",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? "Générée" : "Non générée"),
        },
        {
            ...ActionsColumnProps,
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction title="Voir le détail" onClick={() => handleViewIntervention(params.row.id)} />
                    {params.row.certificateFileUuid && (
                        <DownloadAction
                            title="Télécharger l'attestation de surveillance"
                            onClick={() => getFile(params.row.id, params.row.certificateFileUuid)}
                        />
                    )}
                    {params.row.sampleFileUuid && (
                        <DownloadAction
                            title="Télécharger les fiches de prélèvement"
                            onClick={() => getFile(params.row.id, params.row.sampleFileUuid)}
                        />
                    )}
                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP]}
                    >
                        <EditAction
                            title="Modifier"
                            onClick={() => {
                                !params.row.sampleFileUuid
                                    ? handleOpenCorrectionModal(InterventionCorrectionType.UPDATE, params.row.id)
                                    : handleEditIntervention(params.row.id);
                            }}
                        />
                        <DeleteAction
                            title="Supprimer"
                            onClick={() => {
                                setSelectedInterventionId(params.row.id);
                                !params.row.sampleFileUuid
                                    ? handleOpenCorrectionModal(InterventionCorrectionType.DELETE, params.row.id)
                                    : setOpenDeleteDialog(true);
                            }}
                        />
                    </PermissionsCheck>
                </Box>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<IMonitoringInterventionFilters> = {
        id: { label: "Numéro Intervention", type: FilterType.INPUT },
        interventionDate: { label: "Date", type: FilterType.DATEPICKER },
        dpe: { label: "DPE", type: FilterType.INPUT },
        veterinary: { label: "Vétérinaire", type: FilterType.INPUT },
        farm: { label: "Établissement", type: FilterType.INPUT },
        farmCity: { label: "Commune établissement", type: FilterType.INPUT },
        farmDepartmentCode: {
            label: "Département établissement",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
        workshopId: { label: "Identifiant atelier", type: FilterType.INPUT },
        status: {
            label: "Statut Intervention",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(MonitoringInterventionStatus),
        },
        elisaSampled: {
            label: "Indicateur sérologie ELISA-NP",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Prélevé", key: "true" },
                { label: "Non prélevé", key: "false" },
            ],
            valuesAlphabeticalSort: false,
            objectProperties: {
                filterKey: "serologicalSample",
                objectData: { serologicalType: "ELISA_NP" },
                field: "sampleRealized",
            },
        },
        postVaccinationSampled: {
            label: "Indicateur sérologie supervision de la vaccination",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Prélevé", key: "true" },
                { label: "Non prélevé", key: "false" },
            ],
            valuesAlphabeticalSort: false,
            objectProperties: {
                filterKey: "serologicalSample",
                objectData: { serologicalType: "POST_VACCINATION" },
                field: "sampleRealized",
            },
        },
        isCertified: {
            label: "Attestation",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Générée", key: "true" },
                { label: "Non générée", key: "false" },
            ],
        },
    };

    return (<>
        <Stack spacing={2} sx={{
            width: "100%"
        }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                }}>
                <Typography variant="h4">Mes interventions de surveillance</Typography>
                <PermissionsCheck
                    requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                    preferences={[Preference.IAHP]}
                >
                    <GenericButton
                        onClick={() => navigate(routerLinks.iahp.monitoringIntervention.form())}
                        label="Nouvelle intervention"
                        startIcon={<Add />}
                    />
                </PermissionsCheck>
            </Box>
            <Card>
                <CardContent
                    sx={{
                        "& .has-certificate": {
                            backgroundColor: statusColor.successBackground,
                        },
                    }}
                >
                    <InfoText message="En vert, les interventions de surveillance dont l'attestation a été générée." />
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            id: "",
                            interventionDate: [null, null],
                            dpe: "",
                            veterinary: "",
                            farm: "",
                            farmCity: "",
                            farmDepartmentCode: [],
                            workshopId: "",
                            status: [],
                            elisaSampled: "",
                            postVaccinationSampled: "",
                            isCertified: "",
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={monitoringInterventions}
                        columns={columns}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        autoHeight
                        sortingMode="server"
                        paginationMode="server"
                        sortModel={sortModel}
                        rowCount={rowCount}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={["asc", "desc"]}
                        filterMode="server"
                        getRowClassName={(params) => (params.row.certificateFileUuid ? "has-certificate" : "")}
                    />
                </CardContent>
            </Card>
        </Stack>
        {openDeleteDialog && (
            <GenericConfirmDialog
                title={`Suppression de l'intervention ${interventionToDelete.id}`}
                message={`Êtes-vous sûr de vouloir supprimer cette intervention de surveillance ? Cette action est irréversible.`}
                onClose={handleDeleteIntervention}
            />
        )}
        {openRecapDialog && (
            <ViewDialog
                intervention={selectedIntervention}
                onClose={() => {
                    setOpenRecapDialog(false);
                    setSelectedIntervention(null);
                }}
            />
        )}
        {correctionType && (
            <CorrectionDialog
                interventionId={selectedIntervention.generalInformation.id}
                correctionType={correctionType}
                interventionType={"surveillance"}
                onClose={() => {
                    setCorrectionType(null);
                    setSelectedInterventionId(null);
                }}
                onValid={handleCorrectionType}
            />
        )}
    </>);
}
