// === NPM
import { KyResponse } from "ky";
import { formatParams } from "@/resources/utils";
// === LOCAL
import {
    IAmv,
    IAmvCreateUpdate,
    IAmvVersion,
    IChargeable,
    IChargeableCreateUpdate,
    IChargeableRequiredFields,
    IChargeableVersion,
    IVat,
    IVatCreateUpdate,
    IVatVersion,
} from "../components/VaccinationIahp/Billing/interface";
import { HttpMethod, Loader } from "../interfaces/global";
import { IReferential } from "../interfaces/referential";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class BillingService {
    async getVats(payload?: { name?: string; date?: string }): Promise<KyResponse<IVat[]>> {
        return HttpService.fetch(endpoints.billingService.vat.base(), {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async getVatVersions(vatKey: string): Promise<KyResponse<IVatVersion[]>> {
        return HttpService.fetch(endpoints.billingService.vat.versions(vatKey), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async createVatVersion(vatKey: string, json: IVatCreateUpdate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.vat.versions(vatKey), {
            method: HttpMethod.POST,
            headers: {
                loader: Loader.BUTTON,
            },
            json,
        });
    }

    async updateVatVersion(uuid: string, json: IVatCreateUpdate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.vat.specificVersion(uuid), {
            method: HttpMethod.PUT,
            headers: {
                loader: Loader.BUTTON,
            },
            json,
        });
    }

    async deleteVatVersion(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.vat.specificVersion(uuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getVatReferentials(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.billingService.referential.vat(), {
            method: HttpMethod.GET,
        });
    }

    async getAmvs(payload?: { name?: string; date?: string }): Promise<KyResponse<IAmv[]>> {
        return HttpService.fetch(endpoints.billingService.amv.base(), {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async getAmvVersions(amvKey: string): Promise<KyResponse<IAmvVersion[]>> {
        return HttpService.fetch(endpoints.billingService.amv.versions(amvKey), {
            method: HttpMethod.GET,
        });
    }

    async createAmvVersion(amvKey: string, json: IAmvCreateUpdate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.amv.versions(amvKey), {
            method: HttpMethod.POST,
            headers: {
                loader: Loader.BUTTON,
            },
            json,
        });
    }

    async updateAmvVersion(uuid: string, json: IAmvCreateUpdate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.amv.specificVersion(uuid), {
            method: HttpMethod.PUT,
            headers: {
                loader: Loader.BUTTON,
            },
            json,
        });
    }

    async deleteAmvVersion(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.amv.specificVersion(uuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getAmvReferentials(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.billingService.referential.amv(), {
            method: HttpMethod.GET,
        });
    }

    async getChargeables(payload?: { name?: string; date?: string }): Promise<KyResponse<IChargeable[]>> {
        return HttpService.fetch(endpoints.billingService.chargeables.base(), {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async getChargeablesVersions(chargeableKey: string): Promise<KyResponse<IChargeableVersion[]>> {
        return HttpService.fetch(endpoints.billingService.chargeables.versions(chargeableKey), {
            method: HttpMethod.GET,
        });
    }

    async getChargeableRequiredFields(chargeableKey: string): Promise<KyResponse<IChargeableRequiredFields>> {
        return HttpService.fetch(endpoints.billingService.chargeables.requiredFields(chargeableKey), {
            method: HttpMethod.GET,
        });
    }

    async createChargeableVersion(chargeableKey: string, json: IChargeableCreateUpdate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.chargeables.versions(chargeableKey), {
            method: HttpMethod.POST,
            headers: {
                loader: Loader.BUTTON,
            },
            json,
        });
    }

    async updateChargeableVersion(uuid: string, json: IChargeableCreateUpdate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.chargeables.specificVersion(uuid), {
            method: HttpMethod.PUT,
            headers: {
                loader: Loader.BUTTON,
            },
            json,
        });
    }

    async deleteChargeableVersion(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.billingService.chargeables.specificVersion(uuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getChargeablesReferentials(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.billingService.referential.chargeables(), {
            method: HttpMethod.GET,
        });
    }
}

export default new BillingService();
