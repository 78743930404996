// === NPM
import React from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";

export default function FaqSkeleton() {
    return (
        (<Box sx={{
            height: "100%"
        }}>
            <PageContent>
                <Stack spacing={4} sx={{
                    width: "100%"
                }}>
                    <Stack spacing={2} sx={{
                        alignItems: "center"
                    }}>
                        <Typography variant="h4">Comment pouvons-nous vous aider ?</Typography>
                        <Skeleton variant="rounded" width="50%" height={80} />
                        <Typography> Rechercher par tag : </Typography>
                        <Stack spacing={2} direction="row">
                            <Skeleton variant="rounded" width={80} height={30} />
                            <Skeleton variant="rounded" width={80} height={30} />
                        </Stack>
                    </Stack>
                    <Skeleton variant="text" width="30%" height={40} />
                    <Skeleton variant="rounded" width="100%" height={60} sx={{ borderRadius: 8 }} />
                    <Skeleton variant="rounded" width="100%" height={60} sx={{ borderRadius: 8 }} />
                </Stack>
            </PageContent>
        </Box>)
    );
}
