// === Import: NPM
import React from "react";
import { Box, Stack, Tooltip } from "@mui/material";
// === Import: LOCAL
import { GenericText } from "./GenericText";

interface GenericIconTextProps {
    text: string;
    icon: React.ReactElement;
    iconTooltip: string;
    bold?: boolean;
}
export function GenericIconText({ text, icon, iconTooltip, bold }: Readonly<GenericIconTextProps>) {
    return (
        (<Stack direction="row" spacing={1} sx={{
            alignItems: "center"
        }}>
            <Tooltip title={iconTooltip}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-start"
                    }}>
                    {icon}
                </Box>
            </Tooltip>
            <GenericText value={text} bold={bold} />
        </Stack>)
    );
}
