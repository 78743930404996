// === NPM
import React, { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import update from "immutability-helper";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import { ICategory } from "@/interfaces/faq";
import { HttpStatus } from "@/interfaces/global";
import FaqService from "@/services/FaqService";
import { FaqDropableType, FaqOutletContext } from "../../../interface";
import { CategoryCard } from "./CategoryCard";
import CategoryDialog from "./CategoryDialog";

export default function Category() {
    const { categories, getCategories, setCategories } = useOutletContext<FaqOutletContext>();
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

    const [, drop] = useDrop(() => ({ accept: FaqDropableType.CATEGORY }));

    const findCategory = useCallback(
        (uuid: string) => {
            const category = categories.find((c) => c.uuid === uuid);
            return {
                category,
                index: categories.indexOf(category),
            };
        },
        [categories]
    );

    const moveCategory = useCallback(
        (uuid: string, atIndex: number) => {
            const { category, index } = findCategory(uuid);
            setCategories(
                update(categories, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, category],
                    ],
                })
            );
        },
        [findCategory, categories, setCategories]
    );

    const handleCreateCategory = async (category: ICategory) => {
        const res = await FaqService.postCategory(category);
        if (res.status === HttpStatus.CREATED) {
            await getCategories();
            toast.success("Catégorie créée avec succès");
            setOpenCreateDialog(false);
        }
    };

    const renderCategories = () => {
        return categories.length > 0 ? (
            categories.map((category) => (
                <CategoryCard
                    key={category.uuid}
                    category={category}
                    findCategory={findCategory}
                    moveCategory={moveCategory}
                />
            ))
        ) : (
            <Typography variant="h6" align="center">
                Aucune catégorie
            </Typography>
        );
    };

    return (<>
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <Box
                sx={{
                    justifyContent: "space-between",
                    display: "flex"
                }}>
                <Typography variant="h4">Gestion des catégories</Typography>
                <GenericButton
                    label="Ajouter une catégorie"
                    startIcon={<Add />}
                    onClick={() => {
                        setOpenCreateDialog(true);
                    }}
                />
            </Box>
            <Stack spacing={2} ref={drop}>
                {renderCategories()}
            </Stack>
        </Stack>
        {openCreateDialog && (
            <CategoryDialog
                onClose={() => setOpenCreateDialog(false)}
                onValid={handleCreateCategory}
                title={"Création d'une catégorie"}
            />
        )}
    </>);
}
