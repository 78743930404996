// === NPM
import React, { RefObject } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Add, Delete, Help } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    FormControlLabel,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { IMEPMaskComponent } from "@/components/generics/inputs/IMEPMaskComponent";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { InjectionType } from "@/interfaces/vaccination";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { getEnumKeyByValue, toLocaleDateFormat } from "@/resources/utils";
import { IAnimalBatch, ISearchVaccinationIntervention } from "../../../interface";
import { useFormIntervention } from "../../../useFormIntervention";
import InterventionAutocomplete from "./containers/InterventionAutocomplete";
import WorkshopAutocomplete from "./containers/WorkshopAutocomplete";

interface AnimalBatchesProps {
    formRef: RefObject<HTMLButtonElement>;
    vaccinationDate: string;
    onValid: () => void;
}

export default function AnimalBatches({ formRef, vaccinationDate, onValid }: Readonly<AnimalBatchesProps>) {
    const { form, setForm } = useFormIntervention();
    const injectionType = form.animalInformation.injectionType;

    const animalBatchesSchema = z
        .object({
            hasImep: z.boolean(),
            imepNumber: z
                .string()
                .nullable()
                .optional()
                .refine((value) => !value || RegExp("[A-Z]{3}[0-9]{2}.[0-9]{3}.[0-9]{3}").test(value), {
                    message: "Le format de l'IMEP est incorrect",
                }),
            declaredWorkshopId: stringRequired(),
            previousInterventionId: z
                .string()
                .nullable()
                .optional()
                .refine(
                    (value) =>
                        (!value && injectionType === getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE)) ||
                        value,
                    { message: FORM_TEXT.required }
                ),
            installationDate: z
                .string()
                .nullable()
                .optional()
                .refine(
                    (value) =>
                        (!value && injectionType === getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE)) ||
                        value,
                    { message: FORM_TEXT.required }
                )
                .refine((value) => !value || (value && DateTime.fromISO(value) <= DateTime.now().startOf("day")), {
                    message: "La date de mise en place déclarée doit être dans le passé",
                }),
            hatchingDate: z
                .string()
                .nullable()
                .optional()
                .refine(
                    (value) =>
                        (!value && injectionType === getEnumKeyByValue(InjectionType, InjectionType.REMINDER)) || value,
                    {
                        message: FORM_TEXT.required,
                    }
                )
                .refine((value) => !value || (value && DateTime.fromISO(value) < DateTime.fromISO(vaccinationDate)), {
                    message: `La date d'éclosion doit être avant la date d'intervention (${toLocaleDateFormat(
                        vaccinationDate
                    )})`,
                }),
            uuid: z.string().optional(),
        })
        .superRefine((values, context) => {
            if (values.hasImep && !values.imepNumber) {
                context.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: FORM_TEXT.required,
                    path: ["imepNumber"],
                });
            }
        });

    const schema = z.object({
        animalBatches: z.array(animalBatchesSchema),
    });

    const defaultAnimalBatch = {
        hasImep: false,
        imepNumber: null,
        declaredWorkshopId: form.generalInformation.workshopId,
        previousInterventionId: null,
        installationDate: null,
        hatchingDate: null,
        uuid: crypto.randomUUID(),
    };

    type AnimalBatchSchema = z.infer<typeof schema>;

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        register,
        control,
    } = useForm<AnimalBatchSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            animalBatches:
                form.animalBatches.length >= 1
                    ? form.animalBatches.map((ab) => ({
                          ...ab,
                          hasImep: !!ab.imepNumber,
                      }))
                    : [{ ...defaultAnimalBatch }],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "animalBatches",
    });

    const animalBatches = watch("animalBatches");
    const deletable = animalBatches.length > 1;
    const isFirstDose = injectionType === getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE);

    return (
        <form
            onSubmit={handleSubmit((data) => {
                setForm({ ...form, animalBatches: data.animalBatches as IAnimalBatch[] });
                onValid();
            })}
            noValidate
        >
            <Stack spacing={2}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography sx={{ width: { xs: "100%", md: "50%" } }} variant="h5">
                        Déclaration des lots
                    </Typography>
                    <GenericButton
                        startIcon={<Add />}
                        onClick={() => append({ ...defaultAnimalBatch, uuid: crypto.randomUUID() })}
                        label="Ajouter un lot"
                    />
                </Box>
                <Stack spacing={2}>
                    {fields.map((a, index) => (
                        <Card sx={{ p: 2 }} key={a.id}>
                            <StyledCardContent>
                                <Stack>
                                    <Box
                                        sx={{
                                            justifyContent: "space-between",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography variant="bold">Lot {index + 1}</Typography>
                                        {deletable && (
                                            <Button startIcon={<Delete />} color="error" onClick={() => remove(index)}>
                                                Supprimer le lot
                                            </Button>
                                        )}
                                    </Box>
                                    <Controller
                                        name={`animalBatches.${index}.hasImep`}
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography variant={!value ? "bold" : "body1"}>
                                                    IMEP Inconnu
                                                </Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={value}
                                                            onChange={(event) => {
                                                                onChange(event);
                                                                setValue(`animalBatches.${index}.imepNumber`, null);
                                                            }}
                                                        />
                                                    }
                                                    label=""
                                                />
                                                <Typography variant={value ? "bold" : "body1"}>IMEP Connu</Typography>
                                            </Stack>
                                        )}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid
                                            size={{
                                                xs: 12,
                                                md: 6,
                                                lg: 4,
                                            }}
                                        >
                                            <TextField
                                                {...register(`animalBatches.${index}.imepNumber`)}
                                                label="IMEP"
                                                fullWidth
                                                helperText={errors?.animalBatches?.[index]?.imepNumber?.message}
                                                error={!!errors?.animalBatches?.[index]?.imepNumber}
                                                required={animalBatches[index].hasImep}
                                                disabled={!animalBatches[index].hasImep}
                                                slotProps={{
                                                    input: animalBatches[index].hasImep
                                                        ? {
                                                              inputComponent: IMEPMaskComponent as any,
                                                              startAdornment: (
                                                                  <InputAdornment position="start">
                                                                      <Tooltip title="Le format attendu est AAA11.111.111, merci de remplacer les _ par des majuscules et les # par des chiffres.">
                                                                          <Help />
                                                                      </Tooltip>
                                                                  </InputAdornment>
                                                              ),
                                                          }
                                                        : null,

                                                    inputLabel: { shrink: animalBatches[index].hasImep },
                                                }}
                                            />
                                        </Grid>
                                        {!isFirstDose && (
                                            <Grid
                                                size={{
                                                    xs: 12,
                                                    md: 6,
                                                    lg: 4,
                                                }}
                                            >
                                                <InterventionAutocomplete
                                                    value={animalBatches[index].previousInterventionId}
                                                    error={
                                                        errors?.animalBatches?.[index]?.previousInterventionId?.message
                                                    }
                                                    handleChange={(value: ISearchVaccinationIntervention) =>
                                                        setValue(
                                                            `animalBatches.${index}.previousInterventionId`,
                                                            value?.id
                                                        )
                                                    }
                                                    vaccinationDate={vaccinationDate}
                                                />
                                            </Grid>
                                        )}
                                        <Grid
                                            size={{
                                                xs: 12,
                                                md: 6,
                                                lg: 4,
                                            }}
                                        >
                                            <WorkshopAutocomplete
                                                value={animalBatches[index].declaredWorkshopId}
                                                error={errors?.animalBatches?.[index]?.declaredWorkshopId?.message}
                                                handleChange={(event) =>
                                                    setValue(
                                                        `animalBatches.${index}.declaredWorkshopId`,
                                                        event.target.value?.id
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            size={{
                                                xs: 12,
                                                md: 6,
                                                lg: 4,
                                            }}
                                        >
                                            <Controller
                                                name={`animalBatches.${index}.installationDate`}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <GenericDatePicker
                                                        value={value}
                                                        onChange={onChange}
                                                        name="installationDate"
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        label="Date de mise en place déclarée"
                                                        required={
                                                            injectionType !==
                                                            getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE)
                                                        }
                                                        maxDate={DateTime.now()}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            size={{
                                                xs: 12,
                                                md: 6,
                                                lg: 4,
                                            }}
                                        >
                                            <Controller
                                                name={`animalBatches.${index}.hatchingDate`}
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <GenericDatePicker
                                                        value={value}
                                                        onChange={onChange}
                                                        name="hatchingDate"
                                                        error={!!error}
                                                        helperText={error?.message}
                                                        label="Date d'éclosion"
                                                        maxDate={DateTime.fromISO(vaccinationDate).minus({
                                                            day: 1,
                                                        })}
                                                        required={
                                                            injectionType !==
                                                            getEnumKeyByValue(InjectionType, InjectionType.REMINDER)
                                                        }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            size={{
                                                xs: 12,
                                                md: 6,
                                                lg: 4,
                                            }}
                                        >
                                            <GenericTextField
                                                label="Âge à la vaccination (jours)"
                                                disabled
                                                value={
                                                    animalBatches[index].hatchingDate
                                                        ? Math.round(
                                                              DateTime.fromISO(animalBatches[index].hatchingDate).diff(
                                                                  DateTime.fromISO(vaccinationDate),
                                                                  ["days"]
                                                              ).days
                                                          ) * -1
                                                        : ""
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </StyledCardContent>
                        </Card>
                    ))}
                </Stack>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
