// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import EditAction from "@/components/generics/actions/EditAction";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
// === LOCAL
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { createPayload } from "@/resources/utils";
import UserService from "@/services/UserService";
import UpdatePharmacyDialog from "./containers/UpdatePharmacyDialog";
import { IPharmacy } from "./interface";

interface Filters {
    id: string;
    name: string;
    email: string;
}

export default function Pharmacy() {
    const [pharmacies, setPharmacies] = useState<IPharmacy[]>([]);
    const [inputFilters, setInputFilters] = useState<Filters>({
        id: "",
        name: "",
        email: "",
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "name", sort: "asc" }]);
    const [selectedPharmacyId, setSelectedPharmacyId] = useState<string>(null);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);

    const selectedPharmacy = pharmacies.find((a) => a.id === selectedPharmacyId);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getPharmacies();
    }, [pagination, sortModel]);

    const getPharmacies = async () => {
        const payload = {
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            page: pagination.page,
            size: pagination.pageSize,
            ...createPayload(inputFilters),
        };
        const res = await UserService.getPharmacies(payload);
        if (res.status === HttpStatus.OK) {
            setPharmacies(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const handleUpdateAgency = async (pharmacy: IPharmacy) => {
        const res = await UserService.updatePharmacy(selectedPharmacyId, { email: pharmacy.email });
        if (res.status === HttpStatus.OK) {
            setOpenUpdateDialog(false);
            toast.success("Officine modifiée avec succès");
            getPharmacies();
        }
    };

    const columns = [
        {
            field: "name",
            headerName: "Nom de l'officine",
            flex: 1,
        },
        {
            field: "id",
            headerName: "Id de l'officine",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email de l'officine",
            flex: 1,
            sortable: false,
        },
        {
            field: "pharmacists",
            headerName: "Pharmaciens associés",
            sortable: false,
            flex: 1.5,
            renderCell: (params: GridRenderCellParams) => (
                <ul>
                    {params.row.pharmacists.map((pharmacist) => (
                        <li key={pharmacist.id}>{`${pharmacist.lastname} ${pharmacist.firstname}`}</li>
                    ))}
                </ul>
            ),
        },
        {
            ...ActionsColumnProps,
            width: 100,
            renderCell: (params) => (
                <Box>
                    <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_CALYPSO]}>
                        <EditAction
                            title="Modifier l'email"
                            onClick={() => {
                                setOpenUpdateDialog(true);
                                setSelectedPharmacyId(params.row.id);
                            }}
                        />
                    </PermissionsCheck>
                </Box>
            ),
        },
    ];

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const filterConfigurations: FilterConfigurations<Filters> = {
        name: { label: "Nom de l'officine", type: FilterType.INPUT },
        id: { label: "ID de l'officine", type: FilterType.INPUT },
        email: { label: "Email", type: FilterType.INPUT },
    };

    return (<>
        <Stack spacing={2} sx={{
            width: "100%"
        }}>
            <Typography variant="h4">Officines et pharmaciens associés</Typography>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            id: "",
                            name: "",
                            email: "",
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={pharmacies}
                        columns={columns}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        autoHeight
                        sortingMode="server"
                        paginationMode="server"
                        sortModel={sortModel}
                        rowCount={rowCount}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={["asc", "desc"]}
                        filterMode="server"
                    />
                </CardContent>
            </Card>
        </Stack>
        {openUpdateDialog && (
            <UpdatePharmacyDialog
                currentPharmacy={selectedPharmacy}
                onClose={() => setOpenUpdateDialog(false)}
                onValid={handleUpdateAgency}
            />
        )}
    </>);
}
