// === NPM
import React, { useEffect, useRef, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from "@mui/material";
// === LOCAL
import ChipTag from "@/components/generics/ChipTag";
import HtmlBox from "@/components/generics/layout/HtmlBox";
import StyledLinearProgress from "@/components/styled/StyledLinearProgress";
import { IQuestion, ITag } from "@/interfaces/faq";
import { UserTypeHeader } from "@/interfaces/global";
import { colors } from "@/resources/CssConstant";
import FaqService from "@/services/FaqService";
import { IUserQuestionShort } from "../interface";

interface QuestionCardProps {
    question: IUserQuestionShort;
    tags: ITag[];
}

export default function QuestionCard({ question, tags }: Readonly<QuestionCardProps>) {
    const ref = useRef<null | HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [questionDetails, setQuestionDetails] = useState<IQuestion | null>(null);

    useEffect(() => {
        getDetails();
    }, []);

    const executeScroll = () => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const getDetails = async () => {
        const data = await FaqService.getQuestionDetailsCached(question.uuid, UserTypeHeader.USER);
        if (data) {
            setQuestionDetails(data);
        }
    };

    return (
        (<Accordion
            sx={{
                "&:before": {
                    display: "none",
                },
                "&:first-of-type": {
                    borderRadius: 8,
                },
                "&:last-of-type": {
                    borderRadius: 8,
                },
                "borderRadius": 8,
                "p": 1,
            }}
            expanded={expanded}
            onChange={() => {
                setExpanded(!expanded);
                executeScroll();
            }}
            ref={ref}
        >
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ color: colors.primaryColor }}>
                <Typography variant="h5" sx={{
                    color: "primary"
                }}>
                    {question?.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Divider />
                {questionDetails ? (
                    <>
                        <HtmlBox content={questionDetails.content} />
                        <Stack direction="row" spacing={2}>
                            {questionDetails.tagUuids.map((uuid) => (
                                <ChipTag key={uuid} tag={tags.find((tag) => tag.uuid === uuid)} />
                            ))}
                        </Stack>
                    </>
                ) : (
                    <StyledLinearProgress />
                )}
            </AccordionDetails>
        </Accordion>)
    );
}
