// === NPM
import React, { useEffect, useState } from "react";
import { Help, KeyboardArrowDown, Search } from "@mui/icons-material";
import { Autocomplete, IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { ISearchVaccinationIntervention } from "@/components/VaccinationIahp/VaccinationSite/interface";
// === LOCAL
import { useFormIntervention } from "@/components/VaccinationIahp/VaccinationSite/useFormIntervention";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { InjectionType } from "@/interfaces/vaccination";
import { getEnumKeyByValue } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";
import InterventionDialog from "./InterventionDialog";

interface InterventionAutocompleteProps {
    value: string;
    handleChange: (value: ISearchVaccinationIntervention) => void;
    error: string;
    vaccinationDate: string;
}

export default function InterventionAutocomplete({
    value,
    handleChange,
    error,
    vaccinationDate,
}: Readonly<InterventionAutocompleteProps>) {
    const { form } = useFormIntervention();
    const [interventions, setInterventions] = useState<ISearchVaccinationIntervention[]>([]);
    const [batchSearch, setBatchSearch] = useState<string>("");
    const [openInterventionDialog, setOpenInterventionDialog] = useState<boolean>(false);

    useEffect(() => {
        if (value) setBatchSearch(value);
    }, [value]);

    useTimeout(() => getInterventions(), [batchSearch]);

    const getInjectionType = () => {
        const injectionTypes = [];
        if (form.animalInformation.injectionType === getEnumKeyByValue(InjectionType, InjectionType.REMINDER)) {
            injectionTypes.push(
                getEnumKeyByValue(InjectionType, InjectionType.SECOND_DOSE),
                getEnumKeyByValue(InjectionType, InjectionType.REMINDER)
            );
        } else {
            injectionTypes.push(getEnumKeyByValue(InjectionType, InjectionType.FIRST_DOSE));
        }
        return injectionTypes;
    };

    const getInterventions = async () => {
        const res = await VaccinationService.getInterventions("search", {
            page: 0,
            size: 50,
            id: batchSearch,
        });
        if (res.status === HttpStatus.OK) {
            setInterventions(await res.json());
        }
    };

    return (
        <>
            <Autocomplete
                popupIcon={<KeyboardArrowDown />}
                options={interventions}
                value={interventions.find((o) => o.id === value) || null}
                onChange={(_, newValue) => handleChange(newValue)}
                inputValue={batchSearch}
                onInputChange={(_, newInputValue) => {
                    setBatchSearch(newInputValue);
                }}
                getOptionLabel={(option) => option.id}
                fullWidth
                autoComplete
                noOptionsText="Aucun résultat"
                renderInput={(params) => (
                    <TextField
                        variant="outlined"
                        {...params}
                        label="Numéro d’intervention de vaccination précédente"
                        required
                        error={!!error}
                        helperText={error}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Tooltip title="Disponible sur le certificat de la vaccination précédente">
                                            <Help />
                                        </Tooltip>
                                    </InputAdornment>
                                ),

                                endAdornment: (
                                    <InputAdornment position="end" style={{ position: "absolute", right: 0 }}>
                                        <Tooltip title="Rechercher un numéro d'intervention">
                                            <IconButton onClick={() => setOpenInterventionDialog(true)}>
                                                <Search />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                )}
            />
            {openInterventionDialog && (
                <InterventionDialog
                    onClose={() => setOpenInterventionDialog(false)}
                    initFilters={{
                        id: "",
                        vaccinationDate: [null, vaccinationDate],
                        injectionType: getInjectionType(),
                        speciesUuid: [form.animalInformation.speciesUuid],
                        subSpeciesUuid: [form.animalInformation.subSpeciesUuid],
                        farmId: "",
                        workshopId: "",
                        vaccinationInterventionType: [],
                    }}
                    onValid={(intervention: ISearchVaccinationIntervention) => {
                        handleChange(intervention);
                        setOpenInterventionDialog(false);
                    }}
                />
            )}
        </>
    );
}
