// === Import: NPM
import React from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === Import: LOCAL
import { GenericText } from "@/components/generics/text/GenericText";
import { IOrdinalSituation } from "@/components/Profile/interface";

interface OrdinalSituationProps {
    ordinalSituation: IOrdinalSituation;
}
export default function OrdinalSituation({ ordinalSituation }: Readonly<OrdinalSituationProps>) {
    const { dpaAddress } = ordinalSituation;
    return (
        <Stack
            spacing={3}
            sx={{
                height: "100%",
            }}
        >
            <Typography variant="h5">Situation ordinale</Typography>
            <Card sx={{ height: "100%", display: "flex", flexGrow: 1, flexDirection: "column" }}>
                <CardContent>
                    <Grid container>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 6,
                            }}
                        >
                            <Stack>
                                <GenericText bold value="Situation" margin />
                                <GenericText value={ordinalSituation.ordinalSituation} />
                            </Stack>
                        </Grid>
                        <Grid
                            size={{
                                xs: 12,
                                sm: 6,
                            }}
                        >
                            <Stack>
                                <GenericText bold value="Région ordinale" margin />
                                <GenericText value={ordinalSituation.subscriptionRegion} />
                                <GenericText bold value="Coordonnées du DPA" margin />
                                <GenericText value={ordinalSituation.dpaAddressName} />
                                <GenericText value={dpaAddress.way} />
                                <GenericText value={dpaAddress.additionalAddress1} />
                                <GenericText value={dpaAddress.additionalAddress2} />
                                <GenericText
                                    value={`${dpaAddress.zipCode} ${dpaAddress.city} ${dpaAddress.country ?? ""}`}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Stack>
    );
}
