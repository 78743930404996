// === NPM
// === LOCAL
import { KyResponse } from "ky";
import {
    IAnomalyType,
    IHealthReporting,
    IHealthReportingCounts,
    IHealthReportingFilters,
    IHealthReportingForm,
    IHealthReportingFull,
    IHealthReportingStatusForm,
} from "@/components/HealthReporting/AnimalAbuse/interface";
import { HttpMethod, Loader, SearchParams } from "@/interfaces/global";
import { CALYPSO_HEADERS, customClientHeader } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class HealthReportingService {
    async getAnomalyTypes(): Promise<KyResponse<IAnomalyType[]>> {
        return HttpService.fetch(endpoints.healthReportingService.referential.anomalyTypes(), {
            method: HttpMethod.GET,
        });
    }

    async getDraft(noToaster = false): Promise<KyResponse<IHealthReportingForm>> {
        const clientHeader = { ...customClientHeader };
        clientHeader.noToasterOnError = noToaster;
        return HttpService.fetch(endpoints.healthReportingService.draft(), {
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify(clientHeader),
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteDraft(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.healthReportingService.draft(), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putDraft(data: IHealthReportingForm, attachments?: File[]): Promise<KyResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("healthReportingDraft", blob);
        return HttpService.fetch(endpoints.healthReportingService.draft(), {
            body: formData,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async postHealthReporting(data: IHealthReportingForm, attachments?: File[]): Promise<KyResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("healthReporting", blob);
        return HttpService.fetch(endpoints.healthReportingService.reportings(), {
            method: HttpMethod.POST,
            headers: {
                loader: Loader.REQUEST,
            },
            body: formData,
        });
    }

    async getHealthReportingFile(uuid: string, fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.healthReportingService.file(uuid, fileUuid), {
            method: HttpMethod.GET,
        });
    }

    async getDraftFile(fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.healthReportingService.draftFile(fileUuid), {
            method: HttpMethod.GET,
        });
    }

    async getHealthReportings(
        payload: SearchParams & Partial<IHealthReportingFilters>
    ): Promise<KyResponse<IHealthReporting[]>> {
        return HttpService.fetch(endpoints.healthReportingService.reportings(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getHealthReporting(id: string): Promise<KyResponse<IHealthReportingFull>> {
        return HttpService.fetch(endpoints.healthReportingService.reporting(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async patchHealthReportingStatus(
        id: string,
        form: IHealthReportingStatusForm
    ): Promise<KyResponse<IHealthReportingForm>> {
        return HttpService.fetch(endpoints.healthReportingService.reportStatus(id), {
            method: HttpMethod.PATCH,
            json: form,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getHealthReportingZip(id: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.healthReportingService.download(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getHealthReportingsCounts(): Promise<KyResponse<IHealthReportingCounts>> {
        return HttpService.fetch(endpoints.healthReportingService.counts(), {
            method: HttpMethod.GET,
        });
    }
}

export default new HealthReportingService();
