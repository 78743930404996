// === NPM
import React from "react";
import { useDrag } from "react-dnd";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Add } from "@/assets/icons/actions/plus.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { VaccinationSiteUserType } from "@/interfaces/vaccination";
import { toLocaleDateFormat } from "@/resources/utils";
import { ItemTypes, IValidatedVaccinationSite } from "../../interface";

interface VaccinationSiteTileProps {
    vaccinationSite: IValidatedVaccinationSite;
    onDelete?: (vaccinationSite: IValidatedVaccinationSite) => void;
    onAdd?: (vaccinationSite: IValidatedVaccinationSite) => void;
}

export default function VaccinationSiteTile({ vaccinationSite, onDelete, onAdd }: Readonly<VaccinationSiteTileProps>) {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.VACCINATION_SITE_TILE,
            item: vaccinationSite,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [vaccinationSite]
    );

    return (
        (<Accordion
            ref={drag}
            key={vaccinationSite.id}
            sx={{
                "width": "100%",
                "height": "fit-content",
                "cursor": "move",
                "opacity": isDragging ? 0.5 : 1,
                "&:before": {
                    display: "none",
                },
                "alignItems": "center",
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                {onDelete && <DeleteAction title="Supprimer le chantier" onClick={() => onDelete(vaccinationSite)} />}
                {onAdd && (
                    <IconActionButton
                        title="Ajouter le chantier"
                        onClick={() => onAdd(vaccinationSite)}
                        icon={<Add />}
                    />
                )}
                <Typography
                    variant="bold"
                    sx={{
                        alignSelf: "center",
                        color: "primary"
                    }}>
                    {`Chantier n°${vaccinationSite.id} - ${toLocaleDateFormat(vaccinationSite.date)}`}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={1}>
                    <GenericTitleValueText
                        title="Vétérinaire responsable"
                        value={`${vaccinationSite.veterinaryLastname} ${vaccinationSite.veterinaryFirstname}`}
                    />
                    <GenericTitleValueText
                        title="Type de vaccinateur"
                        value={`${VaccinationSiteUserType[vaccinationSite.userType]}`}
                    />
                    <GenericTitleValueText
                        title="Nombre de canards vaccinés"
                        value={`${vaccinationSite.animalCount}`}
                    />
                    <GenericTitleValueText title="Audit" value={vaccinationSite.hasAudit ? "Oui" : "Non"} />
                    <GenericTitleValueText
                        title="Non-conformités majeures"
                        value={vaccinationSite.hasNonConformity ? "Oui" : "Non"}
                    />
                </Stack>
            </AccordionDetails>
        </Accordion>)
    );
}
