// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { ISpeciesDetails } from "@/interfaces/referential";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import ReferentialService from "@/services/ReferentialService";
import FamDamDeliveryTour from "./containers/FamDamDeliveryTour";

export default function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);

    useEffect(() => {
        if (location.pathname === routerLinks.delivery.dashboard.base())
            navigate(routerLinks.delivery.dashboard.trackingLogs());
    }, []);

    useEffect(() => {
        getSpecies();
    }, []);

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["drug_transfer"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(await res.json());
        }
    };

    return (
        <>
            <FamDamDeliveryTour />
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.delivery.dashboard.trackingLogs(),
                        label: "Suivi des flux de collecte",
                    },
                    {
                        url: routerLinks.delivery.dashboard.deliveries(),
                        label: "Suivi des cessions",
                        id: "drug-delivery-tab",
                    },
                    {
                        url: routerLinks.delivery.dashboard.prescriptions(),
                        label: "Suivi des ordonnances",
                        permissions: [
                            UserSituation.ADMIN_CALYPSO,
                            UserSituation.ANMV_MED,
                            UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                            UserSituation.REGISTERED_IN_PRACTICE,
                            UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                        ],
                    },
                ]}
            />
            <PageContent>
                <Outlet context={{ species }} />
            </PageContent>
        </>
    );
}
