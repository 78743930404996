export const CALYPSO_TOKEN = "CALYPSO_TOKEN";
export const CALYPSO_HEADERS = {
    TABLE_COUNT: "x-calypso-count",
    CLIENT_HEADER: "x-calypso-client-header",
    TOKEN: "x-calypso-token",
    USER_TYPE: "x-calypso-user-type",
    DATA_TYPE: "x-calypso-data-type",
    CONTEXT_TYPE: "x-calypso-context-type",
};

export const drawerWidth = 320;
export const headerHeight = 64;

export const isoFormat = "yyyy-MM-dd";
export const displayFormat = "dd/MM/yyyy";

export const customClientHeader = {
    noToasterOnError: false,
};

export const administrationUserSteps = ["Informations de l'utilisateur", "Droits de l'utilisateur"];

export const DocumentMimeTypeFile = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "image/jpg",
    "image/jpeg",
];

export const ManualDrugImportMimeTypeFile = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

export const defaultPagination = { page: 0, pageSize: 25 };

export const defaultRowsPerPage = [25, 50, 100, 250];

export const defaultTextLimit = 2000;

export const maxIntegerValue = 2147483647;

export const defaultIntegerLimit = 10;
