// === NPM
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    Box,
    Card,
    CardContent,
    ClickAwayListener,
    Fade,
    FormControl,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { z } from "zod";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { IAccountWithKeys } from "../../interface";

interface ModifyAccountInformationDialogProps {
    account: IAccountWithKeys;
    title: string;
    onClose: any;
    onValid: any;
    onTokenRenew: any;
}

const formSchema = z.object({
    email: stringRequired().email({ message: FORM_TEXT.email }),
    endDate: z.string().optional(),
    uuid: z.string().optional(),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function ModifyAccountInformationDialog({
    account,
    title,
    onClose,
    onValid,
    onTokenRenew,
}: Readonly<ModifyAccountInformationDialogProps>) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [disabledRenew, setDisabledRenew] = useState<boolean>(false);
    const [loadingToken, setLoadingToken] = useState<boolean>(false);

    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        setValue,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: { ...account, endDate: account.endDate ?? "" },
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    };

    const renewToken = async () => {
        setLoadingToken(true);
        await onTokenRenew(account);
        setLoadingToken(false);
    };

    const renderContent = () => {
        return (
            (<ClickAwayListener onClickAway={() => setOpen(false)}>
                <Card sx={{ display: "flex", flexGrow: 1, flexDirection: "column", pt: 2 }}>
                    <CardContent>
                        <Stack spacing={2}>
                            <TextField
                                {...register("email")}
                                onChange={(event) => {
                                    setValue("email", event.target.value);
                                    setDisabledRenew(true);
                                }}
                                label="Email"
                                required
                                error={!!errors?.email}
                                helperText={errors?.email?.message}
                            />
                            <FormControl fullWidth required error={!!errors.endDate}>
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <GenericDatePicker
                                            value={value}
                                            onChange={onChange}
                                            name="endDate"
                                            error={!!error}
                                            helperText={error?.message}
                                            label="Date de fin"
                                        />
                                    )}
                                />
                            </FormControl>
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                <GenericButton
                                    label="Renouveler le token"
                                    color="error"
                                    onClick={handleClick}
                                    fullWidth={false}
                                    disabled={disabledRenew}
                                />
                            </Box>
                            <Popper
                                open={open}
                                anchorEl={anchorEl}
                                placement="bottom"
                                transition
                                sx={{ zIndex: 10000 }}
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper sx={{ p: 1 }} variant="outlined">
                                            {account.apiKeysNumber > 0 ? (
                                                <Typography sx={{ p: 2 }}>
                                                    Renouveler le token entrainera la révocation de{" "}
                                                    {account.apiKeysNumber}
                                                    clef(s) d'API.
                                                </Typography>
                                            ) : (
                                                <Typography sx={{ p: 2 }}>
                                                    Êtes-vous sûr de vouloir renouveler le token ?
                                                </Typography>
                                            )}
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}>
                                                <GenericButton
                                                    label="Confirmer le renouvellement"
                                                    color="error"
                                                    onClick={renewToken}
                                                    fullWidth={false}
                                                    loading={loadingToken}
                                                />
                                            </Box>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </Stack>
                    </CardContent>
                </Card>
            </ClickAwayListener>)
        );
    };
    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
        />
    );
}
