// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
// === LOCAL
import { DateTime } from "luxon";
import ArchiveAction from "@/components/generics/actions/ArchiveAction";
import EditAction from "@/components/generics/actions/EditAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IAgency, ILocalAgency } from "@/interfaces/user";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { hasAccess } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import UserService from "@/services/UserService";
import { ICreateLocalAgency } from "../../../interface";
import { LocalAgencyFilters } from "../FamDam";
import LocalAgencyDialog from "./LocalAgencyDialog";

interface LocalAgencyFamDamProps {
    activeAgencies: IAgency[];
    inputFilters: LocalAgencyFilters;
    setInputFilters: Dispatch<SetStateAction<LocalAgencyFilters>>;
}

export default function LocalAgencyFamDam({
    activeAgencies,
    inputFilters,
    setInputFilters,
}: Readonly<LocalAgencyFamDamProps>) {
    const auth = useAuth();
    const [localAgencies, setLocalAgencies] = useState<ILocalAgency[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "authorizationNumber", sort: "asc" }]);
    const [selectedLocalAgencyUuid, setSelectedLocalAgencyUuid] = useState<string>(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);

    const selectedLocalAgency = localAgencies.find((la) => la.uuid === selectedLocalAgencyUuid);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getLocalAgencies();
    }, [pagination, sortModel]);

    const getLocalAgencies = async () => {
        const res = await UserService.getLocalAgencies({
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(),
        });
        if (res.status === HttpStatus.OK) {
            setLocalAgencies(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const createPayload = () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
        };

        Object.entries(inputFilters).forEach(([name, value]) => {
            if (Array.isArray(value) && value.every((v) => v === null)) return;
            if (name === "city") {
                payload["postalCode"] = value.map((v) => v.postalCode);
                payload["city"] = value.map((v) => v.name);
                return;
            }
            if (value && value.length > 0) {
                payload[name] = value;
            }
        });
        return payload;
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const handleArchiveLocalAgency = async (confirm: boolean) => {
        if (!confirm) {
            setOpenConfirmDialog(false);
            setSelectedLocalAgencyUuid(null);
            return;
        }
        const res = await UserService.archiveLocalAgency(selectedLocalAgencyUuid);
        if (res.status === HttpStatus.OK) {
            setOpenConfirmDialog(false);
            toast.success("Etablissement archivé avec succès");
            setSelectedLocalAgencyUuid(null);
            getLocalAgencies();
        }
    };

    const handleUpdateLocalAgency = async (localAgency: ICreateLocalAgency) => {
        const res = await UserService.updateLocalAgency(selectedLocalAgencyUuid, localAgency);
        if (res.status === HttpStatus.OK) {
            toast.success("Etablissement modifié avec succès");
            getLocalAgencies();
            setOpenUpdateDialog(false);
        }
    };

    const handleCreateLocalAgency = async (localAgency: ICreateLocalAgency) => {
        const res = await UserService.postLocalAgency(localAgency);
        if (res.status === HttpStatus.CREATED) {
            toast.success("Etablissement créé avec succès");
            getLocalAgencies();
            setOpenCreateDialog(false);
        }
    };

    const columns = [
        {
            field: "authorizationNumber",
            headerName: "Identifiant externe",
            flex: 0.8,
        },
        {
            field: "name",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "agencyName",
            headerName: "Groupe FAM/DAM parent",
            flex: 1,
        },
        {
            field: "archived",
            headerName: "Statut ",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => (params.row.archived ? "Archivé" : "Désarchivé"),
        },
        {
            field: "openingDate",
            headerName: "Date d'ouverture",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
        },
        {
            field: "closingDate",
            headerName: "Date de fermeture",
            flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
        },
        {
            field: "city",
            headerName: "Ville",
            flex: 1,
        },
        {
            field: "postalCode",
            headerName: "Code postal",
            flex: 0.7,
        },
        {
            ...ActionsColumnProps,
            width: 100,
            hide: !hasAccess(auth.userInfo.situation, [UserSituation.ADMIN_CALYPSO]),
            renderCell: (params) => (
                <Box>
                    {(!params.row.closingDate || DateTime.fromISO(params.row.closingDate) > DateTime.now()) && (
                        <>
                            <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_CALYPSO]}>
                                <EditAction
                                    title="Modifier"
                                    onClick={() => {
                                        setOpenUpdateDialog(true);
                                        setSelectedLocalAgencyUuid(params.row.uuid);
                                    }}
                                />
                            </PermissionsCheck>
                            <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_CALYPSO]}>
                                <ArchiveAction
                                    title="Archiver"
                                    onClick={() => {
                                        setOpenConfirmDialog(true);
                                        setSelectedLocalAgencyUuid(params.row.uuid);
                                    }}
                                />
                            </PermissionsCheck>
                        </>
                    )}
                </Box>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<LocalAgencyFilters> = {
        authorizationNumber: { label: "Identifiant externe", type: FilterType.INPUT },
        name: { label: "Nom", type: FilterType.INPUT },
        agencyName: { label: "Groupe FAM/DAM parent", type: FilterType.INPUT },
        archived: {
            label: "Statut",
            type: FilterType.SELECT,
            values: [
                { label: "Archivé", key: "true" },
                { label: "Désarchivé", key: "false" },
            ],
        },
        openingDate: { label: "Date d'ouverture", type: FilterType.DATEPICKER },
        closingDate: { label: "Date de fermeture", type: FilterType.DATEPICKER },
        city: { label: "Ville", type: FilterType.CITY_SEARCH },
    };

    return (<>
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <Typography variant="h4">Gestion des établissements</Typography>
            <Box
                sx={{
                    justifyContent: "flex-end",
                    display: "flex"
                }}>
                <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_CALYPSO]}>
                    <GenericButton
                        label="Créer un établissement"
                        startIcon={<Add />}
                        onClick={() => {
                            setOpenCreateDialog(true);
                        }}
                    />
                </PermissionsCheck>
            </Box>
            <Card>
                <CardContent
                    sx={{
                        "& .archived": {
                            backgroundColor: colors.lightGray,
                        },
                    }}
                >
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            authorizationNumber: "",
                            name: "",
                            openingDate: [null, null],
                            closingDate: [null, null],
                            city: [],
                            agencyName: "",
                            archived: [],
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={localAgencies}
                        columns={columns}
                        page={pagination.page}
                        rowCount={rowCount}
                        pageSize={pagination.pageSize}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        sortingMode="server"
                        paginationMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                        filterMode="server"
                        autoHeight
                        getRowClassName={(params) => (params.row.archived ? "archived" : "")}
                        getRowId={(row) => row.uuid}
                    />
                </CardContent>
            </Card>
        </Stack>
        {openConfirmDialog && (
            <GenericConfirmDialog
                title="Archivage d'un établissement"
                message={`Êtes-vous sûr de vouloir archiver l'établissement ${selectedLocalAgency?.name} ?`}
                onClose={handleArchiveLocalAgency}
            />
        )}
        {openUpdateDialog && (
            <LocalAgencyDialog
                title={selectedLocalAgency?.name}
                currentLocalAgency={selectedLocalAgency}
                onClose={() => setOpenUpdateDialog(false)}
                onValid={handleUpdateLocalAgency}
                activeAgencies={activeAgencies}
            />
        )}
        {openCreateDialog && (
            <LocalAgencyDialog
                title="Créer un établissement"
                onClose={() => setOpenCreateDialog(false)}
                onValid={handleCreateLocalAgency}
                activeAgencies={activeAgencies}
            />
        )}
    </>);
}
