// === NPM
import React, { RefObject, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Event } from "@mui/icons-material";
import {
    Card,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { z } from "zod";
// === LOCAL
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { ComplianceType, IVaccinationSite } from "@/interfaces/vaccination";
import { defaultTextLimit } from "@/resources/AppConstant";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { convertEnumToKeyLabelObject, getEnumKeyByValue, toLocaleDateFormat, typedObjectKeys } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import { IVaccinationInterventionGeneralInformation, IVaccinationSiteWorkshop } from "../../interface";
import { useFormIntervention } from "../../useFormIntervention";

interface GeneralInformationProps {
    vaccinationSite: IVaccinationSite;
    formRef: RefObject<HTMLButtonElement>;
    onValid: () => void;
}

const [firstCompliance, ...others] = typedObjectKeys(ComplianceType);

const formSchema = z.object({
    workshopId: stringRequired(),
});

const reportSchema = z
    .object({
        complianceType: z.enum([firstCompliance, ...others], {
            required_error: FORM_TEXT.required,
            invalid_type_error: FORM_TEXT.required,
        }),
        complianceComment: z
            .string()
            .max(2000, "Le motif de non-conformité ne peut pas excéder 2000 caractères")
            .optional()
            .nullable()
            .refine((value) => !(value && value.trim().length === 0), FORM_TEXT.emptyString),
    })
    .superRefine((object, ctx) => {
        if (
            object.complianceType &&
            object.complianceType !== getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) &&
            !object.complianceComment
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: FORM_TEXT.required,
                path: ["complianceComment"],
            });
        }
    });

const schema = z.intersection(formSchema, reportSchema);

type FormSchema = z.infer<typeof schema>;

export default function GeneralInformation({ vaccinationSite, formRef, onValid }: GeneralInformationProps) {
    const { setForm, form } = useFormIntervention();

    const navigate = useNavigate();

    const {
        formState: { errors },
        handleSubmit,
        watch,
        register,
        control,
        resetField,
    } = useForm<FormSchema>({
        resolver: zodResolver(schema),
        defaultValues: form?.generalInformation ?? {
            workshopId: null,
            complianceType: null,
            complianceComment: null,
        },
    });

    const [workshops, setWorkshops] = useState<IVaccinationSiteWorkshop[]>([]);

    const complianceType = watch("complianceType");
    const complianceComment = watch("complianceComment");

    const disabled = !!form.id;

    useEffect(() => {
        getWorkshops();
    }, []);

    const getWorkshops = async () => {
        const res = await VaccinationService.getVaccinationSiteWorkshops(vaccinationSite.farmId, vaccinationSite.date);
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            setWorkshops(data.filter((workshop) => workshop.vaccinationSiteId === vaccinationSite.id));
        } else {
            navigate(routerLinks.iahp.vaccinationSite.view());
        }
    };
    const renderInterventionInformations = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <GenericTextField label="Chantier" value={vaccinationSite.id} disabled />
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <GenericTextField
                            label="N° ordre - Nom du DPE"
                            value={`${vaccinationSite.dpeId} - ${vaccinationSite.dpeName} - ${vaccinationSite.dpePostalCode} ${vaccinationSite.dpeCity}`}
                            disabled
                        />
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <GenericTextField
                            label="N° ordre - Nom du vétérinaire"
                            value={`${vaccinationSite.veterinaryId} - ${vaccinationSite.veterinaryLastname} ${vaccinationSite.veterinaryFirstname}`}
                            disabled
                        />
                    </Grid>

                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <TextField
                            label="Date d'intervention"
                            value={toLocaleDateFormat(vaccinationSite.date)}
                            disabled
                            fullWidth
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Event color="disabled" />
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderInterventionPlace = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Stack spacing={2} direction="row">
                    <Controller
                        name="workshopId"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <GenericSelect
                                value={value || ""}
                                label="Identifiant atelier"
                                onChange={onChange}
                                error={!!error}
                                required
                                helperText={error?.message}
                                options={workshops}
                                optionsValue="id"
                                optionsLabel="id"
                                disabled={disabled}
                            />
                        )}
                    />
                    <GenericTextField label="Identifiant établissement" value={vaccinationSite.farmId} disabled />

                    <GenericTextField label="Nom de l'établissement" value={vaccinationSite.farmName} disabled />
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderInterventionReport = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Grid container spacing={2}>
                    <Grid
                        sx={{ pl: 3 }}
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                alignItems: "center",
                            }}
                        >
                            <FormControl>
                                <Typography variant="h5">
                                    Résultat du compte-rendu{" "}
                                    <Typography component="span" variant="h5" color="error">
                                        *
                                    </Typography>
                                </Typography>

                                <Controller
                                    name="complianceType"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <>
                                            <RadioGroup
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(e);
                                                    if (
                                                        e.target.value ===
                                                        getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT)
                                                    ) {
                                                        resetField("complianceComment");
                                                    }
                                                }}
                                            >
                                                {convertEnumToKeyLabelObject(ComplianceType).map((item) => (
                                                    <FormControlLabel
                                                        key={item.key}
                                                        value={item.key}
                                                        control={<Radio />}
                                                        label={item.label}
                                                        labelPlacement="end"
                                                    />
                                                ))}
                                            </RadioGroup>
                                            {error && (
                                                <FormHelperText error={!!errors}>{error?.message}</FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </FormControl>
                        </Stack>
                    </Grid>

                    {complianceType &&
                        complianceType !== getEnumKeyByValue(ComplianceType, ComplianceType.COMPLIANT) && (
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 8,
                                }}
                            >
                                <TextField
                                    label="Motif de non conformité du compte-rendu"
                                    {...register("complianceComment")}
                                    rows={5}
                                    multiline
                                    placeholder="Commentaire relatif à la non conformité de l'intervention de vaccination"
                                    required
                                    fullWidth
                                    error={!!errors.complianceComment}
                                    helperText={
                                        errors.complianceComment?.message ??
                                        `${complianceComment?.length ?? 0}/${defaultTextLimit}`
                                    }
                                    slotProps={{
                                        htmlInput: {
                                            maxLength: { defaultTextLimit },
                                        },
                                    }}
                                />
                            </Grid>
                        )}
                </Grid>
            </StyledCardContent>
        </Card>
    );

    return (
        <form
            onSubmit={handleSubmit((data) => {
                setForm((prev) => ({
                    ...prev,
                    generalInformation: data as IVaccinationInterventionGeneralInformation,
                }));
                onValid();
            })}
            noValidate
        >
            <Stack spacing={2}>
                <Typography variant="h5">Informations d'interventions</Typography>
                {renderInterventionInformations()}
                <Typography variant="h5">Lieu d'intervention</Typography>
                {renderInterventionPlace()}
                <Typography variant="h5">Compte-rendu de l'intervention</Typography>
                {renderInterventionReport()}
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
