// === NPM
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, TextField, Typography } from "@mui/material";
import { z, ZodIssueCode } from "zod";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import FileUploader from "@/components/generics/FileUploader";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import TextEditor from "@/components/generics/TextEditor";
import { IHealthAccreditationTrainingCreate } from "@/components/HealthAccreditationTraining/interface";
import useIsFirstRender from "@/hooks/useIsFirstRender";
import { HttpStatus } from "@/interfaces/global";
import { IHealthAccreditationTraining, ITrainingType } from "@/interfaces/training";
import { DocumentMimeTypeFile } from "@/resources/AppConstant";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { validateFile } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";

interface CreateOrUpdateTraining {
    training?: IHealthAccreditationTraining;
    types: ITrainingType[];
    onClose: () => void;
    onValid: (data: IHealthAccreditationTrainingCreate, files?: File[]) => void;
}

const formSchema = z
    .object({
        title: stringRequired().max(100, { message: "Ce champ ne peut pas excéder 100 caractères." }),
        type: stringRequired(),
        targetPublic: stringRequired().max(200, { message: "Ce champ ne peut pas excéder 200 caractères." }),
        duration: z.coerce
            .number({ invalid_type_error: FORM_TEXT.required, required_error: FORM_TEXT.required })
            .positive({ message: "La valeur du champ doit être strictement positive." }),
        ectsPoints: z.coerce
            .number({ invalid_type_error: FORM_TEXT.required, required_error: FORM_TEXT.required })
            .nonnegative({ message: "La valeur du champ doit être supérieure ou égale à 0." }),
        inscriptionNumberMin: z.coerce
            .number({ invalid_type_error: FORM_TEXT.required, required_error: FORM_TEXT.required })
            .int({ message: "La valeur du champ doit être un entier." })
            .positive({ message: "La valeur du champ doit être strictement positive." }),
        inscriptionNumberMax: z.coerce
            .number({ invalid_type_error: FORM_TEXT.required, required_error: FORM_TEXT.required })
            .int({ message: "La valeur du champ doit être un entier." })
            .positive({ message: "La valeur du champ doit être strictement positive." }),
        content: stringRequired().max(2000, { message: "Ce champ ne peut pas excéder 2000 caractères." }),
    })
    .superRefine((values, context) => {
        if (values.inscriptionNumberMax <= values.inscriptionNumberMin) {
            context.addIssue({
                code: ZodIssueCode.custom,
                message: "Le nombre d'inscrit maximal doit être strictement supérieur au nombre minimal.",
                path: ["inscriptionNumberMax"],
            });
        }
    });

type ValidationSchema = z.infer<typeof formSchema>;

export default function CreateOrUpdateTraining({ training, types, onClose, onValid }: CreateOrUpdateTraining) {
    const isFirstRender = useIsFirstRender();

    const [files, setFiles] = useState<File[]>([]);

    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        watch,
        setValue,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: training?.title ?? null,
            type: training?.type?.uuid ?? "",
            targetPublic: training?.targetPublic ?? null,
            duration: training?.duration ?? null,
            ectsPoints: training?.ectsPoints ?? null,
            inscriptionNumberMin: training?.inscriptionNumberMin ?? null,
            inscriptionNumberMax: training?.inscriptionNumberMax ?? null,
            content: training?.content ?? null,
        },
    });

    const type = watch("type");
    const ectsPoints = watch("ectsPoints");
    const content = watch("content");

    useEffect(() => {
        if (training) {
            getTrainingFiles();
        }
    }, []);

    useEffect(() => {
        if (isFirstRender) return;

        if (types.find((t) => t.uuid === type)?.fixed) {
            setValue("ectsPoints", 0);
        } else {
            setValue("ectsPoints", null);
        }
    }, [type]);

    const getTrainingFile = async (fileUuid: string) => {
        let newFile = null;
        const res = await TrainingService.getHealthAccreditationTrainingFile(training.uuid, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            const fileName = res.headers.get("content-disposition").split("filename=")[1];
            newFile = new File([file], fileName, {
                type: res.headers.get("content-type"),
            });
        }
        return newFile;
    };

    const getTrainingFiles = async () => {
        if (!training?.attachedFiles) return;
        const fileList: File[] = [];
        for await (const attachment of training.attachedFiles) {
            await getTrainingFile(attachment.uuid).then((file) => {
                if (file) fileList.push(file);
            });
        }
        setFiles(fileList);
    };

    return (
        <GenericDialog
            title={
                training
                    ? "Modification d'une formation au maintien de l'habilitation sanitaire"
                    : "Ajout d'une formation au maintien de l'habilitation sanitaire"
            }
            onClose={onClose}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    onSubmit={handleSubmit((data) => onValid(data as IHealthAccreditationTrainingCreate, files))}
                />
            )}
            renderContent={() => (
                <Stack spacing={1}>
                    <GenericAccordion title="Informations générales" defaultExpanded>
                        <Stack
                            spacing={2}
                            sx={{
                                m: 1,
                            }}
                        >
                            <Stack spacing={2}>
                                <TextField
                                    {...register("title")}
                                    error={!!errors?.title}
                                    helperText={errors?.title?.message}
                                    label="Titre de la formation"
                                    required
                                />
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error?.message}
                                            label="Type"
                                            options={types}
                                            optionsValue="uuid"
                                            optionsLabel="label"
                                            required
                                        />
                                    )}
                                />
                                <TextField
                                    {...register("targetPublic")}
                                    error={!!errors?.targetPublic}
                                    helperText={errors?.targetPublic?.message}
                                    label="Public cible"
                                    required
                                />
                                <TextField
                                    {...register("duration")}
                                    error={!!errors?.duration}
                                    helperText={errors?.duration?.message}
                                    label="Durée (en heures)"
                                    type="number"
                                    required
                                />
                                <TextField
                                    {...register("ectsPoints")}
                                    error={!!errors?.ectsPoints}
                                    helperText={errors?.ectsPoints?.message}
                                    label="Nombre de crédits ECTS"
                                    type="number"
                                    required
                                    disabled={types.find((t) => t.uuid === type)?.fixed}
                                    slotProps={{
                                        inputLabel: { shrink: !!ectsPoints?.toString() },
                                    }}
                                />
                                <Stack direction="row" spacing={1}>
                                    <TextField
                                        {...register("inscriptionNumberMin")}
                                        error={!!errors?.inscriptionNumberMin}
                                        helperText={errors?.inscriptionNumberMin?.message}
                                        label="Nombre minimum d'inscrits"
                                        type="number"
                                        required
                                        fullWidth
                                    />
                                    <TextField
                                        {...register("inscriptionNumberMax")}
                                        error={!!errors?.inscriptionNumberMax}
                                        helperText={errors?.inscriptionNumberMax?.message}
                                        label="Nombre maximal d'inscrits"
                                        type="number"
                                        required
                                        fullWidth
                                    />
                                </Stack>
                            </Stack>
                            <Stack spacing={1}>
                                <Typography
                                    variant="bold"
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    Contenu et objectifs de la formation{" "}
                                    <Typography component="span" variant="h5" color="error">
                                        *
                                    </Typography>
                                </Typography>
                                <TextEditor
                                    value={content}
                                    handleChange={(content) => setValue("content", content)}
                                    error={!!errors?.content}
                                    helperText={errors?.content?.message}
                                    placeholder="Contenu..."
                                    allowImages={false}
                                    required
                                />
                            </Stack>
                        </Stack>
                    </GenericAccordion>
                    <GenericAccordion title="Fiche (facultative)">
                        <FileUploader
                            files={files}
                            setFiles={setFiles}
                            acceptedFiles={DocumentMimeTypeFile}
                            maxFiles={3}
                            validator={validateFile}
                        />
                    </GenericAccordion>
                </Stack>
            )}
        />
    );
}
