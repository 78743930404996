// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { ReactComponent as GenerateCertificate } from "@/assets/icons/actions/generateCertificate.svg";
// === LOCAL
import { ReactComponent as ImepMissing } from "@/assets/icons/vaccination/imepMissing.svg";
import { ReactComponent as WithCertificate } from "@/assets/icons/vaccination/withCertificate.svg";
import { ReactComponent as WithoutCertificate } from "@/assets/icons/vaccination/withoutCertificate.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import {
    GenericPresetFilters,
    PresetFilterConfigurations,
} from "@/components/generics/presetFilters/GenericPresetFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import InfoText from "@/components/generics/text/InfoText";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination, SearchParams } from "@/interfaces/global";
import { InjectionType, InterventionCorrectionType, VaccinationInterventionType } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { convertEnumToKeyLabelObject, createPayload, getEnumKeyByValue } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import CorrectionDialog from "../containers/CorrectionDialog";
import { VaccinationIahpContext } from "../interface";
import ViewDialog from "../VaccinationSite/containers/ViewDialog";
import {
    IVaccinationIntervention,
    IVaccinationInterventionCounts,
    IVaccinationInterventionDetails,
    IVaccinationInterventionFilters,
} from "../VaccinationSite/interface";
import { useFormIntervention } from "../VaccinationSite/useFormIntervention";

interface VaccinationInterventionTableProps {
    vaccinationSiteId?: number;
    title?: string;
    refreshData?: () => void;
    canEdit?: boolean;
    showPresetFilters?: boolean;
}

const initialValues: IVaccinationInterventionFilters = {
    id: "",
    farmId: "",
    workshopId: "",
    vaccinationSiteId: "",
    vaccinationDate: [null, null],
    vaccineGtinCode: [],
    vaccinationInterventionType: [],
    injectionType: [],
    isCertified: "",
    workshopCity: "",
    workshopDepartmentCode: [],
    certificationDate: [null, null],
    hasImepMissing: "",
};

export default function VaccinationInterventionTable({
    vaccinationSiteId,
    refreshData,
    canEdit = true,
    showPresetFilters = false,
    title,
}: Readonly<VaccinationInterventionTableProps>) {
    const { departments } = useDepartments();
    const navigate = useNavigate();
    const { setForm } = useFormIntervention();
    const { vaccines } = useOutletContext<VaccinationIahpContext>();
    const [interventions, setInterventions] = useState<IVaccinationIntervention[]>([]);
    const [inputFilters, setInputFilters] = useState<IVaccinationInterventionFilters>({
        id: "",
        farmId: "",
        workshopId: "",
        vaccinationSiteId: "",
        vaccinationDate: [null, null],
        vaccineGtinCode: [],
        vaccinationInterventionType: [],
        injectionType: [],
        isCertified: "",
        workshopCity: "",
        workshopDepartmentCode: [],
        certificationDate: [null, null],
        hasImepMissing: "",
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [selectedInterventionId, setSelectedInterventionId] = useState<string>("");
    const [selectedIntervention, setSelectedIntervention] = useState<IVaccinationInterventionDetails>(null);
    const [openRecapDialog, setOpenRecapDialog] = useState<boolean>(false);
    const [correctionType, setCorrectionType] = useState<InterventionCorrectionType>(null);
    const [interventionCount, setInterventionCount] = useState<IVaccinationInterventionCounts>({
        withoutCertificate: 0,
        imepMissing: 0,
        withCertificate: 0,
    });

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getVaccinationInterventionCounts();
    }, []);

    useEffect(() => {
        getInterventions();
    }, [pagination, sortModel]);

    useEffect(() => {
        setPagination((prev) => ({ ...prev, page: 0 }));
    }, [vaccinationSiteId]);

    useEffect(() => {
        if (refreshData) refreshData();
    }, [interventions]);

    const getInterventions = async () => {
        const payload: SearchParams & Partial<IVaccinationInterventionFilters> = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        if (vaccinationSiteId) {
            payload.vaccinationSiteId = vaccinationSiteId.toString();
        }
        const res = await VaccinationService.getInterventions("", payload);
        if (res.status === HttpStatus.OK) {
            setInterventions(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getVaccinationInterventionCounts = async () => {
        const res = await VaccinationService.getVaccinationInterventionCounts();
        if (res.status === HttpStatus.OK) {
            setInterventionCount(await res.json());
        }
    };

    const handleViewIntervention = async (id: string) => {
        const res = await VaccinationService.getIntervention(id);
        if (res.status === HttpStatus.OK) {
            setSelectedIntervention(await res.json());
            setOpenRecapDialog(true);
        }
    };

    const handleUpdateIntervention = async (id: string) => {
        const res = await VaccinationService.getIntervention(id);
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            if (data.certificateFileUuid) {
                setSelectedIntervention(data);
                setSelectedInterventionId(id);
                setCorrectionType(InterventionCorrectionType.UPDATE);
            } else {
                navigate(`${routerLinks.iahp.vaccinationSite.base()}/${vaccinationSiteId}/saisie-intervention/${id}`, {
                    state: { vaccinationSite: data.vaccinationSiteInformation },
                });
            }
        }
    };

    const handleDeleteAction = (intervention: IVaccinationIntervention) => {
        setSelectedInterventionId(intervention.id);
        if (intervention.certificateFileUuid) {
            setCorrectionType(InterventionCorrectionType.DELETE);
        } else {
            setOpenDeleteDialog(true);
        }
    };

    const handleGenerateCertificate = async (interventionId: string) => {
        setSelectedInterventionId(interventionId);
        const res = await VaccinationService.generateCertificateIntervention(interventionId);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Attestation générée avec succès");
            getInterventions();
            getVaccinationInterventionCounts();
        }
    };

    const getFile = async (interventionId: string, fileUuid: string) => {
        const res = await VaccinationService.getVaccinationInterventionFile(interventionId, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            saveAs(file, res.headers.get("content-disposition").split("filename=")[1].slice(1, -1));
        }
    };

    const deleteIntervention = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            return;
        }
        const res = await VaccinationService.deleteIntervention(selectedInterventionId);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenDeleteDialog(false);
            toast.success("Intervention supprimée avec succès");
            getInterventions();
        }
    };

    const handleCreateVaccinationIntervention = async () => {
        const res = await VaccinationService.getVaccinationSite(vaccinationSiteId);
        if (res.status === HttpStatus.OK) {
            navigate(`${routerLinks.iahp.vaccinationSite.base()}/${vaccinationSiteId}/saisie-intervention`, {
                state: { vaccinationSite: await res.json() },
            });
        }
    };

    const handleSaveCorrection = async (reason: string) => {
        if (correctionType === InterventionCorrectionType.UPDATE) {
            setForm({
                type: selectedIntervention.type,
                animalBatches: selectedIntervention.animalBatches,
                animalInformation: selectedIntervention.animalInformation,
                id: selectedIntervention.id,
                generalInformation: {
                    workshopId: selectedIntervention.generalInformation.workshopId,
                    complianceComment: selectedIntervention.generalInformation.complianceComment,
                    complianceType: selectedIntervention.generalInformation.complianceType,
                },
                certificateGeneration: !!selectedIntervention.certificateFileUuid,
            });
            setSelectedIntervention(null);
            navigate(
                `${routerLinks.iahp.vaccinationSite.base()}/${vaccinationSiteId}/saisie-intervention/${
                    selectedIntervention.id
                }`,
                {
                    state: { vaccinationSite: selectedIntervention.vaccinationSiteInformation, reason },
                }
            );
        }
        if (correctionType === InterventionCorrectionType.DELETE) {
            deleteCorrectionIntervention(reason);
        }
    };

    const deleteCorrectionIntervention = async (reason: string) => {
        const res = await VaccinationService.deleteInterventionCorrection(selectedInterventionId, reason);
        if (res.status === HttpStatus.NO_CONTENT) {
            setCorrectionType(null);
            setSelectedInterventionId(null);
            getInterventions();
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Numéro intervention Calypso",
            width: 150,
        },
        ...(!vaccinationSiteId
            ? [
                  {
                      field: "vaccinationSiteId",
                      headerName: "Identifiant chantier",
                      width: 150,
                  },
                  {
                      field: "vaccinationDate",
                      headerName: "Date chantier",
                      width: 150,
                      valueFormatter: (params: GridValueFormatterParams<string>) =>
                          params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
                  },
              ]
            : []),
        {
            field: "workshopId",
            headerName: "Identifiant de l'atelier",
            width: 150,
        },
        {
            field: "workshopCity",
            headerName: "Commune atelier",
            width: 150,
        },
        {
            field: "workshopDepartmentCode",
            headerName: "Département atelier",
            width: 150,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.workshopDepartmentCode
                    ? `${params.row.workshopDepartmentCode} - ${params.row.workshopDepartmentName}`
                    : "",
        },
        {
            field: "farmId",
            headerName: "Identifiant établissement",
            width: 150,
        },
        {
            field: "vaccineGtinCode",
            headerName: "Vaccin",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams) => {
                const vaccine = vaccines?.find((v) => v.gtinCode === params.value);
                return vaccine ? `${vaccine.name} ${vaccine.packaging}` : "";
            },
            sortable: false,
        },
        {
            field: "injectionType",
            headerName: "N°injection",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) => InjectionType[params.value],
            sortable: false,
        },
        {
            field: "vaccinationInterventionType",
            headerName: "Type d'intervention",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams<string>) => VaccinationInterventionType[params.value],
            sortable: false,
        },
        {
            field: "animalCount",
            headerName: "Animaux vaccinés",
            width: 100,
        },
        {
            field: "doseCount",
            headerName: "Doses administrées",
            width: 100,
        },
        {
            field: "certificateFileUuid",
            headerName: "Attestation",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? "Oui" : "Non"),
        },
        {
            field: "certificationDate",
            headerName: "Date édition certificat",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
        },
        {
            field: "hasImepMissing",
            headerName: "IMEP manquants",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams) => (params.value ? "Oui" : "Non"),
        },
        {
            ...ActionsColumnProps,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <ViewAction title="Voir le détail" onClick={() => handleViewIntervention(params.row.id)} />
                    {canEdit && (
                        <PermissionsCheck
                            requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                            preferences={[Preference.IAHP]}
                        >
                            <EditAction title="Modifier" onClick={() => handleUpdateIntervention(params.row.id)} />
                        </PermissionsCheck>
                    )}
                    {params.row.certificateFileUuid && canEdit && (
                        <DownloadAction
                            title="Télécharger le certificat"
                            onClick={() => getFile(params.row.id, params.row.certificateFileUuid)}
                        />
                    )}
                    {canEdit && (
                        <PermissionsCheck
                            requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                            preferences={[Preference.IAHP]}
                        >
                            <DeleteAction title="Supprimer" onClick={() => handleDeleteAction(params.row)} />
                        </PermissionsCheck>
                    )}
                    {!params.row.certificateFileUuid && (canEdit || showPresetFilters) && (
                        <PermissionsCheck
                            requiredPermissions={[UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE]}
                            preferences={[Preference.IAHP]}
                        >
                            <IconActionButton
                                icon={<GenerateCertificate />}
                                title="Générer l'attestation"
                                onClick={() => handleGenerateCertificate(params.row.id)}
                            />
                        </PermissionsCheck>
                    )}
                </Box>
            ),
            width: 250,
        },
    ];

    const filterConfigurations: FilterConfigurations<IVaccinationInterventionFilters> = {
        id: { label: "Numéro Calypso", type: FilterType.INPUT },
        ...(!vaccinationSiteId && {
            vaccinationSiteId: { label: "Identifiant chantier", type: FilterType.INPUT },
            vaccinationDate: { label: "Date de vaccination", type: FilterType.DATEPICKER },
        }),
        workshopId: { label: "Identifiant atelier", type: FilterType.INPUT },
        workshopCity: { label: "Commune atelier", type: FilterType.INPUT },
        workshopDepartmentCode: {
            label: "Département atelier",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
        farmId: { label: "Identifiant établissement", type: FilterType.INPUT },
        vaccineGtinCode: {
            label: "Vaccin",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: vaccines.map((v) => ({ key: v.gtinCode, label: `${v.name} ${v.packaging}` })),
        },
        injectionType: {
            label: "N° injection",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(InjectionType),
        },
        vaccinationInterventionType: {
            label: "Type intervention",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(VaccinationInterventionType),
        },
        isCertified: {
            label: "Attestation",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Générée", key: "true" },
                { label: "Non générée", key: "false" },
            ],
        },
        certificationDate: { label: "Date d'édition du certificat", type: FilterType.DATEPICKER },
        hasImepMissing: {
            label: "IMEP manquants",
            type: FilterType.SINGLE_SELECT,
            values: [
                { label: "Oui", key: "true" },
                { label: "Non", key: "false" },
            ],
        },
    };

    const presetFilterConfigurations: PresetFilterConfigurations<
        IVaccinationInterventionCounts,
        IVaccinationInterventionFilters
    > = {
        withoutCertificate: {
            icon: <WithoutCertificate />,
            title: "Interventions sans attestation",
            value: interventionCount.withoutCertificate,
            config: {
                ...initialValues,
                isCertified: "false",
            },
        },
        imepMissing: {
            icon: <ImepMissing />,
            title: "Interventions en élevage aux IMEP manquants",
            value: interventionCount.imepMissing,
            config: {
                ...initialValues,
                hasImepMissing: "true",
                vaccinationInterventionType: [
                    getEnumKeyByValue(VaccinationInterventionType, VaccinationInterventionType.BREEDING),
                ],
            },
        },
        withCertificate: {
            icon: <WithCertificate />,
            title: "Interventions avec attestation sans IMEP manquants",
            value: interventionCount.withCertificate,
            config: {
                ...initialValues,
                isCertified: "true",
                hasImepMissing: "false",
            },
        },
    };

    const renderWarning = () => (
        <Stack
            spacing={1}
            sx={{
                py: 3,
            }}
        >
            <InfoText py={0} message="En jaune, les interventions de vaccination avec un ou plusieurs IMEP manquants" />
            <InfoText py={0} message="En vert, les interventions de vaccination dont l'attestation a été générée." />
        </Stack>
    );

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography variant="h4">{title ? title : "Mes interventions de vaccination"}</Typography>
                    {canEdit && (
                        <PermissionsCheck
                            requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                            preferences={[Preference.IAHP]}
                        >
                            <GenericButton
                                onClick={handleCreateVaccinationIntervention}
                                label="Nouvelle intervention"
                                startIcon={<Add />}
                            />
                        </PermissionsCheck>
                    )}
                </Box>
                {showPresetFilters && (
                    <GenericPresetFilters
                        superFilterConfiguration={presetFilterConfigurations}
                        inputFilters={inputFilters}
                        setInputFilters={setInputFilters}
                        clearFilters={() => setInputFilters(initialValues)}
                    />
                )}
                <Card>
                    <CardContent
                        sx={{
                            "& .missing": {
                                backgroundColor: statusColor.warningBackground,
                            },
                            "& .has-certificate": {
                                backgroundColor: statusColor.successBackground,
                            },
                        }}
                    >
                        {!showPresetFilters && renderWarning()}
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                farmId: "",
                                workshopId: "",
                                vaccinationSiteId: "",
                                vaccinationDate: [null, null],
                                vaccineGtinCode: [],
                                vaccinationInterventionType: [],
                                injectionType: [],
                                isCertified: "",
                                workshopCity: "",
                                workshopDepartmentCode: [],
                                certificationDate: [null, null],
                                hasImepMissing: "",
                            }}
                            setInputFilters={setInputFilters}
                        />

                        <GenericTable
                            rows={interventions}
                            columns={columns}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            getRowClassName={(params) =>
                                params.row.hasImepMissing
                                    ? "missing"
                                    : params.row.certificateFileUuid
                                      ? "has-certificate"
                                      : ""
                            }
                            sortingOrder={["asc", "desc"]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {openDeleteDialog && (
                <GenericConfirmDialog
                    title={`Suppression de l'intervention n°${selectedInterventionId}`}
                    message={`Êtes-vous sûr de vouloir supprimer cette intervention de vaccination ? Cette action n’est pas réversible.`}
                    onClose={deleteIntervention}
                />
            )}
            {openRecapDialog && selectedIntervention && (
                <ViewDialog
                    intervention={selectedIntervention}
                    onClose={() => {
                        setOpenRecapDialog(false);
                        setSelectedIntervention(null);
                    }}
                />
            )}
            {correctionType && (
                <CorrectionDialog
                    interventionId={selectedInterventionId}
                    correctionType={correctionType}
                    interventionType={"vaccination"}
                    onClose={() => {
                        setCorrectionType(null);
                        setSelectedIntervention(null);
                    }}
                    onValid={handleSaveCorrection}
                />
            )}
        </>
    );
}
