// === Import: NPM
import { OidcProvider } from "@axa-fr/react-oidc";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
// === Import: LOCAL
import { DownloadProvider } from "@/components/Downloads/useDownload";
import { TourProvider } from "@/components/generics/useTour";
import Breadcrumbs from "@/components/Navigation/Breadcrumbs";
import { Drawer } from "@/components/Navigation/Drawer";
import { NavBar } from "@/components/Navigation/NavBar";
import { ProvideNotificationInfo } from "@/components/UserNotifications/containers/useNotificationInfo";
import { DepartmentsProvider } from "./context/useDepartmentContext";
import { GlobalProvider } from "./context/useGlobalContext";
import { CALYPSO_TOKEN, headerHeight } from "./resources/AppConstant";
import { SSOConfiguration } from "./resources/SsoConfig";
import AuthenticatedRouter from "./routers/AuthenticatedRouter";
import KyInterceptors from "./routers/KyInterceptors";
import { routerLinks } from "./routers/RouterConstant";
import UnauthenticatedRouter from "./routers/UnauthenticatedRouter";
import { useAuth } from "./routers/useAuth";
import LocalStorageService from "./services/LocalStorageService";

function AppRouter() {
    const location = useLocation();
    const [configurationName, setConfigurationName] = useState<string>("");
    const auth = useAuth();

    useEffect(() => {
        if (LocalStorageService.getLocaleStorageItem(CALYPSO_TOKEN)) {
            auth.introspection();
        } else {
            auth.logout();
        }
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get("code");
        if (!auth.logged && code) {
            if (location.pathname.includes(routerLinks.loginPsc)) auth.loginPsc(code);
            else auth.login(code);
        }
    }, [location]);

    const renderRouter = () => {
        if (auth.logged) {
            return (
                <GlobalProvider>
                    <KyInterceptors>
                        <DepartmentsProvider>
                            <ProvideNotificationInfo>
                                <DownloadProvider>
                                    <TourProvider>
                                        <Box sx={{ height: "100%", width: "100%" }}>
                                            <NavBar auth={auth} />
                                            <Toolbar />
                                            <Breadcrumbs />
                                            <Box sx={{ height: 50 }} />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    overflow: "auto",
                                                    height: `calc(100% - ${headerHeight}px - 48px)`,
                                                }}
                                            >
                                                <Drawer />
                                                <AuthenticatedRouter />
                                            </Box>
                                        </Box>
                                    </TourProvider>
                                </DownloadProvider>
                            </ProvideNotificationInfo>
                        </DepartmentsProvider>
                    </KyInterceptors>
                </GlobalProvider>
            );
        } else if (auth.logged === false) {
            return (
                <Box sx={{ width: "100%" }}>
                    <UnauthenticatedRouter
                        setConfigurationName={setConfigurationName}
                        configurationName={configurationName}
                    />
                </Box>
            );
        } else {
            return <div></div>;
        }
    };

    return configurationName ? (
        <OidcProvider configurationName={configurationName} configuration={SSOConfiguration[configurationName]}>
            {renderRouter()}
        </OidcProvider>
    ) : (
        renderRouter()
    );
}

export default AppRouter;
