// === NPM
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// === LOCAL
import { HttpStatus } from "@/interfaces/global";
import { IVaccinationSite } from "@/interfaces/vaccination";
import { routerLinks } from "@/routers/RouterConstant";
import VaccinationService from "@/services/VaccinationService";
import { initFormIntervention, useFormIntervention } from "../useFormIntervention";
import Form from "./containers/Form";
import FormSelection from "./containers/FormSelection";

export function FormIntervention() {
    const { form, setForm } = useFormIntervention();
    const { vaccinationSiteId, interventionId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [vaccinationSite, setVaccinationSite] = useState<IVaccinationSite>(location.state?.vaccinationSite ?? null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (vaccinationSiteId && !location.state?.vaccinationSite) {
            getVaccinationSite();
        }
        if (interventionId && !form.id) {
            getIntervention();
        }
        return () => {
            setForm(initFormIntervention);
        };
    }, []);

    const getVaccinationSite = async () => {
        setLoading(true);
        const res = await VaccinationService.getVaccinationSite(+vaccinationSiteId);
        if (res.status === HttpStatus.OK) {
            setVaccinationSite(await res.json());
        } else {
            navigate(routerLinks.home);
        }
        setLoading(false);
    };

    const getIntervention = async () => {
        setLoading(true);
        const res = await VaccinationService.getIntervention(interventionId);
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            setForm({
                ...data,
                generalInformation: {
                    workshopId: data.generalInformation.workshopId,
                    complianceComment: data.generalInformation.complianceComment,
                    complianceType: data.generalInformation.complianceType,
                },
                certificateGeneration: !!data.certificateFileUuid,
            });
        }
        setLoading(false);
    };

    return !form?.type ? <FormSelection /> : <Form vaccinationSite={vaccinationSite} loading={loading} />;
}
