// === NPM
import React, { useEffect, useState } from "react";
// === LOCAL
import { HttpStatus } from "@/interfaces/global";
import { IOrganization } from "@/interfaces/referential";
import ApiKeyService from "@/services/ApiKeyService";
import { IAccountWithKeys, Profile } from "../../../interface";
import SIPartnerLayout from "../containers/SIPartnerLayout";

export default function VaccinationInterventionApiKeys() {
    const [accounts, setAccounts] = useState<IAccountWithKeys[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<IOrganization[]>([]);

    useEffect(() => {
        getVaccinationInterventionOrganizationsReferential();
        getAccounts();
    }, []);

    const getVaccinationInterventionOrganizationsReferential = async () => {
        const res = await ApiKeyService.getVaccinationInterventionOrganizations();
        if (res.status === HttpStatus.OK) {
            setOrganizations(await res.json());
        }
    };

    const getAccounts = async () => {
        setLoading(true);
        const res = await ApiKeyService.getAccounts(Profile.VACCINATION_INTERVENTION);
        if (res.status === HttpStatus.OK) {
            setAccounts(await res.json());
        }
        setLoading(false);
    };

    return (
        <SIPartnerLayout
            organizations={organizations}
            accounts={accounts}
            getAccounts={getAccounts}
            loading={loading}
            createAccount={ApiKeyService.createVaccinationInterventionAccount}
            profile={Profile.VACCINATION_INTERVENTION}
        />
    );
}
