// === NPM
import { KyResponse } from "ky";
// === LOCAL
import { ReceiverUser } from "@/components/Administration/containers/Notifications/interface";
import { IProfileDetails, IProfileFeature, IRight } from "@/components/Administration/containers/Profiles/interface";
import {
    ICreateFamDamUser,
    ICreateMaUser,
    ICreateOvvtUser,
    IUserFilters,
} from "@/components/Administration/containers/User/interface";
import { IActivity } from "@/components/Home/interface";
import { IDpeConsent, IPutDpe, IUserPreferences, IVeterinaryConsent } from "@/components/Parameters/interface";
import { IOVDpe, IProfileInfo } from "@/components/Profile/interface";
import { ICreateLocalAgency, IFamDamUsersFilters } from "@/components/Referentials/containers/Delivery/interface";
import {
    IAgriMinistryGeoUnit,
    IMinistryAgentUser,
} from "@/components/Referentials/containers/Global/containers/AgriMinistryGeoUnit/interface";
import { UsersFilters } from "@/components/Referentials/containers/Global/containers/Ovvt/containers/Users";
import { IOvvtUser } from "@/components/Referentials/containers/Global/containers/Ovvt/interface";
import { IPharmacy } from "@/components/Referentials/containers/Global/containers/Pharmacy/interface";
import { HttpMethod, IReferentialSynchro, Loader, SearchParams } from "@/interfaces/global";
import { IOvvtOrganization, IOvvtOrganizationShort, IReferential, IRegion } from "@/interfaces/referential";
import {
    IAgency,
    IAgriMinistryGeoUnitShort,
    IBaseUser,
    IDpe,
    IDpeShort,
    ILocalAgency,
    IPharmacyShort,
    IType,
    IUserShort,
    IVeterinaryShort,
    UserWithoutProperties,
} from "@/interfaces/user";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class UserService {
    // --------------- USER ---------------

    async getUserInfo(userId: string): Promise<KyResponse<IProfileInfo>> {
        return HttpService.fetch(endpoints.userService.user.userInfo(userId), {
            method: HttpMethod.GET,
        });
    }

    async getUserTutorials(userId: string): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.userService.user.tutorials(userId), {
            method: HttpMethod.GET,
        });
    }

    async postUserTutorial(userId: string, tutorialKey: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.tutorial(userId, tutorialKey), {
            method: HttpMethod.POST,
        });
    }

    async getUserOVDPE(userId: string): Promise<KyResponse<IOVDpe[]>> {
        return HttpService.fetch(endpoints.userService.user.userOVDPE(userId), {
            method: HttpMethod.GET,
        });
    }

    async getUserDpes(userId: string): Promise<KyResponse<IDpeShort[]>> {
        return HttpService.fetch(endpoints.userService.user.userDPE(userId), {
            method: HttpMethod.GET,
        });
    }

    async getLoggedUserDpes(): Promise<KyResponse<IDpeConsent[]>> {
        return HttpService.fetch(endpoints.userService.user.loggedUserDPE(), {
            method: HttpMethod.GET,
            headers: { loader: Loader.TABLE },
        });
    }

    async getUsers(
        payload: SearchParams &
            Partial<{
                id: string;
                firstname: string;
                lastname: string;
                type: string[];
                situation: string[];
            }>
    ): Promise<KyResponse<IUserShort[]>> {
        return HttpService.fetch(endpoints.userService.user.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async updateUserConsent(
        userId: string,
        dpeOrdinalNumber: string,
        json: IVeterinaryConsent
    ): Promise<KyResponse<IVeterinaryConsent>> {
        return HttpService.fetch(endpoints.userService.user.userConsent(userId, dpeOrdinalNumber), {
            method: HttpMethod.PUT,
            json: json,
            headers: { loader: Loader.BUTTON },
        });
    }

    async getUserSynchro(): Promise<KyResponse<IReferentialSynchro>> {
        return HttpService.fetch(endpoints.userService.user.sync(), {
            method: HttpMethod.GET,
        });
    }

    async putUserSynchro(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.sync(), {
            method: HttpMethod.PUT,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getUserLocalAgencies(): Promise<KyResponse<ILocalAgency[]>> {
        return HttpService.fetch(endpoints.userService.user.localAgencies(), {
            method: HttpMethod.GET,
        });
    }

    async getUserActivities(): Promise<KyResponse<IActivity>> {
        return HttpService.fetch(endpoints.userService.user.activities(), {
            method: HttpMethod.GET,
        });
    }

    async getVeterinaries(params: SearchParams): Promise<KyResponse<IVeterinaryShort[]>> {
        return HttpService.fetch(endpoints.userService.veterinaries(), {
            method: HttpMethod.GET,
            searchParams: formatParams(params),
        });
    }

    async getUserPreferences(userId: string): Promise<KyResponse<IUserPreferences>> {
        return HttpService.fetch(endpoints.userService.user.preferences(userId), {
            method: HttpMethod.GET,
        });
    }

    async putUserPreferences(userId: string, json: IUserPreferences): Promise<KyResponse<IUserPreferences>> {
        return HttpService.fetch(endpoints.userService.user.preferences(userId), {
            method: HttpMethod.PUT,
            json: json,
            headers: { loader: Loader.BUTTON },
        });
    }
    // --------------- DPE ---------------

    async getDpes(payload: { page: number; size: number; sorts?: string[] }): Promise<KyResponse<IDpe[]>> {
        return HttpService.fetch(endpoints.userService.dpes.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getDpe(ordinalNumber: string): Promise<KyResponse<IDpeShort>> {
        return HttpService.fetch(endpoints.userService.dpes.dpe(ordinalNumber), {
            method: HttpMethod.GET,
        });
    }

    async putDpe(ordinalNumber: string, json: IPutDpe): Promise<KyResponse<IDpe>> {
        return HttpService.fetch(endpoints.userService.dpes.dpe(ordinalNumber), {
            method: HttpMethod.PUT,
            json: json,
            headers: { loader: Loader.BUTTON },
        });
    }

    async getDpeSynchro(): Promise<KyResponse<IReferentialSynchro>> {
        return HttpService.fetch(endpoints.userService.dpes.sync(), {
            method: HttpMethod.GET,
        });
    }

    async putDpeSynchro(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.dpes.sync(), {
            method: HttpMethod.PUT,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getDpeHistorySynchro(): Promise<KyResponse<IReferentialSynchro>> {
        return HttpService.fetch(endpoints.userService.dpes.historySync(), {
            method: HttpMethod.GET,
        });
    }

    async putDpeHistorySynchro(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.dpes.historySync(), {
            method: HttpMethod.PUT,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getDpeUsers(ordinalNumber: string): Promise<KyResponse<UserWithoutProperties[]>> {
        return HttpService.fetch(endpoints.userService.dpes.users(ordinalNumber), {
            method: HttpMethod.GET,
        });
    }

    // --------------- REFERENTIAL ---------------

    async getRights(): Promise<KyResponse<IRight[]>> {
        return HttpService.fetch(endpoints.userService.referentials.rightsReferential(), {
            method: HttpMethod.GET,
        });
    }

    async getUserTypesReferential(): Promise<KyResponse<IType[]>> {
        return HttpService.fetch(endpoints.userService.referentials.userTypesReferential(), {
            method: HttpMethod.GET,
        });
    }

    async getProfileFeatures(
        payload: SearchParams &
            Partial<{
                situations: string[];
                profile: string;
                feature: string;
            }>
    ): Promise<KyResponse<IProfileFeature[]>> {
        return HttpService.fetch(endpoints.userService.referentials.profileFeatures(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getProfile(profileKey: string): Promise<KyResponse<IProfileDetails>> {
        return HttpService.fetch(endpoints.userService.referentials.profile(profileKey), {
            method: HttpMethod.GET,
            headers: { loader: Loader.TABLE },
        });
    }

    async getProfiles(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.userService.referentials.profiles(), {
            method: HttpMethod.GET,
        });
    }

    async getSituations(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.userService.referentials.situations(), {
            method: HttpMethod.GET,
        });
    }

    async getGeoUnits(): Promise<KyResponse<IAgriMinistryGeoUnitShort[]>> {
        return HttpService.fetch(endpoints.userService.referentials.geoUnits(), {
            method: HttpMethod.GET,
        });
    }

    // ------ ADMIN CALYPSO USERS

    async createUserAdminCalypso(json: IBaseUser): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.adminCalypso.base(), {
            method: HttpMethod.POST,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateUserAdminCalypso(json: IUserShort, userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.adminCalypso.user(userId), {
            method: HttpMethod.PUT,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteUserAdminCalypso(userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.adminCalypso.user(userId), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ------ ANMV USERS

    async createUserAnmv(json: IBaseUser): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.anmv.base(), {
            method: HttpMethod.POST,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateUserAnmv(json: IUserShort, userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.anmv.user(userId), {
            method: HttpMethod.PUT,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteUserAnmv(userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.anmv.user(userId), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ------ FAM DAM USERS

    async getAgencyFamDamUsers(
        payload: SearchParams & Partial<IUserFilters>,
        agencyId: number
    ): Promise<KyResponse<IUserShort[]>> {
        return HttpService.fetch(endpoints.userService.user.famDam.agencyUsers(agencyId), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getFamDamUsers(payload: SearchParams & Partial<IFamDamUsersFilters>): Promise<KyResponse<IUserShort[]>> {
        return HttpService.fetch(endpoints.userService.user.famDam.base(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async createUserFamDam(json: ICreateFamDamUser): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.famDam.base(), {
            method: HttpMethod.POST,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateUserFamDam(json: IUserShort, userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.famDam.user(userId), {
            method: HttpMethod.PUT,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteFamDamUser(userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.famDam.user(userId), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ------ MA USERS

    async createUserMA(json: ICreateMaUser): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.ma.base(), {
            method: HttpMethod.POST,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteMaUser(userId: string, type: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.ma.typeUser(type, userId), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateUserMa(json: IUserShort, userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.ma.user(userId), {
            method: HttpMethod.PUT,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getMaUsers(payload: any): Promise<KyResponse<IUserShort[]>> {
        return HttpService.fetch(endpoints.userService.user.ma.base(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    // ------ AGENCY

    async getAgencies(
        payload: SearchParams &
            Partial<{
                id: string;
                name: string;
                active: string;
            }>
    ): Promise<KyResponse<IAgency[]>> {
        return HttpService.fetch(endpoints.userService.agency.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async postAgency(json: { name: string }): Promise<KyResponse<IAgency>> {
        return HttpService.fetch(endpoints.userService.agency.base, {
            method: HttpMethod.POST,
            json: json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateAgency(agencyId: number, json: { name: string }): Promise<KyResponse<IAgency>> {
        return HttpService.fetch(endpoints.userService.agency.agency(agencyId), {
            method: HttpMethod.PUT,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async archiveAgency(agencyId: number): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.agency.archive(agencyId), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getLocalAgenciesByAgency(
        agencyId: number,
        payload: SearchParams &
            Partial<{
                postalCodes: string[];
                cities: string[];
            }>
    ): Promise<KyResponse<ILocalAgency[]>> {
        return HttpService.fetch(endpoints.userService.agency.localAgenciesByAgency(agencyId), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    // ------ LOCAL AGENCY

    async getLocalAgencies(
        payload: SearchParams &
            Partial<{
                authorizationNumber: string;
                name: string;
                postalCode: string[];
                city: string[];
            }>
    ): Promise<KyResponse<ILocalAgency[]>> {
        return HttpService.fetch(endpoints.userService.localAgency.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async archiveLocalAgency(localAgencyUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.localAgency.archive(localAgencyUuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.REQUEST },
        });
    }

    async postLocalAgency(json: ICreateLocalAgency): Promise<KyResponse<ILocalAgency>> {
        return HttpService.fetch(endpoints.userService.localAgency.base, {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateLocalAgency(localAgencyUuid: string, json: ICreateLocalAgency): Promise<KyResponse<ILocalAgency>> {
        return HttpService.fetch(endpoints.userService.localAgency.localAgency(localAgencyUuid), {
            method: HttpMethod.PUT,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ------ SSO

    async getTokenSso(): Promise<KyResponse<string>> {
        return HttpService.fetch(endpoints.userService.user.tokenSso(), {
            method: HttpMethod.GET,
        });
    }

    // ------ PHARMACY
    async getPharmacies(
        payload: SearchParams &
            Partial<{
                id: string;
                name: string;
            }>
    ): Promise<KyResponse<IPharmacy[]>> {
        return HttpService.fetch(endpoints.userService.pharmacy.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async updatePharmacy(pharmacyId: string, json: { email: string }): Promise<KyResponse<IPharmacy>> {
        return HttpService.fetch(endpoints.userService.pharmacy.pharmacy(pharmacyId), {
            method: HttpMethod.PUT,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getUserPharmacy(userId: string): Promise<KyResponse<IPharmacyShort>> {
        return HttpService.fetch(endpoints.userService.user.pharmacy(userId), {
            method: HttpMethod.GET,
        });
    }

    // ------ AGRI MINISTRY

    async getAgriMinistryGeoUnits(
        payload: SearchParams &
            Partial<{
                externalId: string;
                name: string;
                localisation: string;
                type: string;
            }>
    ): Promise<KyResponse<IAgriMinistryGeoUnit[]>> {
        return HttpService.fetch(endpoints.userService.agriMinistry.geoUnits(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async putAgriMinistryGeoUnit(
        uuid: string,
        json: { name: string; emails: string[] }
    ): Promise<KyResponse<IAgriMinistryGeoUnit>> {
        return HttpService.fetch(endpoints.userService.agriMinistry.geoUnit(uuid), {
            method: HttpMethod.PUT,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getAdminAgentUser(
        payload: SearchParams &
            Partial<{
                firstname: string;
                lastname: string;
                situation: string;
                externalId: string;
                agriMinistryService: string;
                localization: string;
                inseeCode: string;
            }>
    ): Promise<KyResponse<IMinistryAgentUser[]>> {
        return HttpService.fetch(endpoints.userService.agriMinistry.users(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getAllNotificationUsers(
        payload: SearchParams &
            Partial<{
                situation: string[];
                firstname: string;
                lastname: string;
                ordinalNumber: string;
                dpeCity: string;
                dpePostalCode: string;
                dpeName: string;
                dpeOrdinalNumber: string;
                agencyCity: string;
                agencyPostalCode: string;
            }>
    ): Promise<KyResponse<ReceiverUser[]>> {
        return HttpService.fetch(endpoints.userService.user.notifications(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
        });
    }

    async putHomePreferences(preferences: string[], userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.homePreferences(userId), {
            method: HttpMethod.PUT,
            json: { preferences },
            headers: { loader: Loader.BUTTON },
        });
    }

    // ------ OVVT

    async getOvvtOrganizations(
        payload: SearchParams & Partial<Omit<IOvvtOrganization, "deletable" | "emails">>
    ): Promise<KyResponse<IOvvtOrganization[]>> {
        return HttpService.fetch(endpoints.userService.ovvt.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getOvvtUsers(
        organizationUuid: string,
        payload: SearchParams & Partial<UsersFilters>
    ): Promise<KyResponse<IOvvtUser[]>> {
        return HttpService.fetch(endpoints.userService.ovvt.users(organizationUuid), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async createOvvtOrganization(json: IOvvtOrganizationShort): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.ovvt.base, {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateOvvtOrganization(
        uuid: string,
        json: IOvvtOrganizationShort
    ): Promise<KyResponse<IOvvtOrganizationShort>> {
        return HttpService.fetch(endpoints.userService.ovvt.organization(uuid), {
            method: HttpMethod.PUT,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteOvvtOrganization(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.ovvt.organization(uuid), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getOvvtVacantRegions(): Promise<KyResponse<IRegion[]>> {
        return HttpService.fetch(endpoints.userService.ovvt.vacantRegions(), {
            method: HttpMethod.GET,
            headers: { loader: Loader.BUTTON },
        });
    }

    async createUserOvvt(json: ICreateOvvtUser): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.ovvt.base(), {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async updateUserOvvt(json: IUserShort, id: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.ovvt.user(id), {
            method: HttpMethod.PUT,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteOvvtUser(id: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.user.ovvt.user(id), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ------ COMPANIES

    async getCompaniesSynchro(): Promise<KyResponse<IReferentialSynchro>> {
        return HttpService.fetch(endpoints.userService.companies.sync(), {
            method: HttpMethod.GET,
        });
    }

    async putCompaniesSynchro(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.userService.companies.sync(), {
            method: HttpMethod.PUT,
            headers: { loader: Loader.REQUEST },
        });
    }
}

export default new UserService();
