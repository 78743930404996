// === Import: NPM
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
// === Import: LOCAL
import { useProvideGlobal } from "@/context/useGlobalContext";
import { HttpStatus, Loader } from "@/interfaces/global";
import HttpService from "@/services/HttpService";
import { CALYPSO_HEADERS } from "../resources/AppConstant";
import { routerLinks } from "./RouterConstant";
import { useAuth } from "./useAuth";

interface IError {
    message: string;
    path: string;
    status: number;
}

export default function KyInterceptors({ children }) {
    const auth = useAuth();
    const navigate = useNavigate();

    const { setLoadingButton, setLoadingRequest, setLoadingTable } = useProvideGlobal();

    const handleSetLoaders = (headers: Headers, value: boolean) => {
        switch (headers.get("loader")) {
            case Loader.BUTTON:
                setLoadingButton(value);
                return;
            case Loader.REQUEST:
                setLoadingRequest(value);
                return;
            case Loader.TABLE:
                setLoadingTable(value);
                return;
            default:
                break;
        }
    };

    useMemo(() => {
        HttpService.kyInstance = HttpService.kyInstance.extend({
            hooks: {
                beforeRequest: [
                    async (request) => {
                        handleSetLoaders(request.headers, true);
                    },
                ],
                afterResponse: [
                    async (request, _, response) => {
                        handleSetLoaders(request.headers, false);

                        const token = response.headers.get(CALYPSO_HEADERS.TOKEN);
                        if (token) {
                            auth.updateToken(token);
                        }

                        if (!response.ok) {
                            const json: IError = await response.json();
                            const error = new Error(json.message);
                            if (!auth.logged) {
                                return Promise.reject(error);
                            }
                            const customClientHeader = response?.headers?.get(CALYPSO_HEADERS.CLIENT_HEADER);
                            if (response.status === HttpStatus.UNAUTHORIZED) {
                                auth.logout();
                            } else if (response.status === HttpStatus.FORBIDDEN) {
                                navigate(routerLinks.home);
                            } else if (
                                !customClientHeader ||
                                (customClientHeader && !JSON.parse(customClientHeader).noToasterOnError)
                            ) {
                                toast.info(error.message, {
                                    toastId: DateTime.now().toString(),
                                });
                            }
                        }
                        return response;
                    },
                ],
            },
        });
    }, []);

    return children;
}
