// === NPM
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { KyResponse } from "ky";
// === LOCAL
import { ReactComponent as Add } from "@/assets/icons/actions/plus.svg";
import GenericButton from "@/components/generics/buttons/GenericButton";
import ConfirmDialogWithWarning from "@/components/generics/dialogs/ConfirmDialogWithWarning";
import { HttpStatus } from "@/interfaces/global";
import { IOrganization } from "@/interfaces/referential";
import ApiKeyService from "@/services/ApiKeyService";
import { AdministrationApiKeysOutletContext, ICreateAccount, IPeriodicity, Profile } from "../interface";
import CreateAccountDialog from "./dialogs/CreateAccountDialog";
import PeriodicitySplitButton from "./PeriodicitySplitButton";

interface PeriodicityAndCreateAccountProps {
    profile: Profile;
    organizations: IOrganization[];
    createAccount: (newAccount: ICreateAccount) => Promise<KyResponse>;
    getAccounts: () => void;
    disabled?: boolean;
}

export default function PeriodicityAndCreateAccount({
    profile,
    organizations,
    createAccount,
    getAccounts,
    disabled = false,
}: Readonly<PeriodicityAndCreateAccountProps>) {
    const { periodicitiesReferential, apiKeyProfilesReferential, updateProfilePeriodicity } =
        useOutletContext<AdministrationApiKeysOutletContext>();
    const [openChangePeriodiciyDialog, setOpenChangePeriodiciyDialog] = useState<boolean>(false);
    const [openCreateAccountDialog, setOpenCreateAccountDialog] = useState<boolean>(false);
    const [newPeriodicity, setNewPeriodicity] = useState<IPeriodicity>(null);

    const createNewAccount = async (newAccount: ICreateAccount) => {
        const res = await createAccount(newAccount);
        if (res.status === HttpStatus.CREATED) {
            setOpenCreateAccountDialog(false);
            toast.success("Compte créé avec succès");
            getAccounts();
        }
    };

    const handleChangePeriodicity = async (value) => {
        if (!value) {
            setOpenChangePeriodiciyDialog(false);
            setNewPeriodicity(null);
            return;
        }
        const res = await ApiKeyService.putPeriodicity(profile, {
            periodicityKey: newPeriodicity.key,
        });
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            setOpenChangePeriodiciyDialog(false);
            updateProfilePeriodicity(profile, data.periodicityKey);
            toast.success("Périodicité modifiée avec succès");
            setNewPeriodicity(null);
        }
    };

    const periodicitiesOptions = periodicitiesReferential?.map((p) => ({
        ...p,
        signature: (periodicity: IPeriodicity) => {
            setOpenChangePeriodiciyDialog(true);
            setNewPeriodicity(periodicity);
        },
    }));

    return (<>
        <Box
            sx={{
                justifyContent: "space-between",
                display: "flex",
                gap: 3
            }}>
            <PeriodicitySplitButton
                disabled={disabled}
                options={periodicitiesOptions || []}
                currentPeriodicity={apiKeyProfilesReferential?.find((p) => p.key === profile)?.periodicityKey}
            />
            <GenericButton
                disabled={disabled}
                label="Ajouter un compte"
                onClick={() => setOpenCreateAccountDialog(true)}
                startIcon={<Add />}
            />
        </Box>
        {openChangePeriodiciyDialog && (
            <ConfirmDialogWithWarning
                title="Mise à jour de la périodicité"
                warningDisplay={newPeriodicity.key === "never"}
                onClose={handleChangePeriodicity}
                warningText="Cette configuration n'est pas recommandée car non sécurisée"
                confirmationText={`Êtes-vous sûr de vouloir passer la périodicité à ${newPeriodicity.label} ?`}
            />
        )}
        {openCreateAccountDialog && (
            <CreateAccountDialog
                onClose={() => setOpenCreateAccountDialog(false)}
                organizations={organizations}
                onValid={createNewAccount}
                typeSelection={profile === Profile.DRUG_TRANSFER}
            />
        )}
    </>);
}
