// === NPM
import { KyResponse } from "ky";
// === LOCAL
import {
    IAllowedTarget,
    IConfig,
    INotificationCreateUpdate,
    INotificationFull,
    INotificationShort,
    IPublishedNotificationPatch,
    NotificationFilters,
    NotificationPublish,
    SendMode,
} from "@/components/Administration/containers/Notifications/interface";
import {
    IUserNotification,
    IUserNotificationDetails,
    IUserNotificationInfo,
} from "@/components/UserNotifications/interface";
import { HttpMethod, Loader, SearchParams, UserTypeHeader } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class NotificationService {
    async getNotifications(
        userType: UserTypeHeader.ADMIN,
        payload: SearchParams & Partial<NotificationFilters>
    ): Promise<KyResponse<INotificationShort[]>>;
    async getNotifications(
        userType: UserTypeHeader.USER,
        payload: SearchParams & Partial<NotificationFilters>
    ): Promise<KyResponse<IUserNotification[]>>;
    async getNotifications(
        userType: UserTypeHeader,
        payload: SearchParams & Partial<NotificationFilters>
    ): Promise<KyResponse<INotificationShort[] | IUserNotification[]>> {
        return HttpService.fetch(endpoints.notificationService.notifications(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
                loader: Loader.TABLE,
            },
        });
    }

    async getNotificationByUuid(uuid: string, userType: UserTypeHeader.ADMIN): Promise<KyResponse<INotificationFull>>;
    async getNotificationByUuid(
        uuid: string,
        userType: UserTypeHeader.USER
    ): Promise<KyResponse<IUserNotificationDetails>>;
    async getNotificationByUuid(
        uuid: string,
        userType: UserTypeHeader
    ): Promise<KyResponse<INotificationFull | IUserNotificationDetails>> {
        return HttpService.fetch(endpoints.notificationService.notification(uuid), {
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
                loader: Loader.BUTTON,
            },
        });
    }

    async getUserNotificationFile(uuid: string, fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.notificationService.file(uuid, fileUuid), {
            method: HttpMethod.GET,
        });
    }

    async getUserNotificationZip(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.notificationService.download(uuid), {
            method: HttpMethod.GET,
            headers: { loader: Loader.BUTTON },
        });
    }

    async getUserNotificationInfo(): Promise<KyResponse<IUserNotificationInfo>> {
        return HttpService.fetch(endpoints.notificationService.notificationInfo(), {
            method: HttpMethod.GET,
        });
    }

    async markUserNotificationAsRead(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.notificationService.read(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.BUTTON },
        });
    }

    async deleteNotification(uuid: string, userType: UserTypeHeader): Promise<KyResponse> {
        return HttpService.fetch(endpoints.notificationService.notification(uuid), {
            method: HttpMethod.DELETE,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
                loader: Loader.REQUEST,
            },
        });
    }

    async scheduleNotification(uuid: string, json: NotificationPublish): Promise<KyResponse> {
        return HttpService.fetch(endpoints.notificationService.schedule(uuid), {
            method: HttpMethod.PATCH,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async archiveNotification(uuid: string, userType: UserTypeHeader): Promise<KyResponse> {
        return HttpService.fetch(endpoints.notificationService.archive(uuid), {
            method: HttpMethod.PATCH,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
                loader: Loader.REQUEST,
            },
        });
    }

    async putNotification(uuid: string, notification: INotificationCreateUpdate, attachments?: File[]) {
        const blob = new Blob([JSON.stringify(notification)], {
            type: "application/json",
        });
        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("notification", blob);
        return HttpService.fetch(endpoints.notificationService.notification(uuid), {
            method: HttpMethod.PUT,
            body: formData,
            headers: { loader: Loader.REQUEST },
        });
    }

    async postNotification(notification: INotificationCreateUpdate, attachments?: File[]) {
        const blob = new Blob([JSON.stringify(notification)], {
            type: "application/json",
        });

        const formData = new FormData();
        attachments?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("notification", blob);
        return HttpService.fetch(endpoints.notificationService.notifications(), {
            method: HttpMethod.POST,

            body: formData,
            headers: { loader: Loader.REQUEST },
        });
    }

    async patchPublishedNotification(uuid: string, notification: IPublishedNotificationPatch) {
        return HttpService.fetch(endpoints.notificationService.notification(uuid), {
            method: HttpMethod.PATCH,
            json: notification,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getAutoNotificationTypes(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.notificationService.referentials.autoNotificationTypes(), {
            method: HttpMethod.GET,
        });
    }

    async getConfigs(): Promise<KyResponse<IConfig[]>> {
        return HttpService.fetch(endpoints.notificationService.configs(), {
            method: HttpMethod.GET,
        });
    }
    async putConfig(key: string, json: { sendMode: keyof typeof SendMode; publicationDayDelay: number }) {
        return HttpService.fetch(endpoints.notificationService.config(key), {
            method: HttpMethod.PUT,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getAuthorsTypes(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.notificationService.authors(), {
            method: HttpMethod.GET,
        });
    }

    async getAllowedTargets(): Promise<KyResponse<IAllowedTarget[]>> {
        return HttpService.fetch(endpoints.notificationService.allowedTargets(), {
            method: HttpMethod.GET,
        });
    }
}

export default new NotificationService();
