export const endpoints = {
    api: "/api",
    login: "/login",
    loginPsc: "/login-psc",
    introspection: "/introspection",
    userInfo: "/user-info",
    refresh: "/refresh",

    //USER SERVICE
    userService: {
        base: "/api/user-service",
        // /USERS
        user: {
            base: "/api/user-service/users",
            userInfo: (userId: string) => `${endpoints.userService.user.base}/${userId}/infos`,
            userOVDPE: (userId: string) => `${endpoints.userService.user.base}/${userId}/ov-dpe`,
            userDPE: (userId: string) => `${endpoints.userService.user.base}/${userId}/dpes`,
            loggedUserDPE: () => `${endpoints.userService.user.base}/me/dpes`,
            userRights: (userId: string) => `${endpoints.userService.user.base}/${userId}/rights`,
            userConsent: (userId: string, dpeOrdinalNumber: string) =>
                `${endpoints.userService.user.base}/${userId}/dpes/${dpeOrdinalNumber}/consent`,

            //ADMIN CALYPSO
            adminCalypso: {
                base: () => `${endpoints.userService.user.base}/admin-calypso`,
                user: (userId: string) => `${endpoints.userService.user.base}/admin-calypso/${userId}`,
            },
            //FAM DAM
            famDam: {
                base: () => `${endpoints.userService.user.base}/fam-dam`,
                agencyUsers: (agencyId: number) => `${endpoints.userService.user.base}/agency/${agencyId}`,
                user: (userId: string) => `${endpoints.userService.user.base}/fam-dam/${userId}`,
            },
            //ANMV
            anmv: {
                base: () => `${endpoints.userService.user.base}/anmv`,
                user: (userId: string) => `${endpoints.userService.user.base}/anmv/${userId}`,
            },
            //MA
            ma: {
                base: () => `${endpoints.userService.user.base}/ma`,
                typeUser: (type: string, userId: string) => `${endpoints.userService.user.base}/${type}/${userId}`,
                user: (userId: string) => `${endpoints.userService.user.base}/ma/${userId}`,
            },
            //OVVT
            ovvt: {
                base: () => `${endpoints.userService.user.base}/ovvt`,
                user: (userId: string) => `${endpoints.userService.user.ovvt.base()}/${userId}`,
            },
            sync: () => `${endpoints.userService.user.base}/sync`,
            tokenSso: () => `${endpoints.userService.user.base}/token-sso`,
            tutorials: (userId: string) => `${endpoints.userService.user.base}/${userId}/tutorials`,
            tutorial: (userId: string, tutorialKey: string) =>
                `${endpoints.userService.user.base}/${userId}/tutorials/${tutorialKey}`,
            localAgencies: () => `${endpoints.userService.user.base}/local-agencies`,
            activities: () => `${endpoints.userService.user.base}/activities`,
            pharmacy: (userId: string) => `${endpoints.userService.user.base}/${userId}/pharmacy`,
            preferences: (userId: string) => `${endpoints.userService.user.base}/${userId}/preferences`,
            notifications: () => `${endpoints.userService.user.base}/notifications`,
            homePreferences: (userId: string) => `${endpoints.userService.user.base}/${userId}/home-preferences`,
        },
        veterinaries: () => `${endpoints.userService.base}/veterinaries`,
        agency: {
            base: "/api/user-service/agencies",
            localAgenciesByAgency: (agencyId: number) =>
                `${endpoints.userService.agency.base}/${agencyId}/local-agencies`,
            agency: (agencyId: number) => `${endpoints.userService.agency.base}/${agencyId}`,
            archive: (agencyId: number) => `${endpoints.userService.agency.base}/${agencyId}/archive`,
        },
        localAgency: {
            base: "/api/user-service/local-agencies",
            archive: (localAgencyUuid: string) =>
                `${endpoints.userService.localAgency.base}/${localAgencyUuid}/archive`,
            localAgency: (localAgencyUuid: string) => `${endpoints.userService.localAgency.base}/${localAgencyUuid}`,
        },
        dpes: {
            base: "/api/user-service/dpes",
            dpe: (ordinalNumber: string) => `${endpoints.userService.dpes.base}/${ordinalNumber}`,
            sync: () => `${endpoints.userService.dpes.base}/sync`,
            historySync: () => `${endpoints.userService.dpes.base}/history/sync`,
            users: (ordinalNumber) => `${endpoints.userService.dpes.base}/${ordinalNumber}/users`,
        },
        pharmacy: {
            base: "/api/user-service/pharmacies",
            pharmacy: (pharmacyId: string) => `${endpoints.userService.pharmacy.base}/${pharmacyId}`,
        },
        agriMinistry: {
            base: "/api/user-service/agri-ministry",
            geoUnits: () => `${endpoints.userService.agriMinistry.base}/geographic-units`,
            geoUnit: (agriMinistryGeoUnitUuid: string) =>
                `${endpoints.userService.agriMinistry.base}/geographic-units/${agriMinistryGeoUnitUuid}`,
            users: () => `${endpoints.userService.agriMinistry.base}/users`,
        },
        ovvt: {
            base: "/api/user-service/ovvt-organizations",
            organization: (uuid: string) => `${endpoints.userService.ovvt.base}/${uuid}`,
            vacantRegions: () => `${endpoints.userService.ovvt.base}/vaccant-regions`,
            users: (organizationUuid: string) => `${endpoints.userService.ovvt.organization(organizationUuid)}/users`,
        },
        companies: {
            base: "/api/user-service/companies",
            sync: () => `${endpoints.userService.companies.base}/sync`,
        },

        // /REFERENTIALS
        referentials: {
            base: "/api/user-service/referential",
            rightsReferential: () => `${endpoints.userService.referentials.base}/features`,
            userTypesReferential: () => `${endpoints.userService.referentials.base}/types`,
            situations: () => `${endpoints.userService.referentials.base}/situations`,
            profileFeatures: () => `${endpoints.userService.referentials.base}/profile-features`,
            profiles: () => `${endpoints.userService.referentials.base}/profiles`,
            profile: (profileKey: string) => `${endpoints.userService.referentials.base}/profiles/${profileKey}`,
            geoUnits: () => `${endpoints.userService.referentials.base}/geographic-units`,
        },

        //SITUATIONS
        situationRights: (situationKey: string) => `/api/user-service/situations/${situationKey}/rights`,
    },
    // API KEY SERVICE
    apiKeyService: {
        base: "/api/apikey-service",
        referentials: {
            base: "/api/apikey-service/referential",
            profile: () => `${endpoints.apiKeyService.referentials.base}/profiles`,
            periodicities: () => `${endpoints.apiKeyService.referentials.base}/periodicities`,
            apiKeyTypes: () => `${endpoints.apiKeyService.referentials.base}/types`,
            accountTypes: () => `${endpoints.apiKeyService.referentials.base}/account-types`,
            ssoOrganizations: () => `${endpoints.apiKeyService.referentials.base}/sso-organizations`,
            vaccinationInterventionOrganizations: () =>
                `${endpoints.apiKeyService.referentials.base}/vaccination-intervention-organizations`,
            bnoOrganizations: () => `${endpoints.apiKeyService.referentials.base}/bno-organizations`,
        },
        account: {
            base: "/api/apikey-service/accounts",
            accountByUuid: (uuid: string) => `${endpoints.apiKeyService.account.base}/${uuid}`,
            accountToken: (uuid: string) => `${endpoints.apiKeyService.account.base}/${uuid}/token`,
            drugTransfer: () => `${endpoints.apiKeyService.account.base}/drug-transfer`,
            continuousTraining: () => `${endpoints.apiKeyService.account.base}/continuous-training`,
            sso: () => `${endpoints.apiKeyService.account.base}/sso`,
            vaccinationIntervention: () => `${endpoints.apiKeyService.account.base}/vaccination-intervention`,
            bno: () => `${endpoints.apiKeyService.account.base}/bno`,
        },
        apiKey: {
            base: "/api/apikey-service/api-keys",

            apiKeyByUuid: (uuid: string) => `${endpoints.apiKeyService.apiKey.base}/${uuid}`,
        },
        profilePeriodicity: (profileKey: string) =>
            `${endpoints.apiKeyService.base}/profiles/${profileKey}/periodicity`,
        alerts: () => {
            return `${endpoints.apiKeyService.base}/alerts`;
        },
        alertByUuid: (uuid: string) => `${endpoints.apiKeyService.base}/alerts/${uuid}`,
        apiKeyFromToken: () => {
            return `/public/apikey-service/key`;
        },
    },

    // TRAINING SERIVCE
    trainingService: {
        base: "/api/training-service",
        referentials: {
            types: "/api/training-service/referential/types",
            type: (typeUuid: string) => `${endpoints.trainingService.referentials.types}/${typeUuid}`,
            healthAccreditationTypes: () => `${endpoints.trainingService.base}/referential/health-accreditation-types`,
            status: "/api/training-service/referential/status",
            formats: "/api/training-service/referential/formats",
            format: (formatUuid: string) => `${endpoints.trainingService.referentials.formats}/${formatUuid}`,
            restoreFormat: (formatUuid: string) =>
                `${endpoints.trainingService.referentials.formats}/${formatUuid}/restore`,
            archiveFormat: (formatUuid: string) =>
                `${endpoints.trainingService.referentials.formats}/${formatUuid}/archive`,
            horsePowers: () => `${endpoints.trainingService.base}/referential/horse-powers`,
        },
        catalog: {
            base: () => `${endpoints.trainingService.base}/catalog`,
            continuousTrainingCatalog: (query?) => {
                const queryParams = query ? `?${query} ` : "";
                return `${endpoints.trainingService.catalog.base()}/continuous-training${queryParams}`;
            },
        },
        continuousTraining: {
            base: "/api/training-service/continuous-trainings",
            dpes: (dpeOrdinalNumber: string, query?) => {
                const queryParams = query ? `?${query} ` : "";
                return `${endpoints.trainingService.continuousTraining.base}/dpes/${dpeOrdinalNumber}${queryParams}`;
            },
            dpe: (dpeOrdinalNumber: string, trainingUuid: string) =>
                `${endpoints.trainingService.continuousTraining.base}/dpes/${dpeOrdinalNumber}/trainings/${trainingUuid}`,
            dpeFile: (dpeOrdinalNumber: string, trainingUuid: string, fileUuid: string) =>
                `${endpoints.trainingService.continuousTraining.base}/dpes/${dpeOrdinalNumber}/trainings/${trainingUuid}/files/${fileUuid}`,
            trainingInformations: () => `${endpoints.trainingService.continuousTraining.base}/training-infos`,
            training: (trainingUuid: string) => `${endpoints.trainingService.continuousTraining.base}/${trainingUuid}`,
            trainingFile: (trainingUuid: string, fileUuid: string) =>
                `${endpoints.trainingService.continuousTraining.base}/${trainingUuid}/files/${fileUuid}`,
        },
        healthAccreditationTraining: {
            base: "/api/training-service/health-accreditation-trainings",
            training: (uuid: string) => `${endpoints.trainingService.healthAccreditationTraining.base}/${uuid}`,
            trainingFile: (uuid: string, fileUuid: string) =>
                `${endpoints.trainingService.healthAccreditationTraining.training(uuid)}/files/${fileUuid}`,
        },
        sessions: {
            base: "/api/training-service/health-accreditation-training-sessions",
            session: (uuid: string) => `${endpoints.trainingService.sessions.base}/${uuid}`,
            sessionsToProcess: () => `${endpoints.trainingService.sessions.base}/process`,
            cancel: (uuid: string) => `${endpoints.trainingService.sessions.session(uuid)}/cancel`,
            counts: () => `${endpoints.trainingService.sessions.base}/counts`,
            ddppCounts: () => `${endpoints.trainingService.sessions.sessionsToProcess()}/counts`,
            participations: (sessionUuid: string) =>
                `${endpoints.trainingService.sessions.session(sessionUuid)}/participations`,
            attendanceSheet: (sessionUuid: string) =>
                `${endpoints.trainingService.sessions.session(sessionUuid)}/attendance-sheet`,
        },
        participations: {
            base: "/api/training-service/health-accreditation-training-session-participations",
            participation: (uuid: string) => `${endpoints.trainingService.participations.base}/${uuid}`,
            validate: (uuid: string) => `${endpoints.trainingService.participations.base}/${uuid}/validate`,
            invalidate: (uuid: string) => `${endpoints.trainingService.participations.base}/${uuid}/invalidate`,
            present: (uuid: string) => `${endpoints.trainingService.participations.base}/${uuid}/present`,
            absent: (uuid: string) => `${endpoints.trainingService.participations.base}/${uuid}/absent`,
            next: () => `${endpoints.trainingService.participations.base}/next`,
            attestation: (uuid: string) =>
                `${endpoints.trainingService.participations.participation(uuid)}/attestation`,
            info: () => `${endpoints.trainingService.participations.base}/info`,
            validated: () => `${endpoints.trainingService.participations.base}/validated`,
        },
        exports: {
            base: "/api/training-service/exports",
            continuousTrainings: () => `${endpoints.trainingService.exports.base}/continuous-trainings`,
        },
    },

    // DRUG SERVICE
    drugService: {
        base: "/api/drug-service",
        imports: {
            base: "/api/drug-service/imports",
            template: () => `${endpoints.drugService.imports.base}/template`,
            import: (importUuid: string) => `${endpoints.drugService.imports.base}/${importUuid}`,
            importFile: (importUuid: string, fileUuid: string) =>
                `${endpoints.drugService.imports.base}/${importUuid}/files/${fileUuid}`,
            deliveries: () => `${endpoints.drugService.imports.base}/deliveries`,
            fileContent: (importUuid: string, fileUuid: string) =>
                `${endpoints.drugService.imports.base}/${importUuid}/files/${fileUuid}/content`,
            softwares: () => `${endpoints.drugService.imports.base}/softwares`,
        },
        nonDeliveries: () => `${endpoints.drugService.base}/non-deliveries`,
        reporting: {
            base: "/api/drug-service/reporting",
            drugDeliveries: () => `${endpoints.drugService.reporting.base}/drug-deliveries`,
            excludeDelivery: (uuid: string) => `${endpoints.drugService.reporting.base}/deliveries/${uuid}/exclude`,
            reinstateDelivery: (uuid: string) => `${endpoints.drugService.reporting.base}/deliveries/${uuid}/reinstate`,
            drugPrescriptions: () => `${endpoints.drugService.reporting.base}/drug-prescriptions`,
        },
        drafts: {
            base: "/api/drug-service/users",
            draft: (userId: string) => `${endpoints.drugService.drafts.base}/${userId}/draft`,
        },
        prescriptions: {
            base: "/api/drug-service/prescriptions",
            deliveries: () => `${endpoints.drugService.prescriptions.base}/deliveries`,
            drugs: (prescriptionUuid: string) =>
                `${endpoints.drugService.prescriptions.base}/${prescriptionUuid}/drugs/manuals`,
        },
        manualPrescriptions: "/api/drug-service/prescriptions/manuals",
        exports: {
            base: "/api/drug-service/exports",
            importReports: () => `${endpoints.drugService.exports.base}/import-reports`,
        },
    },

    // TRACEABILITY SERVICE
    traceabilityService: {
        base: "/api/traceability-service/traceability",
        deliveries: () => `${endpoints.traceabilityService.base}/deliveries`,
        delivery: (deliveryUuid: string) => `${endpoints.traceabilityService.base}/deliveries/${deliveryUuid}`,
        deliveryFile: (deliveryUuid: string, fileUuid: string) =>
            `${endpoints.traceabilityService.base}/deliveries/${deliveryUuid}/files/${fileUuid}`,
        continuousTrainingOrganization: () => `${endpoints.traceabilityService.base}/continuous-training-organization`,
        users: () => `${endpoints.traceabilityService.base}/users`,
        agencies: () => `${endpoints.traceabilityService.base}/agencies`,
        nonDeliveries: () => `${endpoints.traceabilityService.base}/non-deliveries`,
        vaccinationIntervention: () => `${endpoints.traceabilityService.base}/vaccination-interventions`,
        vaccinationSites: {
            base: () => `${endpoints.traceabilityService.base}/vaccination-sites`,
            details: (uuid: string) => `${endpoints.traceabilityService.vaccinationSites.base()}/${uuid}`,
        },
        scrapping: {
            base: "/api/traceability-service/traceability/scrappings",
            details: (uuid: string) => `${endpoints.traceabilityService.scrapping.base}/${uuid}`,
        },
        referential: {
            base: "/api/traceability-service/referential",
            actions: () => `${endpoints.traceabilityService.referential.base}/actions`,
            userSituations: () => `${endpoints.traceabilityService.referential.base}/user-situations`,
            healthReportingStatuses: () =>
                `${endpoints.traceabilityService.referential.base}/health-reporting-statuses`,
        },
        monitoringIntervention: () => `${endpoints.traceabilityService.base}/monitoring-interventions`,
        monitoringInterventionDetails: (uuid: string) =>
            `${endpoints.traceabilityService.base}/monitoring-interventions/${uuid}`,
        healthReportings: () => `${endpoints.traceabilityService.base}/health-reportings`,
        ovvt: () => `${endpoints.traceabilityService.base}/ovvt-organizations`,
        ovvtDetails: (uuid: string) => `${endpoints.traceabilityService.ovvt()}/${uuid}`,
        healthAccreditationTrainings: () => `${endpoints.traceabilityService.base}/health-accreditation-trainings`,
        healthAccreditationTrainingsDetails: (uuid) =>
            `${endpoints.traceabilityService.healthAccreditationTrainings()}/${uuid}`,
        healthAccreditationTrainingSessions: () =>
            `${endpoints.traceabilityService.base}/health-accreditation-training-sessions`,
        healthAccreditationTrainingSessionsDetails: (uuid) =>
            `${endpoints.traceabilityService.healthAccreditationTrainingSessions()}/${uuid}`,
    },

    // REFERENTIAL SERVICE
    referentialService: {
        species: {
            base: "/api/referential-service/species-category",
            species: () => `${endpoints.referentialService.species.base}/species`,
            speciesUuid: (uuid: string) => {
                return `${endpoints.referentialService.species.base}/species/${uuid}`;
            },
            uses: () => {
                return `/api/referential-service/uses`;
            },
            sync: () => `${endpoints.referentialService.species.base}/sync`,
        },
        organizations: {
            base: "/api/referential-service/organizations",
            drugTransferOrganizationReferential: () =>
                `${endpoints.referentialService.organizations.base}/drug-transfers`,
            drugTransferOrganizationReferentialUuid: (uuid: string) =>
                `${endpoints.referentialService.organizations.base}/drug-transfers/${uuid}`,
            continuousTrainingOrganizationReferential: () =>
                `${endpoints.referentialService.organizations.base}/continuous-training`,
            continuousTrainingOrganizationUuid: (uuid: string) =>
                `${endpoints.referentialService.organizations.base}/continuous-training/${uuid}`,
            continuousTrainingOrganizationArchive: (uuid: string) =>
                `${endpoints.referentialService.organizations.continuousTrainingOrganizationUuid(uuid)}/archive`,
            continuousTrainingOrganizationRestore: (uuid: string) =>
                `${endpoints.referentialService.organizations.continuousTrainingOrganizationUuid(uuid)}/restore`,
        },
        drugs: {
            base: "/api/referential-service/drugs",
            typeIdentifiant: () => `${endpoints.referentialService.drugs.base}/type-identifiants`,
            productStatus: () => `${endpoints.referentialService.drugs.base}/product-status`,
            sync: () => `${endpoints.referentialService.drugs.base}/sync`,
        },
        humanDrugs: {
            base: "/api/referential-service/human-drugs",
        },
        localization: {
            base: "/api/referential-service/localization",
            cities: () => {
                return `${endpoints.referentialService.localization.base}/cities/search`;
            },
            departments: () => {
                return `${endpoints.referentialService.localization.base}/departments`;
            },
            regions: () => {
                return `${endpoints.referentialService.localization.base}/regions`;
            },
            sync: () => `${endpoints.referentialService.localization.base}/sync`,
        },
        settings: {
            base: "/api/referential-service/settings",
            deliveryImportTemplateDate: () =>
                `${endpoints.referentialService.settings.base}/delivery-import-template-date`,
        },
        common: {
            base: "/api/referential-service/common",
            horsePower: () => `${endpoints.referentialService.common.base}/horse-powers`,
        },
    },

    // FAQ SERVICE
    faqService: {
        base: "/api/faq-service",
        categories: {
            base: "/api/faq-service/categories",
            category: (categoryUuid: string) => `${endpoints.faqService.categories.base}/${categoryUuid}`,
            position: (categoryUuid: string) => `${endpoints.faqService.categories.base}/${categoryUuid}/position`,
        },
        tags: {
            base: "/api/faq-service/tags",
            tag: (tagUuid: string) => `${endpoints.faqService.tags.base}/${tagUuid}`,
        },
        questions: {
            base: "/api/faq-service/questions",
            position: (questionUuid: string) => `${endpoints.faqService.questions.base}/${questionUuid}/position`,
            question: (questionUuid: string) => `${endpoints.faqService.questions.base}/${questionUuid}`,
            draft: (questionUuid: string) => `${endpoints.faqService.questions.base}/${questionUuid}/draft`,
        },
    },

    // FARM SERVICE
    farmService: {
        base: "/api/farm-service",
        farms: () => `${endpoints.farmService.base}/farms`,
        farm: (farmId: string) => `${endpoints.farmService.base}/farms/${farmId}`,
        workshops: {
            base: "/api/farm-service/workshops",
            farmByWorkshop: (workshopId: string) => `${endpoints.farmService.workshops.base}/${workshopId}/farm`,
            alert: () => `${endpoints.farmService.workshops.base}/linked-alert`,
            linked: () => `${endpoints.farmService.workshops.base}/linked`,
            file: (workshopId: string, fileUuid: string) =>
                `${endpoints.farmService.workshops.base}/${workshopId}/files/${fileUuid}`,
            cerfa: (workshopId: string) => `${endpoints.farmService.workshops.base}/${workshopId}/cerfa-file`,
            closure: (workshopId: string) => `${endpoints.farmService.workshops.base}/${workshopId}/link-closure`,
        },
        referential: {
            base: "/api/farm-service/referential",
            closureReasons: () => `${endpoints.farmService.referential.base}/closure-reasons`,
        },
        farmLinkedWorkshops: (farmId: string) => `${endpoints.farmService.base}/farms/${farmId}/linking-workshops`,
    },

    // VACCINATION SERVICE
    vaccinationService: {
        base: "/api/vaccination-service",
        referential: {
            base: "/api/vaccination-service/referentials",
            vaccines: () => `${endpoints.vaccinationService.referential.base}/vaccines`,
            orderStatuses: () => `${endpoints.vaccinationService.referential.base}/order-statuses`,
            horsePower: () => `${endpoints.vaccinationService.referential.base}/horse-powers`,
            sectors: () => `${endpoints.vaccinationService.referential.base}/sectors`,
            tiers: () => `${endpoints.vaccinationService.referential.base}/tiers`,
            batches: (vaccineGtinCode: string) =>
                `${endpoints.vaccinationService.referential.base}/vaccines/${vaccineGtinCode}/batches`,
            reasons: () => `${endpoints.vaccinationService.referential.base}/scrapping-reasons`,
            serologicalTypes: () => `${endpoints.vaccinationService.referential.base}/serological-types`,
            nonFullfilmentReasons: () => `${endpoints.vaccinationService.referential.base}/non-fullfilment-reasons`,
            clinicalNonComplianceReasons: () =>
                `${endpoints.vaccinationService.referential.base}/clinical-non-compliance-reasons`,
            monitoringInterventionStatuses: () =>
                `${endpoints.vaccinationService.referential.base}/monitoring-intervention-statuses`,
            memoryStatuses: () => `${endpoints.vaccinationService.referential.base}/memory-statuses`,
            memoryTypes: () => `${endpoints.vaccinationService.referential.base}/memory-types`,
            billingStatuses: () => `${endpoints.vaccinationService.referential.base}/billing-statuses`,
        },
        orders: {
            base: "/api/vaccination-service/orders",
            order: (id: string) => `${endpoints.vaccinationService.orders.base}/${id}`,
        },
        vaccinationSites: {
            base: "/api/vaccination-service/vaccination-sites",
            infos: (id: number) => `${endpoints.vaccinationService.vaccinationSites.base}/${id}/infos`,
            vaccinationSite: (id: number) => `${endpoints.vaccinationService.vaccinationSites.base}/${id}`,
            delete: (id: number) => `${endpoints.vaccinationService.vaccinationSites.base}/${id}/delete`,
            vaccinationInterventions: (id: number) =>
                `${endpoints.vaccinationService.vaccinationSites.base}/${id}/vaccination-interventions`,
            audits: (id: number) => `${endpoints.vaccinationService.vaccinationSites.base}/${id}/audits`,
            nonCompliances: (id: number) =>
                `${endpoints.vaccinationService.vaccinationSites.base}/${id}/non-compliances`,
            workshopsInfo: (id: number) => `${endpoints.vaccinationService.vaccinationSites.base}/${id}/workshops-info`,
            validated: () => `${endpoints.vaccinationService.vaccinationSites.base}/validated`,
            billables: {
                base: "/api/vaccination-service/vaccination-sites/billables",
                billables: () => endpoints.vaccinationService.vaccinationSites.billables.base,
                counts: () => `${endpoints.vaccinationService.vaccinationSites.billables.base}/counts`,
            },
            outsideCalypso: () => `${endpoints.vaccinationService.vaccinationSites.base}/outside-calypso`,
            editBillingStatus: (id: number) => `${endpoints.vaccinationService.vaccinationSites.base}/${id}/status`,
            statusChanges: (id: number) => `${endpoints.vaccinationService.vaccinationSites.base}/${id}/statuses`,
            allowedNextStatuses: (id: number) =>
                `${endpoints.vaccinationService.vaccinationSites.base}/${id}/allowed-next-statuses`,
        },
        vaccinationInterventions: {
            base: "/api/vaccination-service/vaccination-interventions",
            intervention: (interventionId: string) =>
                `${endpoints.vaccinationService.vaccinationInterventions.base}/${interventionId}`,
            updateInterventionCorrection: (interventionId: string) =>
                `${endpoints.vaccinationService.vaccinationInterventions.base}/${interventionId}/update`,
            deleteInterventionCorrection: (interventionId: string) =>
                `${endpoints.vaccinationService.vaccinationInterventions.base}/${interventionId}/delete`,
            generateInterventionCertificate: (interventionId: string) =>
                `${endpoints.vaccinationService.vaccinationInterventions.base}/${interventionId}/certify`,
            batches: () => `${endpoints.vaccinationService.vaccinationInterventions.base}/batches`,
            audit: (interventionId: string) =>
                `${endpoints.vaccinationService.vaccinationInterventions.base}/${interventionId}/audit`,
            file: (interventionId: string, fileUuid: string) =>
                `${endpoints.vaccinationService.vaccinationInterventions.base}/${interventionId}/files/${fileUuid}`,
            counts: () => `${endpoints.vaccinationService.vaccinationInterventions.base}/counts`,
        },
        monitoringInterventions: {
            base: "/api/vaccination-service/monitoring-interventions",
            intervention: (interventionId: string) =>
                `${endpoints.vaccinationService.monitoringInterventions.base}/${interventionId}`,
            correction: (interventionId: string) =>
                `${endpoints.vaccinationService.monitoringInterventions.base}/${interventionId}/correction`,
            file: (interventionId: string, fileUuid: string) =>
                `${endpoints.vaccinationService.monitoringInterventions.base}/${interventionId}/files/${fileUuid}`,
        },
        vaccine: {
            base: "/api/vaccination-service/vaccines",
        },
        scrappings: {
            base: "/api/vaccination-service/scrappings",
            scrapping: (scrappingId: string) => `${endpoints.vaccinationService.scrappings.base}/${scrappingId}`,
        },
        exports: {
            base: "/api/vaccination-service/exports",
            vaccinationIntervention: () => `${endpoints.vaccinationService.exports.base}/vaccination-interventions`,
            monitoringIntervention: () => `${endpoints.vaccinationService.exports.base}/monitoring-interventions`,
            relation: () => `${endpoints.vaccinationService.exports.base}/workshop-relations`,
            vaccinationSites: () => `${endpoints.vaccinationService.exports.base}/billed-vaccination-sites`,
        },
        audits: {
            base: "/api/vaccination-service/audits",
            audit: (uuid: string) => `${endpoints.vaccinationService.audits.base}/${uuid}`,
        },
        nonCompliance: {
            base: "/api/vaccination-service/non-compliances",
            nonCompliance: (uuid: string) => `${endpoints.vaccinationService.nonCompliance.base}/${uuid}`,
        },
        farms: {
            base: "/api/vaccination-service/farms",
            workshops: (farmId: string) => `${endpoints.vaccinationService.farms.base}/${farmId}/workshops`,
        },
        memories: {
            base: "/api/vaccination-service/memories",
            counts: () => `${endpoints.vaccinationService.memories.base}/counts`,
            memory: (id: number) => `${endpoints.vaccinationService.memories.base}/${id}`,
            status: (memoryId: number) => `${endpoints.vaccinationService.memories.memory(memoryId)}/status`,
            vaccinationSites: (memoryId: number) =>
                `${endpoints.vaccinationService.memories.memory(memoryId)}/vaccination-sites`,
            file: (memoryId: number, fileUuid: string) =>
                `${endpoints.vaccinationService.memories.memory(memoryId)}/files/${fileUuid}`,
            removeVaccinationSite: (memoryId: number, vaccinationSiteId: number) =>
                `${endpoints.vaccinationService.memories.vaccinationSites(memoryId)}/${vaccinationSiteId}/unlink`,
        },
    },

    // NOTIFICATION SERVICE
    notificationService: {
        base: "/api/notification-service",
        notifications: () => `${endpoints.notificationService.base}/notifications`,
        notification: (uuid: string) => `${endpoints.notificationService.notifications()}/${uuid}`,
        schedule: (uuid: string) => `${endpoints.notificationService.notification(uuid)}/schedule`,
        archive: (uuid: string) => `${endpoints.notificationService.notification(uuid)}/archive`,
        read: (uuid: string) => `${endpoints.notificationService.notification(uuid)}/read`,
        file: (uuid: string, fileUuid: string) =>
            `${endpoints.notificationService.notification(uuid)}/files/${fileUuid}`,
        download: (uuid: string) => `${endpoints.notificationService.notification(uuid)}/download`,
        notificationInfo: () => `${endpoints.notificationService.notifications()}/user-infos`,
        configs: () => `${endpoints.notificationService.notifications()}/configs`,
        config: (key: string) => `${endpoints.notificationService.notifications()}/configs/${key}`,
        referentials: {
            base: "/api/notification-service/referentials",
            autoNotificationTypes: () => `${endpoints.notificationService.referentials.base}/auto-notification-types`,
        },
        authors: () => `${endpoints.notificationService.notifications()}/authors-types`,
        allowedTargets: () => `${endpoints.notificationService.notifications()}/allowed-targets`,
    },

    // BUTCHERY SECTOR SERVICE
    butcherySectorService: {
        base: "/api/butcherysector-service",
        referential: {
            base: "/api/butcherysector-service/referential",
            horseBreeds: {
                sync: () => `${endpoints.butcherySectorService.referential.base}/horses/breeds/sync`,
            },
            horseColors: {
                sync: () => `${endpoints.butcherySectorService.referential.base}/horses/colors/sync`,
            },
        },
        horse: (id: string) => `${endpoints.butcherySectorService.base}/horses/${id}`,
        horseBreeds: () => `${endpoints.butcherySectorService.referential.base}/horses/breeds`,
        horseColors: () => `${endpoints.butcherySectorService.referential.base}/horses/colors`,
        exclusionStatus: () => `${endpoints.butcherySectorService.referential.base}/horses/exclusion-status`,
        exclusions: () => `${endpoints.butcherySectorService.base}/horses/exclusions`,
        softwareNames: () => `${endpoints.butcherySectorService.exclusions()}/software-names`,
        file: (fileUuid: string) => `${endpoints.butcherySectorService.exclusions()}/file/${fileUuid}`,
    },

    // DOWNLOAD SERVICE
    downloadService: {
        base: "/api/download-service",
        downloads: () => `${endpoints.downloadService.base}/downloads`,
        download: (uuid: string) => `${endpoints.downloadService.downloads()}/${uuid}`,
    },

    //HEALTH REPORTING SERVICE
    healthReportingService: {
        base: "/api/healthreporting-service",
        reportings: () => `${endpoints.healthReportingService.base}/health-reportings`,
        reporting: (id: string) => `${endpoints.healthReportingService.reportings()}/${id}`,
        download: (id: string) => `${endpoints.healthReportingService.reporting(id)}/download`,
        reportStatus: (id: string) => `${endpoints.healthReportingService.reporting(id)}/status`,
        file: (reportingId: string, fileUuid: string) =>
            `${endpoints.healthReportingService.reporting(reportingId)}/files/${fileUuid}`,
        draft: () => `${endpoints.healthReportingService.reportings()}/draft`,
        draftFile: (fileUuid: string) => `${endpoints.healthReportingService.draft()}/files/${fileUuid}`,
        counts: () => `${endpoints.healthReportingService.reportings()}/counts`,
        referential: {
            base: "/api/healthreporting-service/referential",
            anomalyTypes: () => `${endpoints.healthReportingService.referential.base}/anomaly-types`,
        },
    },

    // BILLING SERVICE
    billingService: {
        base: "/api/billing-service",
        referential: {
            base: () => `${endpoints.billingService.base}/referential`,
            vat: () => `${endpoints.billingService.referential.base()}/vat`,
            amv: () => `${endpoints.billingService.referential.base()}/amv`,
            chargeables: () => `${endpoints.billingService.referential.base()}/chargeables`,
        },
        vat: {
            base: () => `${endpoints.billingService.base}/vat`,
            versions: (vatKey: string) => `${endpoints.billingService.vat.base()}/${vatKey}`,
            specificVersion: (uuid: string) => `${endpoints.billingService.vat.base()}/${uuid}`,
        },
        amv: {
            base: () => `${endpoints.billingService.base}/amv`,
            versions: (amvKey: string) => `${endpoints.billingService.amv.base()}/${amvKey}`,
            specificVersion: (uuid: string) => `${endpoints.billingService.amv.base()}/${uuid}`,
        },
        chargeables: {
            base: () => `${endpoints.billingService.base}/chargeables`,
            versions: (chargeableKey: string) => `${endpoints.billingService.chargeables.base()}/${chargeableKey}`,
            specificVersion: (uuid: string) => `${endpoints.billingService.chargeables.base()}/${uuid}`,
            requiredFields: (chargeableKey: string) =>
                `${endpoints.billingService.chargeables.versions(chargeableKey)}/required-fields`,
        },
    },
};
