// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { ISpeciesDetails } from "@/interfaces/referential";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import ReferentialService from "@/services/ReferentialService";

export default function Farm() {
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);
    useEffect(() => {
        getSpecies();
    }, []);

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["iahp"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(await res.json());
        }
    };
    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.iahp.farm.view(),
                        label: "Mes élevages",
                    },
                    {
                        url: routerLinks.iahp.farm.declareRelation(),
                        label: "Déclarer une relation VS",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                        preferences: [Preference.IAHP],
                    },
                ]}
            />
            <PageContent>
                <Outlet context={{ species }} />
            </PageContent>
        </>
    );
}
