// === NPM
import React, { CSSProperties, useEffect, useState } from "react";
import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as DOMPurify from "dompurify";
import { applyPatch } from "fast-json-patch";
// === LOCAL
import { ReactComponent as Content } from "@/assets/icons/shared/comment.svg";
import { ReactComponent as Training } from "@/assets/icons/shared/paper.svg";
import TitleTextPatchValue from "@/components/generics/text/TitleTextPatchedValue";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { TraceabilityActions } from "@/interfaces/traceability";
import TraceabilityService from "@/services/TraceabilityService";
import {
    IHealthAccreditationTrainingTraceabilityDetails,
    IHealthAccreditationTrainingWithTypeName,
} from "../../../interface";

interface HealthAccreditationTrainingTraceabilityDetailsProps {
    traceabilityUuid: string;
    traceabilityActions: IReferential[];
}

export default function HealthAccreditationTrainingTraceabilityDetails({
    traceabilityUuid,
    traceabilityActions,
}: Readonly<HealthAccreditationTrainingTraceabilityDetailsProps>) {
    const theme = useTheme();

    const [traceabilityDetails, setTraceabilityDetails] =
        useState<IHealthAccreditationTrainingTraceabilityDetails>(null);
    const [content, setContent] = useState<IHealthAccreditationTrainingWithTypeName>(null);

    useEffect(() => {
        if (traceabilityUuid) getTraceabilityDetails();
    }, [traceabilityUuid]);

    const getTraceabilityDetails = async () => {
        const res = await TraceabilityService.getTraceabilityHealthAccreditationTrainingDetails(traceabilityUuid);
        if (res.status === HttpStatus.OK) {
            let trainingObject;
            const data = await res.json();
            if (data.action.includes(TraceabilityActions.DELETE)) {
                trainingObject = data.value;
            } else {
                trainingObject = applyPatch(data.value, data.patch, false, false).newDocument;
            }
            setContent(trainingObject);
            setTraceabilityDetails(await res.json());
        }
    };

    return (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4">
                    {traceabilityDetails
                        ? traceabilityActions.find((action) => action.key === traceabilityDetails.action)?.label
                        : "Détails de traçabilité"}
                </Typography>
            </Box>
            {traceabilityDetails ? (
                <Card>
                    <StyledCardContent>
                        <Grid
                            container
                            rowSpacing={4}
                            columnSpacing={2}
                            sx={{
                                m: 2,
                            }}
                        >
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                }}
                            >
                                <Box>
                                    <Training />
                                </Box>
                                <Stack spacing={1}>
                                    <Box
                                        sx={{
                                            mt: -0.25,
                                        }}
                                    >
                                        <Typography
                                            variant="bold"
                                            sx={{
                                                fontSize: 18,
                                            }}
                                        >
                                            Informations sur la formation
                                        </Typography>
                                    </Box>
                                    <TitleTextPatchValue
                                        title="Points ECTS"
                                        value={`${content.ectsPoints} ECTS`}
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/ectsPoints"
                                    />
                                    <TitleTextPatchValue
                                        title="Type de la formation"
                                        value={content.type}
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/type"
                                    />
                                    <TitleTextPatchValue
                                        value={content.targetPublic}
                                        title="Public cible"
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/targetPublic"
                                    />
                                    <TitleTextPatchValue
                                        value={content.inscriptionNumberMin}
                                        title="Nombre minimum d'inscrits"
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/inscriptionNumberMin"
                                    />
                                    <TitleTextPatchValue
                                        value={content.inscriptionNumberMax}
                                        title="Nombre maximum d'inscrits"
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/inscriptionNumberMax"
                                    />
                                    <TitleTextPatchValue
                                        value={`${content.duration}h`}
                                        title="Durée"
                                        patches={traceabilityDetails.patch}
                                        action={traceabilityDetails.action}
                                        path="/duration"
                                    />
                                </Stack>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    md: 6,
                                }}
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                }}
                            >
                                <Box>
                                    <Content />
                                </Box>
                                <Stack spacing={1}>
                                    <Box
                                        sx={{
                                            mt: -0.25,
                                        }}
                                    >
                                        <Typography
                                            variant="bold"
                                            sx={{
                                                fontSize: 18,
                                            }}
                                        >
                                            Contenu et objectif de la formation
                                        </Typography>
                                    </Box>
                                    <div
                                        style={
                                            (traceabilityDetails.patch.find((patchObject) =>
                                                patchObject.path.includes("content")
                                            ) && traceabilityDetails.action.includes(TraceabilityActions.UPDATE)
                                                ? theme.typography.patched
                                                : theme.typography.body1) as CSSProperties
                                        }
                                        className="ql-editor"
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(content.content),
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </StyledCardContent>
                </Card>
            ) : (
                <Card>
                    <StyledCardContent>
                        <Typography align="center">
                            Sélectionnez une traçabilité dont vous souhaitez voir les détails
                        </Typography>
                    </StyledCardContent>
                </Card>
            )}
        </Stack>
    );
}
