// === NPM
import React, { PropsWithChildren } from "react";
import { Box } from "@mui/material";

export default function PowerBiBox({ children }: PropsWithChildren) {
    return (
        (<Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                clipPath: "inset(2px 0px 38px 2px)"
            }}>
            {children}
        </Box>)
    );
}
