// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { TransitionGroup } from "react-transition-group";
import { Add } from "@mui/icons-material";
import { Collapse, Skeleton, Stack } from "@mui/material";
import { KyResponse } from "ky";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { IAmvCreateUpdate, IAmvVersion } from "@/components/VaccinationIahp/Billing/interface";
import { HttpStatus } from "@/interfaces/global";
import BillingService from "@/services/BillingService";
import AmvVersionForm from "./containers/AmvVersionForm";
import AmvVersionTile from "./containers/AmvVersionTile";

interface AmvVersionsDialog {
    amvKey: string;
    title: string;
    onClose: () => void;
}

export default function AmvVersionsDialog({ amvKey, title, onClose }: Readonly<AmvVersionsDialog>) {
    const [versions, setVersions] = useState<IAmvVersion[]>([]);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getAmvVersions();
    }, []);

    const getAmvVersions = async () => {
        setLoading(true);
        const res = await BillingService.getAmvVersions(amvKey);
        if (res.status === HttpStatus.OK) {
            setVersions(await res.json());
        }
        setLoading(false);
    };

    const handleSuccess = async (res: KyResponse, status: HttpStatus, message: string) => {
        if (res.status === status) {
            toast.success(message);
            getAmvVersions();
        }
    };

    const createVersion = async (data: IAmvCreateUpdate) => {
        const res = await BillingService.createAmvVersion(amvKey, data);
        handleSuccess(res, HttpStatus.CREATED, "Vous avez ajouté une nouvelle version");
        setIsAdding(false);
    };

    const updateVersion = async (data: IAmvCreateUpdate, uuid: string) => {
        const res = await BillingService.updateAmvVersion(uuid, data);
        handleSuccess(res, HttpStatus.NO_CONTENT, "Vos modifications ont bien été enregistrées");
    };

    const deleteVersion = async (uuid: string) => {
        const res = await BillingService.deleteAmvVersion(uuid);
        handleSuccess(res, HttpStatus.NO_CONTENT, "La version a bien été supprimée");
    };

    const renderContent = () =>
        loading ? (
            <Skeleton variant="rounded" width="100%" height="150px" sx={{ borderRadius: "30px" }} />
        ) : (
            <Stack
                spacing={2}
                sx={{
                    alignItems: "center",
                }}
            >
                <GenericButton
                    onClick={() => setIsAdding(true)}
                    label="Ajouter une version"
                    startIcon={<Add />}
                    disabled={isAdding}
                />
                <TransitionGroup style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px" }}>
                    {isAdding && (
                        <Collapse>
                            <AmvVersionForm
                                amvVersion={{
                                    uuid: null,
                                    version: versions.length + 1,
                                    startDate: null,
                                    rate: null,
                                    used: false,
                                }}
                                constraints={{
                                    previousDate: versions[0].startDate,
                                    previousRate: versions[0].rate,
                                }}
                                onCancel={() => setIsAdding(false)}
                                onSubmit={createVersion}
                            />
                        </Collapse>
                    )}
                    {versions.map((v, index) => (
                        <Collapse key={v.uuid}>
                            <AmvVersionTile
                                amvVersion={v}
                                constraints={{
                                    previousDate: versions[index + 1]?.startDate,
                                    nextDate: versions[index - 1]?.startDate,
                                    previousRate: versions[index + 1]?.rate,
                                    nextRate: versions[index - 1]?.rate,
                                }}
                                updateVersion={updateVersion}
                                deleteVersion={deleteVersion}
                            />
                        </Collapse>
                    ))}
                </TransitionGroup>
            </Stack>
        );

    return (
        <GenericDialog
            title={`Gestion des versions de ${title}`}
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} displaySubmit={false} closeLabel="Fermer" />}
        />
    );
}
