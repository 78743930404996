// === NPM
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardActions, CardHeader, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import { ReactComponent as Duration } from "@/assets/icons/shared/duration.svg";
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Success } from "@/assets/icons/shared/valid.svg";
import { ReactComponent as Star } from "@/assets/icons/training/catalog/ects.svg";
import { ReactComponent as OvvtOrganization } from "@/assets/icons/training/catalog/organization.svg";
import { ReactComponent as Type } from "@/assets/icons/training/catalog/type.svg";
import { ReactComponent as Register } from "@/assets/icons/training/inscriptions/register.svg";
import { ReactComponent as Cancel } from "@/assets/icons/training/inscriptions/unregister.svg";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import {
    InscriptionStatus,
    IRegistration,
    IRegistrationCreate,
    ISession,
    ISessionShortVeterinary,
    SessionOutletContext,
    SessionStatus,
} from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { colors, statusColor } from "@/resources/CssConstant";
import { getEnumKeyByValue } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import RegisterDialog from "../../../../RegisterDialog";
import ViewSessionDialog from "../../../../ViewSessionDialog";

interface ISessionCardProps {
    session: ISessionShortVeterinary;
    refresh: () => void;
}

export default function SessionCard({ session, refresh }: Readonly<ISessionCardProps>) {
    const { regions } = useOutletContext<SessionOutletContext>();
    const [completeSession, setCompleteSession] = useState<ISession>(null);
    const [openRegisterDialog, setOpenRegisterDialog] = useState<boolean>(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [registration, setRegistration] = useState<IRegistration>(null);

    const getCompleteSession = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSession(session.uuid);
        if (res.status === HttpStatus.OK) {
            setCompleteSession(await res.json());
        }
    };

    const getRegistration = async () => {
        const res = await TrainingService.getRegistration(session.registrationUuid);
        if (res.status === HttpStatus.OK) {
            setRegistration(await res.json());
        }
    };

    const handleRegister = async (data: IRegistrationCreate) => {
        const res = await TrainingService.postRegistration({ ...data, sessionUuid: session.uuid });
        if (res.status === HttpStatus.CREATED) {
            toast.success("Inscription prise en compte avec succès");
            setOpenRegisterDialog(false);
            refresh();
        }
    };

    const updateRegistration = async (data: IRegistrationCreate) => {
        const res = await TrainingService.putRegistration(registration.uuid, data);
        if (res.status === HttpStatus.OK) {
            toast.success("Inscription mise à jour avec succès");
            setRegistration(null);
            refresh();
        }
    };

    const unregister = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            return;
        }
        const res = await TrainingService.deleteRegistration(session.registrationUuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Inscription annulée avec succès");
            setOpenDeleteDialog(false);
            refresh();
        }
    };

    return (<>
        <Card
            variant="outlined"
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <CardHeader
                title={
                    <Typography variant="h5" sx={{
                        color: colors.primaryColor
                    }}>
                        {`${session.internalId} : ${session.healthAccreditationTraining.title}`}
                    </Typography>
                }
                sx={{ m: 2 }}
            />
            {session.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED) && (
                <IconBannerText
                    icon={<Warning />}
                    message="Cette session est clôturée"
                    color={statusColor.warningText}
                    backgroundColor={statusColor.warningBackground}
                />
            )}
            {session.registrationStatus ===
                getEnumKeyByValue(InscriptionStatus, InscriptionStatus.PRE_REGISTERED) && (
                <IconBannerText
                    icon={<Info />}
                    message="Vous êtes préinscrit à la session"
                    color="black"
                    backgroundColor={statusColor.infoBackground}
                />
            )}
            {session.registrationStatus === getEnumKeyByValue(InscriptionStatus, InscriptionStatus.REGISTERED) && (
                <IconBannerText
                    icon={<Success />}
                    message="Vous êtes inscrit à la session"
                    color={statusColor.successText}
                    backgroundColor={statusColor.successBackground}
                />
            )}
            <StyledCardContent sx={{ mx: 2 }}>
                <Stack spacing={1}>
                    <GenericIconText
                        icon={<Star />}
                        text={`${session.healthAccreditationTraining.ectsPoints} ECTS`}
                        iconTooltip="Points ECTS"
                    />
                    <GenericIconText
                        icon={<Calendar />}
                        text={DateTime.fromISO(session.startDate).toLocaleString(DateTime.DATETIME_SHORT)}
                        iconTooltip="Date de formation"
                    />
                    <GenericIconText
                        icon={<OvvtOrganization />}
                        text={`${session.ovvtOrganization.name} - ${regions.find((r) => r.inseeCode === session.ovvtOrganization.regionInseeCode)?.name}`}
                        iconTooltip="Ovvt organisatrice"
                    />
                    <GenericIconText
                        icon={<Type />}
                        text={session.healthAccreditationTraining.type.label}
                        iconTooltip="Type de la formation"
                    />
                    <GenericIconText
                        icon={<Pet />}
                        text={session.healthAccreditationTraining.targetPublic}
                        iconTooltip="Public cible"
                    />
                    <GenericIconText
                        icon={<Duration />}
                        text={`${session.healthAccreditationTraining.duration}h`}
                        iconTooltip="Durée"
                    />
                </Stack>
            </StyledCardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <ViewAction title="Voir le détail" onClick={() => getCompleteSession()} />
                {DateTime.fromISO(session.startDate).startOf("day") > DateTime.now() &&
                    session.registrationStatus !==
                        getEnumKeyByValue(InscriptionStatus, InscriptionStatus.UNREGISTERED) && (
                        <>
                            {session.registrationStatus ===
                                getEnumKeyByValue(InscriptionStatus, InscriptionStatus.PRE_REGISTERED) && (
                                <EditAction title="Modifier" onClick={() => getRegistration()} />
                            )}
                            <IconActionButton
                                icon={<Cancel />}
                                title="Se désinscrire"
                                onClick={() => setOpenDeleteDialog(true)}
                                color={colors.error}
                            />
                        </>
                    )}
                {DateTime.fromISO(session.startDate).startOf("day") > DateTime.now().startOf("day") &&
                    session.registrationStatus ===
                        getEnumKeyByValue(InscriptionStatus, InscriptionStatus.UNREGISTERED) && (
                        <IconActionButton
                            icon={<Register />}
                            title="S'inscrire à la session"
                            onClick={() => setOpenRegisterDialog(true)}
                        />
                    )}
            </CardActions>
        </Card>
        {completeSession && (
            <ViewSessionDialog session={completeSession} onClose={() => setCompleteSession(null)} />
        )}
        {openRegisterDialog && (
            <RegisterDialog
                sessionTitle={session.trainingTitle}
                closed={session.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED)}
                onValid={handleRegister}
                onClose={() => setOpenRegisterDialog(false)}
            />
        )}
        {registration && (
            <RegisterDialog
                registration={registration}
                sessionTitle={session.trainingTitle}
                onValid={updateRegistration}
                onClose={() => setRegistration(null)}
            />
        )}
        {openDeleteDialog &&
            (DateTime.fromISO(session.startDate).startOf("day") <= DateTime.now().startOf("day").plus({ day: 3 }) &&
            session.registrationStatus === getEnumKeyByValue(InscriptionStatus, InscriptionStatus.REGISTERED) ? (
                <GenericDialog
                    title="Désinscription"
                    onClose={() => setOpenDeleteDialog(false)}
                    renderActions={() => (
                        <GenericActionsDialog
                            onClose={() => setOpenDeleteDialog(false)}
                            displaySubmit={false}
                            closeLabel="Fermer"
                        />
                    )}
                    renderContent={() => (
                        <IconBannerText
                            icon={<Warning />}
                            message={`Vous souhaitez vous désinscrire de la session ${session.trainingTitle} à 3 jours de son organisation. Merci de contacter directement l'OVVT responsable afin qu'elle procède à votre désinscription dans Calypso.`}
                            color={statusColor.warningText}
                            backgroundColor={statusColor.warningBackground}
                        />
                    )}
                />
            ) : (
                <GenericConfirmDialog
                    title="Désinscription"
                    message={`Êtes-vous sûr de vouloir vous désinscrire de la session ${session.internalId} - ${session.trainingTitle} ?`}
                    onClose={unregister}
                />
            ))}
    </>);
}
