// === NPM
import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Feather } from "@/assets/icons/form/feather.svg";
import { ReactComponent as Generate } from "@/assets/icons/training/filters/generate.svg";
import { ReactComponent as Presence } from "@/assets/icons/training/filters/presence.svg";
import {
    FilterConfigurations,
    GenericFilters,
    SortingConfiguration,
} from "@/components/generics/filters/GenericFilters";
import { GenericPagination } from "@/components/generics/layout/GenericPagination";
import {
    GenericPresetFilters,
    PresetFilterConfigurations,
} from "@/components/generics/presetFilters/GenericPresetFilters";
import {
    ISessionsToProcessFilters as IAttestationsDdppFilters,
    ISessionCountsDdpp,
    ISessionCountsDdppProcess,
    ISessionDdppProcess,
} from "@/components/HealthAccreditationTraining/interface";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import SessionCard from "./containers/SessionCard";

type IAttestationsDdppSortConfig = Pick<ISessionDdppProcess, "trainingTitle" | "startDate">;

const initialValues: IAttestationsDdppFilters = {
    internalId: "",
    trainingTitle: "",
    startDate: [null, null],
    veterinaryId: "",
    needAttendanceSheetImport: "",
    needAttendanceSheetSign: "",
    needAttestationSign: "",
};

export default function AttestationsDdpp() {
    const [sessions, setSessions] = useState<ISessionDdppProcess[]>([]);
    const [sessionCounts, setSessionCounts] = useState<ISessionCountsDdppProcess>(null);
    const [inputFilters, setInputFilters] = useState<IAttestationsDdppFilters>({
        internalId: "",
        trainingTitle: "",
        startDate: [null, null],
        veterinaryId: "",
        needAttendanceSheetImport: "",
        needAttendanceSheetSign: "",
        needAttestationSign: "",
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sort, setSort] = useState({ field: "startDate", direction: "desc" });

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getSessions();
    }, [pagination, sort]);

    useEffect(() => {
        getSessionCounts();
    }, []);

    const getSessions = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: [`${sort.field},${sort.direction}`],
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getHealthAccreditationTrainingSessionToProcessDdpp(payload);
        if (res.status === HttpStatus.OK) {
            setSessions(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getSessionCounts = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSessionDdppProcessCounts();
        if (res.status === HttpStatus.OK) {
            setSessionCounts(await res.json());
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const sortingConfiguration: SortingConfiguration<IAttestationsDdppSortConfig>[] = [
        {
            label: "Titre de la formation - Alphabétique",
            value: SortDirection.ASC,
            field: "trainingTitle",
        },
        {
            label: "Titre de la formation - Alphabétique inverse",
            value: SortDirection.DESC,
            field: "trainingTitle",
        },
        {
            label: "Date - Croissant",
            value: SortDirection.ASC,
            field: "startDate",
        },
        {
            label: "Date - Décroissant",
            value: SortDirection.DESC,
            field: "startDate",
        },
    ];

    const filterConfigurations: FilterConfigurations<IAttestationsDdppFilters> = {
        internalId: { type: FilterType.INPUT, label: "Identifiant" },
        trainingTitle: { type: FilterType.INPUT, label: "Nom de la formation" },
        startDate: { type: FilterType.DATEPICKER, label: "Date de formation" },
        veterinaryId: { type: FilterType.INPUT, label: "Vétérinaire" },
        needAttendanceSheetImport: {
            type: FilterType.SINGLE_SELECT,
            label: "Feuille d'émargement à importer",
            values: [
                { key: "true", label: "Oui" },
                { key: "false", label: "Non" },
            ],
        },
        needAttendanceSheetSign: {
            type: FilterType.SINGLE_SELECT,
            label: "Feuille d'émargement à signer",
            values: [
                { key: "true", label: "Oui" },
                { key: "false", label: "Non" },
            ],
        },
        needAttestationSign: {
            type: FilterType.SINGLE_SELECT,
            label: "Attestations à signer",
            values: [
                { key: "true", label: "Oui" },
                { key: "false", label: "Non" },
            ],
        },
    };

    const presetFilterConfigurations: PresetFilterConfigurations<ISessionCountsDdpp, IAttestationsDdppFilters> = {
        editAttendanceSheet: {
            icon: <Presence />,
            value: sessionCounts?.importAttendanceSheet,
            title: "Feuilles d'émargement à importer",
            config: {
                ...initialValues,
                needAttendanceSheetImport: "true",
            },
        },
        finishedSessions: {
            icon: <Feather />,
            value: sessionCounts?.signAttendanceSheet,
            title: "Feuilles d'émargement à signer",
            config: {
                ...initialValues,
                needAttendanceSheetSign: "true",
            },
        },
        generateSign: {
            icon: <Generate />,
            value: sessionCounts?.generateSign,
            title: "Attestations à générer et à signer",
            config: {
                ...initialValues,
                needAttestationSign: "true",
            },
        },
    };

    return (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <Typography variant="h4">Mes sessions à traiter</Typography>
            </Box>
            <GenericPresetFilters
                superFilterConfiguration={presetFilterConfigurations}
                inputFilters={inputFilters}
                setInputFilters={setInputFilters}
                clearFilters={() => setInputFilters(initialValues)}
            />
            <GenericFilters
                inputFilters={inputFilters}
                filterConfigurations={filterConfigurations}
                initialValues={{
                    internalId: "",
                    trainingTitle: "",
                    startDate: [null, null],
                    veterinaryId: "",
                    needAttendanceSheetImport: "",
                    needAttendanceSheetSign: "",
                    needAttestationSign: "",
                }}
                setInputFilters={setInputFilters}
                sortingConfigurations={sortingConfiguration}
                sort={sort}
                setSort={setSort}
            />
            {sessions.map((session) => (
                <SessionCard session={session} key={session.uuid} />
            ))}
            <GenericPagination
                page={pagination.page}
                pageSize={pagination.pageSize}
                pageCount={Math.ceil(rowCount / pagination.pageSize)}
                setPage={handlePageChange}
                setPageSize={handlePageSizeChange}
            />
        </Stack>
    );
}
