// === NPM
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import useTimeout from "@/hooks/useTimeout";
import { FilterType } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { colors } from "@/resources/CssConstant";
import { createPayload } from "@/resources/utils";
// === LOCAL
import { BillingReferentialType, IAmv, IChargeable, IVat } from "../../../interface";
import ChargeableTile from "../Tiles/ChargeableTile";
import VatAndAmvTile from "../Tiles/VatAndAmvTile";
import "./CarouselStyle.scss";

interface ISearchFilters {
    name: string[];
    date: string;
}

type BillingCarouselProps =
    | {
          elements: IVat[] | IAmv[];
          type: BillingReferentialType;
          chargeables?: never;
          referentials: IReferential[];
          getBillingReferentials: (payload?: { name?: string; date?: string }) => void;
      }
    | {
          elements?: never;
          chargeables: IChargeable[];
          type: BillingReferentialType;
          referentials: IReferential[];
          getBillingReferentials: (payload?: { name?: string; date?: string }) => void;
      };

export default function BillingCarousel({
    elements,
    chargeables,
    referentials,
    type,
    getBillingReferentials,
}: BillingCarouselProps) {
    const theme = useTheme();
    const [inputFilters, setInputFilters] = useState<ISearchFilters>({
        name: [],
        date: "",
    });
    const isDesktop = theme.breakpoints.up("lg");
    const isMedium = theme.breakpoints.between("md", "lg");
    const isMobile = theme.breakpoints.down("sm");
    const tilesLength = chargeables ? chargeables.length : elements.length;
    const responsive = {
        lg: {
            breakpoint: { max: 4000, min: 1600 },
            items: 3,
            partialVisibilityGutter: 40,
            slidesToSlide: 2,
        },
        md: {
            breakpoint: { max: 1600, min: 1200 },
            items: 2,
            partialVisibilityGutter: 40,
            slidesToSlide: 1,
        },
        sm: {
            breakpoint: { max: 1200, min: 0 },
            items: 1,
            partialVisibilityGutter: 40,
            slidesToSlide: 1,
        },
    };

    useTimeout(() => {
        const payload = { ...createPayload(inputFilters) };
        getBillingReferentials(payload);
    }, [inputFilters]);

    const tilesLengthIsEqualToList = () => {
        if (isDesktop) {
            return tilesLength === responsive.lg.items;
        }
        if (isMedium) {
            return tilesLength === responsive.md.items;
        }
        if (isMobile) {
            return tilesLength === responsive.sm.items;
        }
    };

    const showDots = () => {
        if (isDesktop) {
            return tilesLength > responsive.lg.items;
        }
        if (isMedium) {
            return tilesLength > responsive.md.items;
        }
        if (isMobile) {
            return tilesLength > responsive.sm.items;
        }
    };

    const CustomDot = ({ onClick, active }: any) => {
        return (
            <Button
                variant="outlined"
                onClick={(e) => {
                    onClick();
                    e.preventDefault();
                }}
                sx={{
                    backgroundColor: active ? colors.primaryColor : colors.white,
                    borderRadius: "50%",
                    borderColor: colors.primaryColor,
                    borderWidth: "2px",
                    minWidth: "4px",
                    padding: "4px",
                    margin: "4px",
                }}
            />
        );
    };

    const filterConfigurations: FilterConfigurations<ISearchFilters> = {
        name: { label: "Nom", type: FilterType.SELECT, values: referentials },
        date: { label: "Date", type: FilterType.SINGLE_DATEPICKER, minDate: "2023-01-01" },
    };

    const renderTiles = () => {
        switch (type) {
            case BillingReferentialType.VAT:
                return elements.map((element) => (
                    <VatAndAmvTile
                        isVat
                        key={element.key}
                        element={element}
                        title={referentials.find((item) => item.key === element.key)?.label}
                    />
                ));
            case BillingReferentialType.AMV:
                return elements.map((element) => (
                    <VatAndAmvTile
                        isVat={false}
                        key={element.key}
                        element={element}
                        title={referentials.find((item) => item.key === element.key)?.label}
                    />
                ));
            case BillingReferentialType.CHARGEABLES:
                return chargeables.map((chargeable) => (
                    <ChargeableTile
                        key={chargeable.key}
                        element={chargeable}
                        title={referentials.find((item) => item.key === chargeable.key)?.label}
                    />
                ));
        }
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h5">{type}</Typography>
            <GenericFilters
                inputFilters={inputFilters}
                filterConfigurations={filterConfigurations}
                initialValues={{
                    name: [],
                    date: "",
                }}
                setInputFilters={setInputFilters}
            />
            <Box
                sx={{
                    paddingBottom: "30px",
                    position: "relative",
                }}
            >
                <Carousel
                    responsive={responsive}
                    showDots={showDots()}
                    renderDotsOutside
                    partialVisible
                    customDot={<CustomDot />}
                    itemClass="spacing"
                    containerClass={tilesLengthIsEqualToList() ? "container" : "container2"}
                    draggable={!isDesktop}
                >
                    {renderTiles()}
                </Carousel>
            </Box>
        </Stack>
    );
}
