// === NPM
import { KyResponse } from "ky";
// === LOCAL
import { HttpMethod } from "@/interfaces/global";
import { IUserInfo } from "@/interfaces/user";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class AuthenticationService {
    async login(json: { code: string }): Promise<KyResponse<IUserInfo>> {
        return HttpService.fetch(endpoints.login, {
            method: HttpMethod.POST,
            json,
        });
    }

    async loginPsc(json: { code: string }): Promise<KyResponse<IUserInfo>> {
        return HttpService.fetch(endpoints.loginPsc, {
            method: HttpMethod.POST,
            json,
        });
    }

    async introspection(): Promise<KyResponse<IUserInfo>> {
        return HttpService.fetch(endpoints.introspection, {
            method: HttpMethod.GET,
        });
    }

    async refreshUserInfo(): Promise<KyResponse<IUserInfo>> {
        return HttpService.fetch(endpoints.userInfo, {
            method: HttpMethod.GET,
        });
    }

    async refresh(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.refresh, {
            method: HttpMethod.POST,
        });
    }
}

export default new AuthenticationService();
