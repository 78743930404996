// === NPM
import React, { useContext, useState } from "react";
import { CancelRounded } from "@mui/icons-material";
import { Card, CardContent, Chip, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Amv } from "@/assets/icons/billing/amv.svg";
import { ReactComponent as Vat } from "@/assets/icons/billing/vat.svg";
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Paper } from "@/assets/icons/shared/paper.svg";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { IReferential } from "@/interfaces/referential";
import { colors } from "@/resources/CssConstant";
import { toLocaleDateFormat } from "@/resources/utils";
import { IChargeable } from "../../../interface";
import { AmvReferentialsContext, VatReferentialsContext } from "../../Management";
import ChargeableVersionsDialog from "../Dialogs/ChargeableVersionsDialog/ChargeableVersionsDialog";
import TileTitle from "./containers/TileTitle";
import VersionChip from "./containers/VersionChip";

interface ChargeableTileProps {
    element: IChargeable;
    title: string;
}

export default function ChargeableTile({ element, title }: Readonly<ChargeableTileProps>) {
    const vatReferentials: IReferential[] = useContext(VatReferentialsContext);
    const amvReferentials: IReferential[] = useContext(AmvReferentialsContext);
    const [openManageDialog, setOpenManageDialog] = useState<boolean>(false);

    const renderTextualIndicators = () => (
        <Stack spacing={2}>
            <GenericIconText
                icon={<Vat />}
                iconTooltip="Taux de TVA"
                text={vatReferentials.find((vat) => vat.key === element.vatKey)?.label}
            />
            <GenericIconText
                icon={<Amv />}
                iconTooltip="Référence AMV"
                text={amvReferentials.find((amv) => amv.key === element.amvKey)?.label ?? "Aucune référence AMV"}
            />
            <GenericIconText
                icon={<Paper />}
                iconTooltip="Référence multiplicateur externe"
                text={element.externalMultiplierDescription ?? "Aucune référence multiplicateur externe"}
            />
        </Stack>
    );

    const renderBottomIndicators = () => (
        <Stack
            direction="row"
            sx={{
                justifyContent: "space-around",
                flexGrow: 1,
                alignItems: "end",
            }}
        >
            <Stack
                sx={{
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="bold"
                    sx={{
                        color: colors.primaryColor,
                    }}
                >
                    Multiplicateur AMV
                </Typography>
                <Typography
                    variant="bold"
                    sx={{
                        fontSize: 38,
                        color: colors.primaryColor,
                    }}
                >
                    {element.amvMultiplierFactor ? `x${element.amvMultiplierFactor.toFixed(4)}` : `N/A`}
                </Typography>
            </Stack>
            <Stack
                sx={{
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="bold"
                    sx={{
                        color: colors.primaryColor,
                    }}
                >
                    Multiplicateur Externe
                </Typography>
                <Typography
                    variant="bold"
                    sx={{
                        fontSize: 38,
                        color: colors.primaryColor,
                    }}
                >
                    {element.externalMultiplierFactor ? `x${element.externalMultiplierFactor.toFixed(4)}` : `N/A`}
                </Typography>
            </Stack>
        </Stack>
    );

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 1,
                    paddingX: 2,
                    width: "100%",
                    height: "100%",
                }}
            >
                <TileTitle title={title} setOpenManageDialog={setOpenManageDialog} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Stack spacing={2} height="100%">
                        <Typography sx={{ fontStyle: "italic" }}>{element.description}</Typography>
                        <Stack direction="row" spacing={2}>
                            <VersionChip versionNumber={element.version} />
                            {!element.active && (
                                <Chip
                                    icon={<CancelRounded />}
                                    label="Inactive"
                                    sx={{
                                        border: `3px solid #616161`,
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                />
                            )}
                        </Stack>
                        <GenericIconText
                            icon={<Calendar />}
                            iconTooltip="Dates de validité"
                            text={
                                element.endDate
                                    ? `Du ${toLocaleDateFormat(element.startDate) ?? "01/01/2023"} au ${toLocaleDateFormat(element.endDate)}`
                                    : `Débute le ${toLocaleDateFormat(element.startDate) ?? "01/01/2023"}`
                            }
                        />
                        {element.active && renderTextualIndicators()}
                        {element.active && renderBottomIndicators()}
                    </Stack>
                </CardContent>
            </Card>
            {openManageDialog && (
                <ChargeableVersionsDialog
                    chargeableKey={element.key}
                    title={title}
                    onClose={() => setOpenManageDialog(false)}
                />
            )}
        </>
    );
}
