// === NPM
import React, { Dispatch, SetStateAction } from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, MobileStepper, Stack, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { colors } from "@/resources/CssConstant";

interface FormStepperProps {
    activeStep: number;
    handleNextStep: () => void;
    handlePreviousStep: () => void;
    steps: string[];
    children: JSX.Element;
    setOpenConfirmCancelDialog: Dispatch<SetStateAction<boolean>>;
}

export default function FormStepper({
    activeStep,
    steps,
    handleNextStep,
    handlePreviousStep,
    children,
    setOpenConfirmCancelDialog,
}: Readonly<FormStepperProps>) {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const dispayValidateButton = activeStep === steps.length - 1;
    const direction = theme.direction === "rtl";

    const renderCancel = () => (
        <SecondaryButton onClick={() => setOpenConfirmCancelDialog(true)} variant="outlined">
            Annuler
        </SecondaryButton>
    );

    const renderMobile = () => (
        <Stack spacing={2}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                {renderCancel()}
            </Box>
            <MobileStepper
                sx={{ width: "100%", mt: 0.5, borderRadius: 10 }}
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                nextButton={
                    dispayValidateButton ? (
                        <Button color="primary" size="small" onClick={handleNextStep}>
                            Vérifier ma saisie
                        </Button>
                    ) : (
                        <Button color="primary" size="small" onClick={handleNextStep}>
                            Suivant
                            {direction ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    )
                }
                backButton={
                    <Button color="primary" size="small" onClick={handlePreviousStep}>
                        {direction ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                        Précédent
                    </Button>
                }
            />
        </Stack>
    );

    return (
        (<Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            {steps.length > 1 ? (
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            ) : (
                <Typography variant="h6">{steps[0]}</Typography>
            )}
            {!md && (
                <Box>
                    <Typography variant="h6">{`${activeStep + 1} - ${steps[activeStep]}`}</Typography>
                </Box>
            )}
            {children}
            {!md && renderMobile()}
            {md && (
                <Box
                    sx={{
                        mt: 2,
                        justifyContent: "space-between",
                        width: "100%",
                        display: "flex"
                    }}>
                    <LoadingButton sx={{ background: colors.white }} onClick={handlePreviousStep} variant="outlined">
                        Précédent
                    </LoadingButton>
                    <Box sx={{
                        display: "flex"
                    }}>{renderCancel()}</Box>
                    <Box>
                        {dispayValidateButton ? (
                            <GenericButton label="Vérifier ma saisie" onClick={handleNextStep} />
                        ) : (
                            <GenericButton label="Suivant" onClick={handleNextStep} />
                        )}
                    </Box>
                </Box>
            )}
        </Stack>)
    );
}
