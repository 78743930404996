// === NPM
import React from "react";
import { LoadingButton } from "@mui/lab";
import { Card, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === LOCAL
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { IWorkshopShort } from "@/interfaces/farm";

interface ConflictDialogProps {
    onClose: () => void;
    workshops: IWorkshopShort[];
    onConfirm: () => void;
}

export default function ConflictDialog({ onClose, workshops, onConfirm }: Readonly<ConflictDialogProps>) {
    const { loadingRequest } = useProvideGlobal();

    const renderWorkshopText = () => {
        if (workshops.length > 1) {
            return (
                <Typography>
                    Les ateliers
                    <Typography variant="bold"> {workshops.map((w) => w.id).join(", ")}</Typography> sont déjà en
                    relation avec un DPE.
                </Typography>
            );
        }
        return (
            <Typography>
                L'atelier
                <Typography variant="bold"> {workshops.map((w) => w.id).join(", ")}</Typography> est déjà en relation
                avec un DPE.
            </Typography>
        );
    };
    return (
        <Dialog maxWidth="md" open>
            <DialogTitle>Erreur d'affectation</DialogTitle>
            <DialogContent dividers>
                <Card>
                    <StyledCardContent>
                        <Stack
                            spacing={2}
                            sx={{
                                p: 2,
                            }}
                        >
                            {renderWorkshopText()}

                            <Typography>
                                Merci de vérifier qu'un autre DPE de votre société d’exercice n’est pas concerné.
                            </Typography>
                            <Typography>
                                Dans le cas où vous êtes sûr des informations saisies, souhaitez-vous mettre en alerte
                                cette relation afin que le référent IAHP traite la demande ?{" "}
                            </Typography>
                        </Stack>
                    </StyledCardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        justifyContent: "center",
                    }}
                >
                    <Grid>
                        <SecondaryButton variant="outlined" onClick={onClose} disabled={loadingRequest}>
                            Retour au formulaire
                        </SecondaryButton>
                    </Grid>
                    <Grid>
                        <LoadingButton onClick={onConfirm} variant="contained" loading={loadingRequest}>
                            Mettre en alerte
                        </LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
