// === NPM
import React from "react";
import { Add } from "@mui/icons-material";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";

export default function CategoryAndTagSkeleton() {
    const renderCategorySkeleton = () => (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <Box
                sx={{
                    justifyContent: "space-between",
                    display: "flex"
                }}>
                <Typography variant="h4">Gestion des catégories</Typography>
                <GenericButton label="Ajouter une catégorie" startIcon={<Add />} disabled onClick={undefined} />
            </Box>
            <Stack spacing={2}>
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
                <Skeleton variant="rounded" height={60} />
            </Stack>
        </Stack>
    );

    const renderTagSkeleton = () => (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <Box
                sx={{
                    justifyContent: "space-between",
                    display: "flex"
                }}>
                <Typography variant="h4">Gestion des tags</Typography>
                <GenericButton label="Ajouter un tag" startIcon={<Add />} disabled onClick={undefined} />
            </Box>
            <Skeleton variant="rounded" sx={{ borderRadius: 10 }} height={150} />
        </Stack>
    );
    return (
        (<Stack
            spacing={8}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            {renderCategorySkeleton()}
            {renderTagSkeleton()}
        </Stack>)
    );
}
