// === NPM
import React from "react";
// === LOCAL
import { UserType } from "@/interfaces/user";
import { useAuth } from "@/routers/useAuth";
import InscriptionsDdpp from "./containers/InscriptionsDdpp/InscriptionsDdpp";
import InscriptionsOvvt from "./containers/InscriptionsOvvt/InscriptionsOvvt";

export function Inscriptions() {
    const auth = useAuth();

    switch (auth.userInfo.type) {
        case UserType.OVVT:
            return <InscriptionsOvvt />;
        case UserType.DDPP:
            return <InscriptionsDdpp />;
    }
}
