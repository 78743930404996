// === NPM
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Card, Checkbox, Chip, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { z } from "zod";
import { ReactComponent as Check } from "@/assets/icons/farm/check.svg";
import { ReactComponent as Close } from "@/assets/icons/farm/close-square.svg";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { defaultTextLimit } from "@/resources/AppConstant";
import { colors, statusColor } from "@/resources/CssConstant";
import { FORM_TEXT } from "@/resources/FormUtils";
import { IVaccinationSiteWorkshopInfo } from "../../interface";

interface VaccinationSiteDeleteDialogProps {
    vaccinationSiteId: number;
    vaccinationSiteWorkshopsInfo: IVaccinationSiteWorkshopInfo[];
    onClose: () => void;
    onValid: (data: { reason: string }) => void;
    hasCertificate: boolean;
}

export default function VaccinationSiteDeleteDialog({
    vaccinationSiteId,
    vaccinationSiteWorkshopsInfo: vaccinationSiteWorkshopsInfo,
    onClose,
    onValid,
    hasCertificate,
}: Readonly<VaccinationSiteDeleteDialogProps>) {
    const [confirm, setConfirm] = useState<boolean>(false);

    const formSchema = z.object({
        reason: z
            .string()
            .max(defaultTextLimit, "Le motif ne doit pas dépasser 2000 caractères")
            .nullable()
            .optional()
            .refine((value) => !hasCertificate || (hasCertificate && value?.trim().length > 0), {
                message: FORM_TEXT.required,
            }),
    });

    type FormSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        register,
        watch,
        handleSubmit,
    } = useForm<FormSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            reason: null,
        },
    });

    const reason = watch("reason");

    const renderWorkshopInformations = (workshopsInfos: IVaccinationSiteWorkshopInfo) => (
        <GenericAccordion
            title={`Atelier ${workshopsInfos.workshopId}`}
            key={workshopsInfos.workshopId}
            defaultExpanded
        >
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography variant="bold">Audit et contrôle</Typography>
                    <Stack direction="row" spacing={10}>
                        <GenericIconText
                            text={workshopsInfos.hasAudit ? "Audit réalisé" : "Audit non réalisé"}
                            icon={workshopsInfos.hasAudit ? <Check /> : <Close />}
                            iconTooltip="Réalisation de l'audit"
                        />
                        <GenericIconText
                            text={
                                workshopsInfos.nonCompliant
                                    ? "Contrôle des non-conformités majeures réalisé"
                                    : "Contrôle des non-conformités majeures non réalisé"
                            }
                            icon={workshopsInfos.nonCompliant ? <Check /> : <Close />}
                            iconTooltip="Réalisation du contrôle des non-conformités majeures"
                        />
                    </Stack>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="bold">Interventions de vaccinations</Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {workshopsInfos.interventionIds.map((interventionId) => (
                            <Chip
                                key={interventionId}
                                label={interventionId}
                                sx={{
                                    "border": `3px solid ${colors.primaryColor}`,
                                    "color": `${colors.primaryColor}`,
                                    "backgroundColor": colors.white,
                                    "& .MuiChip-label": {
                                        color: colors.primaryColor,
                                        fontSize: 16,
                                        fontWeight: 600,
                                    },
                                }}
                            />
                        ))}
                    </Box>
                </Stack>
            </Stack>
        </GenericAccordion>
    );

    const renderDeleteConfirmation = () => (
        <Card sx={{ p: 2 }}>
            <StyledCardContent>
                <Stack spacing={2}>
                    <IconBannerText
                        message={
                            "Attention \n Vous vous apprêtez à supprimer un chantier de vaccination. En supprimant le chantier, toutes les informations associées à ce chantier (interventions de vaccination, audit, contrôle des non-conformités majeures) seront également supprimées. Cette action est définitive."
                        }
                        color={statusColor.warningText}
                        backgroundColor={statusColor.warningBackground}
                        icon={<Warning />}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={confirm} onChange={(e) => setConfirm(e.target.checked)} />}
                        label="Je souhaite supprimer le chantier ainsi que toutes les informations associées"
                    />
                    {hasCertificate && (
                        <TextField
                            label="Motif de correction"
                            {...register("reason")}
                            rows={3}
                            multiline
                            required
                            fullWidth
                            error={!!errors.reason}
                            helperText={errors?.reason?.message ?? `${reason?.length ?? 0}/${defaultTextLimit}`}
                            slotProps={{
                                htmlInput: {
                                    maxLength: { defaultTextLimit },
                                }
                            }}
                        />
                    )}
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Stack spacing={2} sx={{
            width: "100%"
        }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Informations des ateliers
            </Typography>
            {vaccinationSiteWorkshopsInfo.map((workshopsInfos) => renderWorkshopInformations(workshopsInfos))}
            <Typography variant="h5">Confirmation de suppression</Typography>
            {renderDeleteConfirmation()}
        </Stack>
    );

    return (
        <GenericDialog
            maxWidth="lg"
            onClose={onClose}
            title={`Suppression du chantier n°${vaccinationSiteId}`}
            renderContent={renderContent}
            renderActions={() => (
                <GenericActionsDialog
                    validLabel="Confirmer"
                    onClose={onClose}
                    onSubmit={handleSubmit(onValid)}
                    disableSubmit={(!hasCertificate && !confirm) || (hasCertificate && (!reason || !confirm))}
                />
            )}
        />
    );
}
