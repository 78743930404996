// === NPM
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Card, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";
// === LOCAL
import Calendar from "@/assets/icons/shared/calendar.svg";
import Duration from "@/assets/icons/shared/duration.svg";
import Location from "@/assets/icons/shared/location.svg";
import Pet from "@/assets/icons/shared/pet.svg";
import Star from "@/assets/icons/shared/star.svg";
import CalendarImage from "@/assets/icons/training/calendrier.svg";
import ViewAction from "@/components/generics/actions/ViewAction";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import {
    INextSessionScheduled,
    ISession,
    SessionOutletContext,
} from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { colors, drawerColors } from "@/resources/CssConstant";
import TrainingService from "@/services/TrainingService";
import ViewSessionDialog from "./ViewSessionDialog";

export default function NextSessionCard() {
    const [inscription, setInscription] = useState<INextSessionScheduled>(null);
    const [session, setSession] = useState<ISession>(null);
    const { species } = useOutletContext<SessionOutletContext>();

    useEffect(() => {
        getInscription();
    }, []);

    const getInscription = async () => {
        const res = await TrainingService.getNextSessionScheduled();
        if (res.status === HttpStatus.OK) {
            const data = await res.text();
            if (data) {
                setInscription(JSON.parse(data));
            }
        }
    };

    const getSession = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSession(inscription.sessionUuid);
        if (res.status === HttpStatus.OK) {
            setSession(await res.json());
        }
    };

    const formatSpecies = (inscription: INextSessionScheduled) => {
        return inscription.speciesUuids.map((uuid) => species.find((s) => s.uuid === uuid).label).join(", ");
    };

    return (
        <>
            <Card>
                <StyledCardContent>
                    {inscription ? (
                        <Stack
                            sx={{
                                p: 2,
                            }}
                        >
                            <Stack
                                direction="row"
                                sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography
                                    variant="bold"
                                    sx={{
                                        fontSize: 20,
                                        color: drawerColors.text,
                                    }}
                                >
                                    Ma prochaine formation planifiée
                                </Typography>
                                <ViewAction title="Voir le détail" onClick={() => getSession()} />
                            </Stack>
                            <Grid
                                container
                                rowSpacing={4}
                                columnSpacing={2}
                                sx={{
                                    m: 1,
                                }}
                            >
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 4,
                                    }}
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            alignItems: "center",
                                        }}
                                    >
                                        <CalendarImage />
                                        <Typography
                                            variant="bold"
                                            sx={{
                                                color: colors.primaryColor,
                                            }}
                                        >
                                            {inscription.trainingTitle}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 4,
                                    }}
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                    }}
                                >
                                    <Stack spacing={1}>
                                        <GenericIconText
                                            icon={<Calendar />}
                                            iconTooltip="Date de formation"
                                            text={DateTime.fromISO(inscription?.startDate).toLocaleString(
                                                DateTime.DATETIME_MED
                                            )}
                                        />
                                        <GenericIconText
                                            icon={<Location />}
                                            iconTooltip="Adresse"
                                            text={inscription?.address}
                                        />
                                        <GenericIconText
                                            icon={<Duration />}
                                            iconTooltip="Durée"
                                            text={`${inscription?.duration}h`}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 12,
                                        md: 4,
                                    }}
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                    }}
                                >
                                    <Stack spacing={1}>
                                        <GenericIconText
                                            icon={<Star />}
                                            iconTooltip="Points ECTS"
                                            text={inscription?.ectsPoints}
                                        />
                                        <GenericIconText
                                            icon={<Pet />}
                                            iconTooltip="Espèces traitées"
                                            text={formatSpecies(inscription)}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    ) : (
                        <Typography>Aucune formation à venir</Typography>
                    )}
                </StyledCardContent>
            </Card>
            {session && <ViewSessionDialog session={session} onClose={() => setSession(null)} />}
        </>
    );
}
