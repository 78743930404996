// === NPM
import React from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Stack, Switch, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import SecondaryButton from "@/components/styled/SecondaryButton";
import {
    INonCompliance,
    IVaccinationSiteShort,
    IWorkshopNonCompliance,
    NonComplianceAction,
} from "@/interfaces/vaccination";
import { defaultTextLimit } from "@/resources/AppConstant";
import { FORM_TEXT, PATTERN, stringRequired } from "@/resources/FormUtils";
import { convertEnumToKeyLabelObject } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import { VaccinationSiteOutletContext } from "../../../interface";

interface NonComplianceDialogProps {
    onClose: () => void;
    nonCompliance?: INonCompliance;
    workshopIds: string[];
    vaccinationSite: IVaccinationSiteShort;
    onValid: (data: INonCompliance) => void;
}

export default function NonComplianceDialog({
    onClose,
    nonCompliance,
    workshopIds,
    vaccinationSite,
    onValid,
}: Readonly<NonComplianceDialogProps>) {
    const { userInfo } = useAuth();

    const workshopSchema = z
        .object({
            id: stringRequired(),
            inspectionPerformed: z.boolean(),
            actionPerformedType: z
                .enum(
                    [
                        "REVACCINATION",
                        "PHARMACOLOGICAL_VIGILANCE_DECLARATION",
                        "REINFORCEMENT_OF_MONITORING",
                        "INFORMATION_TO_DDPP",
                        "OTHER",
                    ],
                    {
                        invalid_type_error: FORM_TEXT.required,
                    }
                )
                .nullable(),
            comment: z
                .string()
                .max(2000, "Le motif de non-conformité ne peut pas excéder 2000 caractères")
                .optional()
                .nullable()
                .refine((value) => !(value && value.trim().length === 0), FORM_TEXT.emptyString),
        })
        .superRefine((object, ctx) => {
            if (object.inspectionPerformed) {
                if (!object.actionPerformedType) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["actionPerformedType"],
                    });
                }
                if (object.actionPerformedType && object.actionPerformedType === "OTHER" && !object.comment) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: FORM_TEXT.required,
                        path: ["comment"],
                    });
                }
            }
        });

    const nonComplianceSchema = z.object({
        date: stringRequired()
            .refine((value) => DateTime.fromISO(value) >= DateTime.fromISO(vaccinationSite.date), {
                message: "La date du contrôle ne peut pas précéder la date du chantier",
            })
            .refine((value) => DateTime.fromISO(value) <= DateTime.now(), {
                message: "La date du contrôle ne peut pas être dans le futur",
            }),
        distance: z
            .number({
                required_error: FORM_TEXT.required,
                invalid_type_error: "Le nombre de kilomètres parcourus doit être un nombre entier positif",
            })
            .int("Le nombre de kilomètres parcourus doit être un nombre entier positif")
            .nonnegative("Le nombre de kilomètres parcourus doit être un nombre entier positif"),
        plateNumber: stringRequired()
            .regex(new RegExp(PATTERN.plateNumber), FORM_TEXT.plateNumber)
            .transform((v) => v.toUpperCase()),
        horsePower: stringRequired(),
        workshops: z.array(workshopSchema),
    });

    type NonComplianceSchema = z.infer<typeof nonComplianceSchema>;

    const {
        formState: { errors },
        handleSubmit,
        watch,
        register,
        control,
        setValue,
    } = useForm<NonComplianceSchema>({
        resolver: zodResolver(nonComplianceSchema),
        defaultValues: nonCompliance ?? {
            date: "",
            distance: null,
            plateNumber: userInfo.vehicle?.plateNumber ?? null,
            horsePower: userInfo.vehicle?.horsePower ?? "",
            workshops: workshopIds.map((workshop) => ({
                id: workshop,
                inspectionPerformed: false,
                actionPerformedType: null,
                comment: null,
            })),
        },
    });

    const { fields } = useFieldArray({
        control,
        name: "workshops",
        keyName: "uuid",
    });

    const workshops = watch("workshops");

    const { horsePower } = useOutletContext<VaccinationSiteOutletContext>();

    const renderGeneralInfo = () => (
        <GenericAccordion title="Informations générales" defaultExpanded>
            <Stack spacing={1} direction="row">
                <Controller
                    name="date"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <GenericDatePicker
                            value={value}
                            name="date"
                            label="Date du contrôle"
                            onChange={onChange}
                            error={!!error}
                            required
                            helperText={error?.message}
                            minDate={DateTime.fromISO(vaccinationSite.date)}
                            maxDate={DateTime.now()}
                        />
                    )}
                />
                <TextField
                    {...register("distance", {
                        valueAsNumber: true,
                    })}
                    label="Kilomètres parcourus (aller et retour)"
                    error={!!errors.distance}
                    helperText={errors.distance?.message}
                    required
                    fullWidth
                />
                <TextField
                    {...register("plateNumber")}
                    label="Immatriculation"
                    error={!!errors.plateNumber}
                    helperText={errors.plateNumber?.message}
                    required
                    fullWidth
                    slotProps={{
                        htmlInput: { style: { textTransform: "uppercase" } },
                    }}
                />
                <Controller
                    name="horsePower"
                    control={control}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <GenericSelect
                            value={value}
                            label="Chevaux fiscaux"
                            onChange={onChange}
                            error={!!error}
                            required
                            helperText={error?.message}
                            options={horsePower}
                            optionsValue="key"
                        />
                    )}
                />
            </Stack>
        </GenericAccordion>
    );

    const renderNonComplianceWorkshop = (workshop: IWorkshopNonCompliance, index: number) => (
        <GenericAccordion title={`Atelier ${workshopIds[index]}`} key={workshop.id} defaultExpanded>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <Typography variant="h5">
                        Réalisation du contrôle{" "}
                        <Typography component="span" variant="h5" color="error">
                            *
                        </Typography>
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        <Typography variant={!workshop.inspectionPerformed ? "bold" : "body1"}>
                            Contrôle non réalisé
                        </Typography>
                        <Controller
                            name={`workshops.${index}.inspectionPerformed`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Switch
                                    checked={value}
                                    onChange={(e) => {
                                        onChange(e.target.checked);
                                        setValue(`workshops.${index}.actionPerformedType`, null);
                                        setValue(`workshops.${index}.comment`, null);
                                    }}
                                />
                            )}
                        />

                        <Typography variant={workshop.inspectionPerformed ? "bold" : "body1"}>
                            Contrôle réalisé
                        </Typography>
                    </Stack>
                </Grid>
                {workshops[index]?.inspectionPerformed && (
                    <>
                        <Grid
                            sx={{ pl: 3 }}
                            size={{
                                xs: 12,
                                md: 6,
                            }}
                        >
                            <Controller
                                name={`workshops.${index}.actionPerformedType`}
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericSelect
                                        onChange={onChange}
                                        options={convertEnumToKeyLabelObject(NonComplianceAction)}
                                        label="Actions mises en œuvre"
                                        optionsValue="key"
                                        value={value}
                                        error={!!error}
                                        required
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={12}>
                            <TextField
                                label="Commentaire"
                                {...register(`workshops.${index}.comment`)}
                                rows={3}
                                multiline
                                placeholder="Commentaire relatif au contrôle de non-conformité majeure"
                                required={workshops[index]?.actionPerformedType === "OTHER"}
                                fullWidth
                                error={!!errors.workshops?.[index]?.comment}
                                helperText={
                                    errors.workshops?.[index]?.comment?.message ??
                                    `${workshops?.[index]?.comment?.length ?? 0}/${defaultTextLimit}`
                                }
                                slotProps={{
                                    htmlInput: {
                                        maxLength: { defaultTextLimit },
                                    },
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </GenericAccordion>
    );

    const renderContent = () => (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
            }}
        >
            {renderGeneralInfo()}
            {fields.map((workshop, index) => renderNonComplianceWorkshop(workshop as IWorkshopNonCompliance, index))}
        </Stack>
    );

    const displayActionButton = () => (
        <>
            <SecondaryButton onClick={onClose} variant="outlined">
                Fermer
            </SecondaryButton>
            <Button onClick={handleSubmit(onValid)} variant="contained" color="primary">
                Enregistrer
            </Button>
        </>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Contrôle de non-conformité majeure du chantier n°${vaccinationSite.id}`}
            onClose={onClose}
            renderContent={renderContent}
            renderActions={displayActionButton}
        />
    );
}
