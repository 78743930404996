// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { getEnumKeyByValue } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import NotificationService from "@/services/NotificationService";
import { SelectedNotificationProvider } from "./containers/useSelectedNotification";
import { AllowedTargetType, IAllowedTarget } from "./interface";

export default function Notifications() {
    const auth = useAuth();
    const isAnmvNotif = auth.userInfo.situation === UserSituation.ANMV_NOTIF;
    const specificTypes = isAnmvNotif
        ? [
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL)],
              },
          ]
        : [
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.ALL)],
              },
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.TRAINING_ORGANIZATION),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.TRAINING_ORGANIZATION)],
              },
              {
                  type: getEnumKeyByValue(AllowedTargetType, AllowedTargetType.VIMS),
                  situations: [getEnumKeyByValue(AllowedTargetType, AllowedTargetType.VIMS)],
              },
          ];

    const [allowedTargets, setAllowedTargets] = useState<IAllowedTarget[]>([]);

    useEffect(() => {
        getUserTypes();
    }, []);

    const getUserTypes = async () => {
        const res = await NotificationService.getAllowedTargets();
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            const allUsers = [...data.filter((target) => target.situations.length > 0), ...specificTypes];
            setAllowedTargets(allUsers);
        }
    };

    return (
        (<Stack
            sx={{
                height: "100%",
                width: "100%",
                px: 2
            }}>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.admin.notifications.gestion(),
                        label: "Gestion des notifications manuelles",
                    },
                    {
                        url: routerLinks.admin.notifications.form(),
                        label: "Saisie d'une notification",
                    },
                    {
                        url: routerLinks.admin.notifications.autoNotifConfig(),
                        label: "Gestion des notifications automatiques",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                    },
                    {
                        url: routerLinks.admin.notifications.automatic(),
                        label: "Suivi des notifications automatiques",
                        permissions: [UserSituation.ADMIN_CALYPSO],
                    },
                ]}
            />
            <PageContent>
                <SelectedNotificationProvider>
                    <Outlet context={{ allowedTargets }} />
                </SelectedNotificationProvider>
            </PageContent>
        </Stack>)
    );
}
