// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, FormControl, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Home } from "@/assets/icons/shared/home.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Workshop } from "@/assets/icons/shared/workshop.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { IWorkshopRelation } from "@/interfaces/farm";
import { IReferential } from "@/interfaces/referential";
import { UserType } from "@/interfaces/user";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { toLocaleDateFormat } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import { IClosureRelation } from "../../../interface";

interface CloseRelationDialogProps {
    onClose: () => void;
    onConfirm: (data: IClosureRelation) => void;
    relation: IWorkshopRelation;
    closureReasons: IReferential[];
}

enum closingReasonsKeys {
    CALYPSO_ADMIN = "CLOSED_BY_CALYPSO_ADMIN",
    IAHP_ADMIN = "CLOSED_BY_IAHP_ADMIN",
    VETERINARY = "CLOSED_BY_VETERINARY",
}

export default function CloseRelationDialog({
    onClose,
    onConfirm,
    relation,
    closureReasons,
}: Readonly<CloseRelationDialogProps>) {
    const auth = useAuth();

    const formSchema = z.object({
        date: stringRequired().refine(
            (value) =>
                (relation.openingDate ? DateTime.fromISO(value) >= DateTime.fromISO(relation.openingDate) : true) &&
                DateTime.fromISO(value) <= DateTime.now(),
            {
                message: relation.openingDate
                    ? "La date de fermeture de la relation ne peut pas être avant la date d'ouverture ni dans le futur"
                    : "La date de fermeture de la relation ne peut pas être dans le futur",
            }
        ),
        reason: stringRequired(),
        comment: z
            .string()
            .max(2000, { message: "Ce champ doit faire moins de 2000 caractères" })
            .refine((value) => !(value.trim().length === 0 && value.length > 0), FORM_TEXT.emptyString)
            .optional(),
    });

    type ValidationSchema = z.infer<typeof formSchema>;

    const initReason = () => {
        if (auth.userInfo.type === UserType.ADMIN_CALYPSO) {
            return closingReasonsKeys.CALYPSO_ADMIN;
        } else if (auth.userInfo.type === UserType.VETERINARY) {
            return closingReasonsKeys.VETERINARY;
        } else {
            return closingReasonsKeys.IAHP_ADMIN;
        }
    };

    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            date: "",
            reason: initReason(),
            comment: "",
        },
    });

    const renderContent = () => (
        <Stack spacing={2}>
            <Card>
                <StyledCardContent>
                    <Stack spacing={3} sx={{
                        p: 2
                    }}>
                        <Typography variant="h6" sx={{
                            fontWeight: 700
                        }}>
                            Informations de la relation
                        </Typography>
                        <Stack spacing={1}>
                            <Typography variant="bold">Établissement concerné</Typography>
                            <GenericIconText
                                icon={<Home />}
                                text={relation.farmName}
                                iconTooltip="Nom de l'établissement"
                            />
                            <GenericIconText icon={<Location />} text={relation.workshopCity} iconTooltip="Commune" />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography variant="bold">Atelier</Typography>
                            <GenericIconText
                                icon={<Workshop />}
                                text={`Identifiant : ${relation.workshopId}`}
                                iconTooltip="Identifiant de l'atelier"
                            />
                            <GenericIconText
                                icon={<Home />}
                                text={`${relation.dpeId} - ${relation.dpeName}`}
                                iconTooltip="Informations du DPE"
                            />
                            <GenericIconText
                                icon={<Calendar />}
                                text={toLocaleDateFormat(relation.openingDate)}
                                iconTooltip="Date d'ouverture"
                            />
                        </Stack>
                    </Stack>
                </StyledCardContent>
            </Card>
            <Card>
                <StyledCardContent>
                    <Stack spacing={3} sx={{
                        p: 2
                    }}>
                        <Typography variant="h6" sx={{
                            fontWeight: 700
                        }}>
                            Informations de clôture
                        </Typography>
                        <FormControl fullWidth required error={!!errors.date}>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericDatePicker
                                        value={value}
                                        label="Date de clôture de la relation VS"
                                        name="date"
                                        onChange={onChange}
                                        minDate={relation.openingDate ? DateTime.fromISO(relation.openingDate) : null}
                                        maxDate={DateTime.now()}
                                        error={!!error}
                                        helperText={error?.message}
                                        required
                                    />
                                )}
                            />
                        </FormControl>
                        <FormControl fullWidth required error={!!errors.reason}>
                            <Controller
                                name="reason"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericSelect
                                        value={value}
                                        onChange={onChange}
                                        label="Motif de clôture"
                                        required
                                        optionsValue="key"
                                        options={closureReasons}
                                        error={!!error}
                                        helperText={error?.message}
                                        disabled
                                    />
                                )}
                            />
                        </FormControl>
                        <Controller
                            name="comment"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <GenericComment
                                    value={value}
                                    label="Commentaire"
                                    onChange={onChange}
                                    helperText={error?.message}
                                    rows={5}
                                    placeholder="Commentaire sur les informations de clôture"
                                />
                            )}
                        />
                    </Stack>
                </StyledCardContent>
            </Card>
        </Stack>
    );

    return (
        <GenericDialog
            title="Clôturer la relation"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onConfirm)} />}
        />
    );
}
