// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Card, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
// === LOCAL
import { ReactComponent as Check } from "@/assets/icons/farm/check.svg";
import { ReactComponent as Close } from "@/assets/icons/farm/close-square.svg";
import { ReactComponent as Comment } from "@/assets/icons/shared/comment.svg";
import { ReactComponent as Search } from "@/assets/icons/shared/search.svg";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { INonCompliance, IWorkshopNonCompliance, NonComplianceAction } from "@/interfaces/vaccination";
import { toLocaleDateFormat } from "@/resources/utils";
import { VaccinationSiteOutletContext } from "../../../interface";

interface ViewNonComplianceDialogProps {
    onClose: () => void;
    nonCompliance: INonCompliance;
}

export default function ViewNonComplianceDialog({ onClose, nonCompliance }: Readonly<ViewNonComplianceDialogProps>) {
    const { horsePower } = useOutletContext<VaccinationSiteOutletContext>();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up("md"));

    const renderNonComplianceInfo = () => (
        <Stack spacing={1} sx={{
            p: 2
        }}>
            <Typography variant="bold" sx={{
                fontSize: 18
            }}>
                Informations générales
            </Typography>
            <Stack direction={md ? "row" : "column"} sx={{
                justifyContent: "space-between"
            }}>
                <GenericTitleValueText title="Date du contrôle" value={toLocaleDateFormat(nonCompliance.date)} />
                <GenericTitleValueText title="Kilomètres parcourus" value={nonCompliance.distance} />
                <GenericTitleValueText title="Immatriculation" value={nonCompliance.plateNumber} />
                <GenericTitleValueText
                    title="Chevaux fiscaux"
                    value={horsePower.find((h) => h.key === nonCompliance.horsePower)?.label}
                />
            </Stack>
        </Stack>
    );

    const renderWorkshopNonCompliance = (workshop: IWorkshopNonCompliance) => (
        <Box sx={{ gridColumn: { xs: "span 2", md: "span 1" } }} key={workshop.id}>
            <Card sx={{ height: "100%", p: 2 }}>
                <StyledCardContent>
                    <Stack spacing={1}>
                        <Typography variant="bold">Atelier {workshop.id} </Typography>

                        <GenericIconText
                            text={workshop.inspectionPerformed ? "Contrôle réalisé" : "Contrôle non réalisé"}
                            icon={workshop.inspectionPerformed ? <Check /> : <Close />}
                            iconTooltip="Réalisation du contrôle"
                        />
                        {workshop.inspectionPerformed && (
                            <GenericIconText
                                text={NonComplianceAction[workshop.actionPerformedType]}
                                icon={<Search />}
                                iconTooltip="Actions mises en œuvre"
                            />
                        )}
                        {workshop.comment && (
                            <GenericIconText text={workshop.comment} icon={<Comment />} iconTooltip="Commentaire" />
                        )}
                    </Stack>
                </StyledCardContent>
            </Card>
        </Box>
    );

    const renderContent = () => (
        <Stack spacing={2} sx={{
            width: "100%"
        }}>
            <Card>
                <StyledCardContent>{renderNonComplianceInfo()}</StyledCardContent>
            </Card>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gridAutoRows: "1fr",
                    gap: 2
                }}>
                {nonCompliance.workshops.map((workshop) => renderWorkshopNonCompliance(workshop))}
            </Box>
        </Stack>
    );

    const displayActionButton = () => (
        <SecondaryButton onClick={onClose} variant="outlined">
            Fermer
        </SecondaryButton>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title={`Récapitulatif du contrôle des non-conformités majeures du chantier n°${nonCompliance.vaccinationSiteId}`}
            onClose={onClose}
            renderContent={renderContent}
            renderActions={displayActionButton}
        />
    );
}
