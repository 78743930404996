// === NPM
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { UserType } from "@/interfaces/user";
import {
    IMonitoringInterventionForm,
    InterventionCorrectionType,
    MonitoringInterventionStatus,
} from "@/interfaces/vaccination";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { getEnumKeyByValue, nullifyEmptyFields } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import VaccinationService from "@/services/VaccinationService";
import { FormMonitoringInterventionProvider } from "./Form/containers/useFormMonitoringIntervention";
import { IMonitoringInterventionCorrection } from "./interface";

export default function MonitoringIntervention() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [horsePower, setHorsePower] = useState<IReferential[]>([]);
    const [serologicalTypes, setSerologicalTypes] = useState<IReferential[]>([]);
    const [clinicalNonComplianceReasons, setClinicalNonComplianceReasons] = useState<IReferential[]>([]);
    const [nonFullfilmentReasons, setNonFullfilmentReasons] = useState<IReferential[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([
            getHorsePower(),
            getSerologicalTypes(),
            getClinicalNonComplianceReasons(),
            getNonFullfilmentReasons(),
        ]).then(() => {
            setLoading(false);
        });
    };

    const getSerologicalTypes = async () => {
        const res = await VaccinationService.getSerologicalTypes();
        if (res.status === HttpStatus.OK) {
            setSerologicalTypes(await res.json());
        }
    };

    const getHorsePower = async () => {
        const res = await VaccinationService.getHorsePower();
        if (res.status === HttpStatus.OK) {
            setHorsePower(await res.json());
        }
    };

    const getClinicalNonComplianceReasons = async () => {
        const res = await VaccinationService.getClinicalNonComplianceReasons();
        if (res.status === HttpStatus.OK) {
            setClinicalNonComplianceReasons(await res.json());
        }
    };

    const getNonFullfilmentReasons = async () => {
        const res = await VaccinationService.getNonFullfilmentReasons();
        if (res.status === HttpStatus.OK) {
            setNonFullfilmentReasons(await res.json());
        }
    };

    const formatIntervention = (intervention: IMonitoringInterventionForm): IMonitoringInterventionForm => {
        switch (intervention.generalInformation.status) {
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.REALIZED):
                return nullifyEmptyFields({
                    ...intervention,
                    generalInformation: {
                        ...intervention.generalInformation,
                        nonFullfilmentReason: null,
                    },
                });
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.PLANNED):
                return nullifyEmptyFields({
                    finalized: false,
                    generalInformation: {
                        ...intervention.generalInformation,
                        nonFullfilmentReason: null,
                        plateNumber: null,
                        horsePower: null,
                        distance: null,
                    },
                    interventionData: null,
                    workshops: intervention.workshops,
                });
            case getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.NOT_REALIZED):
                return intervention;
        }
    };

    const correctIntervention = async (
        correctionType: InterventionCorrectionType,
        reason: string,
        intervention: IMonitoringInterventionForm
    ) => {
        const formattedIntervention = { ...formatIntervention(intervention), finalized: true };
        const payload: IMonitoringInterventionCorrection = {
            type: correctionType,
            reason: reason,
            body: formattedIntervention,
        };
        const res = await VaccinationService.correctIntervention(intervention.generalInformation.id, payload);
        if (res.status === HttpStatus.NO_CONTENT) {
            navigate(routerLinks.iahp.monitoringIntervention.view());
            toast.success(
                correctionType === InterventionCorrectionType.UPDATE
                    ? "L'intervention a bien été modifiée"
                    : "L'intervention a bien été supprimée"
            );
        }
        return res;
    };

    return (<>
        <PermissionsTabs
            tabs={[
                {
                    url: routerLinks.iahp.monitoringIntervention.view(),
                    label: "Mes interventions de surveillance",
                },
                {
                    url: routerLinks.iahp.monitoringIntervention.form(),
                    label: "Saisir une intervention de surveillance",
                    permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                    preferences: [Preference.IAHP],
                },
            ]}
        />
        <FormMonitoringInterventionProvider>
            <PageContent>
                {auth.userInfo.type !== UserType.VETERINARY ||
                auth.userInfo.properties.dpes.some((dpe) => dpe.sanitaryDpe) ? (
                    <Outlet
                        context={{
                            loading,
                            horsePower,
                            serologicalTypes,
                            clinicalNonComplianceReasons,
                            nonFullfilmentReasons,
                            correctIntervention,
                            formatIntervention,
                        }}
                    />
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%"
                        }}>
                        <Typography variant="h4">
                            Vous ne pouvez pas effectuer de saisie car vous ne possédez pas de DPE ayant un lien
                            vétérinaire sanitaire.
                        </Typography>
                    </Box>
                )}
            </PageContent>
        </FormMonitoringInterventionProvider>
    </>);
}
