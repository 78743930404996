// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { IUserShort, UserType } from "@/interfaces/user";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import UserService from "@/services/UserService";
import { IFamDamUsersFilters } from "../../../interface";

interface FamDamUsersProps {
    inputFilters: IFamDamUsersFilters;
    setInputFilters: Dispatch<SetStateAction<IFamDamUsersFilters>>;
}

export default function FamDamUsers({ inputFilters, setInputFilters }: Readonly<FamDamUsersProps>) {
    const [famDamUsers, setFamDamUsers] = useState<IUserShort[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "asc" }]);
    const [situations, setSituations] = useState<IReferential[]>([]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getSituations();
    }, []);

    useEffect(() => {
        getFamDamUsers();
    }, [pagination, sortModel]);

    const getFamDamUsers = async () => {
        const res = await UserService.getFamDamUsers({
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        });
        if (res.status === HttpStatus.OK) {
            setFamDamUsers(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getSituations = async () => {
        const res = await UserService.getUserTypesReferential();
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            const famDamType = data.find((userType) => userType.key === UserType.FAM_DAM);
            setSituations(famDamType?.situations ?? []);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Identifiant",
            flex: 1,
        },
        {
            field: "lastname",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "firstname",
            headerName: "Prénom",
            flex: 1,
        },
        {
            field: "agencyName",
            headerName: "Groupe FAM/DAM",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => params.row.properties.agencyName,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "situation",
            headerName: "Situation",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                situations.find((situation) => situation.key === params.row.situation)?.label,
        },
    ];

    const filterConfigurations: FilterConfigurations<IFamDamUsersFilters> = {
        id: { label: "Identifiant", type: FilterType.INPUT },
        lastname: { label: "Nom", type: FilterType.INPUT },
        firstname: { label: "Prénom", type: FilterType.INPUT },
        agencyName: { label: "Groupe FAM/DAM", type: FilterType.INPUT },
        email: { label: "Email", type: FilterType.INPUT },
        situation: {
            label: "Situation",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: situations.map((situation) => ({ label: `${situation.label}`, key: situation.key })),
        },
    };

    return (
        (<Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <Typography variant="h4">Utilisateurs FAM/DAM</Typography>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            id: "",
                            agencyName: "",
                            firstname: "",
                            lastname: "",
                            email: "",
                            situation: [],
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={famDamUsers}
                        columns={columns}
                        page={pagination.page}
                        rowCount={rowCount}
                        pageSize={pagination.pageSize}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        sortingMode="server"
                        paginationMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                        filterMode="server"
                        autoHeight
                        getRowId={(row) => row.id}
                    />
                </CardContent>
            </Card>
        </Stack>)
    );
}
