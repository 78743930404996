// === NPM
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Box, Stack, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
// === LOCAL
import { ReactComponent as Wrong } from "@/assets/icons/actions/cancel.svg";
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import { ReactComponent as Absent } from "@/assets/icons/training/inscriptions/absent.svg";
import { ReactComponent as Present } from "@/assets/icons/training/inscriptions/present.svg";
import { ReactComponent as Register } from "@/assets/icons/training/inscriptions/register.svg";
import { ReactComponent as Unregister } from "@/assets/icons/training/inscriptions/unregister.svg";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import {
    InscriptionStatus,
    IParticipationOvvtDdpp,
    PresenceStatus,
} from "@/components/HealthAccreditationTraining/interface";
import { HttpStatus } from "@/interfaces/global";
import { statusColor } from "@/resources/CssConstant";
import TrainingService from "@/services/TrainingService";
import InscriptionsTable from "../InscriptionsTable";

export default function InscriptionsOvvt() {
    const [selectedInscription, setSelectedInscription] = useState<IParticipationOvvtDdpp>(null);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [pastSession, setPastSession] = useState<boolean>(null);

    const changeRegistrationStatus = async (uuid: string, status: InscriptionStatus) => {
        const res =
            status === InscriptionStatus.REGISTERED
                ? await TrainingService.validateParticipation(uuid)
                : await TrainingService.invalidateParticipation(uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Statut d'inscription mis à jour avec succès.");
            setRefresh((prev) => !prev);
        }
    };

    const changePresenceStatus = async (uuid: string, status: PresenceStatus) => {
        const res =
            status === PresenceStatus.PRESENT
                ? await TrainingService.patchPresent(uuid)
                : await TrainingService.patchAbsent(uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Présence mise à jour avec succès.");
            setRefresh((prev) => !prev);
        }
    };

    const removeVeterinary = async () => {
        const res = await TrainingService.deleteRegistration(selectedInscription.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Inscription supprimée avec succès");
            setRefresh((prev) => !prev);
            setSelectedInscription(null);
        }
    };

    const renderActionColumn = (params: GridRenderCellParams) => {
        if (pastSession === null || pastSession === undefined) return;
        if (pastSession) {
            return (
                <Box>
                    {(PresenceStatus[params.row.presenceStatus] === PresenceStatus.UNDEFINED ||
                        PresenceStatus[params.row.presenceStatus] === PresenceStatus.ABSENT) && (
                        <IconActionButton
                            icon={<Present />}
                            title="Marquer comme Présent"
                            onClick={() => changePresenceStatus(params.row.uuid, PresenceStatus.PRESENT)}
                        />
                    )}
                    {(PresenceStatus[params.row.presenceStatus] === PresenceStatus.UNDEFINED ||
                        PresenceStatus[params.row.presenceStatus] === PresenceStatus.PRESENT) && (
                        <IconActionButton
                            icon={<Absent />}
                            title="Marquer comme Absent"
                            onClick={() => changePresenceStatus(params.row.uuid, PresenceStatus.ABSENT)}
                        />
                    )}
                </Box>
            );
        } else {
            return (
                <Box>
                    {InscriptionStatus[params.row.registrationStatus] === InscriptionStatus.PRE_REGISTERED ? (
                        <IconActionButton
                            icon={<Register />}
                            title="Inscrire ce vétérinaire"
                            onClick={() => changeRegistrationStatus(params.row.uuid, InscriptionStatus.REGISTERED)}
                        />
                    ) : (
                        <IconActionButton
                            icon={<Unregister />}
                            title="Passer en préinscrit"
                            onClick={() => changeRegistrationStatus(params.row.uuid, InscriptionStatus.PRE_REGISTERED)}
                        />
                    )}
                    <IconActionButton
                        icon={<Wrong />}
                        title="Désinscrire ce vétérinaire"
                        onClick={() => setSelectedInscription(params.row)}
                    />
                </Box>
            );
        }
    };

    return (
        <>
            <InscriptionsTable
                pastSession={pastSession}
                setPastSession={setPastSession}
                refresh={refresh}
                renderActionColumn={renderActionColumn}
            />
            {!!selectedInscription && (
                <GenericDialog
                    title={`Désinscription du vétérinaire ${selectedInscription.veterinary}`}
                    onClose={() => setSelectedInscription(null)}
                    renderActions={() => (
                        <GenericActionsDialog
                            onClose={() => setSelectedInscription(null)}
                            onSubmit={removeVeterinary}
                            validLabel="Oui"
                            closeLabel="Non"
                        />
                    )}
                    renderContent={() => (
                        <Stack spacing={1}>
                            <IconBannerText
                                icon={<Warning />}
                                message={
                                    "Attention\nVous allez définitivement désinscrire un vétérinaire d'une session"
                                }
                                color={statusColor.warningText}
                                backgroundColor={statusColor.warningBackground}
                            />
                            <Typography>
                                {/* TODO add session information see w/ Loïc how to do it */}
                                {`Confirmez-vous la désinscription du vétérinaire ${selectedInscription.veterinary} de la session de formation ?`}
                            </Typography>
                            <Typography>Cette désinscription sera définitive.</Typography>
                        </Stack>
                    )}
                />
            )}
        </>
    );
}
