// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === LOCAL
import { ReactComponent as Calendar } from "@/assets/icons/shared/calendar.svg";
import { ReactComponent as Car } from "@/assets/icons/shared/car.svg";
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { IMonitoringInterventionGeneralInformation, MonitoringInterventionStatus } from "@/interfaces/vaccination";
import { getEnumKeyByValue, toLocaleDateFormat } from "@/resources/utils";
import { MonitoringInterventionOutletContext } from "../interface";

interface InitializationAccordionProps {
    generalInformation: IMonitoringInterventionGeneralInformation;
    farmName: string;
    workshopsLength: number;
    dpeName: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
}

export default function InitializationAccordion({
    generalInformation,
    farmName,
    workshopsLength,
    dpeName,
    veterinaryFirstname,
    veterinaryLastname,
}: Readonly<InitializationAccordionProps>) {
    const { serologicalTypes, horsePower, nonFullfilmentReasons } =
        useOutletContext<MonitoringInterventionOutletContext>();

    const getSamples = (status: string) => {
        const retrievedSamples = generalInformation.serologicalSamples
            .reduce((samples: string[], sample) => {
                if (sample[status]) {
                    const label = serologicalTypes.find((s) => s.key === sample.sampleType)?.label;
                    samples.push(label);
                }
                return samples;
            }, [])
            .join(", ");
        if (retrievedSamples.length > 0) return retrievedSamples;
        else return "Aucun prélèvement";
    };

    return (
        <GenericAccordion title="Initialisation de l'intervention" defaultExpanded>
            <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 4,
                    }}
                    sx={{
                        display: "flex",
                        gap: 1,
                    }}
                >
                    <Box>
                        <Calendar />
                    </Box>
                    <Stack spacing={1}>
                        <Box
                            sx={{
                                mt: -0.25,
                            }}
                        >
                            <Typography
                                variant="bold"
                                sx={{
                                    fontSize: 18,
                                }}
                            >
                                Informations d'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText
                            title="Date de l'intervention"
                            value={toLocaleDateFormat(generalInformation.interventionDate)}
                        />
                        <GenericTitleValueText title="DPE" value={`${generalInformation.dpeId} - ${dpeName}`} />
                        <GenericTitleValueText
                            title="Vétérinaire"
                            value={`${generalInformation.veterinaryId} - ${veterinaryFirstname} ${veterinaryLastname}`}
                        />
                        <GenericTitleValueText
                            title="Statut de l'intervention"
                            value={
                                generalInformation.status ===
                                getEnumKeyByValue(
                                    MonitoringInterventionStatus,
                                    MonitoringInterventionStatus.NOT_REALIZED
                                )
                                    ? `${MonitoringInterventionStatus[generalInformation.status]} (${
                                          nonFullfilmentReasons.find(
                                              (nfr) => nfr.key === generalInformation.nonFullfilmentReason
                                          )?.label
                                      })`
                                    : MonitoringInterventionStatus[generalInformation.status]
                            }
                        />
                        <GenericTitleValueText title="Prélèvements planifiés" value={getSamples("planned")} />
                        <GenericTitleValueText title="Prélèvements réalisés" value={getSamples("realized")} />
                    </Stack>
                </Grid>

                {generalInformation.status !==
                    getEnumKeyByValue(MonitoringInterventionStatus, MonitoringInterventionStatus.PLANNED) && (
                    <Grid
                        size={{
                            xs: 12,
                            md: 4,
                        }}
                        sx={{
                            display: "flex",
                            gap: 1,
                        }}
                    >
                        <Box>
                            <Car />
                        </Box>
                        <Stack spacing={1}>
                            <Box
                                sx={{
                                    mt: -0.25,
                                }}
                            >
                                <Typography
                                    variant="bold"
                                    sx={{
                                        fontSize: 18,
                                    }}
                                >
                                    Déplacement
                                </Typography>
                            </Box>
                            <GenericTitleValueText title="Kilomètres parcourus" value={generalInformation.distance} />
                            <GenericTitleValueText title="Immatriculation" value={generalInformation.plateNumber} />
                            <GenericTitleValueText
                                title="Chevaux fiscaux"
                                value={horsePower.find((hp) => hp.key === generalInformation.horsePower)?.label}
                            />
                        </Stack>
                    </Grid>
                )}

                <Grid
                    size={{
                        xs: 12,
                        md: 4,
                    }}
                    sx={{
                        display: "flex",
                        gap: 1,
                    }}
                >
                    <Box>
                        <Location />
                    </Box>
                    <Stack spacing={1}>
                        <Box
                            sx={{
                                mt: -0.25,
                            }}
                        >
                            <Typography
                                variant="bold"
                                sx={{
                                    fontSize: 18,
                                }}
                            >
                                Lieu d'intervention
                            </Typography>
                        </Box>
                        <GenericTitleValueText title="Établissement" value={farmName} />
                        <GenericTitleValueText
                            title="Identifiant de l'établissement"
                            value={generalInformation.farmId}
                        />
                        <GenericTitleValueText title="Ateliers" value={workshopsLength} />
                    </Stack>
                </Grid>
            </Grid>
        </GenericAccordion>
    );
}
