// === NPM
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { stringRequired } from "@/resources/FormUtils";
import GenericCard from "../layout/GenericCard";
import GenericActionsDialog from "./GenericActionsDialog";
import GenericDialog from "./GenericDialog";

interface GenericExportDialogProps {
    onClose: () => void;
    onValid: (filename: string) => void;
    title: string;
}

const formSchema = z.object({
    filename: stringRequired().max(250, "Le nom du fichier est limité à 250 caractères"),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function GenericExportDialog({ onClose, onValid, title }: Readonly<GenericExportDialogProps>) {
    const {
        formState: { errors },
        handleSubmit,
        register,
        watch,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: { filename: "" },
    });

    const filename = watch("filename");

    return (
        (<GenericDialog
            title={title}
            onClose={onClose}
            paperProps={{
                sx: { width: "100%" },
            }}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    onSubmit={handleSubmit((data) => {
                        onValid(data.filename);
                    })}
                />
            )}
            renderContent={() => (
                <GenericCard>
                    <TextField
                        {...register("filename")}
                        label="Nom du fichier"
                        fullWidth
                        required
                        variant="standard"
                        helperText={errors.filename?.message ?? `${filename.length}/${250}`}
                        error={!!errors.filename}
                        slotProps={{
                            htmlInput: {
                                maxLength: 250,
                            }
                        }}
                    />
                </GenericCard>
            )}
        />)
    );
}
