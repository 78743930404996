// === NPM
import { KyResponse } from "ky";
// === LOCAL
import { IQuestionDraft } from "@/components/Administration/containers/Faq/interface";
import { IFaqFilters, IUserQuestionShort } from "@/components/Faq/interface";
import { ICategory, IQuestion, IQuestionShort, ITag } from "@/interfaces/faq";
import { HttpMethod, Loader, UserTypeHeader } from "@/interfaces/global";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class FaqService {
    // --------------- CATEGORIES ---------------

    async getCategories(userType: UserTypeHeader): Promise<KyResponse<ICategory[]>> {
        return HttpService.fetch(endpoints.faqService.categories.base, {
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
                loader: Loader.REQUEST,
            },
        });
    }

    async postCategory(category: ICategory): Promise<KyResponse<ICategory>> {
        return HttpService.fetch(endpoints.faqService.categories.base, {
            method: HttpMethod.POST,
            json: category,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putCategory(category: ICategory): Promise<KyResponse<ICategory>> {
        return HttpService.fetch(endpoints.faqService.categories.category(category.uuid), {
            method: HttpMethod.PUT,
            json: category,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteCategory(categoryUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.faqService.categories.category(categoryUuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async patchCategoryPosition(categoryUuid: string, position: number): Promise<KyResponse<ICategory>> {
        return HttpService.fetch(endpoints.faqService.categories.position(categoryUuid), {
            method: HttpMethod.PATCH,
            json: { position },
        });
    }

    // --------------- TAGS ---------------
    async getTags(): Promise<KyResponse<ITag[]>> {
        return HttpService.fetch(endpoints.faqService.tags.base, {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async postTag(tag: ITag): Promise<KyResponse<ITag>> {
        return HttpService.fetch(endpoints.faqService.tags.base, {
            method: HttpMethod.POST,
            json: tag,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putTag(tag: ITag): Promise<KyResponse<ITag>> {
        return HttpService.fetch(endpoints.faqService.tags.tag(tag.uuid), {
            method: HttpMethod.PUT,
            json: tag,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteTag(tagUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.faqService.tags.tag(tagUuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    // --------------- QUESTION ---------------

    async getQuestions(userType: UserTypeHeader.ADMIN): Promise<KyResponse<IQuestionShort[]>>;
    async getQuestions(userType: UserTypeHeader.USER, payload?: IFaqFilters): Promise<KyResponse<IUserQuestionShort[]>>;
    async getQuestions(
        userType: UserTypeHeader,
        payload?: IFaqFilters
    ): Promise<KyResponse<IQuestionShort[] | IUserQuestionShort[]>> {
        return HttpService.fetch(endpoints.faqService.questions.base, {
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
                loader: Loader.TABLE,
            },
            searchParams: formatParams(payload ?? {}),
        });
    }

    async postQuestion(question: IQuestion): Promise<KyResponse<IQuestion>> {
        return HttpService.fetch(endpoints.faqService.questions.base, {
            method: HttpMethod.POST,
            json: question,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putQuestion(question: IQuestion): Promise<KyResponse<IQuestion>> {
        return HttpService.fetch(endpoints.faqService.questions.question(question.uuid), {
            method: HttpMethod.PUT,
            json: question,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteQuestion(questionUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.faqService.questions.question(questionUuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    questionDetailsCache: Map<string, IQuestion> = new Map();

    getQuestionDetailsCached = async (questionUuid: string, userType: UserTypeHeader): Promise<IQuestion> => {
        if (!this.questionDetailsCache.has(questionUuid)) {
            this.questionDetailsCache.set(
                questionUuid,
                await (await this.getQuestionDetails(questionUuid, userType)).json()
            );
        }
        return this.questionDetailsCache.get(questionUuid);
    };

    replaceQuestionDetailsCached = (question: IQuestion): void => {
        this.questionDetailsCache.set(question.uuid, question);
    };

    removeQuestionDetailsCached = (questionUuid: string): void => {
        this.questionDetailsCache.delete(questionUuid);
    };

    async getQuestionDetails(questionUuid: string, userType: UserTypeHeader): Promise<KyResponse<IQuestion>> {
        return HttpService.fetch(endpoints.faqService.questions.question(questionUuid), {
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
                loader: Loader.BUTTON,
            },
        });
    }

    getQuestionDraft = async (questionUuid: string): Promise<KyResponse<IQuestionDraft>> => {
        return HttpService.fetch(endpoints.faqService.questions.draft(questionUuid), {
            method: HttpMethod.GET,
            headers: { loader: Loader.BUTTON },
        });
    };

    async patchQuestionPosition(
        questionUuid: string,
        json: { position: number; categoryUuid: string }
    ): Promise<KyResponse<IQuestionShort>> {
        return HttpService.fetch(endpoints.faqService.questions.position(questionUuid), {
            method: HttpMethod.PATCH,
            json,
        });
    }
}

export default new FaqService();
