// === NPM
import React, { useEffect, useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { VaccinationSiteUserType } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { convertEnumToKeyLabelObject, createPayload, toLocaleDateFormat } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";
import VaccinationInterventionTable from "../../containers/VaccinationInterventionTable";
import { IBillable, IBillableOutsideCalypsoFilters } from "../interface";
import VaccinationSitesDataGrid from "./containers/VaccinationSitesDataGrid";

export default function VaccinationSitesOutsideCalypso() {
    const initialValues = {
        id: "",
        date: [],
        userType: [],
        dpe: "",
        veterinary: "",
        farmId: "",
    };

    const [billables, setBillables] = useState<IBillable[]>([]);
    const [inputFilters, setOutsideCalypsoInputFilters] = useState<IBillableOutsideCalypsoFilters>(initialValues);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [vaccinationSiteId, setVaccinationSiteId] = useState<number>(null);
    const [vaccinationSiteDate, setVaccinationSiteDate] = useState<string>(null);

    useEffect(() => {
        getBillables();
    }, [pagination, sortModel]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    const getBillables = async () => {
        const res = await VaccinationService.getBillables(true, {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        });
        if (res.status === HttpStatus.OK) {
            setBillables(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const filterConfigurations: FilterConfigurations<IBillableOutsideCalypsoFilters> = {
        id: {
            label: "Identifiant chantier",
            type: FilterType.INPUT,
        },
        date: {
            label: "Date chantier",
            type: FilterType.DATEPICKER,
        },
        userType: {
            label: "Type de vaccinateur",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(VaccinationSiteUserType),
        },
        dpe: {
            label: "DPE",
            type: FilterType.INPUT,
        },
        veterinary: {
            label: "Vétérinaire",
            type: FilterType.INPUT,
        },
        farmId: {
            label: "Identifiant Établissement",
            type: FilterType.INPUT,
        },
    };

    return (
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%",
            }}
        >
            <Typography variant="h4">Mes chantiers facturés hors Calypso</Typography>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={initialValues}
                        setInputFilters={setOutsideCalypsoInputFilters}
                    />
                    <VaccinationSitesDataGrid
                        outsideCalypso
                        billables={billables}
                        pagination={pagination}
                        setPagination={setPagination}
                        rowCount={rowCount}
                        sortModel={sortModel}
                        setSortModel={setSortModel}
                        setVaccinationSiteId={setVaccinationSiteId}
                        setVaccinationSiteDate={setVaccinationSiteDate}
                        getBillables={getBillables}
                    />
                </CardContent>
            </Card>
            {vaccinationSiteId && (
                <VaccinationInterventionTable
                    vaccinationSiteId={vaccinationSiteId}
                    canEdit={false}
                    title={`Suivi du chantier n°${vaccinationSiteId} en date du ${toLocaleDateFormat(vaccinationSiteDate)}`}
                />
            )}
        </Stack>
    );
}
