// === Import: NPM
import React from "react";
import { Box, Button, Card, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === Import: LOCAL
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { UserSituation } from "@/resources/PermissionConstant";

export default function VeterinaryProfileSkeleton() {
    return (
        <Grid
            container
            spacing={5}
            sx={{
                p: 2,
            }}
        >
            <PermissionsCheck requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}>
                <Grid size={12}>
                    <Box
                        sx={{
                            width: "100%",
                            justifyContent: "flex-end",
                            display: "flex",
                        }}
                    >
                        <Button variant="contained" disabled>
                            Modifier mes données
                        </Button>
                    </Box>
                </Grid>
            </PermissionsCheck>
            <Grid
                size={{
                    xs: 12,
                    lg: 6,
                }}
            >
                <Typography sx={{ mb: 3 }} variant="h5">
                    Informations personnelles
                </Typography>
                <Card>
                    <Skeleton variant="rectangular" animation="wave" height={300} />
                </Card>
            </Grid>
            <Grid
                size={{
                    xs: 12,
                    lg: 6,
                }}
            >
                <Typography sx={{ mb: 3 }} variant="h5">
                    Situation ordinale
                </Typography>
                <Card>
                    <Skeleton variant="rectangular" animation="wave" height={300} />
                </Card>
            </Grid>
            <Grid size={12}>
                <Typography sx={{ mb: 3 }} variant="h5">
                    Domiciles professionnels d'exercice
                </Typography>
                <Card sx={{ width: 400 }}>
                    <Skeleton variant="rectangular" animation="wave" height={200} width={400} />
                </Card>
            </Grid>
        </Grid>
    );
}
