// === NPM
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === LOCAL
import { ReactComponent as Location } from "@/assets/icons/shared/location.svg";
import { ReactComponent as Workshop } from "@/assets/icons/shared/workshop.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import GenericTitleValueText from "@/components/generics/text/GenericTitleValueText";
import { useDepartments } from "@/context/useDepartmentContext";
import { colors } from "@/resources/CssConstant";

interface WorkshopsAccordionProps {
    departmentInseeCode: string;
    workshops: { id: string; city: string }[];
    farmId: string;
    farmName: string;
}

export default function WorkshopsAccordion({
    departmentInseeCode,
    workshops,
    farmId,
    farmName,
}: Readonly<WorkshopsAccordionProps>) {
    const { departments } = useDepartments();
    const department = departments.find((department) => department.inseeCode === departmentInseeCode);

    const renderWorkshop = (workshop) => (
        <Box
            sx={{
                textAlign: "center",
                py: 1,
                px: 2,
                borderRadius: 10,
                border: `1px solid ${colors.primaryColor}`,
                background: colors.lightGreen,
                width: "fit-content",
            }}
        >
            <Typography
                variant="bold"
                sx={{
                    color: "primary",
                }}
            >
                {workshop.id} - {workshop.city} - {department?.name}
            </Typography>
        </Box>
    );
    return (
        <GenericAccordion title="Ateliers d'intervention" defaultExpanded>
            <Grid
                sx={{
                    display: "flex",
                    gap: 1,
                }}
            >
                <Box>
                    <Location />
                </Box>
                <Stack spacing={1}>
                    <Box
                        sx={{
                            mt: -0.25,
                        }}
                    >
                        <Typography
                            variant="bold"
                            sx={{
                                fontSize: 18,
                            }}
                        >
                            Établissement
                        </Typography>
                    </Box>
                    <GenericTitleValueText title="Nom" value={farmName} />
                    <GenericTitleValueText title="Identifiant" value={farmId} />
                </Stack>
            </Grid>
            <Grid
                sx={{
                    display: "flex",
                    gap: 1,
                    mt: 2,
                }}
            >
                <Box>
                    <Workshop />
                </Box>
                <Stack spacing={1}>
                    <Box
                        sx={{
                            mt: -0.25,
                        }}
                    >
                        <Typography
                            variant="bold"
                            sx={{
                                fontSize: 18,
                            }}
                        >
                            Ateliers
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={2}>
                {workshops.map((workshop) => (
                    <Grid
                        key={workshop.id}
                        size={{
                            xs: 12,
                            md: 6,
                            lg: 3,
                        }}
                    >
                        {renderWorkshop(workshop)}
                    </Grid>
                ))}
            </Grid>
        </GenericAccordion>
    );
}
