// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, Stack, Typography } from "@mui/material";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericCard from "@/components/generics/layout/GenericCard";
import { InterventionCorrectionType } from "@/interfaces/vaccination";
import { defaultTextLimit } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { stringRequired } from "@/resources/FormUtils";

interface CorrectionDialogProps {
    interventionId: string;
    correctionType: InterventionCorrectionType;
    interventionType: string;
    onClose: () => void;
    onValid: (motif: string) => void;
}

const schema = z.object({
    reason: stringRequired().max(defaultTextLimit, "Le motif ne doit pas dépasser 2000 caractères"),
});

type Schema = z.infer<typeof schema>;

export default function CorrectionDialog({
    interventionId,
    correctionType,
    interventionType,
    onClose,
    onValid,
}: Readonly<CorrectionDialogProps>) {
    const {
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<Schema>({
        resolver: zodResolver(schema),
        defaultValues: {
            reason: null,
        },
    });

    const isDelete = correctionType === InterventionCorrectionType.DELETE;

    const renderContent = () => (
        <Stack spacing={2}>
            <IconBannerText
                icon={<Warning />}
                message={
                    isDelete
                        ? `Attention : la suppression d’une intervention de ${interventionType} est définitive et supprime également le certificat de ${interventionType} dans Calypso. \n Le certificat déjà édité et transmis ne sera plus valable.`
                        : `Attention : la fonctionnalité de correction ne modifie pas le certificat de ${interventionType} déjà généré.`
                }
                color={statusColor.warningText}
                backgroundColor={statusColor.warningBackground}
            />
            <GenericCard>
                <Stack spacing={2}>
                    {isDelete && (
                        <Typography>
                            {`Êtes-vous sûr de vouloir supprimer cette intervention de ${interventionType} ? Cette action n’est pas réversible.`}
                        </Typography>
                    )}
                    <FormControl fullWidth error={!!errors?.reason}>
                        <Controller
                            name="reason"
                            control={control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <GenericComment
                                    value={value}
                                    onChange={onChange}
                                    helperText={error?.message}
                                    label={`Motif de ${
                                        correctionType === InterventionCorrectionType.DELETE
                                            ? "suppression"
                                            : "correction"
                                    } de l'intervention`}
                                    rows={5}
                                    placeholder="Veuillez renseigner le motif"
                                />
                            )}
                        />
                    </FormControl>
                </Stack>
            </GenericCard>
        </Stack>
    );

    return (
        <GenericDialog
            title={`${
                isDelete
                    ? `Suppression de l'intervention de ${interventionType} n°${interventionId}`
                    : `Correction de l'intervention de ${interventionType} n°${interventionId}`
            }`}
            onClose={onClose}
            maxWidth="xl"
            renderContent={renderContent}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={onClose}
                    onSubmit={handleSubmit((data) => onValid(data.reason))}
                    closeLabel={isDelete ? "Non" : "Annuler"}
                    validLabel={isDelete ? "Oui" : "Continuer"}
                />
            )}
        />
    );
}
