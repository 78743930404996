// === NPM
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormControl, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { z } from "zod";
// === LOCAL
import GenericButton from "@/components/generics/buttons/GenericButton";
import ChipTag from "@/components/generics/ChipTag";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import TextEditor from "@/components/generics/TextEditor";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { useProvideGlobal } from "@/context/useGlobalContext";
import { IQuestion } from "@/interfaces/faq";
import { stringRequired } from "@/resources/FormUtils";
import { FaqOutletContext } from "../../../interface";

interface QuestionDialogProps {
    onClose: () => void;
    onValid: (question: IQuestion) => void;
    title: string;
    currentQuestion?: IQuestion;
}

const initialValues = {
    title: null,
    content: "",
    categoryUuid: "",
    tagUuids: [],
    published: null,
};

const formSchema = z.object({
    title: stringRequired(),
    content: stringRequired(),
    categoryUuid: stringRequired(),
    tagUuids: z.array(z.string()).optional(),
    published: z.boolean(),
});

type ValidationSchema = z.infer<typeof formSchema>;

export default function QuestionDialog({ onClose, onValid, title, currentQuestion }: Readonly<QuestionDialogProps>) {
    const { loadingTable, loadingRequest } = useProvideGlobal();
    const { categories, tags } = useOutletContext<FaqOutletContext>();

    const {
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        watch,
        register,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: currentQuestion ?? initialValues,
    });

    const watchTagUuids = watch("tagUuids");

    const handleTagClick = (tagUuid: string) => {
        const isSelected = watchTagUuids.includes(tagUuid);
        let tagLists = [];
        if (isSelected) {
            tagLists = watchTagUuids.filter((item) => item !== tagUuid);
        } else {
            tagLists = [...watchTagUuids, tagUuid];
        }
        setValue("tagUuids", tagLists);
    };

    const renderCategory = () => (
        <GenericAccordion defaultExpanded title="I. Catégorie">
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                }}
            >
                <FormControl fullWidth required error={!!errors.categoryUuid}>
                    <Controller
                        name="categoryUuid"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <GenericSelect
                                disabled={!!currentQuestion}
                                value={value}
                                label="Catégorie de la question"
                                error={!!error}
                                helperText={error?.message}
                                onChange={onChange}
                                optionsValue="uuid"
                                optionsLabel="title"
                                options={categories}
                                required
                            />
                        )}
                    />
                </FormControl>
            </Stack>
        </GenericAccordion>
    );

    const renderTags = () => (
        <GenericAccordion defaultExpanded title="II. Tags">
            <Grid container spacing={2}>
                {tags.map((tag) => (
                    <Grid key={tag.uuid}>
                        <ChipTag
                            tag={tag}
                            handleClick={() => handleTagClick(tag.uuid)}
                            selected={watchTagUuids.includes(tag.uuid)}
                        />
                    </Grid>
                ))}
            </Grid>
        </GenericAccordion>
    );

    const renderQuestionTitle = () => (
        <GenericAccordion defaultExpanded title="III. Intitulé de la question">
            <TextField
                label="Intitulé de la question"
                {...register("title")}
                error={!!errors?.title}
                helperText={errors?.title?.message}
                required
                fullWidth
            />
        </GenericAccordion>
    );

    const renderEditor = () => (
        <GenericAccordion defaultExpanded title="IV. Réponse">
            <TextEditor
                value={watch("content")}
                handleChange={(data) => setValue("content", data)}
                error={!!errors.content}
                helperText={errors?.content?.message}
                required
            />
        </GenericAccordion>
    );

    const renderActions = () => (
        <>
            <SecondaryButton
                id="cancel-dialog-btn"
                onClick={onClose}
                variant="outlined"
                disabled={loadingTable || loadingRequest}
            >
                Annuler
            </SecondaryButton>
            <GenericButton
                onClick={(event) => {
                    setValue("published", false);
                    handleSubmit((data) =>
                        onValid({
                            ...data,
                            uuid: currentQuestion?.uuid ?? null,
                        } as unknown as IQuestion)
                    )(event);
                }}
                color="primary"
                label="Enregistrer"
                loading={loadingTable || loadingRequest}
            />
            <GenericButton
                onClick={(event) => {
                    setValue("published", true);
                    handleSubmit((data) =>
                        onValid({
                            ...data,
                            uuid: currentQuestion?.uuid ?? null,
                        } as unknown as IQuestion)
                    )(event);
                }}
                color="primary"
                label="Enregistrer et publier"
                loading={loadingTable || loadingRequest}
            />
        </>
    );

    return (
        <GenericDialog
            title={title}
            onClose={onClose}
            paperProps={{
                sx: { width: "100%" },
            }}
            renderActions={() => renderActions()}
            renderContent={() => (
                <Stack spacing={2}>
                    {renderCategory()}
                    {renderTags()}
                    {renderQuestionTitle()}
                    {renderEditor()}
                </Stack>
            )}
        />
    );
}
