// === NPM
import React, { useState } from "react";
import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
// === LOCAL
import { ReactComponent as Regenerate } from "@/assets/icons/shared/refresh.svg";
import { ReactComponent as Certificate } from "@/assets/icons/training/certificateIcon.svg";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import { PresenceStatus } from "@/components/HealthAccreditationTraining/interface";
import { HttpStatus } from "@/interfaces/global";
import { useAuth } from "@/routers/useAuth";
import TrainingService from "@/services/TrainingService";
import InscriptionsTable from "../InscriptionsTable";

export default function InscriptionsDdpp() {
    const auth = useAuth();
    const [refresh, setRefresh] = useState<boolean>(false); //useful to refresh table after request
    const [pastSession, setPastSession] = useState<boolean>(null);

    const downloadAttestation = async (uuid: string) => {
        const res = await TrainingService.getHealthAccreditationAttestation(uuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            saveAs(file, res.headers["content-disposition"].split("filename=")[1].slice(1, -1));
        }
    };

    const renderActionColumn = (params: GridRenderCellParams) => {
        if (!pastSession) return;
        return (
            <Box>
                {params.row.attestationSigned ? (
                    <>
                        <DownloadAction
                            title="Télécharger l'attestation"
                            onClick={() => downloadAttestation(params.row.uuid)}
                        />
                        <IconActionButton
                            title="Regénérer l'attestation"
                            onClick={() => alert("todo")} // a faire lorsque YouSign sera intégré
                            icon={<Regenerate />}
                        />
                    </>
                ) : (
                    <IconActionButton
                        icon={<Certificate />}
                        title="Générer et signer l'attestation"
                        onClick={() => alert("todo")} // a faire lorsque YouSign sera intégré
                        disabled={
                            PresenceStatus[params.row.presenceStatus] !== PresenceStatus.PRESENT ||
                            params.row.departmentInseeCode !== auth.userInfo.properties.inseeCode
                        }
                    />
                )}
            </Box>
        );
    };

    return (
        <InscriptionsTable
            pastSession={pastSession}
            setPastSession={setPastSession}
            refresh={refresh}
            renderActionColumn={renderActionColumn}
        />
    );
}
