// === NPM
import { KyResponse } from "ky";
// === LOCAL
import { WorkshopFilters } from "@/components/Referentials/containers/Global/containers/Workshops/Workshops";
import { ViewRelationFilters } from "@/components/VaccinationIahp/Farm/containers/ViewRelation/ViewRelation";
import { IClosureRelation } from "@/components/VaccinationIahp/Farm/interface";
import { IFarmVaccinationSite } from "@/components/VaccinationIahp/VaccinationSite/interface";
import { FarmHeader, IFarm, IFarmMonitoring, IWorkshop, IWorkshopRelation } from "@/interfaces/farm";
import { HttpMethod, Loader, SearchParams } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class FarmService {
    async getFarm(farmId: string, payload?: { speciesUuid: string }): Promise<KyResponse<IFarm>> {
        return HttpService.fetch(endpoints.farmService.farm(farmId), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify({ noToasterOnError: true }),
            },
        });
    }

    async getFarms(
        payload: SearchParams & { dpeId: string; monitoringInterventionDate: string; id: string },
        dataType: FarmHeader.MONITORING
    ): Promise<KyResponse<IFarmMonitoring[]>>;
    async getFarms(payload: SearchParams & { dpeId: string; id: string }): Promise<KyResponse<IFarmVaccinationSite[]>>;
    async getFarms(
        payload: SearchParams & { dpeId: string; id: string; monitoringInterventionDate?: string },
        dataType?: FarmHeader
    ): Promise<KyResponse<IFarmMonitoring[] | IFarmVaccinationSite[]>> {
        return HttpService.fetch(endpoints.farmService.farms(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.DATA_TYPE]: dataType,
            },
        });
    }

    async getFarmByWorkshop(workshopId: string, payload?: { speciesUuid: string }): Promise<KyResponse<IFarm>> {
        return HttpService.fetch(endpoints.farmService.workshops.farmByWorkshop(workshopId), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify({ noToasterOnError: true }),
            },
        });
    }

    async postWorkshopAlert(workshopIds: string[], dpeId: string, openingDate: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.farmService.workshops.alert(), {
            method: HttpMethod.POST,
            json: {
                workshopIds,
                dpeId,
                openingDate,
            },
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async patchFarmWorkshop(
        farmId: string,
        payload: { workshopIds: string[]; dpeId: string; openingDate: string },
        cerfaFile: File
    ): Promise<KyResponse> {
        const blob = new Blob([JSON.stringify(payload)], {
            type: "application/json",
        });

        const formData = new FormData();
        formData.append("file", cerfaFile);
        formData.append("link", blob);
        return HttpService.fetch(endpoints.farmService.farmLinkedWorkshops(farmId), {
            method: HttpMethod.PATCH,
            body: formData,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getWorkshops(payload: SearchParams & Partial<WorkshopFilters>): Promise<KyResponse<IWorkshop[]>> {
        return HttpService.fetch(endpoints.farmService.workshops.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async postWorkshops(file: File): Promise<KyResponse> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch(endpoints.farmService.workshops.base, {
            method: HttpMethod.POST,
            body: formData,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getWorkshopLinks(
        payload: SearchParams & Partial<ViewRelationFilters>
    ): Promise<KyResponse<IWorkshopRelation[]>> {
        return HttpService.fetch(endpoints.farmService.workshops.linked(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getWorkshopFile(workshopId: string, fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.farmService.workshops.file(workshopId, fileUuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async patchCerfa(relationId: string, file: File): Promise<KyResponse<string>> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch(endpoints.farmService.workshops.cerfa(relationId), {
            method: HttpMethod.PATCH,
            body: formData,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getClosureReasons(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.farmService.referential.closureReasons(), {
            method: HttpMethod.GET,
        });
    }

    async patchClosure(workshopId: string, json: IClosureRelation): Promise<KyResponse> {
        return HttpService.fetch(endpoints.farmService.workshops.closure(workshopId), {
            method: HttpMethod.PATCH,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }
}

export default new FarmService();
