// === NPM
import React, { Dispatch, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Help } from "@mui/icons-material";
import {
    Box,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { KyResponse } from "ky";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Check } from "@/assets/icons/farm/check.svg";
import { ReactComponent as CloseSquare } from "@/assets/icons/farm/close-square.svg";
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericButton from "@/components/generics/buttons/GenericButton";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { IFarm } from "@/interfaces/farm";
import { HttpStatus } from "@/interfaces/global";
import { ISpeciesDetails } from "@/interfaces/referential";
import { colors, statusColor } from "@/resources/CssConstant";
import { FORM_TEXT } from "@/resources/FormUtils";
import FarmService from "@/services/FarmService";

interface WorkshopSelectionProps {
    updateWorkshops: (value: string[]) => void;
    workshopIds: string[];
    species: ISpeciesDetails[];
    workShopError: string;
    farm: IFarm;
    setFarm: Dispatch<SetStateAction<IFarm>>;
    setSelectedSpecies: Dispatch<SetStateAction<ISpeciesDetails>>;
    dpeId: string;
}

const workshopSelectionSchema = z
    .object({
        speciesUuid: z
            .string({
                required_error: FORM_TEXT.required,
                invalid_type_error: FORM_TEXT.required,
            })
            .trim()
            .min(1, { message: FORM_TEXT.required }),
        farmId: z
            .string({
                invalid_type_error: FORM_TEXT.required,
            })
            .trim()
            .optional(),
        workshopId: z
            .string({
                invalid_type_error: FORM_TEXT.required,
            })
            .trim()
            .optional(),
    })
    .superRefine((values, context) => {
        if (!values.workshopId && !values.farmId) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "L'identifiant de l'atelier ou l'identifiant de l'élevage est requis.",
                path: ["farmId"],
            });
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "L'identifiant de l'atelier ou l'identifiant de l'élevage est requis.",
                path: ["workshopId"],
            });
        }
    });

type WorkshopSelectionSchemaValidation = z.infer<typeof workshopSelectionSchema>;

export default function WorkshopSelection({
    updateWorkshops,
    workshopIds,
    species,
    workShopError,
    farm,
    setFarm,
    setSelectedSpecies,
    dpeId,
}: Readonly<WorkshopSelectionProps>) {
    const {
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        register,
        watch,
    } = useForm<WorkshopSelectionSchemaValidation>({
        resolver: zodResolver(workshopSelectionSchema),
        defaultValues: {
            speciesUuid: "",
            farmId: null,
            workshopId: null,
        },
    });

    const watchFarmId = watch("farmId");
    const [searchError, setSearchError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const searchWorkshops = async (formValues: WorkshopSelectionSchemaValidation) => {
        setLoading(true);
        setSearchError(false);
        let res: KyResponse<IFarm>;
        if (formValues.farmId) {
            res = await FarmService.getFarm(formValues.farmId, { speciesUuid: formValues.speciesUuid });
        } else if (formValues.workshopId) {
            res = await FarmService.getFarmByWorkshop(formValues.workshopId, { speciesUuid: formValues.speciesUuid });
        }
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            setFarm(data);
            updateWorkshops(data.workshops.filter((ws) => ws.dpeId !== dpeId).map((workshop) => workshop.id));
            setValue("farmId", data.id, { shouldTouch: true });
        } else {
            setSearchError(true);
            setFarm(null);
        }
        setLoading(false);
    };

    const handleWorkshop = (event: React.ChangeEvent<HTMLInputElement>) => {
        const tempWorkshopIds = [...workshopIds];
        const { value } = event.target;
        workshopIds.indexOf(value) === -1
            ? tempWorkshopIds.push(value)
            : tempWorkshopIds.splice(workshopIds.indexOf(value), 1);
        updateWorkshops(tempWorkshopIds);
    };

    const validateForm = (formValues: WorkshopSelectionSchemaValidation) => {
        searchWorkshops(formValues);
        setSelectedSpecies(species.find((s) => s.uuid === formValues.speciesUuid));
    };

    const renderFarmText = () => {
        if (farm.workshops?.length === 0)
            return (
                <IconBannerText
                    message="L'identifiant renseigné ne correspond à aucun atelier ou élevage pour l'espèce sélectionnée.
                Dans le cas où vous êtes sûr des informations saisies, merci de contacter au plus vite la DDPP concernée pour traitement et mise à jour."
                    color={statusColor.errorText}
                    backgroundColor={statusColor.errorBackground}
                    icon={<CloseSquare />}
                />
            );
        if (farm.closed || farm.workshops.some((ws) => ws.closed))
            return (
                <IconBannerText
                    message={`Établissement trouvé : ${farm.name}.
                    L’identifiant saisi correspond à un atelier ou un élevage fermé. Si vous souhaitez vérifier cette information, merci de contacter au plus vite la DDPP concernée pour investigation et mise à jour si besoin. Vous pouvez tout de même enregistrer cette relation. L'éventuelle réouverture de l’atelier par la DDPP permettra une mise à jour de ce statut dans Calypso.`}
                    color={statusColor.warningText}
                    backgroundColor={statusColor.warningBackground}
                    icon={<Warning />}
                />
            );

        return (
            <IconBannerText
                message={`Établissement trouvé : ${farm.name}`}
                color={statusColor.successText}
                backgroundColor={statusColor.successBackground}
                icon={<Check />}
            />
        );
    };

    return (
        <Card>
            <StyledCardContent>
                <Stack
                    spacing={4}
                    sx={{
                        p: 2,
                    }}
                >
                    <Typography variant="h5">Recherche d'un élevage</Typography>
                    <Stack direction="row" spacing={1}>
                        <FormControl fullWidth error={!!errors.speciesUuid} required>
                            <Controller
                                name="speciesUuid"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <GenericSelect
                                        value={value}
                                        label="Catégorie d'espèces"
                                        error={!!error}
                                        helperText={error?.message}
                                        optionsValue="uuid"
                                        optionsLabel="label"
                                        onChange={onChange}
                                        options={species}
                                        required
                                        size="small"
                                    />
                                )}
                            />
                        </FormControl>

                        <TextField
                            {...register("farmId")}
                            onChange={(event) => {
                                register("farmId").onChange(event);
                                setValue("workshopId", "");
                            }}
                            label="Identifiant de l'élevage"
                            helperText={errors?.farmId?.message}
                            error={!!errors.farmId}
                            size="small"
                            fullWidth
                            variant="outlined"
                            slotProps={{
                                inputLabel: { shrink: !!watchFarmId },
                            }}
                        />
                        <Typography sx={{ alignSelf: "center", color: "#8E8F8E" }}>OU</Typography>
                        <TextField
                            {...register("workshopId")}
                            onChange={(event) => {
                                register("workshopId").onChange(event);
                                setValue("farmId", "");
                            }}
                            label="Identifiant de l'atelier"
                            helperText={errors?.workshopId?.message}
                            error={!!errors.workshopId}
                            variant="outlined"
                            size="small"
                            fullWidth
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="INUAV dans le cas des volailles">
                                                <Help />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    </Stack>
                    <GenericButton
                        sx={{ width: "fit-content" }}
                        label="Rechercher"
                        onClick={handleSubmit(validateForm)}
                        loading={loading}
                    />
                    {farm && renderFarmText()}
                    {searchError && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                backgroundColor: statusColor.errorBackground,
                                borderRadius: 1,
                                gap: 2,
                                px: 1,
                                py: 0.5,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <CloseSquare />
                            </Box>
                            <Typography
                                variant="bold"
                                sx={{
                                    color: statusColor.errorText,
                                }}
                            >
                                L'identifiant renseigné ne correspond à aucun atelier ou élevage pour l'espèce
                                sélectionnée. Dans le cas où vous êtes sûr des informations saisies, merci de contacter
                                au plus vite la DDPP concernée pour traitement et mise à jour.
                            </Typography>
                        </Box>
                    )}
                    {farm && farm.workshops?.length > 0 && (
                        <Stack spacing={1} sx={{ maxWidth: { xs: "fit-content", md: "30%" } }}>
                            <Typography variant="bold"> Liste des ateliers à affecter</Typography>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup sx={{ gap: 1 }}>
                                    {farm.workshops
                                        .filter((ws) => ws.dpeId !== dpeId)
                                        .map((workshop) => (
                                            <FormControlLabel
                                                key={workshop.id}
                                                labelPlacement="start"
                                                sx={{
                                                    justifyContent: "space-between",
                                                    px: 2,
                                                    ml: 0,
                                                    borderRadius: 10,
                                                    fontWeight: 700,
                                                    border: `1px solid ${colors.primaryColor}`,
                                                    background: workshopIds.includes(workshop.id)
                                                        ? colors.lightGreen
                                                        : "",
                                                }}
                                                slotProps={{
                                                    typography: {
                                                        fontWeight: 700,
                                                        color: "primary",
                                                    },
                                                }}
                                                control={
                                                    <Checkbox
                                                        value={workshop.id}
                                                        checked={workshopIds.includes(workshop.id)}
                                                        onChange={handleWorkshop}
                                                    />
                                                }
                                                label={`Atelier ${workshop.id}`}
                                            />
                                        ))}
                                </FormGroup>
                            </FormControl>
                        </Stack>
                    )}
                    {workShopError && <Typography color="error">{workShopError}</Typography>}
                </Stack>
            </StyledCardContent>
        </Card>
    );
}
