import { IReferential } from "./referential";

export enum VaccineStatus {
    ACTIVE = "Actif",
    ARCHIVED = "Archivé",
}

export enum ShippingAddressType {
    ORDER_DPE = "ORDER_DPE",
    SHIPPING_DPE = "SHIPPING_DPE",
    DIFFERENT = "DIFFERENT",
}

export interface IVaccine {
    gtinCode: string;
    name: string;
    packaging: string;
    hatchery: boolean;
    injectionDelay: number;
    useKey: string;
    doseNumber: number;
    creationDate: string;
    updateDate: string;
    deliverer: string;
    status: VaccineStatus;
    dilutableVaccineGtinCode: string;
    type: VaccineType;
}

export enum VaccineType {
    DILUENT = "DILUENT",
    VACCINE_WITH_DILUENT = "VACCINE_WITH_DILUENT",
    VACCINE_WITHOUT_DILUENT = "VACCINE_WITHOUT_DILUENT",
}

export interface VaccinationOutletContext {
    vaccines: IVaccine[];
    scrappingReasons: IReferential[];
    loading: boolean;
}

export interface IScrappingShort {
    date: string;
    dpeId: string;
    batchUuid: string;
    vaccineGtinCode: string;
    quantity: string;
    reason: string;
    comment: string;
}

/// ---- MONITORING INTERVENTIONS

export enum MonitoringInterventionStatus {
    PLANNED = "Planifiée",
    REALIZED = "Réalisée",
    NOT_REALIZED = "Non réalisée",
}

export type VaccineBatch = { number: string; uuid: string };

export interface IMonitoringInterventionForm {
    finalized: boolean;
    generalInformation: IMonitoringInterventionGeneralInformation;
    interventionData: IMonitoringInterventionData;
    workshops: IMonitoringInterventionWorkshop[];
}

export interface IMonitoringInterventionDetails extends Omit<IMonitoringInterventionForm, "generalInformation"> {
    generalInformation: IMonitoringInterventionGeneralInformationDetails;
    certificateFileUuid: string;
}

export interface IMonitoringInterventionGeneralInformation {
    id: string;
    dpeId: string;
    veterinaryId: string;
    interventionDate: string;
    status: keyof typeof MonitoringInterventionStatus | "";
    nonFullfilmentReason: string;
    serologicalSamples: {
        sampleType: string;
        planned: boolean;
        realized: boolean;
    }[];
    plateNumber: string;
    horsePower: string;
    distance: number;
    farmId: string;
}

interface IMonitoringInterventionGeneralInformationDetails extends IMonitoringInterventionGeneralInformation {
    dpeName: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    farmName: string;
    farmCity: string;
    farmDepartmentInseeCode: string;
}

export interface IElisa {
    realized: boolean;
    comment: string;
    bloodSampleNumber: number;
}

export interface IVaccinationSupervision {
    realized: boolean;
    sampleNumber: number;
    compliantAnalysis: boolean;
    comment: string;
}

export interface IMonitoringInterventionWorkshop {
    workshopId: string;
    controls: {
        imeps: string[];
        elisa: IElisa;
        vaccinationSupervision: IVaccinationSupervision;
    };
}

export interface IMonitoringInterventionData {
    complianceClinicalExamination: boolean;
    clinicalNonComplianceReason: string;
    monitoringResultAvailability: boolean;
    comment: string;
    virologySampleNumber: number;
    virologySampleComment: string;
}

// ---- VACCINATION SITE

export enum VaccinationSiteUserType {
    VETERINARY = "Vétérinaire",
    VACCINATION_TEAM = "Équipe de vaccination",
    BREEDER = "Éleveur",
}

export enum InterventionCorrectionType {
    UPDATE = "UPDATE",
    DELETE = "DELETE",
}

export interface IVaccinationSiteShort {
    id: number;
    date: string;
    userType: keyof typeof VaccinationSiteUserType;
    dpeId: string;
    dpeName: string;
    veterinaryId: string;
    veterinaryFirstname: string;
    veterinaryLastname: string;
    farmId: string;
    farmName: string;
    workshopIds: string[];
    departmentInseeCode: string;
    hasCertificate: boolean;
    modifiable: boolean;
    billingStatus: string;
}

export interface IVaccinationSite extends Omit<IVaccinationSiteShort, "workshopIds"> {
    plateNumber: string;
    horsePower: string;
    distance: number;
    interventionDuration: number;
    workshops: {
        id: string;
        city: string;
    }[];
    migrated: boolean;
    dpeCity: string;
    dpePostalCode: string;
}

export interface IWorkshopAudit {
    id: string;
    auditPerformed: boolean;
    complianceType: keyof typeof ComplianceType;
    complianceComment: string;
}

export enum ComplianceType {
    COMPLIANT = "Conforme",
    NOT_COMPLIANT_MINOR = "Non-conformité mineure",
    NOT_COMPLIANT_MAJOR = "Non-conformité majeure",
}

export interface IAudit {
    uuid?: string;
    vaccinationSiteId?: string;
    date: string;
    distance: number;
    plateNumber: string;
    horsePower: string;
    workshops: IWorkshopAudit[];
}

export interface INonCompliance {
    vaccinationSiteId?: string;
    uuid?: string;
    distance: number;
    plateNumber: string;
    horsePower: string;
    date: string;
    workshops: IWorkshopNonCompliance[];
}

export interface IWorkshopNonCompliance {
    id: string;
    inspectionPerformed: boolean;
    actionPerformedType: keyof typeof NonComplianceAction;
    comment: string;
}

export enum NonComplianceAction {
    REVACCINATION = "Revaccination",
    PHARMACOLOGICAL_VIGILANCE_DECLARATION = "Déclaration de pharmacovigilance",
    REINFORCEMENT_OF_MONITORING = "Renforcement de la surveillance",
    INFORMATION_TO_DDPP = "Information à la DDetsPP",
    OTHER = "Autre",
}

export enum InjectionType {
    FIRST_DOSE = "1ère injection",
    SECOND_DOSE = "2ème injection",
    REMINDER = "Rappel",
}

export enum VaccinationInterventionType {
    BREEDING = "Élevage",
    HATCHERY = "Couvoir",
}

export enum VaccineUsage {
    IAHP = "IAHP",
    HED_CATTLE = "HED_CATTLE",
    BLUE_TONGUE_CATTLE = "BLUE_TONGUE_CATTLE",
    BLUE_TONGUE_SHEEP = "BLUE_TONGUE_SHEEP",
}
