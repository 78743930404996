// === NPM
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
// === LOCAL
import useIsFirstRender from "@/hooks/useIsFirstRender";
import { HttpStatus, ICityFilter } from "@/interfaces/global";
import ReferentialService from "@/services/ReferentialService";
import GenericAutocomplete from "../../inputs/GenericAutocomplete";

interface CitySearchFilterProps {
    handleChange: (event: React.SyntheticEvent) => void;
}

export default function CitySearchFilter({ handleChange }: Readonly<CitySearchFilterProps>) {
    const [city, setCity] = useState<{ name: string; postalCode: string }>(null);
    const [search, setSearch] = useState<string>("");
    const [citiesOptions, setCitiesOptions] = useState<ICityFilter[]>([]);

    const timeoutRef = useRef(null);
    const isFirstRender = useIsFirstRender();

    useEffect(() => {
        if (isFirstRender) {
            return;
        }
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(async () => {
            getCities();
        }, 500);
    }, [search]);

    const getCities = async () => {
        const payload = { page: 0, size: 20 };
        if (search && !isNaN(+search.charAt(0))) payload["postalCode"] = search;
        if (search && search.charAt(0).match(/[a-zA-Z]/)) payload["name"] = search;

        const res = await ReferentialService.getCities(payload);
        const options: ICityFilter[] = [];
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            data.forEach((c) => {
                c.postalCodes.forEach((p) => options.push({ name: c.name, postalCode: p, inseeCode: c.inseeCode }));
            });
        }
        setCitiesOptions(options);
    };

    const renderCityOptions = (p, o) => (
        <Box component="li" {...p} key={p.id}>
            {o.name} ({o.postalCode})
        </Box>
    );

    return (
        <GenericAutocomplete
            value={city}
            options={citiesOptions}
            optionValue="label"
            onChange={(event) => {
                setCity(event.target.value);
                handleChange(event);
            }}
            label="Ville ou code postal"
            renderOption={renderCityOptions}
            onInputChange={(event, newInputValue) => {
                setSearch(newInputValue);
            }}
            inputValue={search}
            isOptionEqualToValue={(option, value) =>
                option.name === value?.name && option.postalCode === value?.postalCode
            }
            getOptionLabel={(option) =>
                search.charAt(0).match(/[a-zA-Z]/) ? option.name : option.postalCode ?? ""
            }
            variant="standard"
        />
   );
}
