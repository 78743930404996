// === NPM
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Card, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import FileUploader from "@/components/generics/FileUploader";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { IFarm } from "@/interfaces/farm";
import { HttpStatus } from "@/interfaces/global";
import { ISpeciesDetails } from "@/interfaces/referential";
import { DocumentMimeTypeFile } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { FORM_TEXT } from "@/resources/FormUtils";
import { validateFile } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import FarmService from "@/services/FarmService";
import { FarmOutletContext } from "../../interface";
import ConfirmDialog from "./containers/ConfirmDialog";
import ConflictDialog from "./containers/ConflictDialog";
import WorkshopSelection from "./containers/WorkshopSelection";

const declareRelationSchema = z
    .object({
        dpeId: z
            .string({
                required_error: FORM_TEXT.required,
                invalid_type_error: FORM_TEXT.required,
            })
            .trim()
            .min(1, { message: FORM_TEXT.required }),
        openingDate: z
            .string({
                required_error: FORM_TEXT.required,
                invalid_type_error: FORM_TEXT.required,
            })
            .trim()
            .min(1, { message: FORM_TEXT.required })
            .refine((value) => (value ? DateTime.fromISO(value) <= DateTime.now().startOf("day") : false), {
                message: "La date d'ouverture de la relation ne peut pas être dans le futur",
            }),
        workshopIds: z.array(z.string()).min(1, "Veuillez sélectionner au moins un atelier"),
        withFile: z.enum(["true", "false"], { required_error: "Veuillez sélectionner une option" }),
        files: z.array(z.instanceof(File)).max(1, "Maximum 1 fichier autorisé"),
    })
    .superRefine((values, context) => {
        if (values.withFile === "true" && values.files.length === 0) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Le fichier est obligatoire",
                path: ["files"],
            });
        }
    });

type DeclareRelationValidationSchema = z.infer<typeof declareRelationSchema>;

export default function DeclareRelation() {
    const auth = useAuth();
    const navigate = useNavigate();

    const {
        formState: { errors },
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
    } = useForm<DeclareRelationValidationSchema>({
        resolver: zodResolver(declareRelationSchema),
        defaultValues: {
            dpeId: "",
            openingDate: "",
            workshopIds: [],
            withFile: "true",
            files: [],
        },
    });

    const { species } = useOutletContext<FarmOutletContext>();
    const [farm, setFarm] = useState<IFarm>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openConflictDialog, setOpenConflictDialog] = useState<boolean>(false);
    const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
    const [selectedSpecies, setSelectedSpecies] = useState<ISpeciesDetails>(null);

    const watchDpeId = watch("dpeId");
    const watchWithFile = watch("withFile");
    const watchWorkshopIds = watch("workshopIds");

    const postAlert = async () => {
        const res = await FarmService.postWorkshopAlert(
            farm.workshops.filter((w) => w.dpeId && getValues().workshopIds.includes(w.id)).map((w) => w.id),
            getValues().dpeId,
            getValues().openingDate
        );
        if (res.status === HttpStatus.ACCEPTED) {
            setOpenConflictDialog(false);
            toast.success("La relation a été transférée au référent IAHP pour traitement.");
            navigate(routerLinks.iahp.farm.view());
        }
    };

    const patchFarmWorkshop = async () => {
        const res = await FarmService.patchFarmWorkshop(
            farm.id,
            {
                workshopIds: getValues().workshopIds,
                dpeId: getValues().dpeId,
                openingDate: getValues().openingDate,
            },
            getValues().withFile ? files?.[0] : null
        );
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("La relation a été créée avec succès.");
            auth.refreshUserInfo();
            navigate(routerLinks.iahp.farm.view());
        }
    };

    const updateWorkshops = (dpeId: string) => {
        if (!farm) return;
        setValue(
            "workshopIds",
            farm.workshops.filter((ws) => ws.dpeId !== dpeId).map((workshop) => workshop.id),
            { shouldDirty: true }
        );
    };

    const updateClickedWorkshops = (wsList: string[]) => {
        setValue("workshopIds", wsList, { shouldDirty: true });
    };

    const validateForm = (formValues: DeclareRelationValidationSchema) => {
        const worksShops = farm.workshops.filter((w) => formValues.workshopIds.includes(w.id));
        setFiles(formValues.files);
        if (worksShops.some((w) => w.dpeId)) {
            setOpenConflictDialog(true);
        } else {
            setOpenConfirmDialog(true);
        }
    };

    const handleConfirmCancel = (confirm: boolean) => {
        setOpenCancelDialog(false);
        if (confirm) navigate(routerLinks.iahp.farm.view());
    };

    const renderDpe = () => (
        <Card>
            <StyledCardContent>
                <Stack
                    spacing={2}
                    direction="row"
                    sx={{
                        p: 2,
                    }}
                >
                    <FormControl error={!!errors.dpeId} required sx={{ width: { xs: "100%", md: "50%" } }}>
                        <Controller
                            name="dpeId"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <GenericSelect
                                    value={value}
                                    label="DPE"
                                    onChange={(event) => {
                                        onChange(event);
                                        updateWorkshops(event.target.value);
                                    }}
                                    required
                                    options={auth.userInfo.properties.dpes.map((dpe) => ({
                                        id: dpe.id,
                                        name: `${dpe.id} - ${dpe.name}`,
                                    }))}
                                    optionsValue="id"
                                    optionsLabel="name"
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl error={!!errors.openingDate} required fullWidth>
                        <Controller
                            name="openingDate"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <GenericDatePicker
                                    value={value}
                                    onChange={onChange}
                                    label="Date d'ouverture de la relation VS"
                                    required
                                    name="openingDate"
                                    maxDate={DateTime.now()}
                                    error={!!error}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </FormControl>
                </Stack>
            </StyledCardContent>
        </Card>
    );

    const renderRelationFile = () => (
        <Card>
            <StyledCardContent>
                <Stack
                    spacing={4}
                    sx={{
                        p: 2,
                    }}
                >
                    <Typography variant="h5">Document CERFA</Typography>
                    <FormControl>
                        <Controller
                            name="withFile"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <RadioGroup
                                    value={value}
                                    onChange={(event) => {
                                        onChange(event);
                                        if (event.target.value === "false") setValue("files", []);
                                    }}
                                >
                                    <FormControlLabel
                                        value={"true"}
                                        control={<Radio />}
                                        label="Je dispose du CERFA permettant de confirmer la validité de cette déclaration"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={"false"}
                                        control={<Radio />}
                                        label="Je ne dispose pas du CERFA permettant de confirmer la validité de cette déclaration"
                                        labelPlacement="end"
                                    />

                                    {error && (
                                        <Typography
                                            color="error"
                                            sx={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {error?.message}
                                        </Typography>
                                    )}
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    {watchWithFile === "true" && (
                        <FormControl fullWidth error={!!errors.files} required>
                            <Controller
                                name="files"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <FileUploader
                                            files={value}
                                            setFiles={onChange}
                                            required
                                            label="Déposer le fichier"
                                            info="Les formats acceptés sont .docx, .jpg, .pdf et la taille des fichiers ne doit pas excéder 10 Mo."
                                            acceptedFiles={DocumentMimeTypeFile}
                                            validator={validateFile}
                                        />
                                        {error?.message && (
                                            <Typography
                                                color="error"
                                                sx={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                {error?.message}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        </FormControl>
                    )}

                    <IconBannerText
                        message="Nous vous recommandons fortement de télécharger le CERFA et/ou de vérifier avec l'éleveur la validité de cette déclaration. En cas de conflit de relation avec un autre DPE, la relation ne sera active dans Calypso qu’après traitement par la DDPP."
                        color={statusColor.warningText}
                        backgroundColor={statusColor.warningBackground}
                        icon={<Warning />}
                    />
                </Stack>
            </StyledCardContent>
        </Card>
    );

    return (
        <>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(validateForm)}
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <Stack spacing={2}>
                    <Typography variant="h4">Déclarer une relation vétérinaire sanitaire</Typography>
                    <Typography variant="h5">DPE à affecter</Typography>
                    {renderDpe()}
                    <Typography variant="h5">Élevage et ateliers</Typography>
                    <WorkshopSelection
                        updateWorkshops={updateClickedWorkshops}
                        workshopIds={watchWorkshopIds}
                        species={species.filter((s) => !s.parentUuid)}
                        farm={farm}
                        setFarm={setFarm}
                        workShopError={errors?.workshopIds?.message}
                        setSelectedSpecies={setSelectedSpecies}
                        dpeId={watchDpeId}
                    />
                    {farm && farm.workshops.length > 0 && (
                        <>
                            <Typography variant="h5">Validité de la déclaration</Typography>
                            {renderRelationFile()}
                        </>
                    )}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: 2,
                            width: "100%",
                        }}
                    >
                        <SecondaryButton variant="outlined" onClick={() => setOpenCancelDialog(true)}>
                            Annuler
                        </SecondaryButton>
                        <Button type="submit" variant="contained">
                            Vérifier la relation
                        </Button>
                    </Box>
                </Stack>
            </Box>
            {openConflictDialog && (
                <ConflictDialog
                    onClose={() => setOpenConflictDialog(false)}
                    workshops={farm.workshops.filter((w) => w.dpeId && getValues().workshopIds.includes(w.id))}
                    onConfirm={postAlert}
                />
            )}
            {openConfirmDialog && (
                <ConfirmDialog
                    onClose={() => setOpenConfirmDialog(false)}
                    onConfirm={patchFarmWorkshop}
                    farm={farm}
                    dpe={auth.userInfo.properties.dpes.find((d) => d.id === getValues().dpeId)}
                    fileName={files[0]?.name}
                    workshops={farm.workshops.filter((w) => getValues().workshopIds.includes(w.id))}
                    species={selectedSpecies}
                    openingDate={getValues("openingDate")}
                />
            )}
            {openCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleConfirmCancel}
                />
            )}
        </>
    );
}
