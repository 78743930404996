// === Import: NPM
import React, { PropsWithChildren, useContext, useState } from "react";
// === Import: LOCAL
import { HttpStatus } from "@/interfaces/global";
import DownloadService from "@/services/DownloadService";
import { IDownload } from "./interface";

// TODO move it to context folders

export interface UseDownload {
    downloads: IDownload[];
    availableDownloadCount: number;
    getAvailableDownloads: () => void;
}

export const downloadContext = React.createContext<UseDownload>({
    downloads: [],
    availableDownloadCount: 0,
    getAvailableDownloads: null,
});

export const useDownload = (): UseDownload => {
    return useContext(downloadContext);
};

export const DownloadProvider = ({ children }: PropsWithChildren) => {
    const download = useDownloadProvider();
    return <downloadContext.Provider value={download}>{children}</downloadContext.Provider>;
};

const useDownloadProvider = () => {
    const [downloads, setDownloads] = useState<IDownload[]>([]);
    const [availableDownloadCount, setAvailableDownloadCount] = useState<number>(0);

    const getAvailableDownloads = async () => {
        const res = await DownloadService.getDownloads();
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            setDownloads(data);
            setAvailableDownloadCount(data.length);
        }
    };

    return {
        downloads,
        availableDownloadCount,
        getAvailableDownloads,
    };
};
