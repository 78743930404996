// === NPM
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// === LOCAL
import PageContent from "@/components/generics/layout/PageContent";
import { HttpStatus } from "@/interfaces/global";
import { ISpeciesDetails } from "@/interfaces/referential";
import { UserSituation } from "@/resources/PermissionConstant";
import PermissionsTabs from "@/resources/PermissionsTabs";
import { routerLinks } from "@/routers/RouterConstant";
import HealthReportingService from "@/services/HealthReportingService";
import ReferentialService from "@/services/ReferentialService";
import { FormHealthReportingProvider } from "./containers/HealthReportingForm/containers/useFormHealthReporting";
import { IAnomalyType } from "./interface";

export default function AnimalAbuse() {
    const [loading, setLoading] = useState<boolean>(true);
    const [anomalyTypes, setAnomalyTypes] = useState<IAnomalyType[]>([]);
    const [species, setSpecies] = useState<ISpeciesDetails[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        Promise.all([getSpecies(), getAnomalyTypes()]).then(() => {
            setLoading(false);
        });
    };

    const getAnomalyTypes = async () => {
        const res = await HealthReportingService.getAnomalyTypes();
        if (res.status === HttpStatus.OK) {
            setAnomalyTypes(await res.json());
        }
    };

    const getSpecies = async () => {
        const res = await ReferentialService.getSpecies({ use: ["health_reporting"], sorts: ["label", "asc"] });
        if (res.status === HttpStatus.OK) {
            setSpecies(await res.json());
        }
    };

    return (
        <>
            <PermissionsTabs
                tabs={[
                    {
                        url: routerLinks.healthReporting.animalAbuse.view(),
                        label: "Mes signalements de maltraitance",
                    },
                    {
                        url: routerLinks.healthReporting.animalAbuse.form(),
                        label: "Déclarer un signalement de maltraitance",
                        permissions: [UserSituation.REGISTERED_IN_PRACTICE],
                    },
                ]}
            />
            <PageContent>
                <FormHealthReportingProvider>
                    <Outlet context={{ loading, species, anomalyTypes }} />
                </FormHealthReportingProvider>
            </PageContent>
        </>
    );
}
