// === Import: NPM
import React from "react";
import { Link } from "@mui/material";
import { saveAs } from "file-saver";
// === Import: LOCAL
import { HttpStatus } from "@/interfaces/global";
import { IAttachment } from "@/interfaces/notification";
import NotificationService from "@/services/NotificationService";

interface DownloadLinkProps {
    file: IAttachment;
    notificationUuid: string;
}

export default function DownloadLink({ file, notificationUuid }: Readonly<DownloadLinkProps>) {
    const getFile = async () => {
        const res = await NotificationService.getUserNotificationFile(notificationUuid, file.uuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            saveAs(file, res.headers.get("content-disposition").split("filename=")[1].slice(1, -1));
        }
    };

    return (
        (<Link component="button" variant="body2" onClick={() => getFile()} sx={{
            color: "inherit"
        }}>
            {file.filename}
        </Link>)
    );
}
