// === NPM
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Box, Card, CardContent, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { KyResponse } from "ky";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as GenerateExport } from "@/assets/icons/actions/generateExport.svg";
import GenericExportDialog from "@/components/generics/dialogs/GenericExportDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PageContent from "@/components/generics/layout/PageContent";
import GenericTable from "@/components/generics/table/GenericTable";
import InfoText from "@/components/generics/text/InfoText";
import { useDepartments } from "@/context/useDepartmentContext";
import { ActionsColumnProps, FilterType, HttpStatus, IExportParams } from "@/interfaces/global";
import { IDpeProperties, UserType } from "@/interfaces/user";
import { defaultPagination } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { createPayload } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import VaccinationService from "@/services/VaccinationService";
import { IAdminExportFilters, IExportFilters } from "./interface";

enum ExportType {
    VACCINATION_INTERVENTION = "VACCINATION_INTERVENTION",
    MONITORING_INTERVENTION = "MONITORING_INTERVENTION",
    RELATION = "RELATION",
    VACCINATION_SITES = "VACCINATION_SITES",
}

interface ExportRow {
    id: ExportType;
    exportName: string;
    exportDescription: string;
}

export default function Exports() {
    const auth = useAuth();
    const { departments } = useDepartments();
    const isVeterinary: boolean = auth.userInfo.type === UserType.VETERINARY;
    const currentYear = DateTime.now().year.toString();
    const veterinaryInitialValues: IExportFilters = {
        year: currentYear,
        departmentsInseeCode: [],
        dpesId: [],
    };
    const adminInitialValues: IAdminExportFilters = {
        year: currentYear,
        departmentsInseeCode: [],
    };
    const dpes: IDpeProperties[] = isVeterinary ? auth.userInfo.properties.dpes : [];

    const [exportFilters, setExportFilters] = useState<IExportFilters>(
        isVeterinary ? veterinaryInitialValues : adminInitialValues
    );
    const [openExportDialog, setOpenExportDialog] = useState<boolean>(false);
    const [loadingContent, setLoadingContent] = useState<boolean>(false);

    const getExportFile = async (exportType: ExportType) => {
        switch (exportType) {
            case ExportType.VACCINATION_INTERVENTION:
                getFile(VaccinationService.getVaccinationInterventionExport);
                return;
            case ExportType.MONITORING_INTERVENTION:
                getFile(VaccinationService.getMonitoringInterventionExport);
                return;
            case ExportType.RELATION:
                getFile(VaccinationService.getRelationExport);
                return;
            case ExportType.VACCINATION_SITES:
                getFile(VaccinationService.getVaccinationSitesExport);
                return;
        }
    };

    const getExportParams = () => {
        const startDate = DateTime.fromObject({ year: +exportFilters.year }).startOf("year").toISO();
        const endDate = DateTime.fromObject({ year: +exportFilters.year }).endOf("year").toISO();
        const transformedPayload = {
            exportDate: startDate + "," + endDate,
            departmentsInseeCode: exportFilters.departmentsInseeCode.join(","),
            dpesId: isVeterinary ? exportFilters.dpesId.join(",") : null,
        };
        return createPayload(transformedPayload);
    };

    const getFile = async (exportMethod: (payload: IExportParams) => Promise<KyResponse>) => {
        setLoadingContent(true);
        const params = getExportParams();
        const res = await exportMethod({ params });
        if (res.status === HttpStatus.ACCEPTED) {
            toast.success(
                "Votre fichier est en cours de génération, vous pourrez le récupérer dans quelques instants dans la page Mes téléchargements."
            );
        }
        setLoadingContent(false);
    };

    const handleExportIahpData = async (filename: string) => {
        const params = getExportParams();
        const res = await VaccinationService.exportIahpData({ filename, params });
        if (res.status === HttpStatus.ACCEPTED) {
            toast.success(
                "Votre fichier est en cours de génération, vous pourrez le récupérer dans quelques instants dans la page Mes téléchargements."
            );
            setOpenExportDialog(false);
        }
    };

    const exportRows: ExportRow[] = [
        {
            id: ExportType.VACCINATION_INTERVENTION,
            exportName: "Interventions de vaccination",
            exportDescription: "Fichier contenant les interventions de vaccination",
        },
        {
            id: ExportType.MONITORING_INTERVENTION,
            exportName: "Interventions de surveillance",
            exportDescription: "Fichier contenant les interventions de surveillance",
        },
        {
            id: ExportType.RELATION,
            exportName: "Relations VS-Ateliers",
            exportDescription: "Fichier contenant les relations vétérinaires sanitaires et ateliers",
        },
        ...(auth.userInfo.situation === UserSituation.ADMIN_CALYPSO ||
        auth.userInfo.situation === UserSituation.ADMIN_DDPP
            ? [
                  {
                      id: ExportType.VACCINATION_SITES,
                      exportName: "Prestations de chantiers de vaccination",
                      exportDescription: "Fichier contenant les prestations des chantiers de vaccination",
                  },
              ]
            : []),
    ];

    const columns = [
        {
            field: "exportName",
            headerName: "Type de fichier",
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: "exportDescription",
            headerName: "Description du fichier",
            flex: 2,
            sortable: false,
            filterable: false,
        },
        {
            ...ActionsColumnProps,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <Tooltip
                        title={
                            !exportFilters.year
                                ? "Veuillez d'abord sélectionner une année"
                                : "Générer et télécharger le document"
                        }
                    >
                        <span>
                            <IconButton
                                onClick={() => getExportFile(params.row.id)}
                                sx={{ alignSelf: "center", color: colors.primaryColor }}
                                disabled={!exportFilters.year || loadingContent}
                            >
                                <GenerateExport />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            ),
            width: 100,
        },
    ];

    const adminFilterConfigurations: FilterConfigurations<IAdminExportFilters> = {
        year: { label: "Année", type: FilterType.YEARPICKER },
        departmentsInseeCode: {
            label: "Département INUAV",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
    };

    const veterinaryFilterConfigurations: FilterConfigurations<IExportFilters> = {
        year: { label: "Année", type: FilterType.YEARPICKER },
        dpesId: {
            label: "DPE",
            type: FilterType.SELECT,
            values: dpes.map((dpe) => ({ label: `${dpe.id} - ${dpe.name}`, key: dpe.id })),
        },
        departmentsInseeCode: {
            label: "Département INUAV",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
    };

    return (
        <PageContent>
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography variant="h4">Mes exports des données de vaccination IAHP</Typography>
                </Box>
                <Card>
                    <CardContent>
                        <InfoText message="Veuillez renseigner au moins une année pour générer le fichier d'export" />
                        <GenericFilters
                            inputFilters={exportFilters}
                            filterConfigurations={
                                isVeterinary ? veterinaryFilterConfigurations : adminFilterConfigurations
                            }
                            initialValues={isVeterinary ? veterinaryInitialValues : adminInitialValues}
                            setInputFilters={setExportFilters}
                        />
                        <GenericTable
                            rows={exportRows}
                            columns={columns}
                            autoHeight
                            getRowId={(row) => row.id}
                            pageSize={defaultPagination.pageSize}
                            disableToolbarButton={true}
                            disableCsvExportButton={false}
                            csvExportButtonLabel="Télécharger tous les documents"
                            onCsvExportClick={() => setOpenExportDialog(true)}
                            hideFooter
                        />
                    </CardContent>
                </Card>
            </Stack>
            {openExportDialog && (
                <GenericExportDialog
                    onClose={() => setOpenExportDialog(false)}
                    onValid={handleExportIahpData}
                    title="Export des données de vaccination IAHP"
                />
            )}
        </PageContent>
    );
}
