// === NPM
import React from "react";
import { Stack, Typography } from "@mui/material";
// === LOCAL
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import BnoApiKeys from "./IcadApiKeys/BnoApiKeys";
import SsoApiKeys from "./SsoApiKey/SsoApiKeys";
import VaccinationInterventionApiKeys from "./VaccinationInterventionApiKeys/VaccinationInterventionApiKeys";

export default function SIPartners() {
    return (
        (<Stack
            spacing={2}
            sx={{
                width: "100%",
                py: 2
            }}>
            <Typography variant="h4">SI Partenaires</Typography>
            <GenericAccordion title="Comptes SSO" defaultExpanded>
                <SsoApiKeys />
            </GenericAccordion>
            <GenericAccordion title="Comptes Interventions de vaccinations" defaultExpanded>
                <VaccinationInterventionApiKeys />
            </GenericAccordion>
            <GenericAccordion title="Comptes BNO" defaultExpanded>
                <BnoApiKeys />
            </GenericAccordion>
        </Stack>)
    );
}
