// === NPM
import React, { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardActions, CardHeader, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Plus } from "@/assets/icons/actions/plus.svg";
import { ReactComponent as Warning } from "@/assets/icons/shared/danger-triangle.svg";
import { ReactComponent as Duration } from "@/assets/icons/shared/duration.svg";
import { ReactComponent as Pet } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Star } from "@/assets/icons/shared/star.svg";
import { ReactComponent as Attendees } from "@/assets/icons/training/catalog/attendees.svg";
import { ReactComponent as Type } from "@/assets/icons/training/catalog/type.svg";
import ArchiveAction from "@/components/generics/actions/ArchiveAction";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import IconBannerText from "@/components/generics/IconBannerText";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { IHealthAccreditationTrainingCreate } from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { IHealthAccreditationTraining, IHealthAccreditationTrainingShort, ITrainingType } from "@/interfaces/training";
import { colors, statusColor } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { routerLinks } from "@/routers/RouterConstant";
import TrainingService from "@/services/TrainingService";
import CreateOrUpdateTraining from "./CreateOrUpdateTraining";
import ViewDialog from "./ViewDialog";

interface TrainingCardProps {
    training: IHealthAccreditationTrainingShort;
    types: ITrainingType[];
    refresh: () => void;
}

export default function TrainingCard({ training, types, refresh }: Readonly<TrainingCardProps>) {
    const navigate = useNavigate();

    const [completeTraining, setCompleteTraining] = useState<IHealthAccreditationTraining>(null);
    const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
    const [openArchiveDialog, setOpenArchiveDialog] = useState<boolean>(false);

    const updateTraining = async (data: IHealthAccreditationTrainingCreate, files?: File[]) => {
        const res = await TrainingService.putHealthAccreditationTraining(training.uuid, data, files ?? null);
        if (res.status === HttpStatus.OK) {
            refresh();
            setOpenUpdateDialog(false);
            toast.success("Formation mise à jour avec succès");
        }
    };

    const archiveTraining = async () => {
        const res = await TrainingService.archiveHealthAccreditationTraining(training.uuid);
        if (res.status === HttpStatus.NO_CONTENT) {
            refresh();
            setOpenArchiveDialog(false);
            toast.success("Formation archivée avec succès");
        }
    };

    const handleOpenDialog = async (setOpenDialog: Dispatch<SetStateAction<boolean>>) => {
        const res = await TrainingService.getHealthAccreditationTraining(training.uuid);
        if (res.status === HttpStatus.OK) {
            setCompleteTraining(await res.json());
            setOpenDialog(true);
        }
    };

    return (<>
        <Card
            variant="outlined"
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: training.archived && colors.disabled,
            }}
        >
            <CardHeader
                title={
                    <Typography variant="h5" sx={{
                        color: colors.primaryColor
                    }}>
                        {`${training.internalId} : ${training.title}`}
                    </Typography>
                }
                sx={{ m: 2 }}
            />
            <StyledCardContent sx={{ mx: 2 }}>
                <Stack spacing={1}>
                    <GenericIconText
                        icon={<Star />}
                        text={`${training.ectsPoints} ECTS`}
                        iconTooltip="Points ECTS"
                    />
                    <GenericIconText
                        icon={<Type />}
                        text={training.type.label}
                        iconTooltip="Type de la formation"
                    />
                    <GenericIconText icon={<Pet />} text={training.targetPublic} iconTooltip="Public cible" />
                    <GenericIconText
                        icon={<Attendees />}
                        text={`${training.inscriptionNumberMin} à ${training.inscriptionNumberMax} participants`}
                        iconTooltip="Participants"
                    />
                    <GenericIconText icon={<Duration />} text={`${training.duration}h`} iconTooltip="Durée" />
                </Stack>
            </StyledCardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                <ViewAction title="Voir le détail" onClick={() => handleOpenDialog(setOpenViewDialog)} />
                {!training.archived && (
                    <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_OVVT, UserSituation.USER_OVVT]}>
                        <IconActionButton
                            title="Créer une session"
                            icon={<Plus />}
                            onClick={() =>
                                navigate(routerLinks.healthAccreditationTraining.sessions.form(), {
                                    state: {
                                        session: {
                                            healthAccreditationTraining: {
                                                uuid: training.uuid,
                                            },
                                        },
                                    },
                                })
                            }
                        />
                        <PermissionsCheck requiredPermissions={[UserSituation.ADMIN_OVVT]}>
                            <EditAction title="Modifier" onClick={() => handleOpenDialog(setOpenUpdateDialog)} />
                            <ArchiveAction title="Archiver" onClick={() => setOpenArchiveDialog(true)} />
                        </PermissionsCheck>
                    </PermissionsCheck>
                )}
            </CardActions>
        </Card>
        {openViewDialog && <ViewDialog training={completeTraining} onClose={() => setOpenViewDialog(false)} />}
        {openUpdateDialog && (
            <CreateOrUpdateTraining
                training={completeTraining}
                types={types}
                onClose={() => setOpenUpdateDialog(false)}
                onValid={updateTraining}
            />
        )}
        {openArchiveDialog && (
            <GenericDialog
                title="Archivage d'une formation"
                onClose={() => setOpenArchiveDialog(false)}
                renderActions={() => (
                    <GenericActionsDialog
                        onClose={() => setOpenArchiveDialog(false)}
                        onSubmit={archiveTraining}
                        closeLabel="Non"
                        validLabel="Oui"
                    />
                )}
                renderContent={() => (
                    <Stack spacing={2}>
                        {training.hasSessions && (
                            <IconBannerText
                                icon={<Warning />}
                                message={"Attention :\nDes sessions sont actuellement associées à cette formation."}
                                color={statusColor.warningText}
                                backgroundColor={statusColor.warningBackground}
                            />
                        )}
                        <Typography>
                            Êtes-vous sûr de vouloir archiver la formation{" "}
                            <Typography variant="bold">{training.title}</Typography> ?
                        </Typography>
                        <Typography>
                            En archivant la formation, vous ne pourrez plus créer de sessions associées à cette
                            formation. Les sessions actuellement associées ne seront pas impactées.
                        </Typography>
                    </Stack>
                )}
            />
        )}
    </>);
}
