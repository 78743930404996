// === NPM
import React, { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Delete } from "@mui/icons-material";
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
// === LOCAL
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { TrainerType } from "@/interfaces/training";
import { IVeterinaryShort } from "@/interfaces/user";
import { convertEnumToKeyLabelObject } from "@/resources/utils";
import UserService from "@/services/UserService";

interface TrainerCardProps {
    index: number;
    formValidation: UseFormReturn<{
        trainers?: {
            trainerType?: string;
            veterinaryId?: string;
            lastname?: string;
            firstname?: string;
        }[];
    }>;
    handleDeleteTrainer: (index: number) => void;
    trainersLength: number;
}

export default function TrainerCard({
    index,
    formValidation,
    handleDeleteTrainer,
    trainersLength,
}: Readonly<TrainerCardProps>) {
    const {
        formState: { errors },
        register,
        setValue,
        watch,
    } = formValidation;

    const errorsTrainer = errors?.trainers?.[index];
    const trainer = watch(`trainers.${index}`);

    const [veterinaries, setVeterinaries] = useState<IVeterinaryShort[]>([]);
    const [selectedVeterinary, setSelectedVeterinary] = useState<IVeterinaryShort>(null);
    const [veterinaryIdSearch, setVeterinaryIdSearch] = useState<string>(trainer?.veterinaryId ?? "");

    useEffect(() => {
        if (veterinaryIdSearch) {
            getVeterinaries().then(async (res) => {
                const data = await res.json();
                setSelectedVeterinary(data.find((v) => v.id === veterinaryIdSearch) ?? null);
            });
        }
    }, []);

    useTimeout(() => {
        if (veterinaryIdSearch) {
            getVeterinaries();
        }
    }, [veterinaryIdSearch]);

    const getVeterinaries = async () => {
        const payload = { page: 0, size: 20, id: veterinaryIdSearch };
        const res = await UserService.getVeterinaries(payload);
        if (res.status === HttpStatus.OK) {
            setVeterinaries(await res.json());
        }
        return res;
    };

    const onChangeVeterinary = (event) => {
        if (event?.target?.value) {
            setSelectedVeterinary(event.target.value);
            setValue(`trainers.${index}.veterinaryId`, event.target.value?.id);
        }
    };

    return (
        (<Card sx={{ p: 2, m: 2 }}>
            <StyledCardContent>
                <Stack spacing={2}>
                    <Box
                        sx={{
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "center"
                        }}>
                        <Typography variant="bold">Formateur {index + 1}</Typography>
                        {trainersLength > 1 && (
                            <Button startIcon={<Delete />} color="error" onClick={() => handleDeleteTrainer(index)}>
                                Supprimer le formateur
                            </Button>
                        )}
                    </Box>
                    <Controller
                        name={`trainers.${index}.trainerType`}
                        control={formValidation.control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <GenericSelect
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error?.message}
                                label="Type de formateur"
                                required
                                options={convertEnumToKeyLabelObject(TrainerType)}
                                optionsLabel="label"
                                optionsValue="key"
                            />
                        )}
                    />
                    {TrainerType[trainer?.trainerType] === TrainerType.VETERINARY && (
                        <GenericAutocomplete
                            value={selectedVeterinary}
                            onChange={onChangeVeterinary}
                            onInputChange={(event, value) => setVeterinaryIdSearch(value)}
                            label="Vétérinaire formateur"
                            options={veterinaries}
                            required
                            getOptionLabel={(option) =>
                                option.id ? `${option.id} - ${option.lastname} ${option.firstname}` : ""
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            error={!!errorsTrainer?.veterinaryId}
                            helperText={errorsTrainer?.veterinaryId?.message}
                        />
                    )}
                    {TrainerType[trainer?.trainerType] === TrainerType.MINISTRY_AGENT && (
                        <Stack spacing={1} direction="row">
                            <TextField
                                {...register(`trainers.${index}.lastname`)}
                                error={!!errorsTrainer?.lastname}
                                helperText={errorsTrainer?.lastname?.message}
                                label="Nom de l'agent"
                                fullWidth
                                required
                            />
                            <TextField
                                {...register(`trainers.${index}.firstname`)}
                                error={!!errorsTrainer?.firstname}
                                helperText={errorsTrainer?.firstname?.message}
                                label="Prénom de l'agent"
                                fullWidth
                                required
                            />
                        </Stack>
                    )}
                </Stack>
            </StyledCardContent>
        </Card>)
    );
}
