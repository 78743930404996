// === NPM
import { KyResponse } from "ky";
// === LOCAL
import {
    IAccount,
    IAccountType,
    IAccountWithKeys,
    IAlert,
    IApiKey,
    IApiKeyType,
    ICreateAccount,
    IPeriodicity,
    IProfile,
} from "@/components/Administration/containers/ApiKeys/interface";
import { HttpMethod, Loader } from "@/interfaces/global";
import { IOrganization } from "@/interfaces/referential";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class ApiKeyService {
    // ----------- ACCOUNTS -----------

    async getAccounts(type: string): Promise<KyResponse<IAccountWithKeys[]>> {
        return HttpService.fetch(endpoints.apiKeyService.account.base, {
            method: HttpMethod.GET,
            searchParams: formatParams({ type }),
            headers: { loader: Loader.TABLE },
        });
    }

    async patchAccount(uuid: string, payload: { email: string; endDate: string }): Promise<KyResponse<IAccount>> {
        return HttpService.fetch(endpoints.apiKeyService.account.accountByUuid(uuid), {
            method: HttpMethod.PATCH,
            json: payload,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteAccount(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.account.accountByUuid(uuid), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    async postAccountToken(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.account.accountToken(uuid), {
            method: HttpMethod.POST,
            headers: { loader: Loader.REQUEST },
        });
    }

    async createDrugTransferAccount(json: ICreateAccount): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.account.drugTransfer(), {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async createContinuousTrainingAccount(json: ICreateAccount): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.account.continuousTraining(), {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async createSsoAccount(json: ICreateAccount): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.account.sso(), {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async createVaccinationInterventionAccount(json: ICreateAccount): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.account.vaccinationIntervention(), {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    async createBnoAccount(json: ICreateAccount): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.account.bno(), {
            method: HttpMethod.POST,
            json,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ----------- ALERTS -----------

    async getAlerts(payload: any): Promise<KyResponse<IAlert[]>> {
        return HttpService.fetch(endpoints.apiKeyService.alerts(), {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
            headers: { loader: Loader.TABLE },
        });
    }

    async updateAlert(uuid: string, payload: { treated: boolean }): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.alertByUuid(uuid), {
            method: HttpMethod.PUT,
            json: payload,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ----------- API KEY -----------

    async getApiKeys(payload: any): Promise<KyResponse<IApiKey[]>> {
        return HttpService.fetch(endpoints.apiKeyService.apiKey.base, {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async patchApiKey(uuid: string, payload: { endDate: string }): Promise<KyResponse<IApiKey>> {
        return HttpService.fetch(endpoints.apiKeyService.apiKey.apiKeyByUuid(uuid), {
            method: HttpMethod.PATCH,
            json: payload,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteApiKey(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.apiKeyService.apiKey.apiKeyByUuid(uuid), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ----------- REFERENTIALS -----------

    async getApiKeyProfileReferential(): Promise<KyResponse<IProfile[]>> {
        return HttpService.fetch(endpoints.apiKeyService.referentials.profile(), {
            method: HttpMethod.GET,
        });
    }

    async getPeriodicitiesReferential(): Promise<KyResponse<IPeriodicity[]>> {
        return HttpService.fetch(endpoints.apiKeyService.referentials.periodicities(), {
            method: HttpMethod.GET,
        });
    }

    async getTypesReferential(): Promise<KyResponse<IApiKeyType[]>> {
        return HttpService.fetch(endpoints.apiKeyService.referentials.apiKeyTypes(), {
            method: HttpMethod.GET,
        });
    }

    async getAccountTypesReferential(): Promise<KyResponse<IAccountType[]>> {
        return HttpService.fetch(endpoints.apiKeyService.referentials.accountTypes(), {
            method: HttpMethod.GET,
        });
    }

    async getSsoOrganizations(): Promise<KyResponse<IOrganization[]>> {
        return HttpService.fetch(endpoints.apiKeyService.referentials.ssoOrganizations(), {
            method: HttpMethod.GET,
        });
    }

    async getVaccinationInterventionOrganizations(): Promise<KyResponse<IOrganization[]>> {
        return HttpService.fetch(endpoints.apiKeyService.referentials.vaccinationInterventionOrganizations(), {
            method: HttpMethod.GET,
        });
    }

    async getBnoOrganizations(): Promise<KyResponse<IOrganization[]>> {
        return HttpService.fetch(endpoints.apiKeyService.referentials.bnoOrganizations(), {
            method: HttpMethod.GET,
        });
    }
    // ----------- PERIODICITY -----------

    async putPeriodicity(
        profileKey: string,
        payload: { periodicityKey: string }
    ): Promise<KyResponse<{ periodicityKey: string }>> {
        return HttpService.fetch(endpoints.apiKeyService.profilePeriodicity(profileKey), {
            method: HttpMethod.PUT,
            json: payload,
            headers: { loader: Loader.REQUEST },
        });
    }

    // ----------- API KEY -----------

    async getKeyFromToken(payload: { token: string }): Promise<KyResponse<{ apiKey: string }>> {
        return HttpService.fetch(endpoints.apiKeyService.apiKeyFromToken(), {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
        });
    }
}

export default new ApiKeyService();
