// === NPM
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardContent, FormControl, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { z } from "zod";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import { IOrganization } from "@/interfaces/referential";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";
import { ICreateAccount } from "../../interface";

interface CreateAccountDialogProps {
    onClose: () => void;
    onValid: (newAccount: ICreateAccount) => void;
    organizations: IOrganization[];
    typeSelection: boolean;
}

const initialValues = {
    email: null,
    organizationUuid: "",
};

export default function CreateAccountDialog({
    onClose,
    organizations,
    onValid,
    typeSelection,
}: Readonly<CreateAccountDialogProps>) {
    const formSchema = z.object({
        email: stringRequired().email({ message: FORM_TEXT.email }),
        organizationUuid: stringRequired(),
        endDate: z.string().trim().optional(),
    });

    type ValidationSchema = z.infer<typeof formSchema>;

    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        watch,
        setValue,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(formSchema),
        defaultValues: initialValues,
    });

    const organizationUuid = watch("organizationUuid");
    const email = watch("email");

    useEffect(() => {
        const organization = organizations.find((o) => o.uuid === organizationUuid);
        if (organization) {
            setValue("email", organization.email);
        }
    }, [organizationUuid]);

    const renderContent = () => {
        return (
            (<Card>
                <CardContent>
                    <Stack spacing={2} sx={{
                        p: 2
                    }}>
                        <FormControl fullWidth required error={!!errors.organizationUuid}>
                            <Controller
                                name="organizationUuid"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <GenericSelect
                                        value={value}
                                        label={typeSelection ? "Choix de la souche logicielle" : "Choix de l'organisme"}
                                        onChange={onChange}
                                        optionsValue="uuid"
                                        error={!!error}
                                        helperText={error?.message}
                                        options={organizations}
                                        required
                                    />
                                )}
                            />
                        </FormControl>
                        <TextField
                            {...register("email")}
                            label="Email"
                            required
                            error={!!errors?.email}
                            helperText={errors?.email?.message}
                            slotProps={{
                                inputLabel: { shrink: !!email }
                            }}
                        />
                        <FormControl fullWidth required error={!!errors.endDate}>
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <GenericDatePicker
                                        value={value}
                                        onChange={onChange}
                                        name="endDate"
                                        error={!!error}
                                        helperText={error?.message}
                                        label="Date de fin"
                                        minDate={DateTime.now()}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                </CardContent>
            </Card>)
        );
    };
    return (
        <GenericDialog
            title="Créer un compte"
            onClose={onClose}
            renderContent={() => renderContent()}
            renderActions={() => <GenericActionsDialog onClose={onClose} onSubmit={handleSubmit(onValid)} />}
        />
    );
}
