// === NPM
import React, { useEffect, useState } from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Confirm } from "@/assets/icons/training/filters/confirm.svg";
import { ReactComponent as Edit } from "@/assets/icons/training/filters/edit.svg";
import { ReactComponent as Finished } from "@/assets/icons/training/filters/finished.svg";
import { ReactComponent as Preinscription } from "@/assets/icons/training/filters/preinscription.svg";
import { ReactComponent as Presence } from "@/assets/icons/training/filters/presence.svg";
import { FilterConfigurations } from "@/components/generics/filters/GenericFilters";
import { PresetFilterConfigurations } from "@/components/generics/presetFilters/GenericPresetFilters";
import {
    ISessionCountsOvvt,
    IViewOvvtFilters,
    SessionStatus,
} from "@/components/HealthAccreditationTraining/interface";
import { useDepartments } from "@/context/useDepartmentContext";
import { FilterType, HttpStatus, UserTypeHeader } from "@/interfaces/global";
import { SessionMode } from "@/interfaces/training";
import { convertEnumToKeyLabelObject, getEnumKeyByValue } from "@/resources/utils";
import TrainingService from "@/services/TrainingService";
import SessionsTable from "../SessionsTable";

const initialValues: IViewOvvtFilters = {
    internalId: "",
    trainingTitle: "",
    startDate: [null, null],
    registrationLimitDate: [null, null],
    department: [],
    sessionMode: [],
    status: [],
    needRegistrationCheck: "",
    needPresenceCheck: "",
};

export default function ViewOvvt() {
    const { departments } = useDepartments();

    const [inputFilters, setInputFilters] = useState<IViewOvvtFilters>({
        internalId: "",
        trainingTitle: "",
        startDate: [null, null],
        registrationLimitDate: [null, null],
        department: [],
        sessionMode: [],
        status: [],
        needRegistrationCheck: "",
        needPresenceCheck: "",
    });
    const [sessionCounts, setSessionCounts] = useState<ISessionCountsOvvt>(null);

    useEffect(() => {
        getSessionCounts();
    }, []);

    const getSessionCounts = async () => {
        const res = await TrainingService.getHealthAccreditationTrainingSessionCounts(UserTypeHeader.OVVT);
        if (res.status === HttpStatus.OK) {
            setSessionCounts(await res.json());
        }
    };

    const renderActionToBeDone = (params: GridRenderCellParams) => {
        if (
            params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.CANCELED) ||
            params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.FINISHED)
        ) {
            return "Aucune";
        }

        if (params.row.startDate < DateTime.now().startOf("minute").toISO() && params.row.needPresenceCheck) {
            return "Renseigner les présences";
        }
        if (
            DateTime.fromISO(params.row.startDate).startOf("day").minus({ day: 2 }) <= DateTime.now() &&
            DateTime.now() < DateTime.fromISO(params.row.startDate).endOf("day").minus({ day: 2 })
        ) {
            return "Confirmer la session";
        }
        if (
            (params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE) ||
                params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED)) &&
            params.row.needRegistrationCheck
        ) {
            return "Gérer les préinscriptions";
        }
        if (
            params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE) ||
            params.row.status === getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED)
        ) {
            return "Editer la feuille d'émargement";
        }

        return "Aucune";
    };

    const filterConfigurations: FilterConfigurations<IViewOvvtFilters> = {
        internalId: { type: FilterType.INPUT, label: "Identifiant" },
        trainingTitle: { type: FilterType.INPUT, label: "Nom de la formation" },
        startDate: { type: FilterType.DATEPICKER, label: "Date de formation" },
        registrationLimitDate: { type: FilterType.DATEPICKER, label: "Date limite d'inscription" },
        department: {
            type: FilterType.SELECT_AUTOCOMPLETE,
            label: "Département",
            values: departments.map((d) => ({ key: d.inseeCode, label: `${d.inseeCode} - ${d.name}` })),
        },
        sessionMode: {
            type: FilterType.SELECT,
            label: "Mode de présence",
            values: convertEnumToKeyLabelObject(SessionMode),
        },
        needRegistrationCheck: {
            type: FilterType.SINGLE_SELECT,
            label: "Préinscriptions restantes",
            values: [
                { key: "true", label: "Oui" },
                { key: "false", label: "Non" },
            ],
        },
        needPresenceCheck: {
            type: FilterType.SINGLE_SELECT,
            label: "Présences renseignées",
            values: [
                { key: "false", label: "Oui" },
                { key: "true", label: "Non" },
            ],
        },
        status: { type: FilterType.SELECT, label: "Statut", values: convertEnumToKeyLabelObject(SessionStatus) },
    };

    const presetFilterConfigurationsOvvt: PresetFilterConfigurations<ISessionCountsOvvt, IViewOvvtFilters> = {
        preRegisteredVeterinaryParticipations: {
            icon: <Preinscription />,
            value: sessionCounts?.preRegisteredVeterinaryParticipations,
            title: "Gérer les préinscriptions",
            config: {
                ...initialValues,
                needRegistrationCheck: "true",
                status: [
                    getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE),
                    getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED),
                ],
            },
        },
        twoDaysSessions: {
            icon: <Confirm />,
            value: sessionCounts?.twoDaysSessions,
            title: "Confirmer la session",
            config: {
                ...initialValues,
                startDate: [
                    DateTime.now().startOf("day").plus({ day: 2 }).toISO(),
                    DateTime.now().endOf("day").plus({ day: 2 }).toISO(),
                ],
                status: [
                    getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE),
                    getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED),
                ],
            },
        },
        activeClosedOrFutureSessions: {
            icon: <Edit />,
            value: sessionCounts?.activeClosedOrFutureSessions,
            title: "Editer la feuille d'émargement",
            config: {
                ...initialValues,
                startDate: [DateTime.now().startOf("day").plus({ day: 1 }).toISO(), null],
                status: [
                    getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE),
                    getEnumKeyByValue(SessionStatus, SessionStatus.ACTIVE_CLOSED),
                ],
            },
        },
        unfilledPresenceSessions: {
            icon: <Presence />,
            value: sessionCounts?.unfilledPresenceSessions,
            title: "Renseigner les présences",
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(SessionStatus, SessionStatus.REALIZED)],
                needPresenceCheck: "true",
            },
        },
        finishedSessions: {
            icon: <Finished />,
            value: sessionCounts?.finishedSessions,
            title: "Sessions terminées",
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(SessionStatus, SessionStatus.FINISHED)],
            },
        },
    };

    return (
        <SessionsTable
            inputFilters={inputFilters}
            filterConfigurations={filterConfigurations}
            initialValues={{
                internalId: "",
                trainingTitle: "",
                startDate: [null, null],
                registrationLimitDate: [null, null],
                department: [],
                sessionMode: [],
                status: [],
                needRegistrationCheck: "",
                needPresenceCheck: "",
            }}
            setInputFilters={setInputFilters}
            presetFilterConfigurations={presetFilterConfigurationsOvvt}
            renderActionToBeDone={renderActionToBeDone}
        />
    );
}
