// === NPM
import { KyResponse } from "ky";
// === LOCAL
import {
    IDrugDeliveryReporting,
    IDrugPrescriptionReporting,
    IFileContent,
    IImport,
    INonDelivery,
} from "@/components/AntimicrobialDisposal/Dashboard/interface";
import {
    IManualDelivery,
    IManualDeliveryDraft,
    IManualPrescription,
    IManualPrescriptionDetails,
} from "@/components/AntimicrobialDisposal/ManualDelivery/interface";
import { ArrayOfTwo, HttpMethod, IExportFilename, Loader, SearchParams } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS, customClientHeader } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class DrugService {
    async getImports(
        payload: SearchParams &
            Partial<{
                date: string[];
                importStatus: string[];
                typeCode: string;
                importType: string[];
                senderId: string;
                softwareName: string;
            }>
    ): Promise<KyResponse<IImport[]>> {
        return HttpService.fetch(endpoints.drugService.imports.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async postImport(file: File): Promise<KyResponse> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch(endpoints.drugService.imports.base, {
            method: HttpMethod.POST,
            headers: {
                loader: Loader.REQUEST,
            },
            body: formData,
        });
    }

    async getTemplate(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.imports.template(), {
            method: HttpMethod.GET,
        });
    }

    async getFile(importUuid: string, fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.imports.importFile(importUuid, fileUuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getSoftwares(): Promise<KyResponse<string[]>> {
        return HttpService.fetch(endpoints.drugService.imports.softwares(), {
            method: HttpMethod.GET,
        });
    }

    async postNonDeliveries(json: INonDelivery): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.nonDeliveries(), {
            method: HttpMethod.POST,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getNonDeliveriesAvailable(payload: {
        year?: string;
        localAgencies?: string[];
    }): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.drugService.nonDeliveries(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
        });
    }

    async getDrugDeliveryReporting(
        payload: SearchParams &
            Partial<{
                dpeOrdinalNumber: string;
                prescriptionVeterinaryId: string;
                prescriptionId: string;
                deliveryDate: ArrayOfTwo[];
                transferorId: string;
                transferorName: string;
                transferorType: string[];
                speciesUuid: string[];
                subSpeciesUuid: string[];
                excluded: string[];
            }>
    ): Promise<KyResponse<IDrugDeliveryReporting[]>> {
        return HttpService.fetch(endpoints.drugService.reporting.drugDeliveries(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async excludeDelivery(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.reporting.excludeDelivery(uuid), {
            method: HttpMethod.PATCH,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async reinstateDelivery(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.reporting.reinstateDelivery(uuid), {
            method: HttpMethod.PATCH,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getDrugPrescriptionReporting(payload: SearchParams): Promise<KyResponse<IDrugPrescriptionReporting[]>> {
        return HttpService.fetch(endpoints.drugService.reporting.drugPrescriptions(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    // MANUAL DELIVERY

    async getDraft(userId: string, noToaster = false): Promise<KyResponse<IManualDeliveryDraft>> {
        const clientHeader = { ...customClientHeader };
        clientHeader.noToasterOnError = noToaster;
        return HttpService.fetch(endpoints.drugService.drafts.draft(userId), {
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify(clientHeader),
            },
        });
    }
    async deleteDraft(userId: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.drafts.draft(userId), {
            method: HttpMethod.DELETE,
        });
    }

    async putDraft(userId: string, json: IManualDelivery): Promise<KyResponse<IManualDelivery>> {
        return HttpService.fetch(endpoints.drugService.drafts.draft(userId), {
            method: HttpMethod.PUT,
            json,
        });
    }

    async getPrescription(
        prescriptionId: string,
        dpeId: string,
        veterinaryId: string
    ): Promise<KyResponse<Omit<IManualDelivery, "deliveries">>> {
        return HttpService.fetch(endpoints.drugService.prescriptions.base, {
            method: HttpMethod.GET,
            searchParams: formatParams({ prescriptionId, dpeId, veterinaryId }),
            headers: {
                loader: Loader.BUTTON,
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify({ noToasterOnError: true }),
            },
        });
    }

    async getDeliveries(
        prescriptionId: string,
        dpeId: string,
        veterinaryId: string
    ): Promise<KyResponse<Omit<IManualDelivery, "prescription">>> {
        return HttpService.fetch(endpoints.drugService.prescriptions.deliveries(), {
            method: HttpMethod.GET,
            searchParams: formatParams({ prescriptionId, dpeId, veterinaryId }),
            headers: {
                loader: Loader.BUTTON,
                [CALYPSO_HEADERS.CLIENT_HEADER]: JSON.stringify({ noToasterOnError: true }),
            },
        });
    }

    async postDeliveries(json: IManualDelivery): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.imports.deliveries(), {
            method: HttpMethod.POST,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getManualPrescriptions(
        payload: SearchParams &
            Partial<{
                senderId: string;
                date: ArrayOfTwo;
                prescriptionId: string;
                prescriptionVeterinaryId: string;
                speciesUuid: string[];
                subSpeciesUuid: string[];
                farmId: string;
                farmName: string;
            }>
    ): Promise<KyResponse<IManualPrescription[]>> {
        return HttpService.fetch(endpoints.drugService.manualPrescriptions, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getDrugsByPrescription(prescriptionUuid: string): Promise<KyResponse<IManualPrescriptionDetails>> {
        return HttpService.fetch(endpoints.drugService.prescriptions.drugs(prescriptionUuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getFileContent(importUuid: string, fileUuid: string): Promise<KyResponse<IFileContent>> {
        return HttpService.fetch(endpoints.drugService.imports.fileContent(importUuid, fileUuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async exportImportReports(json: IExportFilename): Promise<KyResponse> {
        return HttpService.fetch(endpoints.drugService.exports.importReports(), {
            method: HttpMethod.POST,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }
}

export default new DrugService();
