// === NPM
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
// === LOCAL
import { ReactComponent as AwaitingPayment } from "@/assets/icons/billing/awaiting_payment.svg";
import { ReactComponent as Cancel } from "@/assets/icons/billing/cancel_action.svg";
import { ReactComponent as Paid } from "@/assets/icons/billing/paid.svg";
import { ReactComponent as ChangeToPaid } from "@/assets/icons/drawer/billing.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import {
    GenericPresetFilters,
    PresetFilterConfigurations,
} from "@/components/generics/presetFilters/GenericPresetFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { createPayload, getEnumKeyByValue, toLocaleDateFormat } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";
import { BillingStatus, ICreateMemory, IMemory, IMemoryCounts, IMemoryFilters, IMemoryStatus } from "../interface";
import LinkedVaccinationSites from "./containers/LinkedVaccinationSites";
import MemoryDialog from "./containers/MemoryDialog";

const initialValues: IMemoryFilters = {
    id: "",
    title: "",
    creationDate: [null, null],
    dpe: "",
    // type: [], // TODO : TO UNCOMMENT LE JOUR Où ON NOUS DEMANDE LE FILTRE
    status: [],
};

export default function Memories() {
    const [memories, setMemories] = useState<IMemory[]>([]);
    const [memoryTypes, setMemoryTypes] = useState<IReferential[]>([]);
    const [memoryStatuses, setMemoryStatuses] = useState<IReferential[]>([]);
    const [memoriesCount, setMemoriesCount] = useState<IMemoryCounts>({ toPay: 0, paid: 0 });
    const [inputFilters, setInputFilters] = useState<IMemoryFilters>(initialValues);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
    const [selectedMemory, setSelectedMemory] = useState<IMemory>(null);
    const [selectedMemoryId, setSelectedMemoryId] = useState<number>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getMemoriesCounts();
        getMemoryTypes();
        getMemoryStatuses();
    }, []);

    useEffect(() => {
        getMemories();
    }, [pagination, sortModel]);

    const getMemories = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await VaccinationService.getMemories(payload);
        if (res.status === HttpStatus.OK) {
            setMemories(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
            setSelectedMemory(null);
        }
    };

    const getMemoriesCounts = async () => {
        const res = await VaccinationService.getMemoriesCounts();
        if (res.status === HttpStatus.OK) {
            setMemoriesCount(await res.json());
        }
    };

    const getMemoryTypes = async () => {
        const res = await VaccinationService.getMemoryTypes();
        if (res.status === HttpStatus.OK) {
            setMemoryTypes(await res.json());
        }
    };

    const getMemoryStatuses = async () => {
        const res = await VaccinationService.getMemoryStatuses();
        if (res.status === HttpStatus.OK) {
            setMemoryStatuses(await res.json());
        }
    };

    const getFile = async (memoryId: number, fileUuid: string) => {
        const res = await VaccinationService.getMemoryFile(memoryId, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            saveAs(file, res.headers.get("content-disposition").split("filename=")[1].slice(1, -1));
        }
    };

    const patchMemoryStatus = async (memoryId: number, status: string) => {
        const res = await VaccinationService.patchMemoryStatus(memoryId, status);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Le statut du mémoire a bien été mis à jour");
            getMemories();
            getMemoriesCounts();
        }
    };

    const handleDeleteMemory = async (confirm: boolean) => {
        if (!confirm) {
            setOpenDeleteDialog(false);
            setSelectedMemoryId(null);
            return;
        }
        const res = await VaccinationService.deleteMemory(selectedMemoryId);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Le mémoire a bien été supprimé");
            setOpenDeleteDialog(false);
            setSelectedMemoryId(null);
            getMemories();
            getMemoriesCounts();
        }
    };

    const handleCreateMemory = async (confirm: boolean, newMemory: ICreateMemory) => {
        if (!confirm) {
            setOpenCreateDialog(false);
            return;
        }
        const res = await VaccinationService.postMemory(newMemory);
        if (res.status === HttpStatus.CREATED) {
            toast.success("Le mémoire a bien été créé");
            setOpenCreateDialog(false);
            getMemories();
            getMemoriesCounts();
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Identifiant mémoire",
            width: 120,
        },
        {
            field: "title",
            headerName: "Libellé mémoire",
            width: 200,
        },
        {
            field: "creationDate",
            headerName: "Date de création",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => toLocaleDateFormat(params.row.creationDate),
        },
        {
            field: "dpeId",
            headerName: "DPE",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) => `${params.row.dpeId} - ${params.row.dpeName}`,
        },
        {
            field: "type",
            headerName: "Type de mémoire",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) =>
                memoryTypes.find((type) => type.key === params.row.type)?.label,
        },
        {
            field: "Statut",
            headerName: "Statut du mémoire",
            flex: 1,
            sortable: false,
            valueGetter: (params: GridRenderCellParams) =>
                memoryStatuses.find((status) => status.key === params.row.status)?.label,
        },
        {
            ...ActionsColumnProps,
            width: 200,
            renderCell: (params: GridRenderCellParams) => (
                <Box>
                    <DownloadAction
                        title={
                            params.row.status === getEnumKeyByValue(IMemoryStatus, IMemoryStatus.TO_PAY)
                                ? "Télécharger une version provisoire du mémoire"
                                : "Télécharger le mémoire"
                        }
                        onClick={(event) => {
                            event.stopPropagation();
                            getFile(params.row.id, params.row.invoiceUuid);
                        }}
                    />
                    <PermissionsCheck requiredPermissions={[UserSituation.USER_DDPP, UserSituation.ADMIN_DDPP]}>
                        {params.row.status === getEnumKeyByValue(IMemoryStatus, IMemoryStatus.TO_PAY) && (
                            <>
                                <IconActionButton
                                    icon={<ChangeToPaid />}
                                    title="Mettre en paiement et éditer le mémoire"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        patchMemoryStatus(
                                            params.row.id,
                                            getEnumKeyByValue(IMemoryStatus, IMemoryStatus.PAID)
                                        );
                                    }}
                                />
                                <DeleteAction
                                    title="Supprimer"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedMemoryId(params.row.id);
                                        setOpenDeleteDialog(true);
                                    }}
                                />
                            </>
                        )}
                        {params.row.status === getEnumKeyByValue(IMemoryStatus, IMemoryStatus.PAID) && (
                            <IconActionButton
                                icon={<Cancel />}
                                title="Annuler le paiement"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    patchMemoryStatus(
                                        params.row.id,
                                        getEnumKeyByValue(IMemoryStatus, IMemoryStatus.TO_PAY)
                                    );
                                }}
                            />
                        )}
                    </PermissionsCheck>
                </Box>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<IMemoryFilters> = {
        id: { label: "Identifiant mémoire", type: FilterType.INPUT },
        title: { label: "Libellé mémoire", type: FilterType.INPUT },
        creationDate: { label: "Date de création", type: FilterType.DATEPICKER },
        dpe: { label: "DPE", type: FilterType.INPUT },
        // TODO : TO UNCOMMENT LE JOUR Où ON NOUS DEMANDE LE FILTRE
        // type: {
        //     label: "Type de mémoire",
        //     type: FilterType.SELECT,
        //     values: memoryTypes,
        // },
        status: {
            label: "Statut du mémoire",
            type: FilterType.SELECT,
            values: memoryStatuses,
        },
    };

    const presetFilterConfigurations: PresetFilterConfigurations<IMemoryCounts, IMemoryFilters> = {
        toPay: {
            icon: <AwaitingPayment />,
            title: IMemoryStatus.TO_PAY,
            value: memoriesCount?.toPay,
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(IMemoryStatus, IMemoryStatus.TO_PAY)],
            },
        },
        paid: {
            icon: <Paid />,
            title: IMemoryStatus.PAID,
            value: memoriesCount?.paid,
            config: {
                ...initialValues,
                status: [getEnumKeyByValue(IMemoryStatus, IMemoryStatus.PAID)],
            },
        },
    };

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <Typography variant="h4">Mémoires de paiement</Typography>
                    <PermissionsCheck requiredPermissions={[UserSituation.USER_DDPP, UserSituation.ADMIN_DDPP]}>
                        <GenericButton
                            onClick={() => setOpenCreateDialog(true)}
                            label="Nouveau mémoire de paiement"
                            startIcon={<Add />}
                        />
                    </PermissionsCheck>
                </Box>
                <GenericPresetFilters
                    superFilterConfiguration={presetFilterConfigurations}
                    inputFilters={inputFilters}
                    setInputFilters={setInputFilters}
                    clearFilters={() => setInputFilters(initialValues)}
                />
                <Card>
                    <StyledCardContent
                        sx={{
                            "& .selected": {
                                backgroundColor: colors.background,
                            },
                        }}
                    >
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                id: "",
                                title: "",
                                creationDate: [null, null],
                                dpe: "",
                                // type: [], // TODO : TO UNCOMMENT LE JOUR Où ON NOUS DEMANDE LE FILTRE
                                status: [],
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            rows={memories}
                            columns={columns}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={["asc", "desc"]}
                            filterMode="server"
                            onRowClick={(params) => setSelectedMemory(params.row)}
                            getRowClassName={(params) => (params.row.id === selectedMemory?.id ? "selected" : "")}
                        />
                    </StyledCardContent>
                </Card>
                {selectedMemory && (
                    <LinkedVaccinationSites
                        memoryId={selectedMemory.id}
                        memoryTitle={selectedMemory.title}
                        dpeId={selectedMemory.dpeId}
                        canDeleteVaccinationSites={
                            selectedMemory.status === getEnumKeyByValue(BillingStatus, BillingStatus.TO_PAY)
                        }
                    />
                )}
            </Stack>
            {openDeleteDialog && (
                <GenericConfirmDialog
                    title={`Suppression du mémoire de paiement n°${selectedMemoryId}`}
                    message={`Êtes-vous sûr de vouloir supprimer ce mémoire de paiement ? Cette action est irréversible.\n
                    Tous les chantiers associés à ce mémoire prendront le statut "Validé DDPP".`}
                    onClose={handleDeleteMemory}
                />
            )}
            {openCreateDialog && <MemoryDialog onClose={handleCreateMemory} />}
        </>
    );
}
