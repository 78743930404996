// === NPM
import React, { RefObject, useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
    Card,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DateTime } from "luxon";
// === LOCAL
import CityPostalCodeAutocomplete from "@/components/generics/inputs/CityPostalCodeAutocomplete";
import GenericDatePicker from "@/components/generics/inputs/GenericDatePicker";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { HttpStatus } from "@/interfaces/global";
import { IDpeProperties, IDpeShort } from "@/interfaces/user";
import { ShippingAddressType } from "@/interfaces/vaccination";
import UserService from "@/services/UserService";
import { ServipharFormSchema } from "../Order";

interface ShippingInformationsProps {
    dpes: IDpeProperties[];
    orderDpe: IDpeShort;
    servipharRef: RefObject<HTMLButtonElement>;
    servipharForm: UseFormReturn<ServipharFormSchema>;
    dpeError: string;
    onValid: () => void;
}

export default function ShippingInformations({
    dpeError,
    dpes,
    orderDpe,
    servipharRef,
    servipharForm,
    onValid,
}: Readonly<ShippingInformationsProps>) {
    const {
        formState: { errors },
        watch,
        handleSubmit,
        setValue,
        control,
        register,
        clearErrors,
    } = servipharForm;
    const [shippingDpeId, setShippingDpeId] = useState<string>("");
    const [shippingDpe, setShippingDpe] = useState<IDpeShort>(null);

    const shippingAddressType = watch("shippingAddressType");
    const complementaryAddress = watch("complementaryAddress");
    const city = watch("city");
    const postalCode = watch("postalCode");
    const address = watch("address");

    useEffect(() => {
        if (shippingDpeId) getDpe();
    }, [shippingDpeId]);

    useEffect(() => {
        if (shippingAddressType === ShippingAddressType.ORDER_DPE && orderDpe) {
            handleAddress(orderDpe);
        }
        if (shippingAddressType === ShippingAddressType.SHIPPING_DPE && shippingDpe) {
            handleAddress(shippingDpe);
        }
    }, [orderDpe, shippingAddressType, shippingDpe]);

    const getDpe = async () => {
        const res = await UserService.getDpe(shippingDpeId);
        if (res.status === HttpStatus.OK) {
            setShippingDpe(await res.json());
        }
    };

    const handleAddress = (dpe: IDpeShort) => {
        setValue("address", dpe.street ?? "");
        setValue("complementaryAddress", setComplementaryAddress(dpe));
        setValue("city", dpe.city ?? "");
        setValue("postalCode", dpe.postalCode ?? "");
        setValue("deliveryName", dpe.name.substring(0, 25));
    };

    const setComplementaryAddress = (dpe: IDpeShort): string => {
        const complementaryAddress = `${dpe.complementaryAddress1 ?? ""} ${dpe.complementaryAddress2 ?? ""}`;
        return complementaryAddress.trim();
    };

    const handleCityPostalCodeChange = (event) => {
        setValue("postalCode", event.target.value?.postalCode ?? "");
        setValue("city", event.target.value?.name ?? "");
    };

    const renderAddress = () => (
        <>
            <Stack direction="row" spacing={2}>
                {shippingAddressType === ShippingAddressType.SHIPPING_DPE && (
                    <FormControl fullWidth error={!!dpeError} required>
                        <InputLabel>Nom du DPE</InputLabel>
                        <Select
                            label="Nom du DPE"
                            onChange={(e) => setShippingDpeId(e.target.value)}
                            value={shippingDpeId}
                            IconComponent={KeyboardArrowDown}
                        >
                            {dpes.map((dpe) => (
                                <MenuItem key={dpe.id} value={dpe.id}>
                                    {`${dpe.id} - ${dpe.name} - ${dpe.postalCode} ${dpe.city}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{dpeError}</FormHelperText>
                    </FormControl>
                )}
                {shippingAddressType === ShippingAddressType.DIFFERENT && (
                    <TextField
                        {...register("deliveryName")}
                        label="Nom"
                        fullWidth
                        error={!!errors.deliveryName}
                        helperText={errors.deliveryName?.message}
                    />
                )}
                <TextField
                    {...register("address")}
                    label="Adresse"
                    fullWidth
                    error={!!errors.address}
                    helperText={errors.address?.message}
                    required
                    slotProps={{
                        inputLabel: { shrink: !!address }
                    }}
                />
                <TextField
                    {...register("complementaryAddress")}
                    label="Complément d'adresse"
                    fullWidth
                    error={!!errors.complementaryAddress}
                    helperText={errors.complementaryAddress?.message}
                    slotProps={{
                        inputLabel: { shrink: !!complementaryAddress }
                    }}
                />
            </Stack>
            <Stack direction="row" spacing={2}>
                {shippingAddressType === ShippingAddressType.DIFFERENT ? (
                    <CityPostalCodeAutocomplete
                        handleChange={handleCityPostalCodeChange}
                        defaultCitySearch={city}
                        defaultPostalCodeSearch={postalCode}
                        initialValues={city ? { name: city, postalCode: postalCode } : null}
                        errors={{ name: errors.city?.message, postalCode: errors.postalCode?.message }}
                        required
                    />
                ) : (
                    <>
                        <GenericTextField value={postalCode} label="Code postal" disabled />
                        <GenericTextField value={city} label="Ville" disabled />
                    </>
                )}
            </Stack>
        </>
    );

    const renderComplimentaryDelivery = () => (
        <>
            <TextField
                {...register("phoneNumber")}
                label="Numéro de téléphone portable"
                fullWidth
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                required
            />
            <Controller
                name="requestedDeliveryDate"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <GenericDatePicker
                        value={value}
                        onChange={onChange}
                        name="requestedDeliveryDate"
                        error={!!error}
                        helperText={error?.message}
                        label="Date de livraison souhaitée"
                        minDate={DateTime.now().plus({ days: 1 })}
                        shouldDisableDate={(day) => day.weekday === 7}
                    />
                )}
            />
        </>
    );

    return (
        (<Card>
            <StyledCardContent>
                <Stack spacing={2} sx={{
                    p: 2
                }}>
                    <FormControl>
                        <Controller
                            name="shippingAddressType"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <RadioGroup
                                    value={value}
                                    onChange={(e) => {
                                        onChange(e);
                                        clearErrors([
                                            "deliveryName",
                                            "address",
                                            "complementaryAddress",
                                            "city",
                                            "postalCode",
                                        ]);
                                        setValue("address", null);
                                        setValue("complementaryAddress", null);
                                        setValue("city", "");
                                        setValue("postalCode", "");
                                        setValue("deliveryName", null);
                                    }}
                                    sx={{ width: "fit-content" }}
                                >
                                    <FormControlLabel
                                        value={ShippingAddressType.ORDER_DPE}
                                        control={<Radio />}
                                        label="L'adresse de livraison est celle du DPE concerné par la commande"
                                    />
                                    <FormControlLabel
                                        value={ShippingAddressType.SHIPPING_DPE}
                                        control={<Radio />}
                                        label="L'adresse de livraison est celle d'un autre DPE"
                                    />
                                    <FormControlLabel
                                        value={ShippingAddressType.DIFFERENT}
                                        control={<Radio />}
                                        label="L'adresse de livraison est différente"
                                    />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                    <Typography variant="bold">Adresse de livraison</Typography>
                    {renderAddress()}
                    <Typography variant="bold">Complément de livraison</Typography>
                    {renderComplimentaryDelivery()}
                </Stack>
            </StyledCardContent>
            <button style={{ display: "none" }} onClick={handleSubmit(onValid)} ref={servipharRef} />
        </Card>)
    );
}
