// === Import: NPM
import React from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === Import: LOCAL
import InfoIcon from "@/components/generics/InfoIcon";
import { IOVDpe } from "@/components/Profile/interface";
import DpeCard from "./DpeCard";

interface DpeProps {
    dpes: IOVDpe[];
}
export default function Dpe({ dpes }: Readonly<DpeProps>) {
    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: "center",
                    mb: 3,
                }}
            >
                <Typography variant="h5">Domiciles professionnels d'exercice</Typography>
                <InfoIcon tooltip="Chaque Domicile Professionnel d’Exercice (DPE) possède son numéro ordinal. Il est composé de : Numéro ordinal société-nombre ou Numéro ordinal vétérinaire-nombre. Sur les fiches, ce numéro précède le nom du DPE" />
            </Stack>
            <Grid container spacing={2}>
                {dpes.map((dpe, i) => (
                    <Grid
                        key={i}
                        size={{
                            xs: 12,
                            sm: 6,
                            md: 4,
                        }}
                    >
                        <DpeCard dpe={dpe} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
