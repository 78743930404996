// === NPM
// === LOCAL
import { KyResponse } from "ky";
import { IManualOrder, IOrder, IServipharOrder } from "@/components/VaccinationCampaign/Vaccination/Order/interface";
import {
    IOrderDetails,
    ITrackingOrder,
    TrackingOrderFilters,
} from "@/components/VaccinationCampaign/Vaccination/OrderTracking/interface";
import { IScrappingFilters } from "@/components/VaccinationCampaign/Vaccination/ScrappingTracking/interface";
import {
    BillingStatusKeys,
    IBillable,
    IBillableCounts,
    IBillableFilters,
    IBillingStatusDtoOut,
    ICreateMemory,
    IMemory,
    IMemoryCounts,
    IMemoryFilters,
    IStatusChange,
    IValidatedVaccinationSite,
} from "@/components/VaccinationIahp/Billing/interface";
import {
    IMonitoringIntervention,
    IMonitoringInterventionCorrection,
    IMonitoringInterventionFilters,
} from "@/components/VaccinationIahp/MonitoringIntervention/interface";
import {
    ISearchVaccinationIntervention,
    IVaccinationIntervention,
    IVaccinationInterventionCounts,
    IVaccinationInterventionDetails,
    IVaccinationInterventionFilters,
    IVaccinationInterventionForm,
    IVaccinationSiteCorrection,
    IVaccinationSiteFilters,
    IVaccinationSiteForm,
    IVaccinationSiteInfo,
    IVaccinationSiteWorkshop,
    IVaccinationSiteWorkshopInfo,
    SearchVaccinationInterventionFilters,
} from "@/components/VaccinationIahp/VaccinationSite/interface";
import { HttpMethod, IExportFilename, IExportParams, Loader, SearchParams } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import {
    IAudit,
    IMonitoringInterventionDetails,
    IMonitoringInterventionForm,
    INonCompliance,
    IScrappingShort,
    IVaccinationSite,
    IVaccinationSiteShort,
    IVaccine,
} from "@/interfaces/vaccination";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class VaccinationService {
    async getVaccines(): Promise<KyResponse<IVaccine[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.vaccines(), {
            method: HttpMethod.GET,
        });
    }

    async getSectors(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.sectors(), {
            method: HttpMethod.GET,
        });
    }

    async getTiers(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.tiers(), {
            method: HttpMethod.GET,
        });
    }

    async postOrder(data: IServipharOrder | IManualOrder): Promise<KyResponse<IOrder>> {
        return HttpService.fetch(endpoints.vaccinationService.orders.base, {
            method: HttpMethod.POST,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getOrders(payload: SearchParams & Partial<TrackingOrderFilters>): Promise<KyResponse<ITrackingOrder[]>> {
        return HttpService.fetch(endpoints.vaccinationService.orders.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getOrderDetails(id: string): Promise<KyResponse<IOrderDetails>> {
        return HttpService.fetch(endpoints.vaccinationService.orders.order(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getOrderStatuses(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.orderStatuses(), {
            method: HttpMethod.GET,
        });
    }

    async getInterventions(
        dataType: "search",
        payload: SearchParams & Partial<SearchVaccinationInterventionFilters>
    ): Promise<KyResponse<ISearchVaccinationIntervention[]>>;
    async getInterventions(
        dataType: "",
        payload: SearchParams & Partial<IVaccinationInterventionFilters>
    ): Promise<KyResponse<IVaccinationIntervention[]>>;
    async getInterventions(
        dataType: "search" | "",
        payload:
            | (SearchParams & Partial<IVaccinationInterventionFilters>)
            | Partial<SearchVaccinationInterventionFilters>
    ): Promise<KyResponse<IVaccinationIntervention[] | ISearchVaccinationIntervention[]>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationInterventions.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.DATA_TYPE]: dataType,
                loader: Loader.TABLE,
            },
        });
    }

    async getVaccineBatches(
        vaccineGtinCode: string,
        payload: SearchParams & { number?: string; uuid?: string[] }
    ): Promise<KyResponse<{ uuid: string; number: string }[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.batches(vaccineGtinCode), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
        });
    }

    async getHorsePower(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.horsePower(), {
            method: HttpMethod.GET,
        });
    }

    async putIntervention(
        id: string,
        data: IVaccinationInterventionForm,
        certificateGeneration: boolean
    ): Promise<KyResponse<IVaccinationInterventionForm>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationInterventions.intervention(id), {
            method: HttpMethod.PUT,
            json: { ...data, certificateGeneration },
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteInterventionCorrection(id: string, reason: string): Promise<KyResponse<void>> {
        return HttpService.fetch(
            endpoints.vaccinationService.vaccinationInterventions.deleteInterventionCorrection(id),
            {
                method: HttpMethod.PATCH,
                json: { reason },
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }

    async patchInterventionCorrection(
        id: string,
        reason: string,
        data: IVaccinationInterventionForm
    ): Promise<KyResponse<void>> {
        return HttpService.fetch(
            endpoints.vaccinationService.vaccinationInterventions.updateInterventionCorrection(id),
            {
                method: HttpMethod.PATCH,
                json: { reason, body: data },
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }

    async deleteIntervention(id: string): Promise<KyResponse<void>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationInterventions.intervention(id), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getIntervention(id: string): Promise<KyResponse<IVaccinationInterventionDetails>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationInterventions.intervention(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async patchIntervention(id: string, data: { uuid: string; imepNumber: string }[]) {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationInterventions.intervention(id), {
            method: HttpMethod.PATCH,
            json: data,
        });
    }

    async generateCertificateIntervention(id: string) {
        return HttpService.fetch(
            endpoints.vaccinationService.vaccinationInterventions.generateInterventionCertificate(id),
            {
                method: HttpMethod.POST,
                headers: {
                    loader: Loader.BUTTON,
                },
            }
        );
    }

    async getVaccinationInterventionFile(interventionId: string, fileId: string) {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationInterventions.file(interventionId, fileId), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getVaccinationInterventionCounts(): Promise<KyResponse<IVaccinationInterventionCounts>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationInterventions.counts(), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    /// ---- SCRAPPING

    async getScrappings(payload: SearchParams & Partial<IScrappingFilters>) {
        return HttpService.fetch(endpoints.vaccinationService.scrappings.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async deleteScrapping(uuid: string) {
        return HttpService.fetch(endpoints.vaccinationService.scrappings.scrapping(uuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getScrappingReasons() {
        return HttpService.fetch(endpoints.vaccinationService.referential.reasons(), {
            method: HttpMethod.GET,
        });
    }

    /// ---- MONITORING INTERVENTIONS

    async getMonitoringInterventions(
        payload: SearchParams & Partial<IMonitoringInterventionFilters>
    ): Promise<KyResponse<IMonitoringIntervention[]>> {
        return HttpService.fetch(endpoints.vaccinationService.monitoringInterventions.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getMonitoringIntervention(id: string): Promise<KyResponse<IMonitoringInterventionDetails>> {
        return HttpService.fetch(endpoints.vaccinationService.monitoringInterventions.intervention(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async postMonitoringIntervention(data: IMonitoringInterventionForm): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.monitoringInterventions.base, {
            method: HttpMethod.POST,
            json: data,
        });
    }

    async putMonitoringIntervention(id: string, data: IMonitoringInterventionForm): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.monitoringInterventions.intervention(id), {
            method: HttpMethod.PUT,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteMonitoringIntervention(id: string): Promise<KyResponse<void>> {
        return HttpService.fetch(endpoints.vaccinationService.monitoringInterventions.intervention(id), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getMonitoringInterventionFile(interventionId: string, fileId: string) {
        return HttpService.fetch(endpoints.vaccinationService.monitoringInterventions.file(interventionId, fileId), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async postScrapping(data: IScrappingShort) {
        return HttpService.fetch(endpoints.vaccinationService.scrappings.base, {
            method: HttpMethod.POST,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putScrapping(uuid: string, data: IScrappingShort) {
        return HttpService.fetch(endpoints.vaccinationService.scrappings.scrapping(uuid), {
            method: HttpMethod.PUT,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }
    async getSerologicalTypes(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.serologicalTypes(), {
            method: HttpMethod.GET,
        });
    }

    async getNonFullfilmentReasons(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.nonFullfilmentReasons(), {
            method: HttpMethod.GET,
        });
    }

    async getClinicalNonComplianceReasons(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.clinicalNonComplianceReasons(), {
            method: HttpMethod.GET,
        });
    }

    async getMonitoringInterventionStatuses(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.monitoringInterventionStatuses(), {
            method: HttpMethod.GET,
        });
    }

    async getVaccinationInterventionExport(data: IExportParams): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.exports.vaccinationIntervention(), {
            method: HttpMethod.POST,
            json: data,
        });
    }

    async getMonitoringInterventionExport(data: IExportParams): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.exports.monitoringIntervention(), {
            method: HttpMethod.POST,
            json: data,
        });
    }

    async getRelationExport(data: IExportParams): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.exports.relation(), {
            method: HttpMethod.POST,
            json: data,
        });
    }

    async correctIntervention(id: string, data: IMonitoringInterventionCorrection): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.monitoringInterventions.correction(id), {
            method: HttpMethod.PUT,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async exportIahpData(data: IExportFilename): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.exports.base, {
            method: HttpMethod.POST,
            json: data,
        });
    }

    // ---- VACCINATION SITES

    async getBillables(
        outsideCalypso: boolean,
        payload: SearchParams & Partial<IBillableFilters>
    ): Promise<KyResponse<IBillable[]>> {
        return HttpService.fetch(
            outsideCalypso
                ? endpoints.vaccinationService.vaccinationSites.outsideCalypso()
                : endpoints.vaccinationService.vaccinationSites.billables.billables(),
            {
                method: HttpMethod.GET,
                headers: {
                    loader: Loader.TABLE,
                },
                searchParams: formatParams(payload),
            }
        );
    }
    async getBillableCount(): Promise<KyResponse<IBillableCounts>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.billables.counts(), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getBillingStatuses(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.billingStatuses(), {
            method: HttpMethod.GET,
        });
    }

    async patchBillingStatus(id: number, data: IBillingStatusDtoOut): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.editBillingStatus(id), {
            method: HttpMethod.PATCH,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getVaccinationSites(
        payload: SearchParams & Partial<IVaccinationSiteFilters>
    ): Promise<KyResponse<IVaccinationSiteShort[]>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.base, {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
            searchParams: formatParams(payload),
        });
    }

    async postVaccinationSite(data: IVaccinationSiteForm): Promise<KyResponse<IVaccinationSite>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.base, {
            method: HttpMethod.POST,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putVaccinationSite(id: number, data: IVaccinationSiteForm): Promise<KyResponse<IVaccinationSite>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.vaccinationSite(id), {
            method: HttpMethod.PUT,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getVaccinationSiteInfos(id: number): Promise<KyResponse<IVaccinationSiteInfo>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.infos(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async deleteVaccinationSite(id: number): Promise<KyResponse<void>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.vaccinationSite(id), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getVaccinationSite(id: number): Promise<KyResponse<IVaccinationSite>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.vaccinationSite(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async patchVaccinationSiteCorrection(id: number, data: IVaccinationSiteCorrection): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.delete(id), {
            method: HttpMethod.PATCH,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async postVaccinationIntervention(
        vaccinationSiteId: number,
        data: IVaccinationInterventionForm,
        certificateGeneration: boolean
    ): Promise<KyResponse<IVaccinationInterventionForm>> {
        return HttpService.fetch(
            endpoints.vaccinationService.vaccinationSites.vaccinationInterventions(vaccinationSiteId),
            {
                method: HttpMethod.POST,
                json: { ...data, certificateGeneration },
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }

    // ---- AUDIT

    async getAudit(uuid: string): Promise<KyResponse<IAudit>> {
        return HttpService.fetch(endpoints.vaccinationService.audits.audit(uuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async deleteAudit(uuid: string): Promise<KyResponse<IAudit>> {
        return HttpService.fetch(endpoints.vaccinationService.audits.audit(uuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async postAudit(vaccinationSiteId: number, audit: IAudit): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.audits(vaccinationSiteId), {
            method: HttpMethod.POST,
            json: audit,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putAudit(uuid: string, audit: IAudit): Promise<KyResponse<IAudit>> {
        return HttpService.fetch(endpoints.vaccinationService.audits.audit(uuid), {
            method: HttpMethod.PUT,
            json: audit,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    // ---- NON COMPLIANCE
    async getNonCompliance(uuid: string): Promise<KyResponse<INonCompliance>> {
        return HttpService.fetch(endpoints.vaccinationService.nonCompliance.nonCompliance(uuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async deleteNonCompliance(uuid: string): Promise<KyResponse<INonCompliance>> {
        return HttpService.fetch(endpoints.vaccinationService.nonCompliance.nonCompliance(uuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }
    async postNonCompliance(vaccinationSiteId: number, nonCompliance: INonCompliance): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.nonCompliances(vaccinationSiteId), {
            method: HttpMethod.POST,
            json: nonCompliance,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putNonCompliance(uuid: string, nonCompliance: INonCompliance): Promise<KyResponse<INonCompliance>> {
        return HttpService.fetch(endpoints.vaccinationService.nonCompliance.nonCompliance(uuid), {
            method: HttpMethod.PUT,
            json: nonCompliance,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getVaccinationSiteWorkshops(farmId: string, date: string): Promise<KyResponse<IVaccinationSiteWorkshop[]>> {
        return HttpService.fetch(endpoints.vaccinationService.farms.workshops(farmId), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
            searchParams: formatParams({ date }),
        });
    }

    async getVaccinationSiteWorkshopsInfo(id: number): Promise<KyResponse<IVaccinationSiteWorkshopInfo[]>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.workshopsInfo(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    // ---- MEMORIES

    async getMemories(payload: SearchParams & Partial<IMemoryFilters>): Promise<KyResponse<IMemory[]>> {
        return HttpService.fetch(endpoints.vaccinationService.memories.base, {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
            searchParams: formatParams(payload),
        });
    }

    async getMemoryStatuses(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.memoryStatuses(), {
            method: HttpMethod.GET,
        });
    }

    async getMemoryTypes(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.vaccinationService.referential.memoryTypes(), {
            method: HttpMethod.GET,
        });
    }

    async getMemoriesCounts(): Promise<KyResponse<IMemoryCounts>> {
        return HttpService.fetch(endpoints.vaccinationService.memories.counts(), {
            method: HttpMethod.GET,
        });
    }

    async getMemoryFile(memoryId: number, fileUuid: string) {
        return HttpService.fetch(endpoints.vaccinationService.memories.file(memoryId, fileUuid), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async patchMemoryStatus(memoryId: number, status: string): Promise<KyResponse<void>> {
        return HttpService.fetch(endpoints.vaccinationService.memories.status(memoryId), {
            method: HttpMethod.PATCH,
            json: { status },
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async postMemory(data: ICreateMemory): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.memories.base, {
            method: HttpMethod.POST,
            json: data,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteMemory(memoryId: number): Promise<KyResponse<void>> {
        return HttpService.fetch(endpoints.vaccinationService.memories.memory(memoryId), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async addVaccinationSitesToMemory(memoryId: number, vaccinationSiteIds: number[]): Promise<KyResponse<void>> {
        return HttpService.fetch(endpoints.vaccinationService.memories.vaccinationSites(memoryId), {
            method: HttpMethod.PATCH,
            json: { vaccinationSiteIds },
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getValidatedVaccinationSites(dpeId: string): Promise<KyResponse<IValidatedVaccinationSite[]>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.validated(), {
            method: HttpMethod.GET,
            searchParams: formatParams({ dpeId }),
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getMemoryBillables(
        id: number,
        payload: SearchParams & Partial<IBillableFilters>
    ): Promise<KyResponse<IBillable[]>> {
        return HttpService.fetch(endpoints.vaccinationService.memories.vaccinationSites(id), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
            searchParams: formatParams(payload),
        });
    }

    async removeVaccinationSiteFromMemory(memoryId: number, vaccinationSiteId: number): Promise<KyResponse<void>> {
        return HttpService.fetch(
            endpoints.vaccinationService.memories.removeVaccinationSite(memoryId, vaccinationSiteId),
            {
                method: HttpMethod.PATCH,
                headers: {
                    loader: Loader.TABLE,
                },
            }
        );
    }

    async getVaccinationSiteStatusChanges(vaccinationSiteId: number): Promise<KyResponse<IStatusChange[]>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.statusChanges(vaccinationSiteId), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.BUTTON,
            },
        });
    }

    async getVaccinationSiteAllowedNextStatuses(vaccinationSiteId: number): Promise<KyResponse<BillingStatusKeys[]>> {
        return HttpService.fetch(endpoints.vaccinationService.vaccinationSites.allowedNextStatuses(vaccinationSiteId), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getVaccinationSitesExport(data: IExportParams): Promise<KyResponse> {
        return HttpService.fetch(endpoints.vaccinationService.exports.vaccinationSites(), {
            method: HttpMethod.POST,
            json: data,
        });
    }
}

export default new VaccinationService();
