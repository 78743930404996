// === NPM
import React from "react";
import { useOutletContext } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL
import { ReactComponent as Danger } from "@/assets/icons/shared/danger-triangle.svg";
import { ReactComponent as Species } from "@/assets/icons/shared/pet.svg";
import { ReactComponent as Sum } from "@/assets/icons/vaccination/sum.svg";
import GenericAccordion from "@/components/generics/layout/GenericAccordion";
import { GenericIconText } from "@/components/generics/text/GenericIconText";
import { colors } from "@/resources/CssConstant";
import { HealthReportingOutletContext, IReportedAnomalyFull } from "../../interface";

interface ReportedAnomaliesAccordionProps {
    reportedAnomalies: IReportedAnomalyFull[];
}

export default function ReportedAnomaliesAccordion({ reportedAnomalies }: Readonly<ReportedAnomaliesAccordionProps>) {
    const { species } = useOutletContext<HealthReportingOutletContext>();

    const renderContent = () => {
        return reportedAnomalies.map((anomaly, index) => (
            <Box
                key={index}
                sx={{
                    bgcolor: colors.lightGray,
                    p: 2,
                    borderRadius: 5
                }}>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <GenericIconText icon={<Danger />} text={anomaly.anomalyType} iconTooltip="Anomalie" />
                        <GenericIconText
                            icon={<Species />}
                            text={species.find((item) => item.uuid === anomaly.species)?.label}
                            iconTooltip="Espèce"
                        />
                        <GenericIconText
                            icon={<Sum width={20} />}
                            text={`${anomaly.animalCount} animaux`}
                            iconTooltip="Nombre d'animaux"
                        />
                    </Stack>
                    {anomaly.individualIdentification && (
                        <Stack spacing={1}>
                            <Typography variant="bold">Identification individuelle :</Typography>
                            <Typography>{anomaly.individualIdentification}</Typography>
                        </Stack>
                    )}
                </Stack>
            </Box>
        ));
    };

    return (
        <GenericAccordion title="II. Anomalies remarquées" defaultExpanded>
            <Stack spacing={2}>{renderContent()}</Stack>
        </GenericAccordion>
    );
}
