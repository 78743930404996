// === Import: NPM
import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
// === Import: LOCAL
import Administration from "@/components/Administration/Administration";
import SIPartners from "@/components/Administration/containers/ApiKeys/containers/SIPartners/SIPartners";
import CategoryAndTag from "@/components/Administration/containers/Faq/containers/CategoryAndTag/CategoryAndTag";
import Question from "@/components/Administration/containers/Faq/containers/Question/Question";
import { Faq as FaqAdministration } from "@/components/Administration/containers/Faq/Faq";
import AutoNotificationConfiguration from "@/components/Administration/containers/Notifications/containers/AutoNotificationConfiguration/AutoNotificationConfiguration";
import { Form as NotificationForm } from "@/components/Administration/containers/Notifications/containers/Form/Form";
import NotificationsDashboardTemplate from "@/components/Administration/containers/Notifications/containers/NotificationsDashboardTemplate/NotificationsDashboardTemplate";
import Notifications from "@/components/Administration/containers/Notifications/Notifications";
import Profiles from "@/components/Administration/containers/Profiles/Profiles";
import HealthAccreditationTrainingSessionTraceability from "@/components/Administration/containers/Traceability/containers/HealthAccreditationTrainingSessionTraceability/HealthAccreditationTrainingSessionTraceability";
import HealthAccreditationTrainingTraceability from "@/components/Administration/containers/Traceability/containers/HealthAccreditationTrainingTraceability/HealthAccreditationTrainingTraceability";
import HealthReportingTraceability from "@/components/Administration/containers/Traceability/containers/HealthReportingTraceability/HealthReportingTraceability";
import OvvtTraceability from "@/components/Administration/containers/Traceability/containers/OvvtTraceability/OvvtTraceability";
import Traceability from "@/components/Administration/containers/Traceability/Traceability";
import UserMa from "@/components/Administration/containers/User/containers/UserMa/UserMa";
import User from "@/components/Administration/containers/User/User";
import AntimicrobialDisposal from "@/components/AntimicrobialDisposal/AntimicrobialDisposal";
import PrescriptionDashboard from "@/components/AntimicrobialDisposal/Dashboard/containers/PrescriptionDashboard/PrescriptionDashboard";
import Dashboard from "@/components/AntimicrobialDisposal/Dashboard/Dashboard";
import ManualDelivery from "@/components/AntimicrobialDisposal/ManualDelivery/ManualDelivery";
import DashBoard from "@/components/AntimicrobialDisposal/PowerBi/containers/DashBoard/DashBoard";
import FollowUp from "@/components/AntimicrobialDisposal/PowerBi/containers/FollowUp/FollowUp";
import Indicators from "@/components/AntimicrobialDisposal/PowerBi/containers/Indicators/Indicators";
import PowerBi from "@/components/AntimicrobialDisposal/PowerBi/PowerBi";
import ContinuousTraining from "@/components/ContinuousTraining/ContinuousTraining";
import Downloads from "@/components/Downloads/Downloads";
import Faq from "@/components/Faq/Faq";
import FilePreviewer from "@/components/generics/FilePreviewer";
import CatalogHealthAccreditationTraining from "@/components/HealthAccreditationTraining/containers/CatalogHealthAccreditationTraining.tsx/CatalogHealthAccreditationTraining";
import AttestationsDdpp from "@/components/HealthAccreditationTraining/containers/Sessions/containers/AttestationsDdpp/AttestationsDdpp";
import ViewAttestations from "@/components/HealthAccreditationTraining/containers/Sessions/containers/AttestationsVeterinary/AttestationsVeterinary";
import { Form as FormHealthAccreditationTraining } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/Form/Form";
import { Inscriptions } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/Inscriptions/Inscriptions";
import InscriptionsVeterinary from "@/components/HealthAccreditationTraining/containers/Sessions/containers/InscriptionsVeterinary/InscriptionsVeterinary";
import { View as ViewHealthAccreditationTrainings } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/View/View";
import Sessions from "@/components/HealthAccreditationTraining/containers/Sessions/Sessions";
import HealthAccreditationTraining from "@/components/HealthAccreditationTraining/HealthAccreditationTraining";
import AnimalAbuse from "@/components/HealthReporting/AnimalAbuse/AnimalAbuse";
import HealthReportingFormManager from "@/components/HealthReporting/AnimalAbuse/containers/HealthReportingForm/FormManager";
import ViewHealthReporting from "@/components/HealthReporting/AnimalAbuse/containers/ViewHealthReportings/ViewHealthReportings";
import HealthReporting from "@/components/HealthReporting/HealthReporting";
import Home from "@/components/Home/Home";
import Parameters from "@/components/Parameters/Parameters";
import UserPreferences from "@/components/Parameters/UserPreferences/UserPreferences";
import Profile from "@/components/Profile/Profile";
import FamDam from "@/components/Referentials/containers/Delivery/containers/FamDam/FamDam";
import Ovvt from "@/components/Referentials/containers/Global/containers/Ovvt/Ovvt";
import Pharmacy from "@/components/Referentials/containers/Global/containers/Pharmacy/Pharmacy";
import Global from "@/components/Referentials/containers/Global/Global";
import Referentials from "@/components/Referentials/Referentials";
import RetrieveKey from "@/components/RetrieveKey/RetrieveKey";
import ButcherySector from "@/components/SanitaryFollowUp/ButcherySector/ButcherySector";
import SanitaryFollowUp from "@/components/SanitaryFollowUp/SanitaryFollowUp";
import UserNotifications from "@/components/UserNotifications/UserNotifications";
import Order from "@/components/VaccinationCampaign/Vaccination/Order/Order";
import OrderTracking from "@/components/VaccinationCampaign/Vaccination/OrderTracking/OrderTracking";
import Scrapping from "@/components/VaccinationCampaign/Vaccination/Scrapping/Scrapping";
import ScrappingTracking from "@/components/VaccinationCampaign/Vaccination/ScrappingTracking/ScrappingTracking";
import Vaccination from "@/components/VaccinationCampaign/Vaccination/Vaccination";
import VaccinationCampaign from "@/components/VaccinationCampaign/VaccinationCampaign";
import Billing from "@/components/VaccinationIahp/Billing/Billing";
import Management from "@/components/VaccinationIahp/Billing/Management/Management";
import Memories from "@/components/VaccinationIahp/Billing/Memories/Memories";
import VaccinationSitesOutsideCalypso from "@/components/VaccinationIahp/Billing/VaccinationSites/VaccinationSitesOutsideCalypso";
import VaccinationSitesWithinCalypso from "@/components/VaccinationIahp/Billing/VaccinationSites/VaccinationSitesWithinCalypso";
import Exports from "@/components/VaccinationIahp/Exports/Exports";
import DeclareRelation from "@/components/VaccinationIahp/Farm/containers/DeclareRelation/DeclareRelation";
import ViewRelation from "@/components/VaccinationIahp/Farm/containers/ViewRelation/ViewRelation";
import Farm from "@/components/VaccinationIahp/Farm/Farm";
import IahpPowerBI from "@/components/VaccinationIahp/IahpPowerBI/IahpPowerBI";
import { FollowUp as MonitoringFollowUp } from "@/components/VaccinationIahp/MonitoringIntervention/FollowUp/FollowUp";
import { Form as FormMonitoring } from "@/components/VaccinationIahp/MonitoringIntervention/Form/Form";
import MonitoringIntervention from "@/components/VaccinationIahp/MonitoringIntervention/MonitoringIntervention";
import TraceabilityIahp from "@/components/VaccinationIahp/TraceabilityIahp/TraceabilityIahp";
import VaccinationIahp from "@/components/VaccinationIahp/VaccinationIahp";
import { Form as VaccinationSiteForm } from "@/components/VaccinationIahp/VaccinationSite/Form/Form";
import VaccinationIntervention from "@/components/VaccinationIahp/VaccinationSite/VaccinationIntervention/VaccinationIntervention";
import { FormIntervention as VaccinationInterventionForm } from "@/components/VaccinationIahp/VaccinationSite/VaccinationInterventionForm/FormIntervention";
import VaccinationSite from "@/components/VaccinationIahp/VaccinationSite/VaccinationSite";
import { View as VaccinationSiteView } from "@/components/VaccinationIahp/VaccinationSite/View/View";
import { drawerWidth } from "@/resources/AppConstant";
import { FeatureFlags, hasFeatureFlag } from "@/resources/hasFeatureFlag";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import RoleRoute from "./RoleRoute";
import { routerLinks } from "./RouterConstant";

const HorseResearch = lazy(() => import("../components/SanitaryFollowUp/ButcherySector/HorseResearch/HorseResearch"));
const Exclusion = lazy(() => import("../components/SanitaryFollowUp/ButcherySector/Exclusion/Exclusion"));

//MANUAL DELIVERY
const FormManager = lazy(
    () => import("../components/AntimicrobialDisposal/ManualDelivery/containers/FormManager/FormManager")
);

const ManualDeliveryDashboard = lazy(
    () =>
        import(
            "../components/AntimicrobialDisposal/ManualDelivery/containers/ManualDeliveryDashboard/ManualDeliveryDashboard"
        )
);

// DELIVERY DASHBOARD
const TrackingLogs = lazy(
    () => import("../components/AntimicrobialDisposal/Dashboard/containers/TrackingLogs/TrackingLogs")
);
const DrugDeliveryDashboard = lazy(
    () => import("../components/AntimicrobialDisposal/Dashboard/containers/DeliveryDashboard/DrugDeliveryDashboard")
);

const DpePreferences = lazy(() => import("../components/Parameters/DpePreferences/DpePreferences"));

// === TRAINING
const ContinuousTrainings = lazy(
    () => import("../components/ContinuousTraining/containers/ContinuousTrainings/ContinuousTrainings")
);
const CatalogContinuousTraining = lazy(
    () => import("../components/ContinuousTraining/containers/CatalogContinuousTraining/CatalogContinuousTraining")
);
// === ADMINISTRATION
// === ADMINISTRATION API KEYS
const ApiKeys = lazy(() => import("../components/Administration/containers/ApiKeys/ApiKeys"));
const Alerts = lazy(() => import("../components/Administration/containers/ApiKeys/containers/Alerts/Alerts"));
const ContinuousTrainingApiKeys = lazy(
    () =>
        import(
            "../components/Administration/containers/ApiKeys/containers/ContinuousTrainingApiKeys/ContinuousTrainingApiKeys"
        )
);
const DeliveryApiKeys = lazy(
    () => import("../components/Administration/containers/ApiKeys/containers/DeliveryApiKeys/DeliveryApiKeys")
);

// === ADMINISTRATION USER
const UserFamDam = lazy(() => import("../components/Administration/containers/User/containers/UserFamDam/UserFamDam"));
const UserGlobal = lazy(() => import("../components/Administration/containers/User/containers/UserGlobal/UserGlobal"));

// === TRACEABILITY
const OrganizationTraceability = lazy(
    () =>
        import(
            "../components/Administration/containers/Traceability/containers/OrganizationTraceability/OrganizationTraceability"
        )
);
const UserTraceability = lazy(
    () => import("../components/Administration/containers/Traceability/containers/UserTraceability/UserTraceability")
);
const FamDamAgencyTraceability = lazy(
    () =>
        import(
            "../components/Administration/containers/Traceability/containers/FamDamAgencyTraceability/FamDamAgencyTraceability"
        )
);
const NonDeliveryTraceability = lazy(
    () =>
        import(
            "../components/Administration/containers/Traceability/containers/NonDeliveryTraceability/NonDeliveryTraceability"
        )
);

const MonitoringInterventionTraceability = lazy(
    () =>
        import(
            "../components/VaccinationIahp/TraceabilityIahp/containers/MonitoringInterventionTraceability/MonitoringInterventionTraceability"
        )
);

const ScrappingTraceability = lazy(
    () =>
        import("../components/VaccinationIahp/TraceabilityIahp/containers/ScrappingTraceability/ScrappingTraceability")
);

const VaccinationSiteTraceability = lazy(
    () =>
        import(
            "../components/VaccinationIahp/TraceabilityIahp/containers/VaccinationSiteTraceability/VaccinationSiteTraceability"
        )
);

// === REFERENTIALS
// === GLOBAL
const Dpes = lazy(() => import("../components/Referentials/containers/Global/containers/Dpes/Dpes"));
const ReferentialDashboard = lazy(() => import("../components/Referentials/containers/Dashboard/Dashboard"));
const SpeciesCategory = lazy(
    () => import("../components/Referentials/containers/Global/containers/SpeciesCategory/SpeciesCategory")
);

const LocalAgency = lazy(
    () => import("../components/Referentials/containers/Global/containers/LocalAgency/LocalAgency")
);

const AgriMinistryGeoUnit = lazy(
    () => import("../components/Referentials/containers/Global/containers/AgriMinistryGeoUnit/AgriMinistryGeoUnit")
);

const Workshops = lazy(() => import("../components/Referentials/containers/Global/containers/Workshops/Workshops"));
// === DELIVERY
const ReferentialsDelivery = lazy(() => import("../components/Referentials/containers/Delivery/Delivery"));

const Drug = lazy(() => import("../components/Referentials/containers/Delivery/containers/Drug/Drug"));
const TypeIdentifiant = lazy(
    () => import("../components/Referentials/containers/Delivery/containers/TypeIdentifiant/TypeIdentifiant")
);
const DrugTransferOrganization = lazy(
    () =>
        import(
            "../components/Referentials/containers/Delivery/containers/DrugTransferOrganization/DrugTransferOrganization"
        )
);

// === TRAINING
const ReferentialsTraining = lazy(() => import("../components/Referentials/containers/Training/Training"));
const Type = lazy(() => import("../components/Referentials/containers/Training/containers/Type/Type"));
const Format = lazy(() => import("../components/Referentials/containers/Training/containers/Format/Format"));

const Organization = lazy(
    () => import("../components/Referentials/containers/Training/containers/Organization/Organization")
);

export default function AuthenticatedRouter() {
    return (
        <Box
            id="page"
            sx={{
                height: "100%",
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                overflow: "auto",
                flexGrow: 1,
                p: 2,
                position: "relative",
            }}
        >
            <Routes>
                {/* Once logged we're still on /connexion url, allow to setup default redirection before router redirection occured */}
                <Route path={routerLinks.login} element={<Navigate to={routerLinks.home} />} />
                <Route path={routerLinks.home} element={<Home />} />
                <Route path={routerLinks.profile} element={<Profile />} />
                <Route path={routerLinks.faq} element={<Faq />} />
                <Route path={routerLinks.filePreview.path} element={<FilePreviewer />} />

                {/* CONTINUOUS TRAINING */}
                <Route path={routerLinks.continuousTraining.base} element={<ContinuousTraining />}>
                    <Route
                        path={routerLinks.continuousTraining.trainings()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                            >
                                <ContinuousTrainings />
                            </RoleRoute>
                        }
                    />
                    <Route
                        path={routerLinks.continuousTraining.catalog()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[UserSituation.REGISTERED_IN_PRACTICE, UserSituation.ADMIN_CALYPSO]}
                            >
                                <CatalogContinuousTraining />
                            </RoleRoute>
                        }
                    />
                </Route>

                {/* HEALTH ACCREDITATION TRAINING*/}
                {hasFeatureFlag(FeatureFlags.FMHS) && (
                    <Route
                        path={routerLinks.healthAccreditationTraining.base}
                        element={<HealthAccreditationTraining />}
                    >
                        <Route
                            path={routerLinks.healthAccreditationTraining.catalog()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_OVVT,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_OVVT,
                                    ]}
                                >
                                    <CatalogHealthAccreditationTraining />
                                </RoleRoute>
                            }
                        />
                        <Route path={routerLinks.healthAccreditationTraining.sessions.base()} element={<Sessions />}>
                            <Route
                                path={routerLinks.healthAccreditationTraining.sessions.view()}
                                element={
                                    <RoleRoute
                                        redirection={routerLinks.home}
                                        permissions={[
                                            UserSituation.ADMIN_CALYPSO,
                                            UserSituation.ADMIN_DDPP,
                                            UserSituation.USER_DDPP,
                                            UserSituation.ADMIN_OVVT,
                                            UserSituation.USER_OVVT,
                                            UserSituation.REGISTERED_IN_PRACTICE,
                                        ]}
                                    >
                                        <ViewHealthAccreditationTrainings />
                                    </RoleRoute>
                                }
                            />
                            <Route
                                path={routerLinks.healthAccreditationTraining.sessions.form()}
                                element={
                                    <RoleRoute
                                        redirection={routerLinks.home}
                                        permissions={[UserSituation.ADMIN_OVVT, UserSituation.USER_OVVT]}
                                    >
                                        <FormHealthAccreditationTraining />
                                    </RoleRoute>
                                }
                            />
                            <Route
                                path={routerLinks.healthAccreditationTraining.sessions.inscriptions()}
                                element={
                                    <RoleRoute
                                        redirection={routerLinks.home}
                                        permissions={[
                                            UserSituation.ADMIN_OVVT,
                                            UserSituation.USER_OVVT,
                                            UserSituation.ADMIN_DDPP,
                                            UserSituation.USER_DDPP,
                                        ]}
                                    >
                                        <Inscriptions />
                                    </RoleRoute>
                                }
                            />
                            <Route
                                path={routerLinks.healthAccreditationTraining.sessions.attestations()}
                                element={
                                    <RoleRoute
                                        redirection={routerLinks.home}
                                        permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    >
                                        <ViewAttestations />
                                    </RoleRoute>
                                }
                            />
                            <Route
                                path={routerLinks.healthAccreditationTraining.sessions.process()}
                                element={
                                    <RoleRoute
                                        redirection={routerLinks.home}
                                        permissions={[UserSituation.ADMIN_DDPP, UserSituation.USER_DDPP]}
                                    >
                                        <AttestationsDdpp />
                                    </RoleRoute>
                                }
                            />
                            <Route
                                path={routerLinks.healthAccreditationTraining.sessions.viewInscriptions()}
                                element={
                                    <RoleRoute
                                        redirection={routerLinks.home}
                                        permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    >
                                        <InscriptionsVeterinary />
                                    </RoleRoute>
                                }
                            />
                        </Route>
                    </Route>
                )}

                {/* NOTIFICATIONS */}
                <Route path={routerLinks.notifications} element={<UserNotifications />} />

                {/* DOWNLOADS */}
                <Route path={routerLinks.downloads} element={<Downloads />} />

                {/* PARAMETERS */}
                <Route path={routerLinks.parameters.base} element={<Parameters />}>
                    <Route
                        path={routerLinks.parameters.dpePreferences()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                ]}
                            >
                                <DpePreferences />
                            </RoleRoute>
                        }
                    />
                    <Route path={routerLinks.parameters.userPreferences()} element={<UserPreferences />} />
                </Route>

                {/* ATM */}
                <Route path={routerLinks.delivery.base} element={<AntimicrobialDisposal />}>
                    <Route
                        path={routerLinks.delivery.dashboard.base()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                    UserSituation.FAM_DAM_ADMIN,
                                    UserSituation.FAM_DAM_USER,
                                    UserSituation.PHARMACIST,
                                ]}
                            >
                                <Dashboard />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.delivery.dashboard.trackingLogs()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ANMV_MED,
                                        UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                        UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                        UserSituation.FAM_DAM_ADMIN,
                                        UserSituation.FAM_DAM_USER,
                                        UserSituation.PHARMACIST,
                                    ]}
                                >
                                    <TrackingLogs />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.delivery.dashboard.deliveries()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ANMV_MED,
                                        UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                        UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                        UserSituation.FAM_DAM_ADMIN,
                                        UserSituation.FAM_DAM_USER,
                                        UserSituation.PHARMACIST,
                                    ]}
                                >
                                    <DrugDeliveryDashboard />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.delivery.dashboard.prescriptions()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ANMV_MED,
                                        UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                        UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                    ]}
                                >
                                    <PrescriptionDashboard />
                                </RoleRoute>
                            }
                        />
                    </Route>
                    <Route
                        path={routerLinks.delivery.powerBi.base()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                    UserSituation.LIBERAL_VETERINARY,
                                ]}
                            >
                                <PowerBi />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.delivery.powerBi.followUp()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED]}
                                >
                                    <FollowUp />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.delivery.powerBi.indicators()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED]}
                                >
                                    <Indicators />
                                </RoleRoute>
                            }
                        />
                        <Route path={routerLinks.delivery.powerBi.dashBoard()} element={<DashBoard />} />
                    </Route>
                    <Route
                        path={routerLinks.delivery.manualDelivery.base()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.PUBLIC_SECTOR_VETERINARIAN,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.THIRD_COUNTRIES_VETERINARY_REGISTERED_DIPLOMA,
                                    UserSituation.PHARMACIST,
                                ]}
                            >
                                <ManualDelivery />
                            </RoleRoute>
                        }
                    >
                        <Route path={routerLinks.delivery.manualDelivery.form()} element={<FormManager />} />
                        <Route
                            path={routerLinks.delivery.manualDelivery.view()}
                            element={<ManualDeliveryDashboard />}
                        />
                    </Route>
                </Route>

                {/* VACCINATION CAMPAIGN */}
                <Route
                    path={routerLinks.vaccinationCampaign.base}
                    element={
                        <RoleRoute
                            permissions={[
                                UserSituation.ADMIN_CALYPSO,
                                UserSituation.ADMIN_DDPP,
                                UserSituation.ADMIN_DGAL,
                                UserSituation.ADMIN_DRAAF,
                                UserSituation.USER_DDPP,
                                UserSituation.USER_DGAL,
                                UserSituation.USER_DRAAF,
                                UserSituation.REGISTERED_IN_PRACTICE,
                            ]}
                            preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                            redirection={routerLinks.home}
                        >
                            <VaccinationCampaign />
                        </RoleRoute>
                    }
                >
                    <Route
                        path={routerLinks.vaccinationCampaign.vaccine.base()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ]}
                                preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                            >
                                <Vaccination />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.vaccinationCampaign.vaccine.order()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                                >
                                    <Order />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.vaccinationCampaign.vaccine.view()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                    ]}
                                    preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                                >
                                    <OrderTracking />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.vaccinationCampaign.vaccine.scrappingView()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                    ]}
                                    preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                                >
                                    <ScrappingTracking />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.vaccinationCampaign.vaccine.scrappingDeclare()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP, Preference.CATTLE, Preference.SHEEP]}
                                >
                                    <Scrapping />
                                </RoleRoute>
                            }
                        />
                    </Route>
                </Route>

                {/* IAHP */}
                <Route
                    path={routerLinks.iahp.base}
                    element={
                        <RoleRoute
                            permissions={[
                                UserSituation.ADMIN_CALYPSO,
                                UserSituation.ADMIN_DDPP,
                                UserSituation.ADMIN_DGAL,
                                UserSituation.ADMIN_DRAAF,
                                UserSituation.USER_DDPP,
                                UserSituation.USER_DGAL,
                                UserSituation.USER_DRAAF,
                                UserSituation.REGISTERED_IN_PRACTICE,
                            ]}
                            preferences={[Preference.IAHP]}
                            redirection={routerLinks.home}
                        >
                            <VaccinationIahp />
                        </RoleRoute>
                    }
                >
                    {/* FARM */}
                    <Route
                        path={routerLinks.iahp.farm.base()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ]}
                                preferences={[Preference.IAHP]}
                            >
                                <Farm />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.iahp.farm.declareRelation()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <DeclareRelation />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.farm.view()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                    ]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <ViewRelation />
                                </RoleRoute>
                            }
                        />
                    </Route>

                    {/* VACCINATION SITE */}
                    <Route
                        path={routerLinks.iahp.vaccinationSite.base()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ]}
                                preferences={[Preference.IAHP]}
                            >
                                <VaccinationSite />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.iahp.vaccinationSite.view()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                    ]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <VaccinationSiteView />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.vaccinationSite.vaccinationIntervention.base()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                    ]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <VaccinationIntervention />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.vaccinationSite.form()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <VaccinationSiteForm />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.vaccinationSite.editForm()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <VaccinationSiteForm />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.vaccinationSite.vaccinationIntervention.form()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <VaccinationInterventionForm />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.vaccinationSite.vaccinationIntervention.editForm()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <VaccinationInterventionForm />
                                </RoleRoute>
                            }
                        />
                    </Route>

                    {/* MONITORING INTERVENTIONS */}
                    <Route
                        path={routerLinks.iahp.monitoringIntervention.base()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ]}
                                preferences={[Preference.IAHP]}
                            >
                                <MonitoringIntervention />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.iahp.monitoringIntervention.form()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <FormMonitoring />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.monitoringIntervention.view()}
                            element={
                                <RoleRoute
                                    redirection={routerLinks.home}
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                    ]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <MonitoringFollowUp />
                                </RoleRoute>
                            }
                        />
                    </Route>

                    {/* TRACEABILITY IAHP */}
                    <Route
                        path={routerLinks.iahp.traceabilityIahp.base()}
                        element={
                            <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                <TraceabilityIahp />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.iahp.traceabilityIahp.monitoringIntervention()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <MonitoringInterventionTraceability />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.traceabilityIahp.scrapping()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <ScrappingTraceability />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.iahp.traceabilityIahp.vaccinationSite()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <VaccinationSiteTraceability />
                                </RoleRoute>
                            }
                        />
                    </Route>

                    {/* IAHP PBI */}
                    <Route
                        path={routerLinks.iahp.iahpPowerBi()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                ]}
                            >
                                <IahpPowerBI />
                            </RoleRoute>
                        }
                    />

                    {/* EXPORTS IAHP */}
                    <Route
                        path={routerLinks.iahp.exports()}
                        element={
                            <RoleRoute
                                redirection={routerLinks.home}
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.USER_DGAL,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                ]}
                                preferences={[Preference.IAHP]}
                            >
                                <Exports />
                            </RoleRoute>
                        }
                    />

                    {/* BILLING */}
                    {hasFeatureFlag(FeatureFlags.BILLING) && (
                        <Route
                            path={routerLinks.iahp.billing.base()}
                            element={
                                <RoleRoute
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.USER_DDPP,
                                    ]}
                                    redirection={routerLinks.home}
                                    preferences={[Preference.IAHP]}
                                >
                                    <Billing />
                                </RoleRoute>
                            }
                        >
                            <Route
                                path={routerLinks.iahp.billing.management()}
                                element={
                                    <RoleRoute
                                        permissions={[UserSituation.ADMIN_CALYPSO]}
                                        redirection={routerLinks.home}
                                    >
                                        <Management />
                                    </RoleRoute>
                                }
                            />
                            <Route
                                path={routerLinks.iahp.billing.vaccinationSites()}
                                element={<VaccinationSitesWithinCalypso />}
                            />
                            <Route path={routerLinks.iahp.billing.memories()} element={<Memories />} />
                            <Route
                                path={routerLinks.iahp.billing.vaccinationSitesOutsideCalypso()}
                                element={<VaccinationSitesOutsideCalypso />}
                            />
                        </Route>
                    )}
                </Route>

                {/* BUTCHERY SECTOR*/}
                <Route
                    path={routerLinks.sanitaryFollowUp.base}
                    element={
                        <RoleRoute
                            permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE]}
                            preferences={[Preference.BUTCHERY_SECTOR]}
                            redirection={routerLinks.home}
                        >
                            <SanitaryFollowUp />
                        </RoleRoute>
                    }
                >
                    <Route
                        path={routerLinks.sanitaryFollowUp.butcherySector.base()}
                        element={
                            <RoleRoute
                                permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE]}
                                preferences={[Preference.BUTCHERY_SECTOR]}
                                redirection={routerLinks.home}
                            >
                                <ButcherySector />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.sanitaryFollowUp.butcherySector.search()}
                            element={
                                <RoleRoute
                                    permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.BUTCHERY_SECTOR]}
                                    redirection={routerLinks.home}
                                >
                                    <HorseResearch />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.sanitaryFollowUp.butcherySector.exclusion()}
                            element={
                                <RoleRoute
                                    permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.BUTCHERY_SECTOR]}
                                    redirection={routerLinks.home}
                                >
                                    <Exclusion />
                                </RoleRoute>
                            }
                        />
                    </Route>
                </Route>

                {/* HEALTH REPORTING */}
                {hasFeatureFlag(FeatureFlags.HEALTH_REPORTING) && (
                    <Route
                        path={routerLinks.healthReporting.base}
                        element={
                            <RoleRoute
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.REGISTERED_IN_PRACTICE,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                ]}
                                redirection={routerLinks.home}
                            >
                                <HealthReporting />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.healthReporting.animalAbuse.base()}
                            element={
                                <RoleRoute
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.REGISTERED_IN_PRACTICE,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                    ]}
                                    redirection={routerLinks.home}
                                >
                                    <AnimalAbuse />
                                </RoleRoute>
                            }
                        >
                            <Route
                                path={routerLinks.healthReporting.animalAbuse.view()}
                                element={
                                    <RoleRoute
                                        permissions={[
                                            UserSituation.ADMIN_CALYPSO,
                                            UserSituation.REGISTERED_IN_PRACTICE,
                                            UserSituation.ADMIN_DDPP,
                                            UserSituation.ADMIN_DGAL,
                                            UserSituation.ADMIN_DRAAF,
                                            UserSituation.USER_DDPP,
                                            UserSituation.USER_DGAL,
                                            UserSituation.USER_DRAAF,
                                        ]}
                                        redirection={routerLinks.home}
                                    >
                                        <ViewHealthReporting />
                                    </RoleRoute>
                                }
                            />
                            <Route
                                path={routerLinks.healthReporting.animalAbuse.form()}
                                element={
                                    <RoleRoute
                                        permissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                        redirection={routerLinks.home}
                                    >
                                        <HealthReportingFormManager />
                                    </RoleRoute>
                                }
                            />
                        </Route>
                    </Route>
                )}

                {/* ADMINISTRATION */}
                <Route
                    path={routerLinks.admin.base}
                    element={
                        <RoleRoute
                            permissions={[
                                UserSituation.ADMIN_CALYPSO,
                                UserSituation.FAM_DAM_ADMIN,
                                UserSituation.ADMIN_DDPP,
                                UserSituation.ADMIN_DGAL,
                                UserSituation.ADMIN_DRAAF,
                                UserSituation.USER_DDPP,
                                UserSituation.USER_DGAL,
                                UserSituation.USER_DRAAF,
                                UserSituation.ANMV_NOTIF,
                                UserSituation.ADMIN_OVVT,
                                UserSituation.USER_OVVT,
                            ]}
                            redirection={routerLinks.home}
                        >
                            <Administration />
                        </RoleRoute>
                    }
                >
                    <Route
                        path={routerLinks.admin.profile()}
                        element={
                            <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                <Profiles />
                            </RoleRoute>
                        }
                    />
                    <Route
                        path={routerLinks.admin.faq.base()}
                        element={
                            <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                <FaqAdministration />
                            </RoleRoute>
                        }
                    >
                        <Route path={routerLinks.admin.faq.questions()} element={<Question />} />
                        <Route path={routerLinks.admin.faq.categoryAndTag()} element={<CategoryAndTag />} />
                    </Route>
                    <Route
                        path={routerLinks.admin.notifications.base()}
                        element={
                            <RoleRoute
                                permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_NOTIF]}
                                redirection={routerLinks.home}
                            >
                                <Notifications />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.admin.notifications.gestion()}
                            element={
                                <NotificationsDashboardTemplate
                                    key={routerLinks.admin.notifications.gestion()}
                                    isAutomaticNotifications={false}
                                    // ANCHOR
                                />
                            }
                        />
                        <Route path={routerLinks.admin.notifications.form()} element={<NotificationForm />} />

                        <Route
                            path={routerLinks.admin.notifications.automatic()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <NotificationsDashboardTemplate
                                        key={routerLinks.admin.notifications.automatic()}
                                        isAutomaticNotifications
                                    />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.notifications.autoNotifConfig()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <AutoNotificationConfiguration />
                                </RoleRoute>
                            }
                        />
                    </Route>
                    {/* USERS */}
                    <Route
                        path={routerLinks.admin.users.base()}
                        element={
                            <RoleRoute
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.FAM_DAM_ADMIN,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                ]}
                                redirection={routerLinks.home}
                            >
                                <User />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.admin.users.global()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <UserGlobal />
                                </RoleRoute>
                            }
                        />

                        <Route
                            path={routerLinks.admin.users.famDam()}
                            element={
                                <RoleRoute permissions={[UserSituation.FAM_DAM_ADMIN]} redirection={routerLinks.home}>
                                    <UserFamDam />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.users.ma()}
                            element={
                                <RoleRoute
                                    permissions={[
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                    ]}
                                    redirection={routerLinks.home}
                                >
                                    <UserMa />
                                </RoleRoute>
                            }
                        />
                    </Route>

                    {/* API KEYS */}
                    <Route
                        path={routerLinks.admin.apiKey.base()}
                        element={
                            <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                <ApiKeys />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.admin.apiKey.delivery()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <DeliveryApiKeys />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.apiKey.siPartners()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <SIPartners />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.apiKey.continuousTraining()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <ContinuousTrainingApiKeys />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.apiKey.alerts()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <Alerts />
                                </RoleRoute>
                            }
                        />
                    </Route>

                    {/* TRACEABILITY */}
                    <Route
                        path={routerLinks.admin.traceability.base()}
                        element={
                            <RoleRoute
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                    UserSituation.ADMIN_OVVT,
                                    UserSituation.USER_OVVT,
                                ]}
                                redirection={routerLinks.home}
                            >
                                <Traceability />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.admin.traceability.nonDelivery()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <NonDeliveryTraceability />
                                </RoleRoute>
                            }
                        />

                        <Route
                            path={routerLinks.admin.traceability.users()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <UserTraceability />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.traceability.organizations()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <OrganizationTraceability />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.traceability.famDamAgencies()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <FamDamAgencyTraceability />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.admin.traceability.healthReportings()}
                            element={
                                <RoleRoute
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                    ]}
                                    redirection={routerLinks.home}
                                >
                                    <HealthReportingTraceability />
                                </RoleRoute>
                            }
                        />
                        {hasFeatureFlag(FeatureFlags.FMHS) && (
                            <>
                                <Route
                                    path={routerLinks.admin.traceability.ovvt()}
                                    element={
                                        <RoleRoute
                                            permissions={[UserSituation.ADMIN_CALYPSO]}
                                            redirection={routerLinks.home}
                                        >
                                            <OvvtTraceability />
                                        </RoleRoute>
                                    }
                                />
                                <Route
                                    path={routerLinks.admin.traceability.healthAccreditationTrainings()}
                                    element={
                                        <RoleRoute
                                            permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.ADMIN_OVVT]}
                                            redirection={routerLinks.home}
                                        >
                                            <HealthAccreditationTrainingTraceability />
                                        </RoleRoute>
                                    }
                                />
                                <Route
                                    path={routerLinks.admin.traceability.healthAccreditationTrainingSessions()}
                                    element={
                                        <RoleRoute
                                            permissions={[
                                                UserSituation.ADMIN_CALYPSO,
                                                UserSituation.ADMIN_OVVT,
                                                UserSituation.USER_OVVT,
                                                UserSituation.ADMIN_DDPP,
                                                UserSituation.USER_DDPP,
                                            ]}
                                            redirection={routerLinks.home}
                                        >
                                            <HealthAccreditationTrainingSessionTraceability />
                                        </RoleRoute>
                                    }
                                />
                            </>
                        )}
                    </Route>
                </Route>

                {/* REFERENTIALS */}
                <Route path={routerLinks.referential.base} element={<Referentials />}>
                    <Route
                        path={routerLinks.referential.dashboard()}
                        element={
                            <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                <ReferentialDashboard />
                            </RoleRoute>
                        }
                    />
                    {/* REFERENTIALS GLOBALS */}
                    <Route
                        path={routerLinks.referential.global.base()}
                        element={
                            <RoleRoute
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.FAM_DAM_ADMIN,
                                    UserSituation.FAM_DAM_USER,
                                    UserSituation.ADMIN_DDPP,
                                    UserSituation.ADMIN_DGAL,
                                    UserSituation.ADMIN_DRAAF,
                                    UserSituation.USER_DDPP,
                                    UserSituation.USER_DGAL,
                                    UserSituation.USER_DRAAF,
                                ]}
                                redirection={routerLinks.home}
                            >
                                <Global />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.referential.global.dpeDirectory()}
                            element={
                                <RoleRoute
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ANMV_MED,
                                        UserSituation.FAM_DAM_ADMIN,
                                        UserSituation.FAM_DAM_USER,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                    ]}
                                    redirection={routerLinks.home}
                                >
                                    <Dpes />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.global.species()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <SpeciesCategory />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.global.famDamAgencies()}
                            element={
                                <RoleRoute
                                    permissions={[UserSituation.FAM_DAM_ADMIN, UserSituation.FAM_DAM_USER]}
                                    redirection={routerLinks.home}
                                >
                                    <LocalAgency />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.global.agriMinistryGeoUnit()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <AgriMinistryGeoUnit />
                                </RoleRoute>
                            }
                        />

                        <Route
                            path={routerLinks.referential.global.workshops()}
                            element={
                                <RoleRoute
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ADMIN_DDPP,
                                        UserSituation.ADMIN_DGAL,
                                        UserSituation.ADMIN_DRAAF,
                                        UserSituation.USER_DDPP,
                                        UserSituation.USER_DGAL,
                                        UserSituation.USER_DRAAF,
                                    ]}
                                    redirection={routerLinks.home}
                                >
                                    <Workshops />
                                </RoleRoute>
                            }
                        />
                        {hasFeatureFlag(FeatureFlags.FMHS) && (
                            <Route
                                path={routerLinks.referential.global.ovvt()}
                                element={
                                    <RoleRoute
                                        permissions={[UserSituation.ADMIN_CALYPSO]}
                                        redirection={routerLinks.home}
                                    >
                                        <Ovvt />
                                    </RoleRoute>
                                }
                            />
                        )}
                    </Route>
                    {/* REFERENTIALS DRUG DELIVERY */}
                    <Route
                        path={routerLinks.referential.delivery.base()}
                        element={
                            <RoleRoute
                                permissions={[
                                    UserSituation.ADMIN_CALYPSO,
                                    UserSituation.ANMV_MED,
                                    UserSituation.PHARMACIST,
                                ]}
                                redirection={routerLinks.home}
                            >
                                <ReferentialsDelivery />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.referential.delivery.typeIdentifiant()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <TypeIdentifiant />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.delivery.drugs()}
                            element={
                                <RoleRoute
                                    permissions={[
                                        UserSituation.ADMIN_CALYPSO,
                                        UserSituation.ANMV_MED,
                                        UserSituation.PHARMACIST,
                                    ]}
                                    redirection={routerLinks.home}
                                >
                                    <Drug />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.delivery.famDam()}
                            element={
                                <RoleRoute
                                    permissions={[UserSituation.ADMIN_CALYPSO, UserSituation.ANMV_MED]}
                                    redirection={routerLinks.home}
                                >
                                    <FamDam />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.delivery.drugTransferOrganization()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <DrugTransferOrganization />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.delivery.pharmacies()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <Pharmacy />
                                </RoleRoute>
                            }
                        />
                    </Route>
                    {/* REFERENTIALS TRAINING */}
                    <Route
                        path={routerLinks.referential.training.base()}
                        element={
                            <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                <ReferentialsTraining />
                            </RoleRoute>
                        }
                    >
                        <Route
                            path={routerLinks.referential.training.types()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <Type />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.training.formats()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <Format />
                                </RoleRoute>
                            }
                        />
                        <Route
                            path={routerLinks.referential.training.organizations()}
                            element={
                                <RoleRoute permissions={[UserSituation.ADMIN_CALYPSO]} redirection={routerLinks.home}>
                                    <Organization />
                                </RoleRoute>
                            }
                        />
                    </Route>
                </Route>
                <Route path={routerLinks.retrieveKey} element={<RetrieveKey />} />
                <Route path="*" element={<Navigate to={routerLinks.home} />} />
            </Routes>
        </Box>
    );
}
