// === NPM
import React, { RefObject, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, FormControl, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";
import { z, ZodIssueCode } from "zod";
// === LOCAL
import GenericComment from "@/components/generics/inputs/GenericComment";
import GenericDateTimePicker from "@/components/generics/inputs/GenericDateTimePicker";
import GenericSelect from "@/components/generics/inputs/GenericSelect";
import GenericTextField from "@/components/generics/inputs/GenericTextField";
import { ISessionCreate } from "@/components/HealthAccreditationTraining/interface";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import { IHealthAccreditationTrainingShort } from "@/interfaces/training";
import { FORM_TEXT, stringRequired } from "@/resources/FormUtils";

type SessionInformationsForm = Pick<
    ISessionCreate,
    "trainingUuid" | "startDate" | "inscriptionNumberMin" | "inscriptionNumberMax" | "comment"
>;

interface SessionInformationsProps {
    registrationNumber: number;
    form: ISessionCreate;
    formRef: RefObject<HTMLButtonElement>;
    onStepChange: (data: SessionInformationsForm, previous?: boolean) => void;
    trainings: IHealthAccreditationTrainingShort[];
    disabled: boolean;
}

export default function SessionInformations({
    registrationNumber,
    form,
    formRef,
    onStepChange,
    trainings,
    disabled,
}: Readonly<SessionInformationsProps>) {
    const formSchema = z.object({
        trainingUuid: stringRequired(),
        startDate: stringRequired().refine((value) => DateTime.fromISO(value) > DateTime.now(), {
            message: "La date de formation ne peut pas être dans le passé.",
        }),
        comment: z
            .string()
            .trim()
            .max(2000, { message: "Ce champ ne peut pas contenir plus de 2000 caractères" })
            .optional()
            .nullable(),
    });

    const inscriptionNumberSchema = z
        .object({
            inscriptionNumberMin: z.coerce
                .number({ invalid_type_error: FORM_TEXT.required, required_error: FORM_TEXT.required })
                .int({ message: "La valeur du champ doit être un entier." })
                .positive({ message: "La valeur du champ doit être strictement positive." }),
            inscriptionNumberMax: z.coerce
                .number({ invalid_type_error: FORM_TEXT.required, required_error: FORM_TEXT.required })
                .int({ message: "La valeur du champ doit être un entier." })
                .positive({ message: "La valeur du champ doit être strictement positive." })
                .refine((value) => !registrationNumber || value >= registrationNumber, {
                    message: `Le nombre actuel d'inscrits à cette session de formation (${registrationNumber}) n'est pas conforme au nombre maximal d'inscrits renseignés dans la session. Merci d'adapter le seuil maximal d'inscrits de cette session ou les statuts d'inscription des vétérinaires.`,
                }),
        })
        .superRefine((values, context) => {
            if (values.inscriptionNumberMax <= values.inscriptionNumberMin) {
                context.addIssue({
                    code: ZodIssueCode.custom,
                    message: "Le nombre d'inscrit maximal doit être strictement supérieur au nombre minimal.",
                    path: ["inscriptionNumberMax"],
                });
            }
        });

    const schema = formSchema.and(inscriptionNumberSchema);

    type ValidationSchema = z.infer<typeof schema>;

    const [training, setTraining] = useState<IHealthAccreditationTrainingShort>(null);

    const {
        formState: { errors },
        handleSubmit,
        control,
        register,
        watch,
        setValue,
    } = useForm<ValidationSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            trainingUuid: form?.trainingUuid,
            startDate: form?.startDate,
            inscriptionNumberMin: form?.inscriptionNumberMin,
            inscriptionNumberMax: form?.inscriptionNumberMax,
            comment: form?.comment,
        },
    });

    const startDate = watch("startDate");
    const inscriptionNumberMin = watch("inscriptionNumberMin");
    const inscriptionNumberMax = watch("inscriptionNumberMax");

    useEffect(() => {
        if (form?.trainingUuid) {
            onChangeTrainingUuid(form.trainingUuid);
        }
    }, [trainings]);

    const onChangeTrainingUuid = (value: string) => {
        const foundTraining = trainings?.find((training) => training.uuid === value);
        setTraining(foundTraining);
        setValue("trainingUuid", foundTraining?.uuid);
        setValue("inscriptionNumberMin", foundTraining?.inscriptionNumberMin);
        setValue("inscriptionNumberMax", foundTraining?.inscriptionNumberMax);
    };

    return (
        <form onSubmit={handleSubmit((data) => onStepChange(data as SessionInformationsForm))} noValidate>
            <Stack spacing={2}>
                <Typography variant="h5">Formation</Typography>
                <Card sx={{ p: 2 }}>
                    <StyledCardContent>
                        <Grid container spacing={2}>
                            <Grid size={12}>
                                <Controller
                                    name="trainingUuid"
                                    control={control}
                                    render={({ field: { value }, fieldState: { error } }) => (
                                        <GenericSelect
                                            value={value}
                                            onChange={(event) => onChangeTrainingUuid(event.target.value)}
                                            error={!!error}
                                            helperText={error?.message}
                                            label="Identifiant - Nom de la formation"
                                            required
                                            options={trainings.map((training) => ({
                                                value: training.uuid,
                                                label: `${training.internalId} - ${training.title}`,
                                            }))}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid size={4}>
                                <TextField
                                    value={training?.type.label}
                                    label="Type de formation"
                                    required
                                    disabled
                                    fullWidth
                                    slotProps={{
                                        inputLabel: { shrink: !!training?.type },
                                    }}
                                />
                            </Grid>

                            <Grid size={4}>
                                <TextField
                                    value={training?.duration}
                                    label="Durée de la formation (en heures)"
                                    required
                                    disabled
                                    fullWidth
                                    slotProps={{
                                        inputLabel: { shrink: !!training?.duration },
                                    }}
                                />
                            </Grid>
                            <Grid size={4}>
                                <FormControl fullWidth required error={!!errors?.startDate}>
                                    <Controller
                                        name="startDate"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                                            <GenericDateTimePicker
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                                helperText={error?.message}
                                                label="Date de début de formation"
                                                name="startDate"
                                                minDateTime={
                                                    disabled
                                                        ? DateTime.now().startOf("day").plus({ days: 3 })
                                                        : DateTime.now()
                                                }
                                                required
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={4}>
                                <GenericTextField
                                    value={
                                        startDate
                                            ? DateTime.fromISO(startDate).minus({ days: 14 }).toLocaleString()
                                            : ""
                                    }
                                    label="Date limite d'inscription"
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </StyledCardContent>
                </Card>
                <Typography variant="h5">Participants</Typography>
                <Card sx={{ p: 2 }}>
                    <StyledCardContent>
                        <Stack spacing={2} direction="row">
                            <TextField
                                {...register("inscriptionNumberMin")}
                                error={!!errors?.inscriptionNumberMin}
                                helperText={errors?.inscriptionNumberMin?.message}
                                label="Nombre minimum de participants"
                                type="number"
                                fullWidth
                                required
                                slotProps={{
                                    inputLabel: { shrink: !!inscriptionNumberMin },
                                }}
                            />
                            <TextField
                                {...register("inscriptionNumberMax")}
                                error={!!errors?.inscriptionNumberMax}
                                helperText={errors?.inscriptionNumberMax?.message}
                                label="Nombre maximum de participants"
                                type="number"
                                fullWidth
                                required
                                slotProps={{
                                    inputLabel: { shrink: !!inscriptionNumberMax },
                                }}
                            />
                        </Stack>
                    </StyledCardContent>
                </Card>
                <Typography variant="h5">Commentaire</Typography>
                <Card sx={{ p: 2 }}>
                    <StyledCardContent>
                        <FormControl fullWidth error={!!errors?.comment}>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                    <GenericComment
                                        value={value}
                                        onChange={onChange}
                                        helperText={error?.message}
                                        label="Commentaire"
                                        rows={5}
                                        placeholder="Commentaire"
                                    />
                                )}
                            />
                        </FormControl>
                    </StyledCardContent>
                </Card>
            </Stack>
            <button style={{ display: "none" }} type="submit" ref={formRef} />
        </form>
    );
}
