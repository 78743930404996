// === NPM
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Stack, Typography } from "@mui/material";
// === LOCAL
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { ISession, ISessionCreate, SessionOutletContext } from "@/components/HealthAccreditationTraining/interface";
import { HttpStatus } from "@/interfaces/global";
import { IHealthAccreditationTrainingShort, SessionMode } from "@/interfaces/training";
import { IAgriMinistryGeoUnitShort } from "@/interfaces/user";
import { getEnumKeyByValue } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import TrainingService from "@/services/TrainingService";
import FormStepper from "./containers/FormStepper";
import SessionInformations from "./containers/SessionInformations/SessionInformations";
import SessionLocation from "./containers/SessionLocation/SessionLocation";
import Trainers from "./containers/Trainers/Trainers";

export function Form() {
    const formRef = useRef<HTMLButtonElement>(null);
    const backRef = useRef<HTMLButtonElement>(null);
    const navigate = useNavigate();

    const location = useLocation();
    const state = location.state;
    const session = state?.session as ISession;
    const initialValues = {
        trainingUuid: session?.healthAccreditationTraining?.uuid ?? "",
        startDate: session?.startDate?.slice(0, -5) ?? null,
        inscriptionNumberMin: session?.inscriptionNumberMin ? +session.inscriptionNumberMin : null,
        inscriptionNumberMax: session?.inscriptionNumberMax ? +session.inscriptionNumberMax : null,
        comment: session?.comment ?? null,
        ddppUuid: session?.ddpp?.uuid ?? null,
        trainingSessionMode: session?.sessionMode ?? getEnumKeyByValue(SessionMode, SessionMode.FACE_TO_FACE),
        address: session?.address ?? null,
        complementaryAddress: session?.complementaryAddress ?? null,
        postalCode: session?.postalCode ?? null,
        city: session?.city ?? null,
        trainers: session?.trainers ?? [
            {
                trainerType: "",
                veterinaryId: null,
                lastname: null,
                firstname: null,
            },
        ],
    };

    const { regions } = useOutletContext<SessionOutletContext>();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState<boolean>(false);
    const [form, setForm] = useState<ISessionCreate>(initialValues);
    const [trainings, setTrainings] = useState<IHealthAccreditationTrainingShort[]>([]);
    const [selectedDdpp, setSelectedDdpp] = useState<IAgriMinistryGeoUnitShort>(null);

    useEffect(() => {
        getTrainings();
    }, []);

    const getTrainings = async () => {
        const payload = { page: 0, size: null };
        const res = await TrainingService.getHealthAccreditationTrainings(payload);
        if (res.status === HttpStatus.OK) {
            setTrainings(await res.json());
        }
    };

    const handleStepChange = (partialFormValues: Partial<ISessionCreate>, previous: boolean = false) => {
        setForm((prev) => ({ ...prev, ...partialFormValues }));
        setActiveStep(activeStep + (previous ? -1 : 1));
    };

    const handleConfirmCancel = (confirm: boolean) => {
        if (confirm) {
            navigate(routerLinks.healthAccreditationTraining.sessions.view());
        } else {
            setOpenConfirmCancelDialog(false);
        }
    };

    const handleSubmit = async (partialFormValues: Partial<ISessionCreate>, previous: boolean = false) => {
        const finalForm = { ...form, ...partialFormValues };

        if (previous) {
            setForm(finalForm);
            setActiveStep(activeStep - 1);
            return;
        }

        if (session?.uuid) {
            updateSession(finalForm);
        } else {
            createSession(finalForm);
        }
    };

    const updateSession = async (data: ISessionCreate) => {
        const res = await TrainingService.putHealthAccreditationTrainingSession(session.uuid, data);
        if (res.status === HttpStatus.OK) {
            toast.success("Session mise à jour avec succès.");
            navigate(routerLinks.healthAccreditationTraining.sessions.view());
        }
    };

    const createSession = async (data: ISessionCreate) => {
        const res = await TrainingService.postHealthAccreditationTrainingSession(data);
        if (res.status === HttpStatus.CREATED) {
            toast.success("Session créée avec succès.");
            navigate(routerLinks.healthAccreditationTraining.sessions.view());
        }
    };

    const renderSteps = () => {
        switch (activeStep) {
            case 0:
                return (
                    <SessionInformations
                        registrationNumber={state?.registrationNumber}
                        form={form}
                        formRef={formRef}
                        onStepChange={handleStepChange}
                        trainings={trainings}
                        disabled={!!session?.uuid}
                    />
                );
            case 1:
                return (
                    <SessionLocation
                        form={form}
                        formRef={formRef}
                        backRef={backRef}
                        onStepChange={handleStepChange}
                        selectedDdpp={selectedDdpp}
                        setSelectedDdpp={setSelectedDdpp}
                        regions={regions}
                        ddppName={session?.ddpp?.name}
                    />
                );
            case 2:
                return <Trainers form={form} formRef={formRef} backRef={backRef} onStepChange={handleSubmit} />;
        }
    };

    return (
        (<Stack
            spacing={3}
            sx={{
                height: "100%",
                width: "100%",
                px: 2
            }}>
            <Typography variant="h4">Nouvelle session de formation</Typography>
            <FormStepper
                activeStep={activeStep}
                handleNextStep={() => {
                    formRef.current.click();
                }}
                handlePreviousStep={() => backRef.current.click()}
                setOpenConfirmCancelDialog={setOpenConfirmCancelDialog}
            >
                <Box sx={{ flexGrow: 1, my: 4 }}>{renderSteps()}</Box>
            </FormStepper>
            {openConfirmCancelDialog && (
                <GenericConfirmDialog
                    title="Annuler la saisie"
                    message="Êtes-vous sûr de vouloir annuler ? Vous allez perdre toutes les informations saisies."
                    onClose={handleConfirmCancel}
                />
            )}
        </Stack>)
    );
}
