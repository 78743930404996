// === NPM
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card, CardHeader, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { z } from "zod";
// === LOCAL
import GenericActionsDialog from "@/components/generics/dialogs/GenericActionsDialog";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import GenericErrorMessageText from "@/components/generics/text/GenericErrorMessageText";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useTimeout from "@/hooks/useTimeout";
import { HttpStatus } from "@/interfaces/global";
import { IDpe } from "@/interfaces/user";
import { colors } from "@/resources/CssConstant";
import { stringRequired } from "@/resources/FormUtils";
import UserService from "@/services/UserService";
import VaccinationService from "@/services/VaccinationService";
import { ICreateMemory, IValidatedVaccinationSite } from "../../interface";
import DropableList from "./DropableList";
import VaccinationSiteTile from "./VaccinationSiteTile";

interface MemoryDialogProps {
    onClose: (value: boolean, memory?: ICreateMemory) => void;
    memoryId?: number;
    title?: string;
    dpeId?: string;
}

const memorySchema = z.object({
    dpeId: stringRequired(),
    title: stringRequired(),
    vaccinationSiteIds: z.array(z.number()).min(1, "Veuillez ajouter au moins un chantier"),
});

type FormSchema = z.infer<typeof memorySchema>;

export default function MemoryDialog({ onClose, memoryId, title, dpeId }: Readonly<MemoryDialogProps>) {
    const [dpes, setDpes] = useState<IDpe[]>([]);
    const [validatedVaccinationSites, setValidatedVaccinationSites] = useState<IValidatedVaccinationSite[]>([]);
    const [selectedVaccinationSites, setSelectedVaccinationSites] = useState<IValidatedVaccinationSite[]>([]);
    const [selectedDpe, setSelectedDpe] = useState<IDpe>(null);
    const [dpeSearch, setDpeSearch] = useState<string>("");

    const {
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        register,
    } = useForm<FormSchema>({
        resolver: zodResolver(memorySchema),
        defaultValues: {
            dpeId: dpeId ?? "",
            title: title ?? "",
            vaccinationSiteIds: [],
        },
    });

    const vaccinationSiteIds = watch("vaccinationSiteIds");

    useEffect(() => {
        if (memoryId) getDpes();
    }, []);

    useEffect(() => {
        getValidatedVaccinationSites();
    }, [selectedDpe]);

    useTimeout(() => {
        if (dpeSearch) {
            getDpes();
        }
    }, [dpeSearch]);

    const isTouchDevice = () => {
        if ("ontouchstart" in window) {
            return true;
        }
        return false;
    };

    const getDpes = async () => {
        const payload = {
            page: 0,
            size: 20,
            nameId: dpeId ?? dpeSearch,
        };
        const res = await UserService.getDpes(payload);
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            if (memoryId) setSelectedDpe(data[0]);
            setDpes(data);
        }
    };

    const getValidatedVaccinationSites = async () => {
        if (!selectedDpe) {
            setValidatedVaccinationSites([]);
            return;
        }
        const res = await VaccinationService.getValidatedVaccinationSites(selectedDpe.ordinalNumber);
        if (res.status === HttpStatus.OK) {
            setValidatedVaccinationSites(await res.json());
        }
    };

    const onChangeDpe = (event) => {
        if (event?.target?.value) {
            setSelectedDpe(event.target.value);
            setValue("dpeId", event.target.value?.ordinalNumber);
        }
    };

    const onSubmit = async (data: FormSchema) => {
        onClose(true, data as ICreateMemory);
    };

    const deleteVaccinationSite = (vaccinationSite: IValidatedVaccinationSite) => {
        const filterSelected = selectedVaccinationSites.filter((site) => site.id !== vaccinationSite.id);
        setSelectedVaccinationSites(filterSelected);
        const updatedValidated = [...validatedVaccinationSites, vaccinationSite];
        setValidatedVaccinationSites(updatedValidated);
        setValue(
            "vaccinationSiteIds",
            vaccinationSiteIds.filter((id) => id !== vaccinationSite.id)
        );
    };

    const addVaccinationSite = (vaccinationSite: IValidatedVaccinationSite) => {
        const filterValidated = validatedVaccinationSites.filter((site) => site.id !== vaccinationSite.id);
        setValidatedVaccinationSites(filterValidated);
        const updateSelected = [...selectedVaccinationSites, vaccinationSite];
        setSelectedVaccinationSites(updateSelected);
        setValue("vaccinationSiteIds", [...vaccinationSiteIds, vaccinationSite.id]);
    };

    const addAll = () => {
        setValidatedVaccinationSites([]);
        const updateSelected = [...selectedVaccinationSites, ...validatedVaccinationSites];
        setSelectedVaccinationSites(updateSelected);
        setValue(
            "vaccinationSiteIds",
            updateSelected.map((v) => v.id)
        );
    };

    const deleteAll = () => {
        setSelectedVaccinationSites([]);
        const updatedValidated = [...validatedVaccinationSites, ...selectedVaccinationSites];
        setValidatedVaccinationSites(updatedValidated);
        setValue("vaccinationSiteIds", []);
    };

    const renderVaccinationSiteDropzone = () => (
        <Stack direction="row" spacing={2} sx={{ maxHeight: "calc(100% - 200px)" }}>
            <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
                <Card sx={{ padding: 2, width: "100%" }}>
                    <CardHeader
                        titleTypographyProps={{ fontSize: 18, fontWeight: 500 }}
                        title="Chantiers du DPE validés par la DDPP"
                        action={
                            <SecondaryButton variant="outlined" onClick={() => addAll()}>
                                Tout ajouter
                            </SecondaryButton>
                        }
                    />
                    <StyledCardContent sx={{ overflow: "auto", height: "85%", alignContent: "center", mt: 1 }}>
                        <Stack
                            spacing={2}
                            sx={{
                                width: "100%",
                            }}
                        >
                            {validatedVaccinationSites.length > 0 ? (
                                validatedVaccinationSites.map((site) => (
                                    <VaccinationSiteTile
                                        key={site.id}
                                        vaccinationSite={site}
                                        onAdd={addVaccinationSite}
                                    />
                                ))
                            ) : (
                                <Typography
                                    variant="bold"
                                    sx={{
                                        alignSelf: "center",
                                        color: colors.secondaryColor,
                                    }}
                                >
                                    {selectedDpe ? "Aucun chantier disponible" : "Veuillez sélectionner un DPE"}
                                </Typography>
                            )}
                        </Stack>
                    </StyledCardContent>
                </Card>

                <Card sx={{ padding: 2, width: "100%" }}>
                    <CardHeader
                        titleTypographyProps={{ fontSize: 18, fontWeight: 500 }}
                        title={
                            <Typography variant="bold">
                                {`Chantiers ajoutés (${selectedVaccinationSites.length})`}
                                <Typography component="span" variant="h5" color="error">
                                    *
                                </Typography>
                            </Typography>
                        }
                        action={
                            <SecondaryButton variant="outlined" onClick={() => deleteAll()}>
                                Tout supprimer
                            </SecondaryButton>
                        }
                    />
                    <StyledCardContent sx={{ overflow: "auto", height: "90%" }}>
                        <GenericErrorMessageText fieldError={errors?.vaccinationSiteIds} />
                        <DropableList
                            addVaccinationSite={addVaccinationSite}
                            onDelete={deleteVaccinationSite}
                            selectedVaccinationSites={selectedVaccinationSites}
                        />
                    </StyledCardContent>
                </Card>
            </DndProvider>
        </Stack>
    );

    const renderVaccinationInformations = () => (
        <Card sx={{ padding: 2, width: "100%", maxHeight: "200px" }}>
            <CardHeader titleTypographyProps={{ fontSize: 18, fontWeight: 500 }} title="Informations du mémoire" />
            <StyledCardContent>
                <Grid container columnSpacing={2}>
                    <Grid size={6}>
                        <TextField
                            fullWidth
                            {...register("title")}
                            required
                            label="Libellé du mémoire"
                            error={!!errors?.title}
                            helperText={errors?.title?.message}
                            disabled={!!memoryId}
                        />
                    </Grid>
                    <Grid size={6}>
                        <GenericAutocomplete
                            value={selectedDpe}
                            onChange={onChangeDpe}
                            onInputChange={(event, value) => setDpeSearch(value)}
                            label="N° ordre - Nom du DPE"
                            options={dpes}
                            required
                            getOptionLabel={(option) =>
                                option.ordinalNumber ? `${option.ordinalNumber} - ${option.name}` : ""
                            }
                            isOptionEqualToValue={(option, value) => option.ordinalNumber === value.ordinalNumber}
                            error={!!errors?.dpeId}
                            helperText={errors?.dpeId?.message}
                            noOptionsText={
                                dpeSearch ? "Aucun résultat" : "Saisissez des caractères pour lancer la recherche"
                            }
                            disabled={!!memoryId}
                        />
                    </Grid>
                </Grid>
            </StyledCardContent>
        </Card>
    );

    const renderContent = () => (
        <Stack spacing={2} sx={{ height: "100%" }}>
            {renderVaccinationInformations()}
            {renderVaccinationSiteDropzone()}
        </Stack>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            paperProps={{ sx: { width: "100%", height: "100%" } }}
            title={memoryId ? `Ajouter des chantiers au mémoire n°${memoryId}` : "Nouveau mémoire de paiement"}
            onClose={() => onClose(false)}
            renderContent={() => renderContent()}
            renderActions={() => (
                <GenericActionsDialog
                    onClose={() => onClose(false)}
                    onSubmit={handleSubmit(onSubmit)}
                    closeLabel="Fermer"
                />
            )}
        />
    );
}
