import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericDialog from "@/components/generics/dialogs/GenericDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import SecondaryButton from "@/components/styled/SecondaryButton";
import {
    ISearchVaccinationIntervention,
    SearchVaccinationInterventionFilters,
    VaccinationSiteOutletContext,
} from "@/components/VaccinationIahp/VaccinationSite/interface";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination } from "@/interfaces/global";
import { InjectionType, VaccinationInterventionType } from "@/interfaces/vaccination";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { colors } from "@/resources/CssConstant";
import { convertEnumToKeyLabelObject, createPayload } from "@/resources/utils";
import VaccinationService from "@/services/VaccinationService";

interface InterventionDialogProps {
    onClose: () => void;
    initFilters: SearchVaccinationInterventionFilters;
    onValid: (intervention: ISearchVaccinationIntervention) => void;
}

export default function InterventionDialog({ onClose, initFilters, onValid }: Readonly<InterventionDialogProps>) {
    const { species, subSpecies } = useOutletContext<VaccinationSiteOutletContext>();
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [interventions, setInterventions] = useState<ISearchVaccinationIntervention[]>([]);
    const [inputFilters, setInputFilters] = useState<SearchVaccinationInterventionFilters>(initFilters);
    const [selectedInterventionId, setSelectedInterventionId] = useState<string>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getInterventionIds();
    }, [pagination]);

    const getInterventionIds = async () => {
        const res = await VaccinationService.getInterventions("search", {
            page: pagination.page,
            size: pagination.pageSize,
            ...createPayload(inputFilters),
        });
        if (res.status === HttpStatus.OK) {
            setInterventions(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const handleValid = () => {
        const intervention = interventions.find((i) => i.id === selectedInterventionId);
        if (intervention) {
            onValid(intervention);
        }
    };

    const displayActionButton = () => (
        <>
            <SecondaryButton onClick={onClose} variant="outlined">
                Annuler
            </SecondaryButton>
            <GenericButton
                disabled={!selectedInterventionId}
                onClick={handleValid}
                color="primary"
                label="Ajouter le numéro d'intervention sélectionné"
            />
        </>
    );

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const filterConfigurations: FilterConfigurations<SearchVaccinationInterventionFilters> = {
        id: { label: "Numéro Calypso", type: FilterType.INPUT },
        vaccinationDate: { label: "Date de vaccination", type: FilterType.DATEPICKER },
        vaccinationInterventionType: {
            label: "Type intervention",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(VaccinationInterventionType),
        },
        workshopId: { label: "Identifiant atelier", type: FilterType.INPUT },
        farmId: { label: "Identifiant établissement", type: FilterType.INPUT },
        injectionType: {
            label: "N° injection",
            type: FilterType.SELECT,
            values: convertEnumToKeyLabelObject(InjectionType),
        },
        speciesUuid: {
            label: "Catégorie d'espèce",
            type: FilterType.SELECT,
            values: species.map((s) => ({ key: s.uuid, label: s.label })),
        },
        subSpeciesUuid: {
            label: "Sous-catégorie d'espèce",
            type: FilterType.SELECT,
            values: subSpecies.map((s) => ({ key: s.uuid, label: s.label })),
        },
    };

    const columns = [
        {
            field: "id",
            headerName: "Numéro intervention Calypso",
            width: 150,
            sortable: false,
        },
        {
            field: "vaccinationDate",
            headerName: "Date intervention",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                params.value ? DateTime.fromISO(params.value).toLocaleString() : "",
            sortable: false,
        },
        {
            field: "vaccinationInterventionType",
            headerName: "Type d'intervention",
            width: 100,
            valueFormatter: (params: GridValueFormatterParams<string>) => VaccinationInterventionType[params.value],
            sortable: false,
        },
        {
            field: "workshopId",
            headerName: "Identifiant de l'atelier",
            width: 150,
            sortable: false,
        },
        {
            field: "farmId",
            headerName: "Identifiant établissement",
            width: 150,
            sortable: false,
        },
        {
            field: "injectionType",
            headerName: "N°injection",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) => InjectionType[params.value],
            sortable: false,
        },
        {
            field: "speciesUuid",
            headerName: "Catégorie d'espèce",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                species.find((s) => s.uuid === params.value)?.label,
            sortable: false,
        },
        {
            field: "subSpeciesUuid",
            headerName: "Sous-catégorie d'espèces",
            width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) =>
                subSpecies.find((s) => s.uuid === params.value)?.label,
            sortable: false,
        },
    ];

    const renderContent = () => (
        <Card>
            <CardContent
                sx={{
                    "& .selected": {
                        backgroundColor: colors.background,
                    },
                }}
            >
                <GenericFilters
                    inputFilters={inputFilters}
                    filterConfigurations={filterConfigurations}
                    initialValues={{
                        id: "",
                        farmId: "",
                        workshopId: "",
                        vaccinationDate: [null, null],
                        vaccinationInterventionType: [],
                        injectionType: [],
                        speciesUuid: [],
                        subSpeciesUuid: [],
                    }}
                    setInputFilters={setInputFilters}
                />

                <GenericTable
                    rows={interventions}
                    columns={columns}
                    onPageSizeChange={handlePageSizeChange}
                    onPageChange={handlePageChange}
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    autoHeight
                    sortingMode="server"
                    paginationMode="server"
                    rowCount={rowCount}
                    onRowClick={(params) => setSelectedInterventionId(params.row.id)}
                    getRowClassName={(params) => (params.row.id === selectedInterventionId ? "selected" : "")}
                    filterMode="server"
                />
            </CardContent>
        </Card>
    );

    return (
        <GenericDialog
            maxWidth="xl"
            title="Trouver un numéro d'intervention"
            onClose={onClose}
            renderContent={renderContent}
            renderActions={displayActionButton}
        />
    );
}
