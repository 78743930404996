// === NPM
import React, { useEffect, useState } from "react";
// === LOCAL
import GenericAutocomplete from "@/components/generics/inputs/GenericAutocomplete";
import useTimeout from "@/hooks/useTimeout";
import { IWorkshop } from "@/interfaces/farm";
import { HttpStatus } from "@/interfaces/global";
import FarmService from "@/services/FarmService";

interface WorkshopAutocompleteProps {
    value: string;
    handleChange: (event) => void;
    error: string;
}

export default function WorkshopAutocomplete({ value, handleChange, error }: Readonly<WorkshopAutocompleteProps>) {
    const [declareWorkshopSearch, setDeclareWorkshopSearch] = useState<string>(value ?? "");
    const [declareWorkshops, setDeclareWorkshops] = useState<IWorkshop[]>([]);

    useEffect(() => {
        if (declareWorkshopSearch) getWorkshops();
    }, []);

    useTimeout(() => getWorkshops(), [declareWorkshopSearch]);

    const getWorkshops = async () => {
        const payload = {
            page: 0,
            size: 50,
            id: declareWorkshopSearch,
        };
        const res = await FarmService.getWorkshops(payload);
        if (res.status === HttpStatus.OK) {
            setDeclareWorkshops(await res.json());
        }
    };

    return (
        <GenericAutocomplete
            label="INUAV de mise en place déclarée"
            options={declareWorkshops}
            value={declareWorkshops.find((w) => w.id === value) || null}
            onChange={handleChange}
            required
            inputValue={declareWorkshopSearch}
            onInputChange={(_, newInputValue) => {
                setDeclareWorkshopSearch(newInputValue);
            }}
            getOptionLabel={(option) => option.id}
            error={!!error}
            helperText={error}
        />
    );
}
