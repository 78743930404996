// === NPM
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { KyResponse } from "ky";
// === LOCAL
import ArchiveAction from "@/components/generics/actions/ArchiveAction";
import EditAction from "@/components/generics/actions/EditAction";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import GenericConfirmDialog from "@/components/generics/dialogs/GenericConfirmDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import {
    ActionsColumnProps,
    FilterType,
    HttpStatus,
    IPagination,
    SearchParams,
    SortDirection,
} from "@/interfaces/global";
import { IUserShort } from "@/interfaces/user";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { ACTIONS_COLUMN_WIDTH } from "@/resources/CssConstant";
import { UserSituation } from "@/resources/PermissionConstant";
import { createPayload } from "@/resources/utils";
import { useAuth } from "@/routers/useAuth";
import { AdministrationUserOutletContext, IUserFilters } from "../interface";
import UserDialog from "./UserDialog";

const initialValues = {
    id: "",
    firstname: "",
    lastname: "",
    email: "",
    situation: [],
};

interface UserSpecificPageProps {
    getData: (payload: SearchParams & Partial<IUserFilters>) => Promise<KyResponse>;
    createUser: (userInfo: IUserShort) => Promise<KyResponse>;
    updateUser: (userInfo: IUserShort, userId: string) => Promise<KyResponse>;
    deleteUser: (userId: string) => Promise<KyResponse>;
    permissions: UserSituation[];
    title: string;
}

export default function UserSpecificPage({
    getData,
    createUser,
    updateUser,
    deleteUser,
    permissions,
    title,
}: Readonly<UserSpecificPageProps>) {
    const auth = useAuth();
    const { userTypesReferential } = useOutletContext<AdministrationUserOutletContext>();
    const userType = userTypesReferential?.find((opt) => opt.key === auth.userInfo.type);
    const [users, setUsers] = useState<IUserShort[]>([]);

    // User table
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "desc" }]);
    const [inputFilters, setInputFilters] = useState<IUserFilters>({
        ...initialValues,
        situation: [...initialValues.situation],
    });

    const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUserShort>(null);

    const [openCreateUserDialog, setOpenCreateUserDialog] = useState<boolean>(false);
    const [openModifyUserDialog, setOpenModifyUserDialog] = useState<boolean>(false);
    const [openConfirmDeletionDialog, setOpenConfirmDeletionDialog] = useState<boolean>(false);

    useEffect(() => {
        getUsers();
    }, [pagination, sortModel, inputFilters]);

    // ---------- BACKEND CALL ----------

    const getUsers = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await getData(payload);
        if (res.status === HttpStatus.OK) {
            setUsers(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const handleDeleteUser = async (value: boolean) => {
        if (!value) {
            setOpenConfirmDeletionDialog(false);
            return;
        }
        const res = await deleteUser(selectedUser.id);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("Utilisateur supprimé avec succès");
            getUsers();
            setOpenConfirmDeletionDialog(false);
        }
    };

    const handleCreateUser = async (user: IUserShort) => {
        const res = await createUser(user);
        if (res.status === HttpStatus.CREATED) {
            toast.success("Utilisateur créé avec succès");
            getUsers();
            setOpenCreateUserDialog(false);
        }
    };

    const handleUpdateUser = async (value: IUserShort) => {
        const res = await updateUser(value, selectedUser.id);
        if (res.status === HttpStatus.OK) {
            toast.success("Utilisateur modifié avec succès");
            getUsers();
            setOpenModifyUserDialog(false);
        }
    };

    const selectAndOpenUserDialog = async (user: IUserShort, readOnly: boolean) => {
        setSelectedUser(user);
        if (readOnly) {
            setOpenUserDialog(true);
        } else {
            setOpenModifyUserDialog(true);
        }
    };

    const selectAndOpenDeletionDialog = async (user: IUserShort) => {
        setSelectedUser(user);
        setOpenConfirmDeletionDialog(true);
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "id",
            headerName: "Identifiant",
            width: 100,
        },
        {
            field: "firstname",
            headerName: "Prénom",
            flex: 1,
        },
        {
            field: "lastname",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            filterable: false,
        },
        {
            field: "situation",
            headerName: "Situation",
            flex: 1,
            type: "singleSelect",
            optionValue: "key",
            optionsValues: userType?.situations,
            valueGetter: (params: GridRenderCellParams) =>
                userType?.situations.find((s) => s.key === params.row.situation)?.label,
        },
        {
            ...ActionsColumnProps,
            renderCell: (params) => (
                <Box>
                    <ViewAction
                        title="Voir les informations de l'utilisateur"
                        onClick={() => selectAndOpenUserDialog(params.row, true)}
                    />
                    <PermissionsCheck requiredPermissions={permissions}>
                        <EditAction
                            title="Modifier l'utilisateur"
                            onClick={() => selectAndOpenUserDialog(params.row, false)}
                        />
                    </PermissionsCheck>
                    {params.row.id !== auth.userInfo.id && (
                        <PermissionsCheck requiredPermissions={permissions}>
                            <ArchiveAction
                                title="Archiver l'utilisateur"
                                onClick={() => selectAndOpenDeletionDialog(params.row)}
                            />
                        </PermissionsCheck>
                    )}
                </Box>
            ),
            width: ACTIONS_COLUMN_WIDTH,
        },
    ];

    const filterConfigurations: FilterConfigurations<IUserFilters> = {
        id: { label: "Identifiant", type: FilterType.INPUT },
        firstname: { label: "Prénom", type: FilterType.INPUT },
        lastname: { label: "Nom", type: FilterType.INPUT },
        email: { label: "Email", type: FilterType.INPUT },
        situation: {
            label: "Situation",
            type: FilterType.SELECT,
            values: userType?.situations ?? [],
        },
    };

    return (<>
        <Stack
            spacing={2}
            sx={{
                height: "100%",
                width: "100%"
            }}>
            <Typography variant="h4">{title}</Typography>
            <Box
                sx={{
                    justifyContent: "flex-end",
                    display: "flex"
                }}>
                <PermissionsCheck requiredPermissions={permissions}>
                    <GenericButton
                        startIcon={<Add />}
                        label="Créer un utilisateur"
                        onClick={() => setOpenCreateUserDialog(true)}
                        id="create-user-btn"
                    />
                </PermissionsCheck>
            </Box>
            <Card>
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            ...initialValues,
                            situation: [...initialValues.situation],
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <Box id="user-table">
                        <GenericTable
                            rows={users}
                            columns={columns}
                            page={pagination.page}
                            rowCount={rowCount}
                            pageSize={pagination.pageSize}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                            filterMode="server"
                            autoHeight
                        />
                    </Box>
                </CardContent>
            </Card>
        </Stack>
        {openCreateUserDialog && (
            <UserDialog
                label="Créer un utilisateur"
                userTypes={userTypesReferential}
                onClose={() => setOpenCreateUserDialog(false)}
                onValid={handleCreateUser}
                type={auth.userInfo.type}
            />
        )}
        {openConfirmDeletionDialog && (
            <GenericConfirmDialog
                title="Suppression d'un utilisateur"
                message={`Êtes-vous sûr de vouloir supprimer ${selectedUser.firstname} ${selectedUser.lastname} ?`}
                onClose={handleDeleteUser}
            />
        )}
        {openUserDialog && (
            <UserDialog
                label={`${selectedUser.lastname} ${selectedUser.firstname}`}
                userTypes={userTypesReferential}
                onClose={() => setOpenUserDialog(false)}
                onValid={() => setOpenUserDialog(false)}
                userValues={selectedUser}
                type={auth.userInfo.type}
                disabled
            />
        )}
        {openModifyUserDialog && (
            <UserDialog
                label={`${selectedUser.lastname} ${selectedUser.firstname}`}
                userTypes={userTypesReferential}
                onClose={() => setOpenModifyUserDialog(false)}
                onValid={handleUpdateUser}
                userValues={selectedUser}
                type={auth.userInfo.type}
            />
        )}
    </>);
}
