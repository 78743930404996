// === NPM
import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
// === LOCAL
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import GenericTable from "@/components/generics/table/GenericTable";
import { StyledCardContent } from "@/components/styled/StyledCardContent";
import useTimeout from "@/hooks/useTimeout";
import { FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IOvvtOrganization, IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS, defaultPagination } from "@/resources/AppConstant";
import { createPayload } from "@/resources/utils";
import UserService from "@/services/UserService";
import { IOvvtUser } from "../interface";

interface UsersProps {
    organization: IOvvtOrganization;
}

export interface UsersFilters {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
}

export default function Users({ organization }: Readonly<UsersProps>) {
    const [users, setUsers] = useState<IOvvtUser[]>([]);
    const [inputFilters, setInputFilters] = useState<UsersFilters>({
        id: "",
        firstname: "",
        lastname: "",
        email: "",
    });
    const [situations, setSituations] = useState<IReferential[]>([]);
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "id", sort: "asc" }]);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        if (organization) {
            getOvvtUsers();
        }
    }, [organization, pagination, sortModel]);

    useEffect(() => {
        getSituations();
    }, []);

    const getOvvtUsers = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await UserService.getOvvtUsers(organization.uuid, payload);
        if (res.status === HttpStatus.OK) {
            setUsers(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getSituations = async () => {
        const res = await UserService.getSituations();
        if (res.status === HttpStatus.OK) {
            setSituations(await res.json());
        }
    };
    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ ...pagination, pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Identifiant",
            flex: 1,
        },
        {
            field: "firstname",
            headerName: "Prénom",
            flex: 1,
        },
        {
            field: "lastname",
            headerName: "Nom",
            flex: 1,
        },
        {
            field: "situation",
            headerName: "Situation",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                situations.find((situation) => situation.key === params.row.situation)?.label,
            sortable: false,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
    ];

    const filterConfigurations: FilterConfigurations<UsersFilters> = {
        id: { type: FilterType.INPUT, label: "Identifiant" },
        firstname: { type: FilterType.INPUT, label: "Prénom" },
        lastname: { type: FilterType.INPUT, label: "Nom" },
        email: { type: FilterType.INPUT, label: "Email" },
    };

    return (<>
        <Typography variant="h4">
            {organization
                ? `Utilisateurs de ${organization.name}`
                : "Utilisateurs liés aux Organismes Vétérinaire à Vocation Technique"}
        </Typography>
        <Card>
            {organization ? (
                <CardContent>
                    <GenericFilters
                        inputFilters={inputFilters}
                        filterConfigurations={filterConfigurations}
                        initialValues={{
                            id: "",
                            firstname: "",
                            lastname: "",
                            email: "",
                        }}
                        setInputFilters={setInputFilters}
                    />
                    <GenericTable
                        rows={users}
                        columns={columns}
                        onPageSizeChange={handlePageSizeChange}
                        onPageChange={handlePageChange}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        autoHeight
                        sortingMode="server"
                        paginationMode="server"
                        sortModel={sortModel}
                        rowCount={rowCount}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                        filterMode="server"
                    />
                </CardContent>
            ) : (
                <StyledCardContent>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center"
                        }}>
                        <Typography>Cliquez sur un organisme pour voir les utilisateurs associés.</Typography>
                    </Box>
                </StyledCardContent>
            )}
        </Card>
    </>);
}
