// === NPM
import { KyResponse } from "ky";
// === LOCAL
import { IHumanDrug } from "@/components/AntimicrobialDisposal/ManualDelivery/interface";
import { IDrugTransferOrganization } from "@/components/Referentials/containers/Delivery/interface";
import { IUse } from "@/components/Referentials/containers/Global/containers/SpeciesCategory/interface";
import { HttpMethod, IReferentialSynchro, Loader } from "@/interfaces/global";
import {
    ICity,
    IContinuousTrainingOrganization,
    IDepartment,
    IDrug,
    IReferential,
    IRegion,
    ISpecies,
    ISpeciesDetails,
    ITypeIdentifiant,
    TypeIdentifiantUse,
} from "@/interfaces/referential";
import { formatParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class ReferentialService {
    // SPECIES

    async getSpecies(
        payload: {
            page?: number;
            size?: number;
            sorts?: string[];
            use?: string[];
            label?: string;
            code?: string;
            parentUuid?: string;
        },
        loader: boolean = true
    ): Promise<KyResponse<ISpeciesDetails[]>> {
        return HttpService.fetch(endpoints.referentialService.species.species(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                loader: loader && Loader.TABLE,
            },
        });
    }

    async getUses(): Promise<KyResponse<IUse[]>> {
        return HttpService.fetch(endpoints.referentialService.species.uses(), {
            method: HttpMethod.GET,
        });
    }

    async postSpecies(json: ISpecies): Promise<KyResponse<ISpecies>> {
        return HttpService.fetch(endpoints.referentialService.species.species(), {
            method: HttpMethod.POST,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async putSpecies(json: ISpecies, specieUuid: string): Promise<KyResponse<ISpecies>> {
        return HttpService.fetch(endpoints.referentialService.species.speciesUuid(specieUuid), {
            method: HttpMethod.PUT,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }
    async deleteSpecies(specieUuid: string): Promise<KyResponse<ISpecies>> {
        return HttpService.fetch(endpoints.referentialService.species.speciesUuid(specieUuid), {
            method: HttpMethod.DELETE,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getSpeciesSynchro(): Promise<KyResponse<IReferentialSynchro>> {
        return HttpService.fetch(endpoints.referentialService.species.sync(), {
            method: HttpMethod.GET,
        });
    }

    async putSpeciesSynchro(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.referentialService.species.sync(), {
            method: HttpMethod.PUT,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    // ORGANIZATIONS

    async getContinuousTrainingOrganizationReferential(payload: {
        page?: number;
        size?: number;
        label?: string;
        externalId?: string;
        email?: string;
        archived?: string[];
        teletransmissionDate?: string[];
        approvalStartDate?: string[];
        approvalEndDate?: string[];
        teletransmissionActive?: string;
        teletransmissionInactive?: string;
    }): Promise<KyResponse<IContinuousTrainingOrganization[]>> {
        return HttpService.fetch(
            endpoints.referentialService.organizations.continuousTrainingOrganizationReferential(),
            {
                method: HttpMethod.GET,
                searchParams: formatParams(payload),
                headers: {
                    loader: Loader.TABLE,
                },
            }
        );
    }

    async postContinuousTrainingOrganization(
        json: IContinuousTrainingOrganization
    ): Promise<KyResponse<IContinuousTrainingOrganization>> {
        return HttpService.fetch(
            endpoints.referentialService.organizations.continuousTrainingOrganizationReferential(),
            {
                method: HttpMethod.POST,
                json,
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }

    async putContinuousTrainingOrganization(
        uuid: string,
        json: IContinuousTrainingOrganization
    ): Promise<KyResponse<IContinuousTrainingOrganization>> {
        return HttpService.fetch(endpoints.referentialService.organizations.continuousTrainingOrganizationUuid(uuid), {
            method: HttpMethod.PUT,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async archiveContinuousTrainingOrganization(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(
            endpoints.referentialService.organizations.continuousTrainingOrganizationArchive(uuid),
            {
                method: HttpMethod.PATCH,
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }

    async restoreContinuousTrainingOrganization(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(
            endpoints.referentialService.organizations.continuousTrainingOrganizationRestore(uuid),
            {
                method: HttpMethod.PATCH,
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }

    async getDrugTransferOrganizationReferential(): Promise<KyResponse<IDrugTransferOrganization[]>> {
        return HttpService.fetch(endpoints.referentialService.organizations.drugTransferOrganizationReferential(), {
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async postDrugTransferOrganization(
        json: IDrugTransferOrganization
    ): Promise<KyResponse<IDrugTransferOrganization>> {
        return HttpService.fetch(endpoints.referentialService.organizations.drugTransferOrganizationReferential(), {
            method: HttpMethod.POST,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async deleteDrugTransferOrganization(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(
            endpoints.referentialService.organizations.drugTransferOrganizationReferentialUuid(uuid),
            {
                method: HttpMethod.DELETE,
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }

    async putDrugTransferOrganization(json: IDrugTransferOrganization, uuid: string): Promise<KyResponse> {
        return HttpService.fetch(
            endpoints.referentialService.organizations.drugTransferOrganizationReferentialUuid(uuid),
            {
                method: HttpMethod.PUT,
                json,
                headers: {
                    loader: Loader.REQUEST,
                },
            }
        );
    }
    // DRUG

    async postHumanDrug(json: IHumanDrug): Promise<KyResponse> {
        return HttpService.fetch(endpoints.referentialService.humanDrugs.base, {
            method: HttpMethod.POST,
            json,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getTypeIdentifiant(use?: TypeIdentifiantUse): Promise<KyResponse<ITypeIdentifiant[]>> {
        return HttpService.fetch(endpoints.referentialService.drugs.typeIdentifiant(), {
            method: HttpMethod.GET,
            searchParams: formatParams({ use }),
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    async getProductStatus(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.referentialService.drugs.productStatus(), {
            method: HttpMethod.GET,
        });
    }
    async getDrugSynchro(): Promise<KyResponse<IReferentialSynchro>> {
        return HttpService.fetch(endpoints.referentialService.drugs.sync(), {
            method: HttpMethod.GET,
        });
    }

    async putDrugSynchro(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.referentialService.drugs.sync(), {
            method: HttpMethod.PUT,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    async getDrugs(payload: any): Promise<KyResponse<IDrug[]>> {
        return HttpService.fetch(endpoints.referentialService.drugs.base, {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
            headers: {
                loader: Loader.TABLE,
            },
        });
    }

    // LOCALIZATION

    async getCities(payload: any): Promise<KyResponse<ICity[]>> {
        return HttpService.fetch(endpoints.referentialService.localization.cities(), {
            searchParams: formatParams(payload),
            method: HttpMethod.GET,
        });
    }

    async getDepartments(): Promise<KyResponse<IDepartment[]>> {
        return HttpService.fetch(endpoints.referentialService.localization.departments(), {
            method: HttpMethod.GET,
        });
    }

    async getRegions(): Promise<KyResponse<IRegion[]>> {
        return HttpService.fetch(endpoints.referentialService.localization.regions(), {
            method: HttpMethod.GET,
        });
    }

    async getLocalizationSynchro(): Promise<KyResponse<IReferentialSynchro>> {
        return HttpService.fetch(endpoints.referentialService.localization.sync(), {
            method: HttpMethod.GET,
        });
    }

    async putLocalizationSynchro(): Promise<KyResponse> {
        return HttpService.fetch(endpoints.referentialService.localization.sync(), {
            method: HttpMethod.PUT,
            headers: {
                loader: Loader.REQUEST,
            },
        });
    }

    // settings

    async getDeliveryImportTemplateDate(): Promise<KyResponse<string>> {
        return HttpService.fetch(endpoints.referentialService.settings.deliveryImportTemplateDate(), {
            method: HttpMethod.GET,
        });
    }
}

export default new ReferentialService();
