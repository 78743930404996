// === Import: NPM
import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";
// === Import: LOCAL
import { HttpStatus } from "@/interfaces/global";
import NotificationService from "@/services/NotificationService";
import { IAlertBanner } from "../interface";

export interface UseNotificationInfo {
    alertBanners: IAlertBanner[];
    unreadNotificationCount: number;
    setUnreadNotificationCount: Dispatch<SetStateAction<number>>;
    getNotificationInfo: () => void;
}

export const notificationInfoContext = React.createContext<UseNotificationInfo>({
    alertBanners: [],
    unreadNotificationCount: 0,
    setUnreadNotificationCount: null,
    getNotificationInfo: null,
});

export const useNotificationInfo = (): UseNotificationInfo => {
    return useContext(notificationInfoContext);
};

export const ProvideNotificationInfo = ({ children }: PropsWithChildren) => {
    const notificationInfo = useProvideNotificationInfo();
    return <notificationInfoContext.Provider value={notificationInfo}>{children}</notificationInfoContext.Provider>;
};

const useProvideNotificationInfo = () => {
    const [alertBanners, setAlertBanners] = useState<IAlertBanner[]>([]);
    const [unreadNotificationCount, setUnreadNotificationCount] = useState<number>(0);

    const getNotificationInfo = async () => {
        const res = await NotificationService.getUserNotificationInfo();
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            setAlertBanners(data.banners);
            setUnreadNotificationCount(data.unreadNotificationCount);
        }
    };

    return {
        alertBanners,
        unreadNotificationCount,
        setUnreadNotificationCount,
        getNotificationInfo,
    };
};
