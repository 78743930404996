// === NPM
import React from "react";
import { Box } from "@mui/material";

export default function Indicators() {
    return (
        (<Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
            }}>
            <img
                src="/work_in_progress.png"
                style={{ width: 400, height: 400, opacity: 0.9 }}
                alt="En cours de construction"
            />
        </Box>)
    );
}
