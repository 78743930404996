// === NPM
import React, { useEffect } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
// === LOCAL
import { ReactComponent as Info } from "@/assets/icons/navbar/information.svg";
import PageContent from "@/components/generics/layout/PageContent";
import { colors } from "@/resources/CssConstant";
import DownloadCard from "./containers/DownloadCard";
import { useDownload } from "./useDownload";

export default function Downloads() {
    const { downloads, getAvailableDownloads } = useDownload();

    useEffect(() => {
        getAvailableDownloads();
    }, []);

    const renderWarning = () => (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                alignItems: "center",
                py: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: colors.primaryColor,
                }}
            >
                <Info />
            </Box>
            <Typography
                variant="bold"
                sx={{
                    color: colors.primaryColor,
                }}
            >
                Vos fichiers sont disponibles pendant 3 jours avant d'être supprimés.
            </Typography>
        </Stack>
    );

    const renderDownloadContent = () => {
        return downloads.length > 0 ? (
            <Grid container spacing={2}>
                {downloads.map((download) => (
                    <Grid
                        key={download.uuid}
                        size={{
                            xs: 12,
                            md: 6,
                            lg: 4,
                        }}
                    >
                        <DownloadCard download={download} />
                    </Grid>
                ))}
            </Grid>
        ) : (
            <Typography sx={{ display: "flex", justifyContent: "center" }} id="download-no-content">
                Aucun téléchargement trouvé.
            </Typography>
        );
    };

    return (
        <PageContent>
            <Stack
                spacing={3}
                sx={{
                    width: "100%",
                }}
            >
                <Typography variant="h4">Mes téléchargements</Typography>
                <Box>
                    {renderWarning()}
                    <Box
                        sx={{
                            py: 2,
                        }}
                    >
                        <Divider />
                    </Box>
                    {renderDownloadContent()}
                </Box>
            </Stack>
        </PageContent>
    );
}
