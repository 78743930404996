// === NPM
import { KyResponse } from "ky";
// === LOCAL
import {
    ICatalog,
    ICreateTraining,
    ITraining,
    ITrainingDpe,
    ITrainingStatus,
    ITrainingUserInformation,
} from "@/components/ContinuousTraining/interface";
import { IAttestationsVeterinaryFilters } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/AttestationsVeterinary/AttestationsVeterinary";
import { IInscriptionsVeterinaryFilters } from "@/components/HealthAccreditationTraining/containers/Sessions/containers/InscriptionsVeterinary/InscriptionsVeterinary";
import {
    ICatalogHealthAccreditationTrainingFilters,
    IHealthAccreditationTrainingCreate,
    IInscriptionsOvvtDdppFilters,
    IInscriptionVeterinary,
    INextSessionScheduled,
    IParticipationDdpp,
    IParticipationOvvtDdpp,
    IRegistration,
    IRegistrationCreate,
    ISession,
    ISessionCounts,
    ISessionCountsDdpp,
    ISessionCountsDdppProcess,
    ISessionCountsOvvt,
    ISessionCreate,
    ISessionDdppProcess,
    ISessionInfo,
    ISessionShortAdminDdppOvvt,
    ISessionShortVeterinary,
    ISessionsToProcessFilters,
    IViewFilters,
} from "@/components/HealthAccreditationTraining/interface";
import { ArrayOfTwo, HttpMethod, IExportFilename, Loader, SearchParams, UserTypeHeader } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import {
    IHealthAccreditationTraining,
    IHealthAccreditationTrainingShort,
    ITrainingReferential,
    ITrainingReferentialArchived,
    ITrainingType,
} from "@/interfaces/training";
import { CALYPSO_HEADERS } from "@/resources/AppConstant";
import { formatParams, objToQueryParams } from "@/resources/utils";
import { endpoints } from "./EndpointConstant";
import HttpService from "./HttpService";

class TrainingService {
    // REFERENTIALS
    async getTypes(): Promise<KyResponse<ITrainingReferential[]>> {
        return HttpService.fetch(endpoints.trainingService.referentials.types, {
            method: HttpMethod.GET,
            headers: { loader: Loader.TABLE },
        });
    }
    async postType(data: { label: string }): Promise<KyResponse<ITrainingReferential[]>> {
        return HttpService.fetch(endpoints.trainingService.referentials.types, {
            method: HttpMethod.POST,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteType(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.referentials.type(uuid), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    async putType(uuid: string, data: { label: string }): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.referentials.type(uuid), {
            method: HttpMethod.PUT,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getStatus(): Promise<KyResponse<ITrainingStatus[]>> {
        return HttpService.fetch(endpoints.trainingService.referentials.status, {
            method: HttpMethod.GET,
        });
    }

    async getFormats(): Promise<KyResponse<ITrainingReferentialArchived[]>> {
        return HttpService.fetch(endpoints.trainingService.referentials.formats, {
            method: HttpMethod.GET,
            headers: { loader: Loader.TABLE },
        });
    }

    async postFormat(data: { label: string }): Promise<KyResponse<ITrainingReferential[]>> {
        return HttpService.fetch(endpoints.trainingService.referentials.formats, {
            method: HttpMethod.POST,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async deleteFormat(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.referentials.format(uuid), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    async putFormat(uuid: string, data: { label: string }): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.referentials.format(uuid), {
            method: HttpMethod.PUT,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async archiveFormat(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.referentials.archiveFormat(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.REQUEST },
        });
    }

    async restoreFormat(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.referentials.restoreFormat(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.REQUEST },
        });
    }

    // CONTINUOUS-TRAINING

    async getUserTrainingInformations(): Promise<KyResponse<ITrainingUserInformation>> {
        return HttpService.fetch(endpoints.trainingService.continuousTraining.trainingInformations(), {
            method: HttpMethod.GET,
        });
    }

    async getContinuousTrainings(
        payload: SearchParams &
            Partial<{
                title: string;
                typeUuid: string[];
                formatUuid: string[];
                startDate: ArrayOfTwo[];
                ectsPoints: string;
                allTraings: string;
            }>
    ): Promise<KyResponse<ITraining[]>> {
        return HttpService.fetch(endpoints.trainingService.continuousTraining.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async postContinuousTraining(data: ICreateTraining, file: File): Promise<KyResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        formData.append("training", blob);
        formData.append("file", file);

        return HttpService.fetch(endpoints.trainingService.continuousTraining.base, {
            method: HttpMethod.POST,
            headers: { loader: Loader.REQUEST },
            body: formData,
        });
    }

    async putContinuousTraining(trainingUuid: string, data: ICreateTraining, file: File): Promise<KyResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        formData.append("training", blob);
        formData.append("file", file);

        return HttpService.fetch(endpoints.trainingService.continuousTraining.training(trainingUuid), {
            method: HttpMethod.PUT,
            body: formData,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getContinuousTrainingFile(trainingUuid: string, fileUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.continuousTraining.trainingFile(trainingUuid, fileUuid), {
            method: HttpMethod.GET,
            headers: { loader: Loader.BUTTON },
        });
    }

    async getContinuousTrainingDpeFile(
        dpeOrdinalNumber: string,
        trainingUuid: string,
        fileUuid: string
    ): Promise<KyResponse> {
        return HttpService.fetch(
            endpoints.trainingService.continuousTraining.dpeFile(dpeOrdinalNumber, trainingUuid, fileUuid),
            {
                method: HttpMethod.GET,
            }
        );
    }

    async deleteTraining(trainingUuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.continuousTraining.training(trainingUuid), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    // CATALOG

    async getContinuousTrainingCatalog(
        payload: SearchParams &
            Partial<{
                title: string;
                organization: string[];
                format: string[];
                type: string[];
                themes: string;
                species: string[];
                startDate: string[];
                departments: string[];
                postalCode: string[];
                municipalities: string[];
            }>
    ): Promise<KyResponse<ICatalog[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch(endpoints.trainingService.catalog.continuousTrainingCatalog(query), {
            method: HttpMethod.GET,
            headers: { loader: Loader.TABLE },
        });
    }

    async getDpeContinuousTraining(
        dpeOrdinalNumber: string,
        payload: { startDate?: string }
    ): Promise<KyResponse<ITrainingDpe[]>> {
        const query = objToQueryParams(payload);

        return HttpService.fetch(endpoints.trainingService.continuousTraining.dpes(dpeOrdinalNumber, query), {
            method: HttpMethod.GET,
            headers: { loader: Loader.TABLE },
        });
    }

    async exportTrainingCertificates(data: IExportFilename): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.exports.continuousTrainings(), {
            method: HttpMethod.POST,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    // HEALTH ACCREDITATION

    async getHealthAccreditationTrainings(
        payload: SearchParams & Partial<ICatalogHealthAccreditationTrainingFilters>
    ): Promise<KyResponse<IHealthAccreditationTrainingShort[]>> {
        return HttpService.fetch(endpoints.trainingService.healthAccreditationTraining.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getHealthAccreditationTraining(uuid: string): Promise<KyResponse<IHealthAccreditationTraining>> {
        return HttpService.fetch(endpoints.trainingService.healthAccreditationTraining.training(uuid), {
            method: HttpMethod.GET,
            headers: { loader: Loader.BUTTON },
        });
    }

    async postHealthAccreditationTraining(
        data: IHealthAccreditationTrainingCreate,
        files: File[]
    ): Promise<KyResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        files?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("training", blob);

        return HttpService.fetch(endpoints.trainingService.healthAccreditationTraining.base, {
            method: HttpMethod.POST,
            body: formData,
            headers: { loader: Loader.REQUEST },
        });
    }

    async putHealthAccreditationTraining(
        uuid: string,
        data: IHealthAccreditationTrainingCreate,
        files: File[]
    ): Promise<KyResponse> {
        const blob = new Blob([JSON.stringify(data)], {
            type: "application/json",
        });
        const formData = new FormData();
        files?.forEach((item) => {
            formData.append("files", item);
        });
        formData.append("training", blob);

        return HttpService.fetch(endpoints.trainingService.healthAccreditationTraining.training(uuid), {
            method: HttpMethod.PUT,

            body: formData,
            headers: { loader: Loader.REQUEST },
        });
    }

    async archiveHealthAccreditationTraining(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.healthAccreditationTraining.training(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getHealthAccreditationTrainingFile(uuid: string, fileUuid: string): Promise<KyResponse<File>> {
        return HttpService.fetch(endpoints.trainingService.healthAccreditationTraining.trainingFile(uuid, fileUuid), {
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationTrainingTypes(): Promise<KyResponse<ITrainingType[]>> {
        return HttpService.fetch(endpoints.trainingService.referentials.healthAccreditationTypes(), {
            method: HttpMethod.GET,
        });
    }

    async postHealthAccreditationTrainingSession(data: ISessionCreate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.sessions.base, {
            method: HttpMethod.POST,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async putHealthAccreditationTrainingSession(uuid: string, data: ISessionCreate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.sessions.session(uuid), {
            method: HttpMethod.PUT,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getHealthAccreditationTrainingSessions(
        payload: SearchParams & Partial<IViewFilters>
    ): Promise<KyResponse<ISessionShortVeterinary[]> | KyResponse<ISessionShortAdminDdppOvvt[]>> {
        return HttpService.fetch(endpoints.trainingService.sessions.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getHealthAccreditationTrainingSession(uuid: string): Promise<KyResponse<ISession>> {
        return HttpService.fetch(endpoints.trainingService.sessions.session(uuid), {
            method: HttpMethod.GET,
            headers: { loader: Loader.BUTTON },
        });
    }

    async cancelHealthAccreditationTrainingSession(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.sessions.cancel(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getHealthAccreditationTrainingSessionCounts(
        userType: UserTypeHeader.OVVT
    ): Promise<KyResponse<ISessionCountsOvvt>>;
    async getHealthAccreditationTrainingSessionCounts(
        userType: UserTypeHeader.DDPP
    ): Promise<KyResponse<ISessionCountsDdpp>>;
    async getHealthAccreditationTrainingSessionCounts(userType: UserTypeHeader): Promise<KyResponse<ISessionCounts>> {
        return HttpService.fetch(endpoints.trainingService.sessions.counts(), {
            method: HttpMethod.GET,
            headers: {
                [CALYPSO_HEADERS.USER_TYPE]: userType,
            },
        });
    }

    async getHealthAccreditationTrainingSessionDdppProcessCounts(): Promise<KyResponse<ISessionCountsDdppProcess>> {
        return HttpService.fetch(endpoints.trainingService.sessions.ddppCounts(), {
            method: HttpMethod.GET,
        });
    }

    async getParticipations(
        sessionUuid: string,
        context: "search",
        payload: SearchParams & Partial<IInscriptionsOvvtDdppFilters>
    ): Promise<KyResponse<IParticipationOvvtDdpp[]>>;
    async getParticipations(sessionUuid: string, context: "ddpp"): Promise<KyResponse<IParticipationDdpp[]>>;
    async getParticipations(
        sessionUuid: string,
        context: "search" | "ddpp",
        payload?: SearchParams & Partial<IInscriptionsOvvtDdppFilters>
    ): Promise<KyResponse<IParticipationOvvtDdpp[] | IParticipationDdpp[]>> {
        return HttpService.fetch(endpoints.trainingService.sessions.participations(sessionUuid), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: {
                [CALYPSO_HEADERS.CONTEXT_TYPE]: context,
                loader: Loader.TABLE,
            },
        });
    }

    async getAttendanceSheet(sessionUuid: string): Promise<KyResponse<File>> {
        return HttpService.fetch(endpoints.trainingService.sessions.attendanceSheet(sessionUuid), {
            method: HttpMethod.GET,
            headers: { loader: Loader.REQUEST },
        });
    }

    async postAttendanceSheet(sessionUuid: string, file: File): Promise<KyResponse> {
        const formData = new FormData();
        formData.append("file", file);

        return HttpService.fetch(endpoints.trainingService.sessions.attendanceSheet(sessionUuid), {
            method: HttpMethod.POST,
            body: formData,
            headers: { loader: Loader.REQUEST },
        });
    }

    async validateParticipation(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.participations.validate(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.BUTTON },
        });
    }

    async invalidateParticipation(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.participations.invalidate(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.BUTTON },
        });
    }

    async patchPresent(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.participations.present(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.BUTTON },
        });
    }

    async patchAbsent(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.participations.absent(uuid), {
            method: HttpMethod.PATCH,
            headers: { loader: Loader.BUTTON },
        });
    }

    async deleteRegistration(uuid: string): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.participations.participation(uuid), {
            method: HttpMethod.DELETE,
            headers: { loader: Loader.REQUEST },
        });
    }

    async postRegistration(data: IRegistrationCreate): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.participations.base, {
            method: HttpMethod.POST,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getRegistration(uuid: string, loader: boolean = true): Promise<KyResponse<IRegistration>> {
        return HttpService.fetch(endpoints.trainingService.participations.participation(uuid), {
            method: HttpMethod.GET,
            headers: { loader: loader && Loader.BUTTON },
        });
    }

    async putRegistration(uuid: string, data: Omit<IRegistrationCreate, "sessionUuid">): Promise<KyResponse> {
        return HttpService.fetch(endpoints.trainingService.participations.participation(uuid), {
            method: HttpMethod.PUT,
            json: data,
            headers: { loader: Loader.REQUEST },
        });
    }

    async getVeterinaryRegistrations(
        payload: SearchParams & Partial<IInscriptionsVeterinaryFilters>
    ): Promise<KyResponse<IInscriptionVeterinary[]>> {
        return HttpService.fetch(endpoints.trainingService.participations.base, {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getVeterinaryAttestations(
        payload: SearchParams & Partial<IAttestationsVeterinaryFilters>
    ): Promise<KyResponse<IInscriptionVeterinary[]>> {
        return HttpService.fetch(endpoints.trainingService.participations.validated(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }

    async getNextSessionScheduled(): Promise<KyResponse<INextSessionScheduled | null>> {
        return HttpService.fetch(endpoints.trainingService.participations.next(), {
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationInfo(): Promise<KyResponse<ISessionInfo>> {
        return HttpService.fetch(endpoints.trainingService.participations.info(), {
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationAttestation(uuid: string): Promise<KyResponse<File>> {
        return HttpService.fetch(endpoints.trainingService.participations.attestation(uuid), {
            method: HttpMethod.GET,
            headers: { loader: Loader.BUTTON },
        });
    }

    async getHorsePowers(): Promise<KyResponse<IReferential[]>> {
        return HttpService.fetch(endpoints.trainingService.referentials.horsePowers(), {
            method: HttpMethod.GET,
        });
    }

    async getHealthAccreditationTrainingSessionToProcessDdpp(
        payload: SearchParams & Partial<ISessionsToProcessFilters>
    ): Promise<KyResponse<ISessionDdppProcess[]>> {
        return HttpService.fetch(endpoints.trainingService.sessions.sessionsToProcess(), {
            method: HttpMethod.GET,
            searchParams: formatParams(payload),
            headers: { loader: Loader.TABLE },
        });
    }
}

export default new TrainingService();
