// === NPM
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { FileUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as ArrowLeft } from "@/assets/icons/actions/arrow-left.svg";
import { ReactComponent as Wrong } from "@/assets/icons/actions/cancel.svg";
import { ReactComponent as Download } from "@/assets/icons/actions/download.svg";
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import { ReactComponent as Valid } from "@/assets/icons/shared/valid.svg";
import { ReactComponent as Certificate } from "@/assets/icons/training/certificateIcon.svg";
import GenericButton from "@/components/generics/buttons/GenericButton";
import ImportDialog from "@/components/generics/dialogs/ImportDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import IconBannerText from "@/components/generics/IconBannerText";
import GenericTable from "@/components/generics/table/GenericTable";
import {
    IInscriptionsOvvtDdppFiltersFutureSession,
    IInscriptionsOvvtDdppFiltersPastSession,
    InscriptionStatus,
    IParticipationOvvtDdpp,
    ISession,
    PresenceStatus,
    SessionOutletContext,
} from "@/components/HealthAccreditationTraining/interface";
import SecondaryButton from "@/components/styled/SecondaryButton";
import { useDepartments } from "@/context/useDepartmentContext";
import { useProvideGlobal } from "@/context/useGlobalContext";
import useTimeout from "@/hooks/useTimeout";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { UserType } from "@/interfaces/user";
import { CALYPSO_HEADERS, defaultPagination, DocumentMimeTypeFile } from "@/resources/AppConstant";
import { statusColor } from "@/resources/CssConstant";
import { createPayload, getEnumKeyByValue, validateFile } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import TrainingService from "@/services/TrainingService";
import ViewSessionDialog from "../../ViewSessionDialog";

interface IInscriptionsTableProps {
    pastSession: boolean;
    setPastSession: Dispatch<SetStateAction<boolean>>;
    refresh: boolean;
    renderActionColumn: (params: GridRenderCellParams) => JSX.Element;
}

export default function InscriptionsTable({
    pastSession,
    setPastSession,
    refresh,
    renderActionColumn,
}: Readonly<IInscriptionsTableProps>) {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { sessionUuid } = useParams();

    const { departments } = useDepartments(); //departments sera utile lorsque la synchro avec les DPA sera faite
    const { horsePowers, species } = useOutletContext<SessionOutletContext>();
    const { loadingRequest, loadingButton } = useProvideGlobal();
    const loading = loadingRequest || loadingButton;

    const [participations, setParticipations] = useState<IParticipationOvvtDdpp[]>([]);
    const [session, setSession] = useState<{
        startDate: string;
        internalId: string;
        title: string;
        ddppInseeCode: string;
    }>(null);
    const [inputFilters, setInputFilters] = useState<
        IInscriptionsOvvtDdppFiltersPastSession | IInscriptionsOvvtDdppFiltersFutureSession
    >({
        veterinary: "",
        lastTrainingDate: [null, null],
        registrationStatus: [],
        presenceStatus: [],
    });
    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "veterinary", sort: "asc" }]);
    const [completeSession, setCompleteSession] = useState<ISession>(null);
    const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getParticipations();
    }, [pagination, sortModel, refresh]);

    useEffect(() => {
        if (sessionUuid && !session) {
            initSession();
        } else {
            setSession(location.state.session);
            setPastSession(
                DateTime.fromISO(location.state.session.startDate.slice(0, -5)) < DateTime.now().plus({ minutes: 30 })
            );
        }
    }, []);

    const getParticipations = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await TrainingService.getParticipations(sessionUuid, "search", payload);
        if (res.status === HttpStatus.OK) {
            setParticipations(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getSession = async () => {
        return await TrainingService.getHealthAccreditationTrainingSession(sessionUuid);
    };

    const initSession = async () => {
        const res = await getSession();
        if (res.status === HttpStatus.OK) {
            const data = await res.json();
            setSession({
                startDate: data.startDate,
                title: data.healthAccreditationTraining.title,
                internalId: data.internalId,
                ddppInseeCode: data.ddpp.department,
            });
            setPastSession(DateTime.fromISO(data.startDate.slice(0, -5)) < DateTime.now().plus({ minutes: 30 }));
        }
    };

    const handleDisplaySession = async () => {
        const res = await getSession();
        if (res.status === HttpStatus.OK) {
            setCompleteSession(await res.json());
        }
    };

    const downloadAttendanceSheetFile = async () => {
        const res = await TrainingService.getAttendanceSheet(sessionUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            saveAs(file, res.headers.get("content-disposition").split("filename=")[1].slice(1, -1));
        }
    };

    const importAttendanceSheet = async (file: File) => {
        const res = await TrainingService.postAttendanceSheet(sessionUuid, file);
        if (res.status === HttpStatus.NO_CONTENT) {
            setOpenImportDialog(false);
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const formatSpecies = (participation: IParticipationOvvtDdpp) => {
        return participation.speciesUuids.map((uuid) => species.find((s) => s.uuid === uuid)?.label).join(", ");
    };

    const renderStatusColumn = (params: GridRenderCellParams) => {
        if (pastSession === null) return;
        if (pastSession) {
            return (
                <Box width={100} display="flex" justifyContent="center">
                    {PresenceStatus[params.row.presenceStatus] === PresenceStatus.UNDEFINED && (
                        <IconBannerText
                            icon={<Info />}
                            message={PresenceStatus.UNDEFINED}
                            color="black"
                            backgroundColor={statusColor.infoBackground}
                        />
                    )}
                    {PresenceStatus[params.row.presenceStatus] === PresenceStatus.PRESENT && (
                        <IconBannerText
                            icon={<Valid />}
                            message={PresenceStatus.PRESENT}
                            color={statusColor.successText}
                            backgroundColor={statusColor.successBackground}
                        />
                    )}
                    {PresenceStatus[params.row.presenceStatus] === PresenceStatus.ABSENT && (
                        <IconBannerText
                            icon={<Wrong />}
                            message={PresenceStatus.ABSENT}
                            color={statusColor.errorText}
                            backgroundColor={statusColor.errorBackground}
                        />
                    )}
                </Box>
            );
        } else {
            return (
                <Box width={100} display="flex" justifyContent="center">
                    {InscriptionStatus[params.row.registrationStatus] === InscriptionStatus.PRE_REGISTERED ? (
                        <IconBannerText
                            icon={<Info />}
                            message={InscriptionStatus.PRE_REGISTERED}
                            color={statusColor.warningText}
                            backgroundColor={statusColor.warningBackground}
                        />
                    ) : (
                        <IconBannerText
                            icon={<Valid />}
                            message={InscriptionStatus.REGISTERED}
                            color="black"
                            backgroundColor={statusColor.infoBackground}
                        />
                    )}
                </Box>
            );
        }
    };

    const columns: GridColDef[] = [
        {
            field: "veterinary",
            flex: 1,
            headerName: "Vétérinaire",
        },
        {
            field: "lastTrainingDate",
            flex: 1,
            headerName: "Date de dernière formation",
            valueGetter: (params: GridRenderCellParams) =>
                params.row.lastTrainingDate
                    ? DateTime.fromISO(params.row.lastTrainingDate).toLocaleString()
                    : "Inexistante",
        },
        {
            field: "species",
            flex: 1,
            headerName: "Espèces",
            valueGetter: (params: GridRenderCellParams) => formatSpecies(params.row),
            sortable: false,
        },
        {
            field: "distance",
            flex: 1,
            headerName: "Distance",
            valueGetter: (params: GridRenderCellParams) => `${params.row.distance}km`,
        },
        {
            field: "horsePower",
            flex: 1,
            headerName: "Chevaux fiscaux",
            valueGetter: (params: GridRenderCellParams) =>
                horsePowers.find((hp) => hp.key === params.row.horsePower)?.label,
        },
        {
            field: pastSession ? "presenceStatus" : "registrationStatus",
            flex: 1,
            headerName: "Statut",
            renderCell: renderStatusColumn,
        },
        {
            ...ActionsColumnProps,
            width: 100,
            renderCell: renderActionColumn,
        },
    ];

    const filterConfigurations: FilterConfigurations<
        IInscriptionsOvvtDdppFiltersPastSession | IInscriptionsOvvtDdppFiltersFutureSession
    > = {
        veterinary: { type: FilterType.INPUT, label: "Vétérinaire" },
        lastTrainingDate: { type: FilterType.DATEPICKER, label: "Date de dernière formation" },
        ...(!pastSession
            ? {
                  registrationStatus: {
                      type: FilterType.SELECT,
                      label: "Statut",
                      values: Object.entries(InscriptionStatus)
                          .filter(
                              ([key]) => key !== getEnumKeyByValue(InscriptionStatus, InscriptionStatus.UNREGISTERED)
                          )
                          .map(([key, label]) => ({ key, label })),
                  },
              }
            : {
                  presenceStatus: {
                      type: FilterType.SELECT,
                      label: "Statut",
                      values: Object.entries(PresenceStatus).map(([key, label]) => ({ key, label })),
                  },
              }),
    };

    return (
        <>
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Box>
                    <LoadingButton
                        onClick={() => navigate(routerLinks.healthAccreditationTraining.sessions.view())}
                        startIcon={<ArrowLeft />}
                    >
                        Revenir aux sessions de formation
                    </LoadingButton>
                </Box>
                <Typography variant="h4">{`Gestion de la session ${session?.internalId} - ${session?.title}`}</Typography>
                <Box display="flex" justifyContent="space-between">
                    <SecondaryButton onClick={handleDisplaySession} variant="outlined" loading={loading}>
                        Voir le détail de la session
                    </SecondaryButton>
                    <Stack spacing={1} direction="row">
                        {auth.userInfo.type === UserType.DDPP &&
                            participations.some(
                                (i) => i.departmentInseeCode === auth.userInfo.properties.inseeCode
                            ) && (
                                <GenericButton
                                    label="Générer et signer les attestations de mes vétérinaires"
                                    onClick={() => alert("todo when yousign is implemented")}
                                    startIcon={<Certificate />}
                                    loading={loading}
                                />
                            )}

                        {/* {auth.userInfo.properties.inseeCode === session?.ddppInseeCode && ( */}
                        <>
                            <GenericButton
                                label="Télécharger la feuille d'émargement vierge"
                                onClick={downloadAttendanceSheetFile}
                                startIcon={<Download />}
                                loading={loading}
                            />
                            <GenericButton
                                label="Importer la feuille d'émargement"
                                onClick={() => setOpenImportDialog(true)}
                                startIcon={<FileUploadOutlined />}
                                loading={loading}
                            />
                        </>
                        {/* )} */}
                    </Stack>
                </Box>
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                veterinary: "",
                                lastTrainingDate: [null, null],
                                registrationStatus: [],
                                presenceStatus: [],
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <GenericTable
                            rows={participations}
                            columns={columns}
                            getRowId={(row) => row.uuid}
                            onPageSizeChange={handlePageSizeChange}
                            onPageChange={handlePageChange}
                            page={pagination.page}
                            pageSize={pagination.pageSize}
                            autoHeight
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            rowCount={rowCount}
                            onSortModelChange={(model) => setSortModel(model)}
                            sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                            filterMode="server"
                        />
                    </CardContent>
                </Card>
            </Stack>
            {completeSession && (
                <ViewSessionDialog session={completeSession} onClose={() => setCompleteSession(null)} />
            )}

            {openImportDialog && (
                <ImportDialog
                    onClose={() => setOpenImportDialog(false)}
                    onValid={importAttendanceSheet}
                    validateFile={validateFile}
                    title="Import de la feuille d'émargement"
                    acceptedFiles={DocumentMimeTypeFile}
                />
            )}
        </>
    );
}
