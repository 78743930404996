// === NPM
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
// === LOCAL
import { ReactComponent as Add } from "@/assets/icons/actions/plus.svg";
import DeleteAction from "@/components/generics/actions/DeleteAction";
import DownloadAction from "@/components/generics/actions/DownloadAction";
import EditAction from "@/components/generics/actions/EditAction";
import IconActionButton from "@/components/generics/actions/IconActionButton";
import ViewAction from "@/components/generics/actions/ViewAction";
import GenericButton from "@/components/generics/buttons/GenericButton";
import ImportDialog from "@/components/generics/dialogs/ImportDialog";
import { FilterConfigurations, GenericFilters } from "@/components/generics/filters/GenericFilters";
import PermissionsCheck from "@/components/generics/PermissionsCheck";
import GenericTable from "@/components/generics/table/GenericTable";
import { useDepartments } from "@/context/useDepartmentContext";
import useTimeout from "@/hooks/useTimeout";
import { IWorkshopRelation } from "@/interfaces/farm";
import { ActionsColumnProps, FilterType, HttpStatus, IPagination, SortDirection } from "@/interfaces/global";
import { IReferential } from "@/interfaces/referential";
import { CALYPSO_HEADERS, defaultPagination, DocumentMimeTypeFile } from "@/resources/AppConstant";
import { Preference, UserSituation } from "@/resources/PermissionConstant";
import { createPayload, validateFile } from "@/resources/utils";
import { routerLinks } from "@/routers/RouterConstant";
import { useAuth } from "@/routers/useAuth";
import FarmService from "@/services/FarmService";
import { IClosureRelation } from "../../interface";
import CloseRelationDialog from "./containers/CloseRelationDialog";
import ViewRelationDialog from "./containers/ViewRelationDialog";

export interface ViewRelationFilters {
    dpeId: string;
    dpeName: string;
    farmId: string;
    farmName: string;
    workshopCity: string;
    workshopDepartmentCode: string[];
    workshopId: string;
    workshopClosingDate: string[];
    openingDate: string[];
    closingDate: string[];
    isClosed: string;
}

export default function ViewRelation() {
    const navigate = useNavigate();
    const auth = useAuth();
    const { departments } = useDepartments();
    const [inputFilters, setInputFilters] = useState<ViewRelationFilters>({
        dpeId: "",
        dpeName: "",
        farmId: "",
        farmName: "",
        workshopCity: "",
        workshopDepartmentCode: [],
        workshopId: "",
        workshopClosingDate: [],
        openingDate: [],
        closingDate: [],
        isClosed: "false",
    });
    const [relations, setRelations] = useState<IWorkshopRelation[]>([]);
    const [closureReasons, setClosureReasons] = useState<IReferential[]>([]);

    const [pagination, setPagination] = useState<IPagination>(defaultPagination);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [openCerfaDialog, setOpenCerfaDialog] = useState<boolean>(false);
    const [openCloseRelationDialog, setOpenCloseRelationDialog] = useState<boolean>(false);
    const [openViewRelationDialog, setOpenViewRelationDialog] = useState<boolean>(false);
    const [selectedRelationUuid, setSelectedRelationUuid] = useState<string>("");
    const [updateCerfa, setUpdateCerfa] = useState<boolean>(false);
    const [previousCerfaFile, setPreviousCerfaFile] = useState<File>(null);

    useTimeout(() => setPagination((prev) => ({ ...prev, page: 0 })), [inputFilters]);

    useEffect(() => {
        getWorkshops();
    }, [pagination, sortModel]);

    useEffect(() => {
        getClosureReasons();
    }, []);

    const getClosureReasons = async () => {
        const res = await FarmService.getClosureReasons();
        if (res.status === HttpStatus.OK) {
            setClosureReasons(await res.json());
        }
    };

    const getWorkshops = async () => {
        const payload = {
            page: pagination.page,
            size: pagination.pageSize,
            sorts: sortModel.map((s) => `${s.field},${s.sort}`),
            ...createPayload(inputFilters),
        };
        const res = await FarmService.getWorkshopLinks(payload);
        if (res.status === HttpStatus.OK) {
            setRelations(await res.json());
            setRowCount(+res.headers.get(CALYPSO_HEADERS.TABLE_COUNT));
        }
    };

    const getFile = async (workshopId: string, fileUuid: string) => {
        const res = await FarmService.getWorkshopFile(workshopId, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            saveAs(file, res.headers.get("content-disposition").split("filename=")[1].slice(1, -1));
        }
    };

    const importCerfa = async (file: File) => {
        const res = await FarmService.patchCerfa(selectedRelationUuid, file);
        if (res.status === HttpStatus.OK) {
            toast.success("Le CERFA a été ajouté avec succès");
            setOpenCerfaDialog(false);
            const data = await res.json();
            setRelations((prev) =>
                prev.map((relation) =>
                    relation.uuid === selectedRelationUuid ? { ...relation, cerfaFileUuid: data } : relation
                )
            );
        }
        setUpdateCerfa(false);
    };

    const retrieveCerfa = async (workshopId: string, fileUuid: string) => {
        const res = await FarmService.getWorkshopFile(workshopId, fileUuid);
        if (res.status === HttpStatus.OK) {
            const file = await res.blob();
            const fileName = res.headers.get("content-disposition").split("filename=")[1].slice(1, -1);
            const newFile = new File([file], fileName, {
                type: res.headers.get("content-type"),
            });
            setPreviousCerfaFile(newFile);
            setSelectedRelationUuid(workshopId);
            setOpenCerfaDialog(true);
            setUpdateCerfa(true);
        }
    };

    const handleCloseRelation = async (closure: IClosureRelation) => {
        const res = await FarmService.patchClosure(selectedRelationUuid, closure);
        if (res.status === HttpStatus.NO_CONTENT) {
            toast.success("La relation a été clôturée avec succès");
            getWorkshops();
            auth.introspection();
            setOpenCloseRelationDialog(false);
            setSelectedRelationUuid("");
        }
    };

    const handlePageSizeChange = (pageSize: number) => {
        setPagination({ pageSize, page: 0 });
    };

    const handlePageChange = (page: number) => {
        setPagination({ ...pagination, page });
    };

    const columns = [
        {
            field: "dpeId",
            headerName: "Numéro ordinal DPE",
            flex: 1,
        },
        {
            field: "dpeName",
            headerName: "Nom DPE",
            flex: 1,
        },
        {
            field: "farmId",
            headerName: "Identifiant établissement",
            flex: 1,
        },
        {
            field: "farmName",
            headerName: "Nom établissement",
            flex: 1,
        },
        {
            field: "workshopId",
            headerName: "Identifiant atelier",
            flex: 1,
        },
        {
            field: "workshopDepartmentCode",
            headerName: "Département atelier",
            width: 150,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.workshopDepartmentCode
                    ? `${params.row.workshopDepartmentCode} - ${params.row.workshopDepartmentName}`
                    : "",
        },
        {
            field: "workshopCity",
            headerName: "Commune atelier",
            flex: 1,
        },
        {
            field: "workshopClosingDate",
            headerName: "Date de clôture atelier",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.workshopClosingDate ? DateTime.fromISO(params.row.workshopClosingDate).toLocaleString() : "",
        },
        {
            field: "openingDate",
            headerName: "Date d'ouverture de la relation VS",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.openingDate ? DateTime.fromISO(params.row.openingDate).toLocaleString() : "",
        },
        {
            field: "closingDate",
            headerName: "Date de clôture de la relation VS",
            flex: 1,
            valueGetter: (params: GridRenderCellParams) =>
                params.row.closingDate ? DateTime.fromISO(params.row.closingDate).toLocaleString() : "",
        },
        {
            field: "closed",
            headerName: "Clôturée",
            flex: 0.7,
            valueGetter: (params: GridRenderCellParams) => (params.row.closingDate ? "Oui" : "Non"),
            sortable: false,
        },
        {
            ...ActionsColumnProps,
            width: 200,
            renderCell: (params) => (
                <>
                    <ViewAction
                        title="Détails de la relation"
                        onClick={() => {
                            setSelectedRelationUuid(params.row.uuid);
                            setOpenViewRelationDialog(true);
                        }}
                    />
                    {!params.row.closingDate && (
                        <Box>
                            {params.row.cerfaFileUuid ? (
                                <>
                                    <PermissionsCheck
                                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                        preferences={[Preference.IAHP]}
                                    >
                                        <EditAction
                                            title="Modifier le CERFA"
                                            onClick={() => {
                                                retrieveCerfa(params.row.uuid, params.row.cerfaFileUuid);
                                            }}
                                        />
                                    </PermissionsCheck>
                                    <DownloadAction
                                        title="Télécharger le CERFA"
                                        onClick={() => getFile(params.row.uuid, params.row.cerfaFileUuid)}
                                    />
                                </>
                            ) : (
                                <PermissionsCheck
                                    requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                                    preferences={[Preference.IAHP]}
                                >
                                    <IconActionButton
                                        icon={<Add />}
                                        title="Ajouter un document CERFA"
                                        onClick={() => {
                                            setSelectedRelationUuid(params.row.uuid);
                                            setOpenCerfaDialog(true);
                                        }}
                                    />
                                </PermissionsCheck>
                            )}
                            <DeleteAction
                                title="Clôturer la relation"
                                onClick={() => {
                                    setSelectedRelationUuid(params.row.uuid);
                                    setOpenCloseRelationDialog(true);
                                }}
                            />
                        </Box>
                    )}
                </>
            ),
        },
    ];

    const filterConfigurations: FilterConfigurations<ViewRelationFilters> = {
        dpeId: { label: "Numéro ordinal DPE", type: FilterType.INPUT },
        dpeName: { label: "Nom du DPE", type: FilterType.INPUT },
        farmId: { label: "Identifiant établissement", type: FilterType.INPUT },
        farmName: { label: "Nom établissement", type: FilterType.INPUT },
        workshopId: { label: "Identifiant atelier", type: FilterType.INPUT },
        workshopDepartmentCode: {
            label: "Département atelier",
            type: FilterType.SELECT_AUTOCOMPLETE,
            values: departments.map((d) => ({ label: `${d.inseeCode} - ${d.name}`, key: d.inseeCode })),
        },
        workshopCity: { label: "Commune atelier", type: FilterType.INPUT },
        workshopClosingDate: { label: "Date de clôture atelier", type: FilterType.DATEPICKER },
        openingDate: { label: "Date d'ouverture de la relation VS", type: FilterType.DATEPICKER },
        closingDate: { label: "Date de clôture de la relation VS", type: FilterType.DATEPICKER },
        isClosed: {
            label: "Statut de la relation",
            type: FilterType.SINGLE_SELECT,
            values: [
                { key: "true", label: "Clôturée" },
                { key: "false", label: "Non clôturée" },
            ],
        },
    };

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    height: "100%",
                    width: "100%",
                }}
            >
                <Typography variant="h4">Suivi de mes élevages</Typography>
                <Box
                    sx={{
                        justifyContent: "flex-end",
                        display: "flex",
                    }}
                >
                    <PermissionsCheck
                        requiredPermissions={[UserSituation.REGISTERED_IN_PRACTICE]}
                        preferences={[Preference.IAHP]}
                    >
                        <GenericButton
                            startIcon={<Add />}
                            label="Déclarer une relation VS"
                            onClick={() => navigate(routerLinks.iahp.farm.declareRelation())}
                            id="create-user-btn"
                        />
                    </PermissionsCheck>
                </Box>
                <Card>
                    <CardContent>
                        <GenericFilters
                            inputFilters={inputFilters}
                            filterConfigurations={filterConfigurations}
                            initialValues={{
                                dpeId: "",
                                dpeName: "",
                                farmId: "",
                                farmName: "",
                                workshopCity: "",
                                workshopDepartmentCode: [],
                                workshopId: "",
                                workshopClosingDate: [],
                                openingDate: [],
                                closingDate: [],
                                isClosed: "",
                            }}
                            setInputFilters={setInputFilters}
                        />
                        <Box id="user-table">
                            <GenericTable
                                rows={relations}
                                columns={columns}
                                getRowId={(row) => row.uuid}
                                page={pagination.page}
                                rowCount={rowCount}
                                pageSize={pagination.pageSize}
                                onPageSizeChange={handlePageSizeChange}
                                onPageChange={handlePageChange}
                                sortingMode="server"
                                paginationMode="server"
                                sortModel={sortModel}
                                onSortModelChange={(model) => setSortModel(model)}
                                sortingOrder={[SortDirection.ASC, SortDirection.DESC]}
                                filterMode="server"
                                autoHeight
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Stack>
            {openCerfaDialog && (
                <ImportDialog
                    previousFiles={updateCerfa ? (previousCerfaFile ? [previousCerfaFile] : []) : []}
                    onValid={importCerfa}
                    onClose={() => {
                        setOpenCerfaDialog(false);
                        setPreviousCerfaFile(null);
                    }}
                    title={updateCerfa ? "Modifier le CERFA" : "Ajouter un document CERFA"}
                    validateFile={validateFile}
                    acceptedFiles={DocumentMimeTypeFile}
                    info="Les formats acceptés sont .docx, .jpg, .pdf et la taille des fichiers ne doit pas excéder 10 Mo."
                />
            )}
            {openCloseRelationDialog && (
                <CloseRelationDialog
                    onClose={() => setOpenCloseRelationDialog(false)}
                    onConfirm={handleCloseRelation}
                    relation={relations.find((relation) => relation.uuid === selectedRelationUuid)}
                    closureReasons={closureReasons}
                />
            )}
            {openViewRelationDialog && (
                <ViewRelationDialog
                    onClose={() => setOpenViewRelationDialog(false)}
                    relation={relations.find((relation) => relation.uuid === selectedRelationUuid)}
                    getFile={getFile}
                    closureReasons={closureReasons}
                />
            )}
        </>
    );
}
