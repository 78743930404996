// === NPM
import React, { useEffect, useState } from "react";
import { PathMatch, useMatch } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import { Captions, Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import { Box, Dialog, Typography } from "@mui/material";
// === LOCAL
import { routerLinks } from "@/routers/RouterConstant";
import LocalStorageService from "@/services/LocalStorageService";

export const MIME_TYPE = {
    png: "image/png",
    jpeg: "image/jpeg",
};

export default function FilePreviewer() {
    const [type, setType] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const urlMatch: PathMatch<string> = useMatch(routerLinks.filePreview.path);

    const getFile = () => {
        setLoading(true);
        const myFileName = urlMatch.params?.fileName;
        const myUrl = LocalStorageService.getLocaleStorageItem(myFileName);
        const fileType = LocalStorageService.getLocaleStorageItem(`${myFileName}Type`);

        setFileName(myFileName);
        setUrl(myUrl);
        setType(fileType);
        setLoading(false);
    };

    useEffect(() => {
        getFile();
        LocalStorageService.removeLocaleStorageItem(fileName);
        LocalStorageService.removeLocaleStorageItem(`${fileName}Type`);
    }, []);

    const renderFile = () => {
        return type === MIME_TYPE.png || type === MIME_TYPE.jpeg ? (
            <Lightbox
                styles={{ slide: { fontSize: 42 } }}
                plugins={[Captions, Zoom]}
                open={true}
                slides={[
                    {
                        src: url,
                        description: fileName,
                        width: 500,
                    },
                ]}
                render={{
                    buttonClose: () => null,
                    buttonPrev: () => null,
                    buttonNext: () => null,
                }}
                zoom={{ zoomInMultiplier: 1.2, scrollToZoom: true, maxZoomPixelRatio: 5 }}
                captions={{ descriptionTextAlign: "center" }}
            />
        ) : (
            <Dialog open fullScreen>
                <embed style={{ height: "100%", width: "100%" }} src={url} />
            </Dialog>
        );
    };

    const rendercontent = () =>
        !url ? (
            <Box
                sx={{
                    textAlign: "center",
                    mt: 2,
                    fontWeight: 500,
                    fontSize: 22
                }}>
                Une erreur s'est produite lors du chargement du fichier
            </Box>
        ) : (
            renderFile()
        );

    return (
        (<Box
            sx={{
                display: "flex",
                height: "100%",
                width: "100%",
                flexDirection: "column",
                gap: 1
            }}>
            <Typography variant="h5">{fileName}</Typography>
            {loading ? (
                <Box
                    sx={{
                        textAlign: "center",
                        mt: 2,
                        fontWeight: 500,
                        fontSize: 22
                    }}>
                    Chargement du fichier en cours
                </Box>
            ) : (
                rendercontent()
            )}
        </Box>)
    );
}
